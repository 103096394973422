import { useDispatch } from "react-redux";
import { HttpService } from "services";
import { IHelperText } from "interfaces/IHelperText";
import { showLoadingSpinner, hideLoadingSpinner, showErrorMessage, hideErrorMessage } from "components/common";
import { addupdateHelperDataItem, deleteHelperData, deleteMultipleHelperData, getHelperData } from "./helpPageSlice";
import { ErrorRegions } from "constant";

export default class HelpPageService {
  public apiEndpointHelperPage = `/api/HelpPage`;
  dispatch = useDispatch();

  public async getHelperPage(showLoader: boolean): Promise<IHelperText[]> {
    showLoader && this.dispatch(showLoadingSpinner());
    try {
      const order: IHelperText[] = await HttpService.get(`${this.apiEndpointHelperPage}`);
      this.dispatch(getHelperData(order));
      showLoader && this.dispatch(hideLoadingSpinner());
    } catch (error) {
      showLoader && this.dispatch(hideLoadingSpinner());
      this.dispatch(showErrorMessage({ error, errorOriginationKey: ErrorRegions.HelpPage }));
      setTimeout(() => {
        this.dispatch(hideErrorMessage(ErrorRegions.HelpPage));
      }, 5000);
    }
    return;
  }

  public async getHelperPageById(id: string): Promise<IHelperText> {
    this.dispatch(showLoadingSpinner());
    let order: IHelperText;
    try {
      order = await HttpService.get(`${this.apiEndpointHelperPage}/${id}`);
      this.dispatch(hideLoadingSpinner());
    } catch (error) {
      this.dispatch(hideLoadingSpinner());
      this.dispatch(showErrorMessage({ error, errorOriginationKey: ErrorRegions.HelpPage }));
      setTimeout(() => {
        this.dispatch(hideErrorMessage(ErrorRegions.HelpPage));
      }, 5000);
    }
    return order;
  }

  public async createHelperPage(newhelperPage: any) {
    this.dispatch(showLoadingSpinner());
    try {
      const response = await HttpService.post(`${this.apiEndpointHelperPage}`, newhelperPage);
      this.dispatch(addupdateHelperDataItem(response));
      this.dispatch(hideLoadingSpinner());
    } catch (error) {
      this.dispatch(hideLoadingSpinner());
      this.dispatch(showErrorMessage({ error, errorOriginationKey: ErrorRegions.HelpPage }));
      setTimeout(() => {
        this.dispatch(hideErrorMessage(ErrorRegions.HelpPage));
      }, 5000);
    }
    return;
  }

  public async updateHelperPage(newhelperPage: any) {
    this.dispatch(showLoadingSpinner());
    try {
      const response = await HttpService.put(`${this.apiEndpointHelperPage}`, newhelperPage);
      this.dispatch(addupdateHelperDataItem(newhelperPage));
      this.dispatch(hideLoadingSpinner());
    } catch (error) {
      this.dispatch(hideLoadingSpinner());
      this.dispatch(showErrorMessage({ error, errorOriginationKey: ErrorRegions.HelpPage }));
      setTimeout(() => {
        this.dispatch(hideErrorMessage(ErrorRegions.HelpPage));
      }, 5000);
    }
    return;
  }

  public async deleteHelperPage(id: any): Promise<any> {
    this.dispatch(showLoadingSpinner());
    try {
      const response = await HttpService.delete(`${this.apiEndpointHelperPage}/${id}`);
      this.dispatch(deleteHelperData(id));
      this.dispatch(hideLoadingSpinner());
    } catch (error) {
      this.dispatch(hideLoadingSpinner());
      this.dispatch(showErrorMessage({ error, errorOriginationKey: ErrorRegions.HelpPage }));
      setTimeout(() => {
        this.dispatch(hideErrorMessage(ErrorRegions.HelpPage));
      }, 5000);
    }
    return;
  }

  public async deleteMultipleHelperPage(ids: number[]): Promise<any> {
    this.dispatch(showLoadingSpinner());
    try {
      const response = await HttpService.delete(`${this.apiEndpointHelperPage}`, ids);
      this.dispatch(deleteMultipleHelperData(ids));
      this.dispatch(hideLoadingSpinner());
    } catch (error) {
      this.dispatch(hideLoadingSpinner());
      this.dispatch(showErrorMessage({ error, errorOriginationKey: ErrorRegions.HelpPage }));
      setTimeout(() => {
        this.dispatch(hideErrorMessage(ErrorRegions.HelpPage));
      }, 5000);
    }
    return;
  }
}
