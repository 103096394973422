import React from "react";
import { SearchBox, ChoiceGroup, IChoiceGroupOption, IconButton, IIconProps, ActionButton } from "@fluentui/react";
import styles from "./Dashboard.module.scss";
import { choiceGroupStyle, searchBoxStyles } from "./ListViewConstant";
import { LinkToHome } from "components/common";
import { useWindowResize } from "hooks";
import { TextConstants } from "constant";

interface IViewHeaderProps {
  title: string;
  showRefreshButton: boolean;
  onClickRefreshData?: () => void;
}

export const ActionHeader = (props: IViewHeaderProps) => {
  const { width } = useWindowResize();
  const { title, showRefreshButton, onClickRefreshData } = props;
  return (
    <div className={styles.filterActionBar}>
      <div className={styles.filterActionBarTopActions}>
        <LinkToHome className={styles.linkToHome} forceShowButton={true} />
        {
        showRefreshButton && <ActionButton
          className={styles.refreshButton}
          iconProps={{ iconName: "Refresh" }}
          allowDisabledFocus
          onClick={onClickRefreshData}
          text={width <= 1200 ? null : TextConstants.Common.Label_Button_Refresh}
          title={TextConstants.Common.Label_Button_Refresh}
        >
        </ActionButton>
      }
      </div>
      
      <div><h2 className={styles.heading}>{title}</h2></div>
      


    </div>
  );
};
