import { IColumn, IContextualMenuItem, IDetailsRowProps, DetailsRow, IRenderFunction } from "@fluentui/react";
import React from "react";
import { IFilterOption } from "../interfaces/IFilterOption";

export const onColumnClick = (refColumns: any, column: IColumn, refItems: any) => {
  const newColumns: IColumn[] = refColumns.current.slice();
  const currColumn: IColumn = newColumns.filter((currCol) => column.key === currCol.key)[0];
  newColumns.forEach((newCol: IColumn) => {
    if (newCol === currColumn) {
      currColumn.isSortedDescending = !currColumn.isSortedDescending;
      currColumn.isSorted = true;
    } else {
      newCol.isSorted = false;
      newCol.isSortedDescending = true;
    }
  });

  const newItems = copyAndSort(refItems.current, currColumn.fieldName!, currColumn.isSortedDescending);
  return newItems;
};

export function copyAndSort(items: any[], columnKey: string, isSortedDescending?: boolean) {
  const key = columnKey as keyof any;
  return items.slice(0).sort((a: any, b: any) => ((isSortedDescending ? a[key] < b[key] : a[key] > b[key]) ? 1 : -1));
}

export const getContextualSchema = (column: any, _onColumnClick: any, _onFilterClick: any): IContextualMenuItem[] => {
  let items: IContextualMenuItem[] = [];
  if (column.fieldName !== "createdOn" && column.fieldName !== "modifiedOn" && column.fieldName !== "assignmentDate") {
    items = [
      {
        key: "aToZ",
        name: "Sort",
        iconProps: { iconName: "Sort" },
        onClick: (
          ev?: React.MouseEvent<HTMLElement, MouseEvent> | React.KeyboardEvent<HTMLElement>,
          item?: IContextualMenuItem
        ) => _onColumnClick(ev, column),
      },
    ];
  } else {
    items = [
      {
        key: "oldToNew",
        name: "Sort",
        iconProps: { iconName: "Sort" },
        onClick: (
          ev?: React.MouseEvent<HTMLElement, MouseEvent> | React.KeyboardEvent<HTMLElement>,
          item?: IContextualMenuItem
        ) => _onColumnClick(ev, column),
      },
    ];
  }
  if (
    column.fieldName === "company" ||
    column.fieldName === "requestTitle" ||
    column.fieldName === "overallStatus" ||
    column.fieldName === "requester" ||
    column.fieldName === "createdBy" ||
    column.fieldName === "modifiedBy" ||
    column.fieldName === "tasks" ||
    column.fieldName === "brand"
  ) {
    items.push({
      key: "filterBy",
      name: "Filter by ", // + column.name,
      canCheck: true,
      iconProps: { iconName: "Filter" },
      checked: column.isFiltered,
      onClick: (ev?: React.MouseEvent<HTMLElement, MouseEvent> | React.KeyboardEvent<HTMLElement>, item?: IContextualMenuItem) =>
        _onFilterClick(column, ev, item),
    });
  }
  return items;
};

export const getValuesForFilterByOption = (data: any, filterBy: any) => {
 // let results = data.map((d: any) => (d[filterBy.fieldName] !== undefined ? d[filterBy.fieldName] : ""));
  let final:any=[];
  let results = data.map((d: any) => {
    if(typeof(d[filterBy.fieldName]) == "object"){
      if(d[filterBy.fieldName] && d[filterBy.fieldName].length > 0){
        d[filterBy.fieldName].forEach((item:any) => {
          if(final.length == 0){
            final.push(item['name']);
          }
          else if(final.indexOf(item['name']) == -1){
            final.push(item['name']);
          }
        });
      }
      
    }
    else{
      return d[filterBy.fieldName] !== undefined ? d[filterBy.fieldName] : "";
    }
  });
  if(final.length > 0){
    return [...new Set(final.sort())];
  }
  else{
    return [...new Set(results.sort())];
  }
  // let uniqueArray = [...new Set(results)];
  //return uniqueArray;
};

export const getFilterItemsForChoiceOptions = (option: any, items: any[]) => {
  let filterItemsArr: any[] = [];
  if (option === "Draft") {
    filterItemsArr = items.reduce((filterItemsArr, val) => {
      if (val["overallStatus"] === "Draft") {
        filterItemsArr.push(val);
      }
      return filterItemsArr;
    }, []);
  } else if (option === "All") {
    filterItemsArr = items.reduce((filterItemsArr, val) => {
      if (val["overallStatus"] === "Draft" || val["overallStatus"] !== "Completed" || val["overallStatus"] === "Completed") {
        filterItemsArr.push(val);
      }
      return filterItemsArr;
    }, []);
  } else if (option === "InProgress") {
    filterItemsArr = items.reduce((filterItemsArr, val) => {
      if (val["overallStatus"] !== "Completed" && val["overallStatus"] !== "Draft") {
        filterItemsArr.push(val);
      }
      return filterItemsArr;
    }, []);
  } else if (option === "Completed") {
    filterItemsArr = items.reduce((filterItemsArr, val) => {
      if (val["overallStatus"] === "Completed") {
        filterItemsArr.push(val);
      }
      return filterItemsArr;
    }, []);
  }
  return filterItemsArr;
};

export const filterDataByColumn = (data: any, filterValues: IFilterOption[]) => {
  let dataClone = [...data];
  filterValues.forEach((filter) => {
    //dataClone = dataClone.filter(filterByColumn(filter.column, value));
    //dataClone = dataClone.filter((item) => filter.values.includes(item[filter.column]));
    dataClone = dataClone.filter((item) =>{
      if(typeof(item[filter.column]) == "object"){
        if(item[filter.column] && item[filter.column].length > 0){
          let count=0;
          let isFilteredReq=false;
          item[filter.column].forEach((itm:any) => {
            if(filter.values.includes(itm['name']) && count==0){
              ++count;
              isFilteredReq= true; 
            }
          });
          return isFilteredReq;
        }        
      }
      else{
        return filter.values.includes(item[filter.column]);
      }
    });
  });

  return dataClone;
};

const escapeRegExp = (str: string) => str.replace(/[-[\]/{}()*+?.\\^$|]/g, "\\$&");

const filterBy = (term: any) => {
  const re = new RegExp(escapeRegExp(term), "i");
  return (item: any) => {
    for (let prop in item) {
      if (!item.hasOwnProperty(prop)) {
        continue;
      }
      if (re.test(item[prop])) {
        return true;
      }
    }
    return false;
  };
};

export const getFilteredItemsForSearchTerm = (searchTerm: string, filteredItems: any[], initialItems: any[]) => {
  if (!!searchTerm) {
    const filterItemsClone = [...filteredItems];

    searchTerm = searchTerm.toLowerCase();
    let filterItemsArr: any[] = [];
    const found: any[] = filterItemsClone.filter(filterBy(searchTerm));
    if (found) {
      found.forEach((element) => {
        filterItemsArr.push(element);
      });
    }
    return filterItemsArr;
  } else {
    return initialItems;
  }
};

export const renderClickableRow = (props: IDetailsRowProps, onRowClick: (item: any) => void): JSX.Element => {
  const onClick = () => onRowClick(props.item);
  const detailsRow = React.createElement("div", { onClick: onClick }, React.createElement(DetailsRow, props));
  return detailsRow;
};

export const renderClickableRowWithMultipleSelectionEnabled = (
  props: IDetailsRowProps,
  onRowClick: (event: Event, item: any) => void,
  defaultRender: IRenderFunction<IDetailsRowProps>
): JSX.Element => {
  const onClick = (event: Event) => onRowClick?.(event, props.item);
  return React.createElement("div", { "data-selection-toggle": "true", onClick: onClick }, defaultRender(props));
};

export const renderClickableRowWithMultipleSelectionDisabled = (
  props: IDetailsRowProps,
  onRowClick: (event: Event, item: any) => void,
  defaultRender: IRenderFunction<IDetailsRowProps>
): JSX.Element => {
  const onClick = (event: Event) => onRowClick?.(event, props.item);
  return React.createElement("div", { "data-selection-disabled": "true", onClick: onClick }, defaultRender(props));
};
