import { RequestStep, IBase } from "interfaces";

export interface IAuditLog extends IBase {
  projectRequestId: number;
  date: Date;
  by: string;
  type: AuditLogEntryType;
  scope: RequestStep;
  message: string;
}

export enum AuditLogEntryType {
  AuditLogEntry = 0,
  Comment = 1
}

export const AuditLogEntryTypeName: { [key in AuditLogEntryType]: string } = {
  [AuditLogEntryType.AuditLogEntry]: "Audit log",
  [AuditLogEntryType.Comment]: "Comment"
}
