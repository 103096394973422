import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { ErrorRegions } from "constant";
import { IErrorMessageComponentProps } from "./ErrorMessage";

const initialState: IErrorMessageComponentProps = {
  showError: false,
  errorMessage: undefined,
  errorOriginationKey: undefined,
};

export const ErrorMessageSlice = createSlice({
  name: "errorMessage",
  initialState: {
    value: { ...initialState },
  },
  reducers: {
    showErrorMessage: (state, action: PayloadAction<{ error: any; errorOriginationKey?: ErrorRegions }>) => {
      let error: any;
      if(typeof action.payload.error === "string"){
        error = action.payload.error;
      }
      else if (action.payload.error.errorMessage) {
        error = action.payload.error.errorMessage;
      } else if (action.payload.error.message) {
        error = action.payload.error.message;
        //if (action.payload.error.stack) {
        //  error = `${error}\nStack Trace: ${action.payload.error.stack}`;
        //}
      } else {
        try {
          error = JSON.stringify(action.payload.error, null, "\n");
        } catch (err) {
          error = action.payload.error;
        }
      }

      state.value = {
        showError: true,
        errorMessage: action?.payload?.error?.error ?? error,
        errorOriginationKey: action.payload?.errorOriginationKey,
      };
    },
    hideErrorMessage: (state, action) => {
      if ((action.payload && state.value.errorOriginationKey === action.payload) || !action.payload) {
        state.value = { ...initialState };
      }
    },
  },
});

export const { showErrorMessage, hideErrorMessage } = ErrorMessageSlice.actions;

export const errorMessageReducer = ErrorMessageSlice.reducer;
