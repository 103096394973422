import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import ResponsibilityMatrixService from "components/admin/responsibilityMatrix/ResponsibilityMatrixService";
import { IResponsibilityMatrix, RequestType } from "interfaces";

export interface IResponsibilityMatrixValue {
    loading: 'idle' | 'loading' | 'succeeded' | 'failed';
    matrix: IResponsibilityMatrix[];
    error: any;
    currentFilters: {
        company: string;
        requestType: RequestType;
    }
}

const initialState = {
    matrix: null,
    loading: 'idle',
    error: null,
    currentFilters: {
        company: null,
        requestType: null
    }
} as IResponsibilityMatrixValue;

export const loadResponsibilityMatrixAsync = createAsyncThunk('respmatrix/loadMatrix', (payload: { requestType: RequestType; company?: string; }) => {
    return ResponsibilityMatrixService.getResponsibilityMatrixBasedonRequestType(payload.requestType, payload.company);
});

export const responsibilityMatrixSlice = createSlice({
    name: "responsibilityMatrix",
    initialState: initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder.addCase(loadResponsibilityMatrixAsync.fulfilled, (state, action) => {
            state.loading = 'succeeded';
            state.matrix = action.payload;
            state.currentFilters = {
                company: action.meta.arg.company,
                requestType: action.meta.arg.requestType
            };
        });
        builder.addCase(loadResponsibilityMatrixAsync.pending, (state, action) => {
            state.loading = 'loading';
        });
        builder.addCase(loadResponsibilityMatrixAsync.rejected, (state, action) => {
            state.loading = 'failed';
            state.matrix = null;
            if (action.error) {
                state.error = action.error.message;
            }
        });
    }
});

export const {

} = responsibilityMatrixSlice.actions;

export const responsibilityMatrixReducer = responsibilityMatrixSlice.reducer;
