import {
  CommandBar, ConstrainMode, DefaultButton, DetailsList, DetailsListLayoutMode, Dropdown, IColumn, ICommandBarItemProps,
  IDetailsHeaderProps, IDetailsListStyles, IDropdownOption, IRenderFunction, ITooltipHostProps, Label, MarqueeSelection, Panel,
  PanelType, PrimaryButton, Selection, SelectionMode, Stack, Text, TextField, Toggle, TooltipHost,
} from "@fluentui/react";
import { useBoolean } from "@fluentui/react-hooks";
import { TextConstants } from "constant";
import { formatISODateTime } from "functions/dateFunctions";
import { ICompany } from "interfaces";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { ChoiceService, CompanyService } from "services";
import ColorSchemeMatrixService from "./ColorSchemeMatrixService";
import styles from "../Admin.module.scss";
import { ConfirmBox } from "components/common";
import { useSelector } from "react-redux";
import { RootState } from "store";
import { rgb2hex } from "functions/rgbColorsGenerator";
import { useWindowResize } from "hooks";
import { AdminPageContainer } from "../AdminPageContainer";

const columns: IColumn[] = [
  {
    key: "column1",
    name: TextConstants.Admin.ColorMatrix.Label_Type,
    fieldName: "type",
    minWidth: 150,
    maxWidth: 200,
    isResizable: true,
    data: "string",
    isPadded: true,
  },
  {
    key: "column2",
    name: TextConstants.Admin.ColorMatrix.Label_Value,
    fieldName: "value",
    minWidth: 150,
    maxWidth: 200,
    isResizable: true,
    data: "string",
    isPadded: true,
  },
  {
    key: "column3",
    name: TextConstants.Admin.ColorMatrix.Label_Color,
    fieldName: "color",
    minWidth: 150,
    maxWidth: 200,
    isResizable: true,
    data: "string",
    isPadded: true,
  }
];
export const ColorSchemeMatrix = () => {
  let companyService = new CompanyService();
  let choiceService = new ChoiceService();
  let colorSchemeMatrixService = new ColorSchemeMatrixService();

  const [isOpen, { setTrue: openPanel, setFalse: dismissPanel }] = useBoolean(false);
  const [objectData, setObject] = useState(Object);
  const [rowData, setRowData] = useState([]);
  const [colorSchemeTypeList, setColorSchemeTypeList] = useState([]);
  const [optionsList, setOptionsListList] = useState(Object);
  const [selectedItemsToDelete, setSelectedItemsToDelete] = useState<number[]>();
  const selection = new Selection({
    onSelectionChanged: () => {
      setSelectedItemsToDelete(selection.getSelection().map((i: any) => i.id));
    },
    selectionMode: SelectionMode.multiple,
  });
  const [hideDialog, { toggle: toggleHideDialog }] = useBoolean(true);
  const [deleteDialogResult, setDeleteDialogResult] = useState(false);
  const masterData = useSelector((state: RootState) => state.masterData);

  const { height } = useWindowResize();

  const addNewItem = () => {
    setObject({ type: colorSchemeTypeList[0].text });
    openPanel();
  };

  const setSelectedItem = (i: any) => {
    setObject(i);
    openPanel();
  };

  const setInputData = (value: any, fieldName: any) => {
    setObject((prevState: Object) => ({
      ...prevState,
      [fieldName]: value,
    }));
  };

  const _cmditems: ICommandBarItemProps[] = [
    {
      key: "newItem",
      text: TextConstants.Label_Add_Item,
      iconProps: { iconName: "Add" },
      ariaLabel: "New",
      onClick: addNewItem,
    },
    {
      key: "deleteItem",
      text: TextConstants.Label_Delete,
      iconProps: { iconName: "Delete" },
      ariaLabel: "Delete",
      onClick: toggleHideDialog,
      disabled: !selectedItemsToDelete?.length,
    },
  ];

  async function getBrandAndCompanies() {
    let options: any = { ...optionsList };
    if (masterData?.company && (masterData?.company as Array<any>).length) {
      let tablesListArr = (masterData?.company as Array<any>).map((meta: IDropdownOption) => ({
        key: meta.text,
        text: meta.text,
      }));
      options["Company"] = tablesListArr;
    } else {
      const data = await companyService.getAllCompanies();
      if (data?.length > 0) {
        let tablesListArr = data.map((meta: ICompany) => ({
          key: meta.name,
          text: meta.name,
        }));
        options["Company"] = tablesListArr;
      }
    }

    if (masterData?.brand && (masterData?.brand as Array<any>).length) {
      options["Brand"] = masterData?.brand;
    } else {
      let data = await choiceService.getDataListByChoice("brand");
      if (data?.length > 0) {
        let tablesListArr = data.map((name: string) => ({
          key: name,
          text: name,
        }));
        options["Brand"] = tablesListArr;
      }
    }
    setOptionsListList(options);
  }

  async function getColorSchemeTypeList() {
    let data = await choiceService.getDataListByChoice("colorSchemeType");
    if (data?.length > 0) {
      let tablesListArr = data.map((name: string) => ({
        key: name,
        text: name,
      }));
      setColorSchemeTypeList(tablesListArr);
    }
  }

  async function getColorSchemeMatrixData() {
    const data = await colorSchemeMatrixService.getAllColorSchemeMatrix();
    if (data?.length > 0) {
      setRowData(data);
    }
  }

  useEffect(() => {
    getColorSchemeTypeList();
    getBrandAndCompanies();
    getColorSchemeMatrixData();
  }, []);

  async function saveColorMatrixData() {
    let payloadData = { ...objectData };
    //delete extra properties
    payloadData.hasOwnProperty("modifiedBy") && delete payloadData.modifiedBy;
    payloadData.hasOwnProperty("createdBy") && delete payloadData.createdBy;

    if (!!objectData.id) {
      await colorSchemeMatrixService.updateColorSchemeMatrix(payloadData);
    } else {
      await colorSchemeMatrixService.createColorSchemeMatrix(payloadData);
    }
    getColorSchemeMatrixData();
    dismissPanel();
  }

  useEffect(() => {
    async function deleteMultipleData() {
      if (deleteDialogResult) {
        let result = await colorSchemeMatrixService.deleteMultipleColorSchemeMatrix(
          selectedItemsToDelete ? selectedItemsToDelete : []
        );
        if (result) {
          let tempdata = [...rowData];
          tempdata = tempdata.filter((value) => !selectedItemsToDelete.includes(value.id));
          setRowData(tempdata);
        }
        setSelectedItemsToDelete([]);
        setDeleteDialogResult(false);
      }
    }
    deleteMultipleData();
  }, [deleteDialogResult]);

  const onRenderDetailsHeader = useCallback((props: IDetailsHeaderProps, defaultRender: (props?: IDetailsHeaderProps) => JSX.Element | null) => {
    if (!props) {
      return null;
    }
    const onRenderColumnHeaderTooltip: IRenderFunction<ITooltipHostProps> = tooltipHostProps => (
      <TooltipHost {...tooltipHostProps} />
    );
    return (
      <div className={styles.stickyHeaderTable}>
        {defaultRender!({
          ...props,
          onRenderColumnHeaderTooltip,
        })}
      </div>
    );
  }, [])

  return (
    <AdminPageContainer pageTitle={TextConstants.Admin.SectionHeading_Manage_Color_Scheme_Matrix} >
        <ConfirmBox
          title={TextConstants.Label_Delete}
          subtext={TextConstants.Admin.Delete_Message_Multiple_Generic}
          open={hideDialog}
          setModalOpen={toggleHideDialog}
          setDeleteDialogResult={setDeleteDialogResult}
        />
        <Stack horizontal horizontalAlign="space-between" verticalAlign="center" tokens={{ childrenGap: 10 }}>
          <CommandBar
            styles={{ root: { padding: 0 } }}
            items={_cmditems}
            ariaLabel="Use left and right arrow keys to navigate between commands"
          />
        </Stack>
        <div style={{ maxHeight: 'calc(100vh - 289px)' }} className={styles.detailListContainer} data-is-scrollable="true">

          <MarqueeSelection selection={selection}>
            <DetailsList
              items={rowData}
              compact={true}
              columns={columns}
              styles={{
                headerWrapper: {
                  position: "sticky",
                  top: 0,
                  zIndex: 1
                }
              }}
              constrainMode={ConstrainMode.unconstrained}
              onRenderDetailsHeader={onRenderDetailsHeader}
              getKey={(i) => i.key}
              layoutMode={DetailsListLayoutMode.justified}
              isHeaderVisible={true}
              onItemInvoked={(i) => setSelectedItem(i)}
              selection={selection}
              selectionPreservedOnEmptyClick={true}
            />
          </MarqueeSelection>
        </div>
        <Panel
          headerText={`${!!objectData.id ? "Edit" : "Add New"} Item `}
          isOpen={isOpen}
          onDismiss={dismissPanel}
          type={PanelType.custom}
          customWidth="50%"
          focusTrapZoneProps={{
            forceFocusInsideTrap: false,
          }}
          closeButtonAriaLabel={TextConstants.Label_Close}
          isFooterAtBottom={true}
        >
          <div className={styles.editPanelContainer}>
            <div className={styles.editPanelFormControls}>
              <Dropdown
                className={styles.marginTopBottom}
                key={TextConstants.Admin.ColorMatrix.Label_Type}
                placeholder="Select"
                label={TextConstants.Admin.ColorMatrix.Label_Type}
                options={colorSchemeTypeList}
                selectedKey={objectData["type"]}
                onChange={(e, o, i) => setInputData(String(o?.key), "type")}
              />
              <Dropdown
                className={styles.marginTopBottom}
                key={TextConstants.Admin.Label_Company}
                placeholder="Select"
                label={!!objectData["type"] ? objectData["type"] : TextConstants.Admin.ColorMatrix.Label_Value}
                options={!!objectData["type"] ? optionsList[objectData["type"]] : []}
                selectedKey={objectData["value"]}
                onChange={(e, o, i) => setInputData(String(o?.key), "value")}
              />
              <TextField
                label={TextConstants.Admin.ColorMatrix.Label_Color}
                value={objectData["color"]}
                onChange={(e: any) => setInputData(e.target.value, "color")}
              />
            </div>
            <div className={styles.editPanelActions}>
              <div>
                <PrimaryButton
                  text={TextConstants.Label_Save}
                  disabled={!objectData["type"] || !objectData["value"] || !objectData["color"]}
                  onClick={saveColorMatrixData}
                  iconProps={{ iconName: "Save" }}
                  className={styles.marginRight}
                />
                <DefaultButton onClick={dismissPanel} text={TextConstants.Label_Cancel} iconProps={{ iconName: "Cancel" }} />
              </div>
            </div>
          </div>
        </Panel>
    </AdminPageContainer>
  );
};
