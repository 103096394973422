import { DefaultButton, Dialog, DialogFooter, DialogType, PrimaryButton } from "@fluentui/react";
import React, { Dispatch, SetStateAction } from "react";
import { useBoolean } from "@fluentui/react-hooks";

type Props = {
  title: string;
  subtext: string;
  open: boolean;
  setModalOpen: () => void;
  setDeleteDialogResult: Dispatch<SetStateAction<boolean>>;
};

export const ConfirmBox = (props: Props) => {
  const dialogContentProps = {
    type: DialogType.normal,
    title: props.title,
    closeButtonAriaLabel: "Close",
    subText: props.subtext,
  };

  return (
    <Dialog hidden={props.open} onDismiss={props.setModalOpen} dialogContentProps={dialogContentProps}>
      <DialogFooter>
        <PrimaryButton
          onClick={() => {
            props.setDeleteDialogResult(true);
            props.setModalOpen();
          }}
          text="Confirm"
        />
        <DefaultButton
          onClick={() => {
            props.setDeleteDialogResult(false);
            props.setModalOpen();
          }}
          text="Cancel"
        />
      </DialogFooter>
    </Dialog>
  );
};
