import { IconButton, Text } from "@fluentui/react";
import React from "react";
import { useSelector } from "react-redux";
import { Link, useSearchParams } from "react-router-dom";
import styles from "../Admin.module.scss";
import { AdminPageContainer } from "../AdminPageContainer";

//const editSolid12 = { iconName: "EditSolid12" };

export const HelpPagePreview = (props: any) => {
  let [searchParam] = useSearchParams();
  const value = useSelector((state: any) =>
    state.helperData.value.filter(
      (x: any) => String(x.key) === searchParam.get("key")
    )
  );

  return (
    <AdminPageContainer pageTitle={"Preview of " + searchParam.get("key")?.toUpperCase() + " Help page"} >
      <div className={styles.adminPageHeader}>
        <div>
          <Link to={`/admin/manage-helper`}>
            <IconButton
              primary
              iconProps={{
                iconName: "NavigateBack",
                styles: {
                  root: {
                    fontSize: '3rem'
                  }
                }
              }}
              title="Back"
              ariaLabel="back"
            />
          </Link>
        </div>
      </div>
      <div className={styles.adminPageView}>
        {!!value[0] && (
          <div dangerouslySetInnerHTML={{ __html: value[0].text }} />
        )}
      </div>
    </AdminPageContainer>
  );
};
