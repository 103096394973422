import { Checkbox, Label, Spinner, Text } from "@fluentui/react";
import { Chart as ChartJS, CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend, ChartOptions } from "chart.js";
import React, { useMemo, useRef } from "react";
import { Bar } from "react-chartjs-2";
import { getRandomColor, getRandomColorArray, getWhiteColorArray } from "functions/rgbColorsGenerator";
import styles from "./Chart.module.scss";
import { TextConstants } from "constant";

export interface IHorizontalBarChartDataSet {
  dataSet: string;
  data: number[];
  color: string;
}

export interface IBarChartData {
  header: string;
  labels: string[];
  data: IHorizontalBarChartDataSet[];
  showLoading: boolean;
}

interface IHorizontalBarChartProps {
  chartOptions: ChartOptions;
  barChartData: IBarChartData;
}

ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend);

const DefaultBarChartOptions: ChartOptions = {
  indexAxis: "y" as const,
  responsive: true,
  plugins: {
    legend: {
      position: "bottom" as const,
      // onClick: () => { },
    },
  },
};

export const HorizontalBarChart = (props: IHorizontalBarChartProps) => {
  const chartRef = useRef<ChartJS<"bar", number[], string>>(null);

  let data = {
    labels: props.barChartData.labels,
    datasets: props.barChartData.data.map((d) => ({
      label: d.dataSet,
      data: d.data,
      backgroundColor: !!d.color ? d.color : getRandomColor(),
      borderColor: getWhiteColorArray(d.data.length),
    })),
  };

  function _onCheckboxClick(dataSetId: string, isChecked: any) {
    const ci = chartRef.current;
    const datasetItem = ci?.legend?.legendItems?.filter((x) => x.text === dataSetId);
    if (!!datasetItem?.length) {
      if (ci?.isDatasetVisible(datasetItem[0].datasetIndex)) {
        ci.hide(datasetItem[0].datasetIndex);
      } else {
        ci?.show(datasetItem[0].datasetIndex);
      }
    }
  }

  const barChartOptions: ChartOptions<any> = useMemo(() => {
    return { ...DefaultBarChartOptions, ...props.chartOptions }
  }, [props.chartOptions]);

  return (
    <div className={styles.barChartWrapperSize}>
      <Label>{props.barChartData.header}</Label>
      {
        props.barChartData.showLoading ? (
          <Spinner
            className={styles.spinnerMargin}
            label={TextConstants.HomePage.Heading_Chart_LoadingData}
            ariaLive="assertive"
            labelPosition="right"
          />
        ) : props.barChartData.data.length > 0 ? (
          <>
            <div className={styles.barChartSize}>
              <Bar ref={chartRef} options={barChartOptions} data={data} />
            </div>
            {/* <div className={styles.chartFilters}>
              {props.barChartData.data.map((d) => (
                <Checkbox
                  key={d.dataSet}
                  label={d.dataSet}
                  defaultChecked
                  onChange={(e, isChecked) => _onCheckboxClick(d.dataSet, isChecked)}
                />
              ))}
            </div> */}
          </>
        ) : (
          <div className={styles.barChartSize}>
            <Text style={{ display: "block", textAlign: "center" }} className={styles.spinnerMargin}>{TextConstants.HomePage.Heading_Chart_NoDataToDisplay}</Text>
          </div>
        )
      }
    </div>
  );
};
