import { Configuration, LogLevel, PopupRequest } from "@azure/msal-browser";

/**
 * Enter here the user flows and custom policies for your B2C application
 * To learn more about user flows, visit: https://docs.microsoft.com/en-us/azure/active-directory-b2c/user-flow-overview
 * To learn more about custom policies, visit: https://docs.microsoft.com/en-us/azure/active-directory-b2c/custom-policy-overview
 */
export const b2cPolicies = {
  names: {
    signUpSignIn: process.env.REACT_APP_POLICY_SIGNUPSIGNIN, //"B2C_1A_SIGNUPSIGNINWITHINVITATIONFLOW",
    forgotPassword: process.env.REACT_APP_POLICY_FORGOTPASSWORD, //"B2C_1A_CHANGEPASSWORD",
    editProfile: process.env.REACT_APP_POLICY_EDITPROFILE, //"B2C_1A_PROFILEEDIT",
  },
  authorities: {
    signUpSignIn: {
      authority: `https://${process.env.REACT_APP_B2C_DOMAIN}.b2clogin.com/${process.env.REACT_APP_B2C_DOMAIN}.onmicrosoft.com/${process.env.REACT_APP_POLICY_SIGNUPSIGNIN}`,
    },
    changePassword: {
      authority: `https://${process.env.REACT_APP_B2C_DOMAIN}.b2clogin.com/${process.env.REACT_APP_B2C_DOMAIN}.onmicrosoft.com/${process.env.REACT_APP_POLICY_FORGOTPASSWORD}`,
    },
    editProfile: {
      authority: `https://${process.env.REACT_APP_B2C_DOMAIN}.b2clogin.com/${process.env.REACT_APP_B2C_DOMAIN}.onmicrosoft.com/${process.env.REACT_APP_POLICY_EDITPROFILE}`,
    },
  },
  authorityDomain: `${process.env.REACT_APP_B2C_DOMAIN}.b2clogin.com`,
};

/**
 * Configuration object to be passed to MSAL instance on creation.
 * For a full list of MSAL.js configuration parameters, visit:
 * https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-browser/docs/configuration.md
 */
export const msalConfig: Configuration = {
  auth: {
    clientId: process.env.REACT_APP_CLIENT_ID, //"3b518f65-6576-42e7-86b1-77b6eda35eae", // This is the ONLY mandatory field that you need to supply.
    authority: b2cPolicies.authorities.signUpSignIn.authority, // Use a sign-up/sign-in user-flow as a default authority
    knownAuthorities: [b2cPolicies.authorityDomain], // Mark your B2C tenant's domain as trusted.
    redirectUri: "/", // Points to window.location.origin. You must register this URI on Azure Portal/App Registration.
    postLogoutRedirectUri: "/", // Indicates the page to navigate after logout.
    navigateToLoginRequestUrl: true, // If "true", will navigate back to the original request location before processing the auth code response.
  },
  cache: {
    cacheLocation: "localStorage", // Configures cache location. "sessionStorage" is more secure, but "localStorage" gives you SSO between tabs.
    storeAuthStateInCookie: false, // Set this to "true" if you are having issues on IE11 or Edge
  },
  system: {
    iframeHashTimeout: 20000,
    loadFrameTimeout: 20000,
    redirectNavigationTimeout: 20000,
    loggerOptions: {
      logLevel: LogLevel.Trace,
      loggerCallback: (level: number, message: string, containsPii: any) => {
        if (containsPii) {
          return;
        }
        switch (level) {
          case LogLevel.Error:
            console.error(message);
            return;
          case LogLevel.Info:
            console.info(message);
            return;
          case LogLevel.Verbose:
            console.debug(message);
            return;
          case LogLevel.Warning:
            console.warn(message);
            return;
        }
      },
    },
  },
};

/**
 * Add here the endpoints and scopes when obtaining an access token for protected web APIs. For more information, see:
 * https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-browser/docs/resources-and-scopes.md
 */
export const protectedResources = {
  api: {
    endpoint: "/",
    scopes: [process.env.REACT_APP_CUSTOM_PERMISSION], // e.g. api://xxxxxx/access_as_user
  },
};

/**
 * Scopes you add here will be prompted for user consent during sign-in.
 * By default, MSAL.js will add OIDC scopes (openid, profile, email) to any login request.
 * For more information about OIDC scopes, visit:
 * https://docs.microsoft.com/en-us/azure/active-directory/develop/v2-permissions-and-consent#openid-connect-scopes
 */
export const loginRequest: PopupRequest = {
  scopes: ["openid", "profile", ...protectedResources.api.scopes],
};

/**
 * An optional silentRequest object can be used to achieve silent SSO
 * between applications by providing a "login_hint" property.
 */
export const silentRequest = {
  scopes: ["openid", "profile", ...protectedResources.api.scopes],
  loginHint: "example@domain.net",
};
