import { IOutcomeOptions } from "ccs-azure";
import { ProjectRequestService } from "components/request/services/ProjectRequestService";
import { IProjectRequest, IProjectTask, RequestStatus, RequestType, RequestStep, RequestStepTab, RequestStepType, RequestTaskType, RequestTaskStatus, NO_REQUESTSTEP } from "interfaces";
import { IProjectRequestStatus } from "interfaces/IProjectRequestStatus";
import { createMachine, StateMachine, AnyEventObject, BaseActionObject, ServiceMap, ResolveTypegenMeta, TypegenDisabled, interpret, Interpreter, assign } from "xstate";

interface Context {
  retries: number;
  projectRequest: IProjectRequest;
  itemCreationDone: boolean;
}

declare type StateMachineServiceInstance = Interpreter<any, any, any, { value: any; context: any }, any>;
export interface ISpeedUpStateMachineServiceInstance extends StateMachineServiceInstance {
  StartingState?: any;
  ResumeMachine?: () => void;
}

type XStateStateMachineType = StateMachine<Context, any, AnyEventObject, { value: any; context: Context; }, BaseActionObject, ServiceMap, ResolveTypegenMeta<TypegenDisabled, AnyEventObject, BaseActionObject, ServiceMap>>;

export interface ISpeedUpStateMachine extends XStateStateMachineType {
  StateMachineType?: "ThirdParty" | "OwnManufactured";
}

export const NavigationOrder: { [key in RequestStep | "Completed"]: number } = {
  [RequestStep.Start]: 1,
  [RequestStep.KickOff]: 2,
  [RequestStep.ProductMasterDataText]: 3,
  [RequestStep.ItemCreation]: 4,
  [RequestStep.BOMCreation]: 5,
  [RequestStep.BOMSAPLoad]: 6,
  [RequestStep.ExternalInput]: 7,
  [RequestStep.TechnicalReadiness]: 8,
  [RequestStep.CountryTasks]: 9,
  Completed: 10
};

export abstract class StateMachineService {
  private static initializedStatus: any = null;

  private static initializeStateMachine(projectRequest: IProjectRequest, latestState: IProjectRequestStatus): ISpeedUpStateMachineServiceInstance {
    const machineDefinition = projectRequest.requestType === RequestType.OwnManufactured ? initStateMachineOwnManufactured(projectRequest, Boolean(latestState?.itemCreationDone)) : initStateMachineThirdParty(projectRequest, Boolean(latestState?.itemCreationDone));
    const machineService: ISpeedUpStateMachineServiceInstance = interpret(machineDefinition);

    let initialState = latestState?.statusValue;
    machineService.StartingState = initialState ? initialState : machineDefinition.initial;

    this.initializedStatus = latestState;
    if (typeof latestState !== 'object') {
      this.initializedStatus = {
        projectRequestId: projectRequest.id,
        statusValue: machineService.StartingState
      } as IProjectRequestStatus;
    }

    machineService.ResumeMachine = () => {
      machineService.start(machineService.StartingState);
    };

    return machineService;
  }

  /**
   * Call this function to initialize the StateMachine with the current projectRequest data and trigger the desired event
   * @param projectRequest projectRequest object
   * @param latestState latest XState as received from SpeedUp database
   * @param stateTransitionEvent xstate event handler to handle the event transition
   * @param eventKey event that is sent to the StateMachine
   * @param updateTasks a function that will be called after successful state transition, but before the server API is called to update the request status in the DB. This function must return all RequestTasks that need to be updated related to the Status change. Function receives eventkey and new xstate state object
   * @returns promise of updated IProjectRequestStatus
   */
  public static processStateMachineEvent(
    projectRequest: IProjectRequest,
    eventKey: string,
    statusComment: string,
    updateTasks: (eventKey: string, projectRequest: IProjectRequest, newStatus: any) => IProjectTask[]
  ): Promise<[IProjectRequestStatus, string, IProjectTask[], RequestStatus?]> {
    return new Promise((resolve, reject) => {
      ProjectRequestService.getProjectRequestStatus(projectRequest.id).then((latestState: IProjectRequestStatus) => {
        const machineService = StateMachineService.initializeStateMachine(projectRequest, latestState);
        machineService.onTransition(StateMachineService.stateTransitionEvent(
          /*onDone*/
          (newStatus: IProjectRequestStatus, newRequestStep: string, projectTasks: IProjectTask[], newRequestStatus?: RequestStatus) => {
            console.log(`%cON-TRANSITION: DONE (newStatus=${newStatus}, newRequestStep:${newRequestStep})`, "color:yellow;font-weight:bold", newStatus);
            resolve([newStatus, newRequestStep, projectTasks, newRequestStatus]);
          },
          /*onError*/
          (msgError: string) => {
            console.log(`%cON-TRANSITION-ERROR: ${msgError}`, "color:yellow;font-weight:bold");
            reject(msgError);
          },
          /*IProjectRequestStatus*/
          this.initializedStatus,
          eventKey,
          statusComment,
          updateTasks,
          projectRequest));
        machineService.ResumeMachine();

        // the following check can't happen before the machine is running (xstate won't return nextEvents before then)
        if (machineService.state.nextEvents.includes(eventKey)) {

          // trigger the event that happened
          machineService.send(eventKey);
        } else {
          console.warn(`Action '${eventKey}' is not a viable action for the current state.`);
          resolve(null);
        }
        // stop this temporary machine, it's not needed anymore
        machineService.stop();
      });
    });
  }

  private static stateTransitionEvent = (
    onDone: (newStatus: IProjectRequestStatus, newRequestStep: string, requestTasks: IProjectTask[], newRequestStatus?: RequestStatus) => void,
    onError: (msgError: string) => void,
    initializedStatus: IProjectRequestStatus,
    eventKey: string,
    statusComment: string,
    updateTasks: (eventKey: string, projectRequest: IProjectRequest, newStatus: any) => IProjectTask[],
    projectRequest: IProjectRequest
  ) => async (newState: any) => {

    if (newState.changed) {
      console.log("EVENT: " + eventKey, newState);
      console.log("Next Events: " + newState.nextEvents.join(", "));

      let updateSuccessful = false;
      let retryCount = 0;
      do {
        try {
          const taskUpdates: IProjectTask[] = updateTasks(eventKey, projectRequest, newState.value);

          const objRequestSteps: string[] = StateMachineService.getNavigationDataFromState(projectRequest, initializedStatus, newState.value);
          const newRequestStep = objRequestSteps.length ? objRequestSteps.join(",") : null;

          let updateRequestStep = null;
          if (newRequestStep && newRequestStep !== projectRequest.requestStep) {
            updateRequestStep = newRequestStep;
          }

          let newRequestStatus: RequestStatus = undefined;
          if (eventKey === WorkflowAction.Reject) {
            newRequestStatus = RequestStatus.Rejected;
          } else if (eventKey === WorkflowAction.Withdraw) {
            newRequestStatus = RequestStatus.Withdrawn;
          } else if (newState.value === WorkflowStateName.RequestDone) {
            newRequestStatus = RequestStatus.Completed;
          }

          initializedStatus.statusValue = newState.value;
          // store context value back for future
          // Special StateMachine-Handling (Non Deterministic?) for ItemCreation / BOM Creation interaction
          initializedStatus.itemCreationDone = (newState.context as Context).itemCreationDone;          
          const { requestStatus, requestTasks } = await ProjectRequestService.setProjectRequestStatus(initializedStatus, taskUpdates, updateRequestStep, newRequestStatus, statusComment);
          updateSuccessful = true;
          if (requestStatus) {
            // unwrap statusValue, since we store strings in db but xstate uses json object
            requestStatus.statusValue = JSON.parse(requestStatus.statusValue);
          }
          onDone(requestStatus, updateRequestStep ?? projectRequest.requestStep, requestTasks, newRequestStatus);
        } catch (stateError: any) {
          // projectRequestStatus couldn't be set, there was a newer status
          console.log(`StateMachine update failed: ${stateError.message}`, stateError);
          retryCount++;
        }
      } while (!updateSuccessful && retryCount < 3);

      if (retryCount === 3) {
        // state update kept failing, show error to user
        onError("Unable to Update State of ProjectRequest. Please reload request and/or try again later.");
      }
    }
  };

  public static matchesStatus(projectRequest: IProjectRequest, status: string): boolean {
    const machineService = StateMachineService.initializeStateMachine(projectRequest, projectRequest.stateMachineStatus);
    machineService.ResumeMachine();
    return machineService.state.matches(status);
  }

  /**
   * This function traverses the xstate object recursively searching for a status key and returns its value
   * @param stateObject xstate object
   * @param stateName stateName
   * @returns value of state
   */
  public static getDetailStateValue(stateObject: any, stateName: string): any {
    if (typeof stateObject === 'object') {
      // iterate through all properties recursively
      let result: string = null;
      const allKeys = Object.keys(stateObject);
      for (let key of allKeys) {
        if (key === stateName) {
          return stateObject[key];
        }
        if (typeof stateObject[key] === 'object') {
          const subResult = this.getDetailStateValue(stateObject[key], stateName);
          if (subResult) {
            return subResult;
          }
        }
      }
      return result;
    }
    return null;
  }

  public static getTaskStates(stateObject: any): string[] {
    let taskStates: string[] = [];
    if (typeof stateObject === 'object') {
      // iterate through all properties recursively
      const allKeys = Object.keys(stateObject);
      for (let key of allKeys) {
        const stateValue = stateObject[key];
        if (typeof stateValue === 'string' && !(stateValue as string).endsWith("Done")) {
          // this key is a taskState        
          taskStates.push(stateValue);
        } else if (typeof stateValue === 'object') {
          const subResults = this.getTaskStates(stateValue);
          if (subResults) {
            taskStates = [...taskStates, ...subResults];
          }
        }
      }
      return taskStates;
    } else if (typeof stateObject === 'string' && !(stateObject as string).endsWith("Done")) {
      return [stateObject as string];
    }
    return null;
  }

  /**
   * This function provides navigation metadata supplied via StateMachine states
   * @param projectRequest 
   * @returns array of Navigation nodes to be active in this state, or null if no metadata was found for current state
   */
  // public static getMetaData(projectRequest: IProjectRequest): IStateMetadata {
  //   try {
  //     const machineService = StateMachineService.initializeStateMachine(projectRequest, projectRequest.stateMachineStatus);
  //     machineService.ResumeMachine();
  //     const curState = machineService.state.value;
  //     if (typeof curState === "string") {
  //       return machineService.state.meta[`fetch.${curState}`];
  //     }
  //     const topState = Object.keys(curState)[0];
  //     return machineService.state.meta[`fetch.${topState}`];
  //   } catch (err) {
  //     return null;
  //   }
  // }

  public static getNavigationDataFromState(projectRequest: IProjectRequest, latestState: IProjectRequestStatus, newState: any): string[] {
    try {
      const machineDefinition = projectRequest.requestType === RequestType.OwnManufactured ? initStateMachineOwnManufactured(projectRequest, Boolean(latestState?.itemCreationDone)) : initStateMachineThirdParty(projectRequest, Boolean(latestState?.itemCreationDone));
      const machineService: ISpeedUpStateMachineServiceInstance = interpret(machineDefinition);

      machineService.start(newState);

      // TODO: find a more elegant solution for this hardcoded CountryTasks check, to make it appear highlighted in the Navi when there are open Country Tasks
      let hasOpenCountryTasks = projectRequest.projectTasks?.some(t => t.taskType === RequestTaskType.Country && t.status === RequestTaskStatus.Open);
      const countryTaskTriggerValue = StateMachineService.getDetailStateValue(newState, WorkflowStateName.CountryTasks);
      hasOpenCountryTasks = hasOpenCountryTasks || Boolean(countryTaskTriggerValue);

      const arrResult: string[] = this.extractNavigationNodes(machineService.state.meta);
      if (hasOpenCountryTasks) {
        arrResult.push(RequestStep.CountryTasks);
      }
      return arrResult.sort((a: string, b: string) => {
        return NavigationOrder[a as RequestStep] < NavigationOrder[b as RequestStep] ? -1 : NavigationOrder[a as RequestStep] > NavigationOrder[b as RequestStep] ? +1 : 0;
      });
    } catch (err) {
      return null;
    }
  }

  private static extractNavigationNodes(obj: any): any {
    let results: any = [];

    Object.values(obj).forEach((value: any) => {
      if (value.hasOwnProperty("navigation")) {
        results = [...results, ...value["navigation"]];
      }
    });
    return results;
  }
}

export enum WorkflowStateName {
  StartTask = "StartTask",
  KickOffTask = "KickOffTask",
  DataPreparationParallelWrapper = "DataPreparationParallelWrapper",
  ExternalInput = "ExternalInput",
  ExternalInputParallelWrapper = "ExternalInputParallelWrapper",
  ExternalInputDone = "ExternalInputDone",
  CustomInputWrapper = "CustomInputWrapper",
  CustomInputsTask = "CustomInputsTask",
  CustomInputsReviewTask = "CustomInputsReviewTask",
  CustomInputsDone = "CustomInputsDone",

  DangerousGoodsInputsWrapper = "DangerousGoodsInputsWrapper",
  DangerousGoodsInputsTask = "DangerousGoodsInputsTask",
  DangerousGoodsInputsReviewTask = "DangerousGoodsInputsReviewTask",
  DangerousGoodsInputsDone = "DangerousGoodsInputsDone",

  RAInputWrapper = "RAInputWrapper",
  RAInputTask = "RAInputTask",
  RAInputReviewTask = "RAInputReviewTask",
  RAInputDone = "RAInputDone",

  RAChecklistTaskWrapper = "RAChecklistTaskWrapper",
  RAChecklistTask = "RAChecklistTask",
  RAChecklistTaskDone = "RAChecklistTaskDone",

  LogisticInputsWrapper = "LogisticInputsWrapper",
  LogisticInputs = "LogisticInputs",
  LogisticInputsTask = "LogisticInputsTask",
  LogisticInputsReviewTask = "LogisticInputsReviewTask",
  LogisticInputsDone = "LogisticInputsDone",

  BomDataPreparationParallelWrapper = "BomDataPreparationParallelWrapper",
  BomDataCollection = "BomDataCollection",
  BomDataCollectionDone = "BomDataCollectionDone",
  BomDataCollectionBOMCheck = "BomDataCollectionBOMCheck",
  BomDataCollectionParallelWrapper = "BomDataCollectionParallelWrapper",

  EngineeringCollection = "EngineeringCollection",
  EngineeringCollectionTask = "EngineeringCollectionTask",
  EngineeringCollectionTaskDone = "EngineeringCollectionTaskDone",

  PackagingCollection = "PackagingCollection",
  PackagingCollectionTask = "PackagingCollectionTask",
  PackagingCollectionTaskDone = "PackagingCollectionTaskDone",

  PMCollection = "PMCollection",
  PMCollectionTask = "PMCollectionTask",
  PMCollectionTaskDone = "PMCollectionTaskDone",

  PM = "PM",
  PMTask = "PMTask",
  PMTaskDone = "PMTaskDone",

  LabellingCollection = "LabellingCollection",
  LabellingCollectionTask = "LabellingCollectionTask",
  LabellingCollectionTaskDone = "LabellingCollectionTaskDone",

  BOMSAPCreationWrapper = "BOMSAPCreationWrapper",
  BOMSAPCreationTask = "BOMSAPCreationTask",
  BOMSAPCreationTaskDone = "BOMSAPCreationTaskDone",
  BOMSAPCreationWrapperDone = "BOMSAPCreationWrapperDone",

  BomDataTranslation = "BomDataTranslation",
  TranslationTask = "TranslationTask",
  ItemCreationTask = "ItemCreationTask",
  BomDataTranslationDone = "BomDataTranslationDone",

  BOMSAPTechnicalReadynessParallelWrapper = "BOMSAPTechnicalReadynessParallelWrapper",

  BOMSAPCreationEntryCheck = "BOMSAPCreationEntryCheck",
  BOMWaitingForItemCreationDone = "BOMWaitingForItemCreationDone",

  TechnicalReadinessParallelWrapperClosure = "TechnicalReadinessParallelWrapperClosure",
  TechnicalReadinessParallelWrapper = "TechnicalReadinessParallelWrapper",
  TechnicalReadinessParallelWrapperClosureDone = "TechnicalReadinessParallelWrapperClosureDone",

  DangerousGoodsWrapper = "DangerousGoodsWrapper",
  DangerousGoods = "DangerousGoods",
  DangerousGoodsTask = "DangerousGoodsTask",
  DangerousGoodsTaskDone = "DangerousGoodsTaskDone",

  EShopWrapper = "EShopWrapper",
  EShop = "EShop",
  EShopTask = "EShopTask",
  EShopTaskDone = "EShopTaskDone",

  CamDBWrapper = "CamDBWrapper",
  CamDB = "CamDB",
  CamDBTask = "CamDBTask",
  CamDBTaskDone = "CamDBTaskDone",

  LabelingWrapper = "LabelingWrapper",
  Labeling = "Labeling",
  LabelingTask = "LabelingTask",
  LabelingTaskDone = "LabelingTaskDone",

  PictureWrapper = "PictureWrapper",
  Picture = "Picture",
  PictureTask = "PictureTask",
  PictureTaskDone = "PictureTaskDone",

  ControllingWrapper = "ControllingWrapper",
  ControllingTask = "ControllingTask",
  ControllingTaskDone = "ControllingTaskDone",

  LogisticWrapper = "LogisticWrapper",
  Logistic = "Logistic",
  LogisticTask = "LogisticTask",
  LogisticTaskDone = "LogisticTaskDone",

  RADBWrapper = "RADBWrapper",
  RADBCondition = "RADBCondition",
  RADBTask = "RADBTask",
  RADBTaskDone = "RADBTaskDone",

  TemperatureWrapper = "TemperatureWrapper",
  Temperature = "Temperature",
  TemperatureTask = "TemperatureTask",
  TemperatureTaskDone = "TemperatureTaskDone",

  SimpleApprovalsWrapper = "SimpleApprovalsWrapper",
  SimpleApprovalCondition = "SimpleApprovalCondition",
  SimpleApprovalsTask = "SimpleApprovalsTask",
  SimpleApprovalsTaskDone = "SimpleApprovalsTaskDone",

  CountryTasks = "CountryTasks",
  CountryTasksTrigger = "CountryTasksTrigger",

  CustomsWrapper = "CustomsWrapper",
  CustomsTask = "CustomsTask",
  CustomsTaskDone = "CustomsTaskDone",

  RequestDone = "RequestDone",

  ThirdPartyTechnicalReadinessBlockWrapper = "ThirdPartyTechnicalReadinessBlockWrapper",
  ThirdPartyTechnicalReadinessDone = "ThirdPartyTechnicalReadinessDone",

  ThirdPartyTranslationWrapper = "ThirdPartyTranslationWrapper",
  ThirdPartyTranslation = "ThirdPartyTranslation",
  ThirdPartyTranslationTaskDone = "ThirdPartyTranslationTaskDone",

  ThirdPartyTechnicalReadinessParallelWrapper = "ThirdPartyTechnicalReadinessParallelWrapper",

  ThirdPartyTranslationCheckWrapper = "ThirdPartyTranslationCheckWrapper",
  ThirdPartyTranslationCheck = "ThirdPartyTranslationCheck",
  ThirdPartyTranslationCheckTask = "ThirdPartyTranslationCheckTask",
  ThirdPartyTranslationCheckTaskDone = "ThirdPartyTranslationCheckTaskDone",

  ThirdPartyCamDBWrapper = "ThirdPartyCamDBWrapper",
  ThirdPartyCamDB = "ThirdPartyCamDB",
  ThirdPartyCamDBRoutingTaskDone = "ThirdPartyCamDBRoutingTaskDone",

  Withdrawn = "Withdrawn"
}

export enum WorkflowAction {
  Submit = "Submit",
  Reject = "Reject",
  ApproveKickOff = "ApproveKickOff",

  ConfirmCustomInput = "ConfirmCustomInput",
  ConfirmCustomInputReview = "ConfirmCustomInputReview",
  RejectCustomInputReview = "RejectCustomInputReview",
  ConfirmDangerousGoodsInputs = "ConfirmDangerousGoodsInputs",
  ConfirmDangerousGoodsInputsReview = "ConfirmDangerousGoodsInputsReview",
  RejectDangerousGoodsInputsReview = "RejectDangerousGoodsInputsReview",

  ConfirmRAInput = "ConfirmRAInput",
  ConfirmRAInputReview = "ConfirmRAInputReview",
  RejectRAInputReview = "RejectRAInputReview",
  ConfirmRAChecklist = "ConfirmRAChecklist",

  ConfirmLogisticInputs = "ConfirmLogisticInputs",
  ConfirmLogisticInputsReview = "ConfirmLogisticInputsReview",
  RejectLogisticInputsReview = "RejectLogisticInputsReview",

  ConfirmEngineeringDone = "ConfirmEngineeringDone",
  ConfirmPackagingDone = "ConfirmPackagingDone",
  ConfirmPMDone = "ConfirmPMDone",
  ConfirmLabelingDone = "ConfirmLabelingDone",
  ConfirmBOMLabelingDone = "ConfirmBOMLabelingDone",

  ConfirmSapCreation = "ConfirmSapCreation",
  ConfirmTranslationTaskDone = "ConfirmTranslationTaskDone",
  ConfirmItemCreationDone = "ConfirmItemCreationDone",

  ConfirmDangerousGoodsDone = "ConfirmDangerousGoodsDone",
  ConfirmEShopDone = "ConfirmEShopDone",
  ConfirmCamDBDone = "ConfirmCamDBDone",
  ConfirmPictureDone = "ConfirmPictureDone",
  ConfirmControllingDone = "ConfirmControllingDone",
  ConfirmLogisticDone = "ConfirmLogisticDone",
  ConfirmRADBDone = "ConfirmRADBDone",
  SkipRADBDone = "SkipRADBDone",
  ConfirmTemperatureTaskDone = "ConfirmTemperatureTaskDone",
  ConfirmSimpleApprovalsTaskDone = "ConfirmSimpleApprovalsTaskDone",
  ConfirmCustomsDone = "ConfirmCustomsDone",

  Confirm = "Confirm",
  ConfirmThirdPartyTranslationCheckTaskDone = "COnfirmThirdPartyTranslationCheckTaskDone",

  Withdraw = "Withdraw"
}

export const reasonOptions = { otherAvailable: true, otherLabel: "Please provide Reason", showOnlyOther: true, reasonLabel: "Reason", largeOtherField: true };

export const KeyToDisplayName: { [key in WorkflowAction]: [string, string, IOutcomeOptions?] } = {
  [WorkflowAction.Submit]: ["Submit", "Submitted"],
  [WorkflowAction.Reject]: ["Reject", "Rejected", reasonOptions],
  [WorkflowAction.ApproveKickOff]: ["Approve", "Approved"],

  [WorkflowAction.ConfirmCustomInput]: ["Confirm", "Confirmed"],
  [WorkflowAction.ConfirmCustomInputReview]: ["Confirm", "Confirmed"],
  [WorkflowAction.RejectCustomInputReview]: ["Reject", "Rejected", reasonOptions],
  [WorkflowAction.ConfirmDangerousGoodsInputs]: ["Confirm", "Confirmed"],
  [WorkflowAction.ConfirmDangerousGoodsInputsReview]: ["Confirm", "Confirmed"],
  [WorkflowAction.RejectDangerousGoodsInputsReview]: ["Reject", "Rejected", reasonOptions],

  [WorkflowAction.ConfirmRAInput]: ["Confirm", "Confirmed"],
  [WorkflowAction.ConfirmRAInputReview]: ["Confirm", "Confirmed"],
  [WorkflowAction.RejectRAInputReview]: ["Reject", "Rejected", reasonOptions],
  [WorkflowAction.ConfirmRAChecklist]: ["Confirm", "Confirmed"],

  [WorkflowAction.ConfirmLogisticInputs]: ["Confirm", "Confirmed"],
  [WorkflowAction.ConfirmLogisticInputsReview]: ["Confirm", "Confirmed"],
  [WorkflowAction.RejectLogisticInputsReview]: ["Reject", "Rejected", reasonOptions],

  [WorkflowAction.ConfirmEngineeringDone]: ["Confirm", "Confirmed"],
  [WorkflowAction.ConfirmPackagingDone]: ["Confirm", "Confirmed"],
  [WorkflowAction.ConfirmPMDone]: ["Confirm", "Confirmed"],
  [WorkflowAction.ConfirmBOMLabelingDone]: ["Confirm", "Confirmed"],

  [WorkflowAction.ConfirmSapCreation]: ["Confirm", "Confirmed"],
  [WorkflowAction.ConfirmTranslationTaskDone]: ["Confirm", "Confirmed"],
  [WorkflowAction.ConfirmItemCreationDone]: ["Confirm", "Confirmed"],

  [WorkflowAction.ConfirmDangerousGoodsDone]: ["Confirm", "Confirmed"],
  [WorkflowAction.ConfirmEShopDone]: ["Confirm", "Confirmed"],
  [WorkflowAction.ConfirmCamDBDone]: ["Confirm", "Confirmed"],
  [WorkflowAction.ConfirmLabelingDone]: ["Confirm", "Confirmed"],
  [WorkflowAction.ConfirmPictureDone]: ["Confirm", "Confirmed"],
  [WorkflowAction.ConfirmControllingDone]: ["Confirm", "Confirmed"],
  [WorkflowAction.ConfirmLogisticDone]: ["Confirm", "Confirmed"],
  [WorkflowAction.ConfirmRADBDone]: ["Confirm", "Confirmed"],
  [WorkflowAction.SkipRADBDone]: ["Skip", "Skipped", reasonOptions],
  [WorkflowAction.ConfirmTemperatureTaskDone]: ["Confirm", "Confirmed"],
  [WorkflowAction.ConfirmSimpleApprovalsTaskDone]: ["Confirm", "Confirmed"],
  [WorkflowAction.ConfirmCustomsDone]: ["Confirm", "Confirmed"],

  [WorkflowAction.Confirm]: ["Confirm", "Confirmed"],
  [WorkflowAction.ConfirmThirdPartyTranslationCheckTaskDone]: ["Confirm", "Confirmed"],

  [WorkflowAction.Confirm]: ["Confirm", "Confirmed"],

  [WorkflowAction.Withdraw]: ["Withdraw", "Withdrawn", { ...reasonOptions, reasonOptional: true }]
};

export const ActionByStepTab: {
  [step in RequestStepType]?: {
    [tab in RequestStepTab]?: {
      [key in WorkflowAction]?: keyof typeof WorkflowAction;
    }
  }
} = {
  TechnicalReadiness: {
    Controlling: {
      ConfirmControllingDone: "ConfirmControllingDone"
    },
    RADB: {
      ConfirmRADBDone: "ConfirmRADBDone",
    }
  }
}

function initStateMachineThirdParty(projectRequest: IProjectRequest, itemCreationDone: boolean): ISpeedUpStateMachine {
  let machine: ISpeedUpStateMachine = createMachine<Context>({
    id: "fetch",
    initial: WorkflowStateName.StartTask,
    context: {
      retries: 0,
      projectRequest: projectRequest,
      itemCreationDone: itemCreationDone
    },
    states: {
      [WorkflowStateName.StartTask]: {
        on: {
          [WorkflowAction.Submit]: WorkflowStateName.KickOffTask,
        },
        meta: {
          navigation: [RequestStep.Start]
        }
      },
      [WorkflowStateName.KickOffTask]: {
        on: {
          [WorkflowAction.Reject]: WorkflowStateName.StartTask,
          [WorkflowAction.Withdraw]: WorkflowStateName.Withdrawn,
          [WorkflowAction.ApproveKickOff]: [{ target: WorkflowStateName.DataPreparationParallelWrapper }],
        },
        meta: {
          navigation: [RequestStep.KickOff]
        }
      },

      // third party
      [WorkflowStateName.DataPreparationParallelWrapper]: {
        type: "parallel",

        states: {
          [WorkflowStateName.ExternalInputParallelWrapper]: {
            initial: WorkflowStateName.ExternalInput,
            states: {
              [WorkflowStateName.ExternalInput]: {
                type: "parallel",
                states: {
                  [WorkflowStateName.CustomInputWrapper]: {
                    initial: WorkflowStateName.CustomInputsTask,
                    states: {
                      [WorkflowStateName.CustomInputsTask]: {
                        on: {
                          [WorkflowAction.ConfirmCustomInput]: WorkflowStateName.CustomInputsReviewTask,
                        },
                      },
                      [WorkflowStateName.CustomInputsReviewTask]: {
                        on: {
                          [WorkflowAction.ConfirmCustomInputReview]: WorkflowStateName.CustomInputsDone,
                          [WorkflowAction.RejectCustomInputReview]: WorkflowStateName.CustomInputsTask
                        },
                      },
                      [WorkflowStateName.CustomInputsDone]: {
                        type: "final",
                      },
                    },
                  },
                  [WorkflowStateName.DangerousGoodsInputsWrapper]: {
                    initial: WorkflowStateName.DangerousGoods,
                    states: {
                      [WorkflowStateName.DangerousGoods]: {
                        on: {
                          "": [
                            {
                              target: WorkflowStateName.DangerousGoodsInputsTask,
                              cond: (context, event) => context.projectRequest.hasPotentialDangerousGoods
                            },
                            {
                              target: WorkflowStateName.DangerousGoodsInputsDone,
                            },
                          ],
                        },
                      },
                      [WorkflowStateName.DangerousGoodsInputsTask]: {
                        on: {
                          [WorkflowAction.ConfirmDangerousGoodsInputs]: WorkflowStateName.DangerousGoodsInputsReviewTask,
                        },
                      },
                      [WorkflowStateName.DangerousGoodsInputsReviewTask]: {
                        on: {
                          [WorkflowAction.ConfirmDangerousGoodsInputsReview]: WorkflowStateName.DangerousGoodsInputsDone,
                          [WorkflowAction.RejectDangerousGoodsInputsReview]: WorkflowStateName.DangerousGoodsInputsTask
                        },
                      },
                      [WorkflowStateName.DangerousGoodsInputsDone]: {
                        type: "final",
                      },
                    },
                  },
                  [WorkflowStateName.RAInputWrapper]: {
                    initial: WorkflowStateName.RAInputTask,
                    states: {
                      [WorkflowStateName.RAInputTask]: {
                        on: {
                          [WorkflowAction.ConfirmRAInput]: WorkflowStateName.RAInputReviewTask,
                        },
                      },
                      [WorkflowStateName.RAInputReviewTask]: {
                        on: {
                          [WorkflowAction.ConfirmRAInputReview]: WorkflowStateName.RAInputDone,
                          [WorkflowAction.RejectRAInputReview]: WorkflowStateName.RAInputTask
                        },
                      },
                      [WorkflowStateName.RAInputDone]: {
                        type: "final",
                      },
                    },
                  },
                  [WorkflowStateName.LogisticInputsWrapper]: {
                    initial: WorkflowStateName.LogisticInputs,
                    states: {
                      [WorkflowStateName.LogisticInputs]: {
                        on: {
                          "": [
                            {
                              target: WorkflowStateName.LogisticInputsTask,
                              cond: (context, event) => context.projectRequest.hasLogisticTask
                            },
                            {
                              target: WorkflowStateName.LogisticInputsDone,
                            },
                          ],
                        },
                      },
                      [WorkflowStateName.LogisticInputsTask]: {
                        on: {
                          [WorkflowAction.ConfirmLogisticInputs]: WorkflowStateName.LogisticInputsReviewTask,
                        },
                      },
                      [WorkflowStateName.LogisticInputsReviewTask]: {
                        on: {
                          [WorkflowAction.ConfirmLogisticInputsReview]: WorkflowStateName.LogisticInputsDone,
                          [WorkflowAction.RejectLogisticInputsReview]: WorkflowStateName.LogisticInputsTask
                        },
                      },
                      [WorkflowStateName.LogisticInputsDone]: {
                        type: "final",
                      },
                    },
                  },
                },
                meta: {
                  navigation: [RequestStep.ExternalInput]
                },
                onDone: WorkflowStateName.ExternalInputDone,
              },

              [WorkflowStateName.ExternalInputDone]: {
                type: "final",
              },
            },
          },

          [WorkflowStateName.ThirdPartyTechnicalReadinessBlockWrapper]: {
            initial: WorkflowStateName.ThirdPartyTranslationWrapper,
            states: {
              [WorkflowStateName.ThirdPartyTranslationWrapper]: {
                initial: WorkflowStateName.ThirdPartyTranslation,
                states: {
                  [WorkflowStateName.ThirdPartyTranslation]: {
                    on: {
                      "": [
                        {
                          target: WorkflowStateName.TranslationTask,
                          cond: (context, event) => context.projectRequest.isTranslationNeeded
                        },
                        {
                          target: WorkflowStateName.ThirdPartyTranslationTaskDone,
                        },
                      ],
                    },
                  },
                  [WorkflowStateName.TranslationTask]: {
                    on: {
                      [WorkflowAction.ConfirmTranslationTaskDone]: WorkflowStateName.ThirdPartyTranslationTaskDone,
                    },
                    meta: {
                      navigation: [RequestStep.ProductMasterDataText]
                    },
                  },
                  [WorkflowStateName.ThirdPartyTranslationTaskDone]: {
                    type: "final",
                  },
                },
                onDone: WorkflowStateName.ItemCreationTask
              },
              [WorkflowStateName.ItemCreationTask]: {
                on: {
                  [WorkflowAction.ConfirmItemCreationDone]: WorkflowStateName.ThirdPartyTechnicalReadinessParallelWrapper,
                },
                meta: {
                  navigation: [RequestStep.ItemCreation]
                },
              },
              [WorkflowStateName.ThirdPartyTechnicalReadinessParallelWrapper]: {
                type: "parallel",
                meta: {
                  navigation: [RequestStep.TechnicalReadiness]
                },
                states: {
                  [WorkflowStateName.ThirdPartyCamDBWrapper]: {
                    initial: WorkflowStateName.ThirdPartyCamDB,
                    states: {
                      [WorkflowStateName.ThirdPartyCamDB]: {
                        on: {
                          "": [
                            {
                              target: WorkflowStateName.CamDBTask,
                              //cond: hasCamDB,
                              cond: (context, event) => context.projectRequest.isCamDBUsed
                            },
                            {
                              target: WorkflowStateName.ThirdPartyCamDBRoutingTaskDone,
                            },
                          ],
                        },
                      },
                      [WorkflowStateName.CamDBTask]: {
                        on: {
                          [WorkflowAction.ConfirmCamDBDone]: WorkflowStateName.ThirdPartyCamDBRoutingTaskDone,
                        },
                      },
                      [WorkflowStateName.ThirdPartyCamDBRoutingTaskDone]: {
                        type: "final",
                      },
                    },
                  },
                  [WorkflowStateName.EShopWrapper]: {
                    initial: WorkflowStateName.EShop,
                    states: {
                      [WorkflowStateName.EShop]: {
                        on: {
                          "": [
                            {
                              target: WorkflowStateName.EShopTask,
                              cond: (context, event) => context.projectRequest.isEShopInvolved
                            },
                            {
                              target: WorkflowStateName.EShopTaskDone,
                            },
                          ],
                        },
                      },
                      [WorkflowStateName.EShopTask]: {
                        on: {
                          [WorkflowAction.ConfirmEShopDone]: WorkflowStateName.EShopTaskDone,
                        },
                      },
                      [WorkflowStateName.EShopTaskDone]: {
                        type: "final",
                      },
                    },
                  },
                  [WorkflowStateName.PM]: {
                    initial: WorkflowStateName.PMTask,
                    states: {
                      [WorkflowStateName.PMTask]: {
                        on: {
                          [WorkflowAction.ConfirmPMDone]: WorkflowStateName.PMTaskDone,
                        },
                      },
                      [WorkflowStateName.PMTaskDone]: {
                        type: "final",
                      },
                    },
                  },
                  [WorkflowStateName.ControllingWrapper]: {
                    initial: WorkflowStateName.ControllingTask,
                    states: {
                      [WorkflowStateName.ControllingTask]: {
                        on: {
                          [WorkflowAction.ConfirmControllingDone]: WorkflowStateName.ControllingTaskDone,
                        },
                      },
                      [WorkflowStateName.ControllingTaskDone]: {
                        type: "final",
                      },
                    },
                  },
                  [WorkflowStateName.RAChecklistTaskWrapper]: {
                    initial: WorkflowStateName.RAChecklistTask,
                    states: {
                      [WorkflowStateName.RAChecklistTask]: {
                        on: {
                          [WorkflowAction.ConfirmRAChecklist]: WorkflowStateName.RAChecklistTaskDone
                        }
                      },
                      [WorkflowStateName.RAChecklistTaskDone]: {
                        type: "final"
                      }
                    }
                  },
                  [WorkflowStateName.SimpleApprovalsWrapper]: {
                    initial: WorkflowStateName.SimpleApprovalCondition,
                    states: {
                      [WorkflowStateName.SimpleApprovalCondition]: {
                        on: {
                          "": [
                            {
                              target: WorkflowStateName.SimpleApprovalsTask,
                              cond: (context, event) => context.projectRequest.hasSimpleApprovalTasks
                            },
                            {
                              target: WorkflowStateName.SimpleApprovalsTaskDone,
                            },
                          ],
                        },
                      },
                      [WorkflowStateName.SimpleApprovalsTask]: {
                        on: {
                          [WorkflowAction.ConfirmSimpleApprovalsTaskDone]: WorkflowStateName.SimpleApprovalsTaskDone,
                        },
                      },
                      [WorkflowStateName.SimpleApprovalsTaskDone]: {
                        type: "final",
                      },
                    },
                  },
                  [WorkflowStateName.CountryTasks]: {
                    initial: WorkflowStateName.CountryTasksTrigger,
                    states: {
                      [WorkflowStateName.CountryTasksTrigger]: {
                        type: "final",

                      }
                    }
                  },
                  [WorkflowStateName.TemperatureWrapper]: {
                    initial: WorkflowStateName.Temperature,
                    states: {
                      [WorkflowStateName.Temperature]: {
                        on: {
                          "": [
                            {
                              target: WorkflowStateName.TemperatureTask,
                              cond: (context, event) => context.projectRequest.temperatureSensitive
                            },
                            {
                              target: WorkflowStateName.TemperatureTaskDone,
                            },
                          ],
                        },
                      },
                      [WorkflowStateName.TemperatureTask]: {
                        on: {
                          [WorkflowAction.ConfirmTemperatureTaskDone]: WorkflowStateName.TemperatureTaskDone,
                        },
                      },
                      [WorkflowStateName.TemperatureTaskDone]: {
                        type: "final",
                      },
                    }
                  },
                  [WorkflowStateName.ThirdPartyTranslationCheckWrapper]: {
                    initial: WorkflowStateName.ThirdPartyTranslationCheck,
                    states: {

                      [WorkflowStateName.ThirdPartyTranslationCheck]: {
                        on: {
                          "": [
                            {
                              target: WorkflowStateName.ThirdPartyTranslationCheckTask,
                              //cond: noTranslationNeeded,
                              cond: (context, event) => !context.projectRequest.isTranslationNeeded
                            },
                            {
                              target: WorkflowStateName.ThirdPartyTranslationCheckTaskDone,
                            },
                          ],
                        },
                      },
                      [WorkflowStateName.ThirdPartyTranslationCheckTask]: {
                        on: {
                          [WorkflowAction.ConfirmThirdPartyTranslationCheckTaskDone]: WorkflowStateName.ThirdPartyTranslationCheckTaskDone,
                        },
                      },
                      [WorkflowStateName.ThirdPartyTranslationCheckTaskDone]: {
                        type: "final",
                      },
                    },
                  }
                },
                onDone: WorkflowStateName.ThirdPartyTechnicalReadinessDone,
              },
              [WorkflowStateName.ThirdPartyTechnicalReadinessDone]: {
                type: "final",
              },
            },
          },
        },
        onDone: WorkflowStateName.RequestDone,
      },
      [WorkflowStateName.RequestDone]: {
        type: "final",
        meta: {
          navigation: ["Completed", RequestStep.CountryTasks]
        }
      },
      [WorkflowStateName.Withdrawn]: {
        type: "final",
        meta: {
          navigation: [NO_REQUESTSTEP]
        }
      }
    },
  });
  machine.StateMachineType = "ThirdParty";
  return machine;
}

function initStateMachineOwnManufactured(projectRequest: IProjectRequest, itemCreationDone: boolean): ISpeedUpStateMachine {
  const machineDefinition: any = {
    id: "fetch",
    initial: WorkflowStateName.StartTask,
    context: {
      retries: 0,
      projectRequest: projectRequest,
      itemCreationDone: itemCreationDone
    },
    states: {
      [WorkflowStateName.StartTask]: {
        on: {
          [WorkflowAction.Submit]: WorkflowStateName.KickOffTask,
        },
        meta: {
          navigation: [RequestStep.Start]
        }
      },
      [WorkflowStateName.KickOffTask]: {
        on: {
          [WorkflowAction.Reject]: WorkflowStateName.StartTask,
          [WorkflowAction.Withdraw]: WorkflowStateName.Withdrawn,
          [WorkflowAction.ApproveKickOff]: [{ target: WorkflowStateName.BomDataPreparationParallelWrapper }],
        },
        meta: {
          navigation: [RequestStep.KickOff]
        }
      },
      [WorkflowStateName.BomDataPreparationParallelWrapper]: {
        type: "parallel",
        // meta: {
        //   navigation: [RequestStep.BOMCreation, RequestStep.ProductMasterDataText]
        // },
        states: {
          [WorkflowStateName.BomDataTranslation]: {
            initial: WorkflowStateName.TranslationTask,
            states: {
              [WorkflowStateName.TranslationTask]: {
                meta: {
                  navigation: [RequestStep.ProductMasterDataText]
                },
                on: {
                  [WorkflowAction.ConfirmTranslationTaskDone]: WorkflowStateName.ItemCreationTask,
                },
              },
              [WorkflowStateName.ItemCreationTask]: {
                meta: {
                  navigation: [RequestStep.ItemCreation]
                },
                on: {
                  [WorkflowAction.ConfirmItemCreationDone]: {
                    target: WorkflowStateName.BOMSAPTechnicalReadynessParallelWrapper,
                    actions: assign<Context>({ itemCreationDone: true })
                  },
                }                
              },
              [WorkflowStateName.BOMSAPTechnicalReadynessParallelWrapper]: {
                type: "parallel",
                states: {                  
                  [WorkflowStateName.TechnicalReadinessParallelWrapperClosure]: {
                    initial: WorkflowStateName.TechnicalReadinessParallelWrapper,
                    states: {
                      [WorkflowStateName.TechnicalReadinessParallelWrapper]: {
                        type: "parallel",
                        meta: {
                          navigation: [RequestStep.TechnicalReadiness]
                        },
                        states: {
                          [WorkflowStateName.DangerousGoodsWrapper]: {
                            initial: WorkflowStateName.DangerousGoods,
                            states: {
                              [WorkflowStateName.DangerousGoods]: {
                                on: {
                                  "": [
                                    {
                                      target: WorkflowStateName.DangerousGoodsTask,
                                      cond: (context: any, event: any) => context.projectRequest.hasPotentialDangerousGoods
                                    },
                                    {
                                      target: WorkflowStateName.DangerousGoodsTaskDone,
                                    },
                                  ],
                                },
                              },
                              [WorkflowStateName.DangerousGoodsTask]: {
                                on: {
                                  [WorkflowAction.ConfirmDangerousGoodsDone]: WorkflowStateName.DangerousGoodsTaskDone,
                                },
                              },
                              [WorkflowStateName.DangerousGoodsTaskDone]: {
                                type: "final",
                              },
                            },
                          },
                          [WorkflowStateName.EShopWrapper]: {
                            initial: WorkflowStateName.EShop,
                            states: {
                              [WorkflowStateName.EShop]: {
                                on: {
                                  "": [
                                    {
                                      target: WorkflowStateName.EShopTask,
                                      cond: (context: any, event: any) => context.projectRequest.isEShopInvolved
                                    },
                                    {
                                      target: WorkflowStateName.EShopTaskDone,
                                    },
                                  ],
                                },
                              },
                              [WorkflowStateName.EShopTask]: {
                                on: {
                                  [WorkflowAction.ConfirmEShopDone]: WorkflowStateName.EShopTaskDone,
                                },
                              },
                              [WorkflowStateName.EShopTaskDone]: {
                                type: "final",
                              },
                            },
                          },
                          [WorkflowStateName.CamDBWrapper]: {
                            initial: WorkflowStateName.CamDB,
                            states: {
                              [WorkflowStateName.CamDB]: {
                                on: {
                                  "": [
                                    {
                                      target: WorkflowStateName.CamDBTask,
                                      cond: (context: any, event: any) => context.projectRequest.isCamDBUsed
                                    },
                                    {
                                      target: WorkflowStateName.CamDBTaskDone,
                                    },
                                  ],
                                },
                              },
                              [WorkflowStateName.CamDBTask]: {
                                on: {
                                  [WorkflowAction.ConfirmCamDBDone]: WorkflowStateName.CamDBTaskDone,
                                },
                              },
                              [WorkflowStateName.CamDBTaskDone]: {
                                type: "final",
                              },
                            },
                          },
                          [WorkflowStateName.LabelingWrapper]: {
                            initial: WorkflowStateName.Labeling,
                            states: {
                              [WorkflowStateName.Labeling]: {
                                on: {
                                  "": [
                                    {
                                      target: WorkflowStateName.LabelingTask,
                                      cond: (context: any, event: any) => context.projectRequest.isLabelingNeeded,
                                    },
                                    {
                                      target: WorkflowStateName.LabelingTaskDone,
                                    },
                                  ],
                                },
                              },
                              [WorkflowStateName.LabelingTask]: {
                                on: {
                                  [WorkflowAction.ConfirmLabelingDone]: WorkflowStateName.LabelingTaskDone,
                                },
                              },
                              [WorkflowStateName.LabelingTaskDone]: {
                                type: "final",
                              },
                            },
                          },
                          [WorkflowStateName.PictureWrapper]: {
                            initial: WorkflowStateName.Picture,
                            states: {
                              [WorkflowStateName.Picture]: {
                                on: {
                                  "": [
                                    {
                                      target: WorkflowStateName.PictureTask,
                                      cond: (context: any, event: any) => context.projectRequest.company.name == "Straumann"
                                    },
                                    {
                                      target: WorkflowStateName.PictureTaskDone,
                                    },
                                  ],
                                },
                              },
                              [WorkflowStateName.PictureTask]: {
                                on: {
                                  [WorkflowAction.ConfirmPictureDone]: WorkflowStateName.PictureTaskDone,
                                },
                              },
                              [WorkflowStateName.PictureTaskDone]: {
                                type: "final",
                              },
                            },
                          },
                          [WorkflowStateName.ControllingWrapper]: {
                            initial: WorkflowStateName.ControllingTask,
                            states: {
                              [WorkflowStateName.ControllingTask]: {
                                on: {
                                  [WorkflowAction.ConfirmControllingDone]: WorkflowStateName.ControllingTaskDone,
                                },
                              },
                              [WorkflowStateName.ControllingTaskDone]: {
                                type: "final",
                              },
                            },
                          },
                          [WorkflowStateName.LogisticWrapper]: {
                            initial: WorkflowStateName.Logistic,
                            states: {
                              [WorkflowStateName.Logistic]: {
                                on: {
                                  "": [
                                    {
                                      target: WorkflowStateName.LogisticTask,
                                      cond: (context: any, event: any) => context.projectRequest.hasLogisticTask,
                                    },
                                    {
                                      target: WorkflowStateName.LogisticTaskDone,
                                    },
                                  ],
                                },
                              },
                              [WorkflowStateName.LogisticTask]: {
                                on: {
                                  [WorkflowAction.ConfirmLogisticDone]: WorkflowStateName.LogisticTaskDone,
                                },
                              },
                              [WorkflowStateName.LogisticTaskDone]: {
                                type: "final",
                              }
                            },
                          },
                          [WorkflowStateName.RADBWrapper]: {
                            initial: WorkflowStateName.RADBCondition,
                            states: {
                              [WorkflowStateName.RADBCondition]: {
                                on: {
                                  "": [
                                    {
                                      target: WorkflowStateName.RADBTask,
                                      cond: (context: any, event: any) => !context.projectRequest.hasCourseMaterials
                                    },
                                    {
                                      target: WorkflowStateName.RADBTaskDone,
                                    },
                                  ],
                                },
                              },
                              [WorkflowStateName.RADBTask]: {
                                on: {
                                  [WorkflowAction.SkipRADBDone]: WorkflowStateName.RADBTaskDone,
                                  [WorkflowAction.ConfirmRADBDone]: WorkflowStateName.RADBTaskDone                                  
                                },
                              },
                              [WorkflowStateName.RADBTaskDone]: {
                                type: "final",
                              },
                            },
                          },
                          [WorkflowStateName.CustomsWrapper]: {
                            initial: WorkflowStateName.CustomsTask,
                            states: {
                              [WorkflowStateName.CustomsTask]: {
                                on: {
                                  [WorkflowAction.ConfirmCustomsDone]: WorkflowStateName.CustomsTaskDone,
                                },
                              },
                              [WorkflowStateName.CustomsTaskDone]: {
                                type: "final",
                              },
                            },
                          },
                          [WorkflowStateName.TemperatureWrapper]: {
                            initial: WorkflowStateName.Temperature,
                            states: {
                              [WorkflowStateName.Temperature]: {
                                on: {
                                  "": [
                                    {
                                      target: WorkflowStateName.TemperatureTask,
                                      cond: (context: any, event: any) => context.projectRequest.temperatureSensitive
                                    },
                                    {
                                      target: WorkflowStateName.TemperatureTaskDone,
                                    },
                                  ],
                                },
                              },
                              [WorkflowStateName.TemperatureTask]: {
                                on: {
                                  [WorkflowAction.ConfirmTemperatureTaskDone]: WorkflowStateName.TemperatureTaskDone,
                                },
                              },
                              [WorkflowStateName.TemperatureTaskDone]: {
                                type: "final",
                              },
                            }
                          },
                          [WorkflowStateName.SimpleApprovalsWrapper]: {
                            initial: WorkflowStateName.SimpleApprovalCondition,
                            states: {
                              [WorkflowStateName.SimpleApprovalCondition]: {
                                on: {
                                  "": [
                                    {
                                      target: WorkflowStateName.SimpleApprovalsTask,
                                      cond: (context:any, event:any) => context.projectRequest.hasSimpleApprovalTasks
                                    },
                                    {
                                      target: WorkflowStateName.SimpleApprovalsTaskDone,
                                    },
                                  ],
                                },
                              },
                              [WorkflowStateName.SimpleApprovalsTask]: {
                                on: {
                                  [WorkflowAction.ConfirmSimpleApprovalsTaskDone]: WorkflowStateName.SimpleApprovalsTaskDone,
                                },
                              },
                              [WorkflowStateName.SimpleApprovalsTaskDone]: {
                                type: "final",
                              },
                            },
                          },
                          [WorkflowStateName.CountryTasks]: {
                            initial: WorkflowStateName.CountryTasksTrigger,
                            states: {
                              [WorkflowStateName.CountryTasksTrigger]: {
                                type: "final"
                              }
                            }
                          },
                        },
                        onDone: WorkflowStateName.TechnicalReadinessParallelWrapperClosureDone,
                      },
                      [WorkflowStateName.TechnicalReadinessParallelWrapperClosureDone]: {
                        type: "final"
                      }
                    }
                  }
                },
                onDone: WorkflowStateName.BomDataTranslationDone,
              },
              [WorkflowStateName.BomDataTranslationDone]: {
                type: "final",
              },
            },
          },
          [WorkflowStateName.BomDataCollection]: {
            initial: WorkflowStateName.BomDataCollectionBOMCheck,
            states: {
              [WorkflowStateName.BomDataCollectionBOMCheck]: {
                on: {
                  "": [
                    {
                      target: WorkflowStateName.BomDataCollectionParallelWrapper,
                      cond: (context: any, event: any) => context.projectRequest.isBOMNeeded,
                    },
                    {
                      target: WorkflowStateName.BomDataCollectionDone,
                    },
                  ],
                },
              },
              [WorkflowStateName.BomDataCollectionParallelWrapper]: {
                type: "parallel",
                meta: {
                  navigation: [RequestStep.BOMCreation]
                },
                states: {
                  [WorkflowStateName.EngineeringCollection]: {
                    initial: WorkflowStateName.EngineeringCollectionTask,
                    states: {
                      [WorkflowStateName.EngineeringCollectionTask]: {
                        on: {
                          [WorkflowAction.ConfirmEngineeringDone]: WorkflowStateName.EngineeringCollectionTaskDone,
                        },
                      },
                      [WorkflowStateName.EngineeringCollectionTaskDone]: {
                        type: "final",
                      },
                    },
                  },
                  [WorkflowStateName.PackagingCollection]: {
                    initial: WorkflowStateName.PackagingCollectionTask,
                    states: {
                      [WorkflowStateName.PackagingCollectionTask]: {
                        on: {
                          [WorkflowAction.ConfirmPackagingDone]: WorkflowStateName.PackagingCollectionTaskDone,
                        },
                      },
                      [WorkflowStateName.PackagingCollectionTaskDone]: {
                        type: "final",
                      },
                    },
                  },
                  [WorkflowStateName.PMCollection]: {
                    initial: WorkflowStateName.PMCollectionTask,
                    states: {
                      [WorkflowStateName.PMCollectionTask]: {
                        on: {
                          [WorkflowAction.ConfirmPMDone]: WorkflowStateName.PMCollectionTaskDone,
                        },
                      },
                      [WorkflowStateName.PMCollectionTaskDone]: {
                        type: "final",
                      },
                    },
                  },
                  [WorkflowStateName.LabellingCollection]: {
                    initial: WorkflowStateName.LabellingCollectionTask,
                    states: {
                      [WorkflowStateName.LabellingCollectionTask]: {
                        on: {
                          [WorkflowAction.ConfirmBOMLabelingDone]: WorkflowStateName.LabellingCollectionTaskDone,
                        },
                      },
                      [WorkflowStateName.LabellingCollectionTaskDone]: {
                        type: "final",
                      },
                    },
                  },
                },
                onDone: WorkflowStateName.BOMSAPCreationWrapper,
              },
              [WorkflowStateName.BOMSAPCreationWrapper]: {
                initial: WorkflowStateName.BOMSAPCreationEntryCheck,
                states: {
                  [WorkflowStateName.BOMSAPCreationEntryCheck]: {
                    on: {
                      "": [
                        {
                          target: WorkflowStateName.BOMSAPCreationTask,
                          cond: (context: any, event: any) => context.itemCreationDone
                        },
                        {
                          target: WorkflowStateName.BOMWaitingForItemCreationDone,
                        },
                      ],        
                    },
                  },
                  [WorkflowStateName.BOMWaitingForItemCreationDone]:{
                    on:{
                      [WorkflowAction.ConfirmItemCreationDone]: WorkflowStateName.BOMSAPCreationTask
                    }
                  },
                  [WorkflowStateName.BOMSAPCreationTask]: {
                    meta: {
                      navigation: [RequestStep.BOMSAPLoad]
                    },
                    on: {
                      [WorkflowAction.ConfirmSapCreation]: WorkflowStateName.BOMSAPCreationWrapperDone,
                    },
                  },
                  [WorkflowStateName.BOMSAPCreationWrapperDone]:{
                    type: "final"
                  }
                },
                onDone: WorkflowStateName.BomDataCollectionDone
              },
              [WorkflowStateName.BomDataCollectionDone]: {
                type: "final",
              },
            },
          },
        },
        onDone: WorkflowStateName.RequestDone,
      },
      [WorkflowStateName.RequestDone]: {
        type: "final",
        meta: {
          navigation: ["Completed"]
        }
      },
      [WorkflowStateName.Withdrawn]: {
        type: "final",
        meta: {
          navigation: [NO_REQUESTSTEP]
        }
      }
    }
  };
  console.log(JSON.stringify(machineDefinition));
  let machine: ISpeedUpStateMachine = createMachine<Context>(machineDefinition);
  machine.StateMachineType = "OwnManufactured";
  return machine;
}
