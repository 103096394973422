import { ProjectRequestTask, RequestStep, RequestStepReverseMapping } from "interfaces";

export const TextConstants = {
  Request: {
    PageHeading_Project_Info: "Project Information",
    PageHeading_Product_Info: "Product Information",
    PageHeading_Translation: "Translation",
    PageHeading_Plm: "PLM Data",
    PageHeading_Logistics: "Logistic Data",
    PageHeading_Check_List: "Check-List",
    SectionHeading_Form: {
      Start: "New Global Product/Service",
      NormalAttachments: "Attachments",
      ExampleOfLabelAttachments: "Example of labels",
    },
    Label_RequestType: "Request Type",
    Label_RequestId: "Request Id",
    Label_Requester: "Requester",
    Label_RequesterEmail: "Requester Email",
    Label_RequestTitle: "Request title",
    Label_RequestNumber: "Request number",
    Label_Description: "Description",
    Label_Status: "Status",
    Label_PreKickOffDate: "Pre Kick-Off date",
    Label_KickOffDate: "Kick-Off date",
    Label_Company: "Company",
    Label_Division: "Division",
    Label_IsEShopInvolved: "E-Shop involved?",
    Label_EShop: "EShop",
    Label_HasPotentialDangerousGoods: "Potential dangerous goods?",
    Label_HasCourseMaterials: "Course Materials",
    Label_IsCamDBUsed: "Camdb will be used?",
    Label_CamDBProcessId: "Camdb Process Id",
    Label_IsLabelingNeeded: "Labeling needed?",
    Label_IsBOMNeeded: "Bom needed?",
    Label_Delegate: "Delegate",
    Label_AssignedTo: "Assigned to",
    Label_KickOffApproval: "Kick-Off approval",
    Label_AddCheckList: "Add new Check-List item",
    Label_ShowAllComments: "Show all comments",
    Label_ShowFullAuditlog: "Show full auditlog",
    Label_NewRequest: "New request",
    Label_RequestStep: "Step",
    Label_IsTranslationNeeded: "Translations done by TechComm?",
    Label_ProjectManager: "Project manager",
    Label_Completed: "Completed",
    Label_CompletedWithCountries: "Completed with countries",
    Label_CheckList: "Check-list",
    Label_PLMDesignOwner: "PLM Design owner",
    Label_Attachments: "Attachments",
    Label_PLMChangeNumber: "PLM change number",
    Label_LogisticFlow: "Logistic flow",
    Label_DistributionType: "Distribution type",
    Label_TemperatureSensitive: "Temperature sensitive?",
    Label_Brand: "Brand",
    Label_CreatedBy: "Created by",
    Label_ModifiedBy: "Modified by",
    Label_CreatedOn: "Created on",
    Label_ModifiedOn: "Modified on",
    Label_HasLogisticTask: "Logistic task?",
    Label_RequestSubmissionTime: "Request submission time",
    Label_RequestReSubmissionTime: "Request resubmission time",
    Label_RejectReason: "Reject reason",

    Label_NoCheckListItemSelected: "No checklist item was specified/selected.",
    SectionHeading_Languages: "Languages",

    Success_Message_RequestCreated: "Project request created successfully.",
    Success_Message_RequestSaved: "Project request saved successfully.",
    Success_Message_RequestSubmitted: "Project request submitted successfully.",

    Error_Message_MaterialRequired: "Atleast one material is required to initiate request.",
    Error_Message_LockingUnsucessfull: "Locking unsucessfull!!",
    Error_Message_LockingUnsucessfullSuggestion: "Could not lock the section!. Please reload request and try again.",
    Error_Message_LockOverriden: "Your lock got expired. Some one else changed the data in section that you were modifying. Request is now reloading.",
    Error_Message_LockingUnsucessfullTrySave: "Lock could not be extended!. To ensure your changes are not lost, please click on Save button immediately, otherwise there are chances that you can loose your changes.",

    RequestTypeSelector: {
      Heading_CreateNewGlobalProductsAndServices: "Create New Global Products and Services",
      Message_GuidanceDescription:
        "Is the <b><u>legal manufacturer</u></b> of this new Global Product/Service:\n<b>Straumann, Neodent, Anthogyr or Clear Correct</b>?",
      Message_DecisionHelpText:
        "*If you are not sure, please do not hesitate to contact us: \nT. Ribeirro (Neodent), M. Grandin (Anthogyr), S. Nag (Clear Correct), S. Proietto / M. Schaub / J. Jukowski / C. Mercadal (Straumann & Others)",
      SecondaryText_OwnManufactured: "New Global Products and Services STM/NEO/ANT/CC",
      SecondaryText_ThirdParty: "New Global Products and Services OTHERS",
    },
    Links_Languages: [
      { url: "#", name: "EN" },
      { url: "#", name: "DE" },
      { url: "#", name: "FR" },
      { url: "#", name: "IT" },
      { url: "#", name: "ES" },
      { url: "#", name: "PT" },
    ],
    Material: {
      Label_ProjectRequestId: "Project request",
      Label_MaterialNumber: "Material number",
      Label_ProductDescription: "Product description",
      Label_HasExpiryDate: "Expiry date?",
      Label_LegalManufacturer: "Legal manufacturer",
      Label_WorkpackageOrbit: "Workpackage orbit",
      Label_ProjectNumber: "Project number",
      Label_ProductHierarchy: "Product hierarchy",
      Label_Brand: "Brand",
      Label_Gtin: "GTIN",
      Label_Hibc: "HIBC",
      Label_MaterialType: "Material type",
      Label_ConfigType: "Config type",
      Label_PhaseStatus: "Phase status",
      Label_BatchManaged: "Batch managed?",
      Label_HasSerialNumber: "Serial number?",
      Label_CogsPlant: "COGS plant",
      Label_PrimaryPackage: "Primary package",
      Label_Contains: "Contains",
      Label_GrossWeight: "Weight (g)",
      Label_Length: "Length (mm)",
      Label_Width: "Width (mm)",
      Label_Height: "Height (mm)",
      Label_Volume: "Volume (cm3)",
      Label_SpecialTemperatureRequired: "Special temp. required",
      Label_MinStorageTemperature: "Min storage temp.",
      Label_MaxStorageTemperature: "Max storage temp.",
      Label_MaxTimeTransport: "Max time transport",
      Label_FrostSensitive: "Frost sensitive",
      Label_OtherRequirements: "Other requirements",
      Label_AirFreightComp: "Air freight comp.",
      Label_TemperatureLoggerRequired: "Temp. logger required",
      Label_LimitedQty: "Limited qty",
      Label_DgClassADR: "DG class ADR",
      Label_UnNumber: "UN number",
      Label_ContainsBattery: "Contains battery",
      Label_MedicalDevice: "Medical device",
      Label_Otc: "OTC",
      Label_RxOnly: "Rx only",
      Label_CndCode: "CND code",
      Label_EURule: "EU rule",
      Label_EUClass: "EU class",
      Label_EcCertificateNo: "EC certificate no.",
      Label_EcDeclarationName: "EC declaration name",
      Label_GmdnCode: "GMDN code",
      Label_ValidFrom: "Valid from",
      Label_TechBHinicalFile: "Tech + B:Hinical file",
      Label_LocalRegistration: "Local registration",
      Label_Sterile: "Sterile",
      Label_ForSingleUse: "Single use?",
      Label_Sterilizable: "Sterilizable",
      Label_SterilizationMethod: "Sterilization method",
      Label_RawMaterials: "Raw materials",
      Label_IfuNumber: "IFU number",
      Label_CountryOfOrigin: "Country of origin",
      Label_CustomsTariffCode: "Custom's tariff code",
      Label_Prh4: "PRH4",
      Label_Prh5: "PRH5",
      Label_BasicUDIDI: "Basic UDI-DI",
      Label_UnitOfUseDI: "Unit of use DI",
      Label_Gmdn: "GMDN",
      Label_EmdnCode: "EMDN code",
      Label_NeedSterilization: "Need sterilization",
      Label_MriStatus: "MRI status",
      Label_Kit: "Kit",
      Label_ComboProduct: "Combo product",
      Label_DmApplicable: "DM applicable",
      Label_DmApplied: "DM applied",
      Label_Diameter: "Diameter (mm)",
      Label_GingivaHeight: "Gingiva height (mm)",
      Label_AbutmentHeight: "Abutment height (mm)",
      Label_Angle: "Angle",
      Label_GranuleSize: "Granule size",
      Label_MdaMdnCode: "MDA/MDN code",
      Label_MdsCodeI: "MDS code I",
      Label_MdsCodeII: "MDS code II",
      Label_MdsCodeIII: "MDS code III",
      Label_MdtCodeI: "MDT code I",
      Label_MdtCodeII: "MDT code II",
      Label_MdtCodeIII: "MDT code III",
      Label_RawMaterial: "Raw material",
      Label_BomItems: "BOM items",
      Label_Translations: "Translations",
      Label_LockInfo: "Lock Info",
      Label_ECCertificateReceived: "EC certificate received",
      Label_EUComplianceLegislation: "EU compliance with which legislation?",
      Label_ItemCategoryApplies: "In which category the item applies?",
      Label_SafetyDataSheetReady: "Safety data sheet ready",
      Label_CountriesAlreadyRegistered: "Countries already registered",
      Label_CountriesWhereUnderRegisteration: "Where is the product currently under registration?",
      Label_SterilizationByUser: "Sterilization by the user",
      Label_IFUAvailable: "IFU available",
      Label_LabOfficeCode: "Lab-Office-Code",
      Label_LabOfficeCodeDescription: "Lab-Office-Code-Description",

      Message_CopyInProgress: "Pasting data is in progress",
      Error_Message_GTINorHIBCRequired: "One of GTIN or HIBC needs to provided."
    },
    BOMItem: {
      Label_ProjectRequestId: "Project request id",
      Label_MaterialId: "Material id",
      Label_Item: "Item",
      Label_ItemCategory: "Item category",
      Label_FixedQty: "Fixed qty",
      Label_Component: "Component",
      Label_ShortText: "Short text",
      Label_ComponentQty: "Component qty",
      Label_BaseUnitOfMeasure: "Base unit of measure",
      Label_ProductionRelevant: "Production relevant",
      Label_ConstructionRelevant: "Construction relevant",
      Label_CostingRelevant: "Costing relevant",
    },
    Translations: {
      Label_ProjectRequestId: "Project request id",
      Label_MaterialId: "Material id",
      Label_ProductMasterText: "Sales text",
      Label_ShortDescription: "Short description",
      Label_MasterDescriptionLine1: "Master description line 1",
      Label_MasterDescriptionLine2: "Master description line 2",
      Label_MasterDescriptionLine3: "Master description line 3",
      Label_MasterDescriptionLine4: "Master description line 4",
      Label_LanguageCode: "Language code",

      Tooltip_DeleteLanguage: "Delete language translations",

      Title_SeeTranslationValidationErrors: "Click to see validation errors in this language translations",
      Label_Button_AddLanguage: "Add new language",

      Label_SelectLanguage: "Select language to add to request",
      Heading_AddNewLanguage: "Add new language",
      Confirmation_Message_DeleteLanguageTransaltions: "Delete \"{0}\" language translations? Once done, this action cannot be reverted."
    },
    Attachment: {
      Label_ProjectRequestId: "Project request Id",
      Label_Data: "Data",
      Label_FileName: "File name",
      Label_RequestStep: "Request step",
      Label_Category: "Category",
      Label_ContentType: "Content type",
    },
    SimpleApproval: {
      Heading_TaskDescription: "Description",
    },
    TaskOverview: {
      Label_Step: "Request step",
      Label_Task: "Task",
      Label_Responsible: "Responsible",
      Label_Status: "Status",
      Label_CompletedOn: "Completed on",
      Label_DueDate: "Due date"
    },
    SectionLock: {
      Label_LockedBy: "Locked by",
      Label_LockedSince: "Locked since",
      Label_LockedUntill: "Locked until",
      Label_EntityId: "Entity Id",
      Label_SectionId: "Section id",
      Message_ClickToUnlock: "Click on lock icon to unlock section.",
      ErrorMessage_SectionUnlockFailed: "Sorry, unable to un-lock section at the moment. Please try after some time."
    },
    Confirmation_Message_DeleteMaterial: "Are you sure you want to delete material(s).",
    Confirmation_Message_DeleteBOMItem: "Are you sure you want to delete the selected BOM Item(s).",
    Confirmation_Message_SaveBOMItemsBeforeSwitchTitle: "Save BOM items",
    Confirmation_Message_SaveBOMItemsBeforeSwitch: "Would you like to save the BOM Item changes before switching?",
    ItemCreation: {
      SectionHeading_Item_SAP_Load: "Item SAP Load",
      SectionHeading_Sales_Text_Load: "Sales Text Load",
      SectionHeading_Short_Text_Load: "Short Text Load",
      SectionHeading_GTIN_Load: "GTIN Load",
    },
  },
  Admin: {
    Label_Select_table_Config: "Select Table Configuration",
    Title_Button_Menu: "Admin Menu",
    Heading_Menu: "Admin Menu",
    SectionHeading_Metadata_Configuration: "Metadata Configuration",
    SectionHeading_ManageGroups: "Manage Groups",
    SectionHeading_ManageGroupUsers: "Manage User Permissions",
    SectionHeading_Manage_FAQ: "Manage FAQ",
    SectionHeading_Manage_Help_Pages: "Manage Help Pages",
    SectionHeading_Edit_Help_Pages: "Edit Help Page",
    SectionHeading_Manage_Responsibility_Matrix: "Task Responsibility",
    SectionHeading_Manage_Color_Scheme_Matrix: "Color Scheme Matrix",
    SectionHeading_External_Invitation: "External User Invitation",
    SectionHeading_All_Invitations: "All Invitations",
    SectionHeading_Action_Required_Invitations: "ACTION REQUIRED: Not Redeemed & Expired",
    SectionHeading_ManageHelpDocuments: "Help Documents",
    Placeholder_Help_Page_Editor: "Write something informative...",
    Label_Help_Page_Step: "Step",
    Delete_Message_Help_Page: "Do you want to delete this Helper Page's?",
    Delete_Message_Faq_Single: "Do you want to delete this FAQ item?",
    Delete_Message_Faq_Multiple: "Do you want to delete selected FAQ item(s)?",
    Delete_Message_Multiple_Generic: "Do you want to delete selected item(s)?",
    Label_Question: "Question",
    Label_Answer: "Answer",
    Label_Group: "Group",
    Label_Task: "Task",
    Label_Tab: "Tab",
    Label_Company: "Company",
    Label_Responsible: "Responsibles",
    Label_DisplayName: "Display name",
    Label_CreateInvitation: "Create Invitation",
    Label_InvitationEmail: "Invitation Email",
    Label_IsRedeemed: "Is Redeemed",
    Label_InvitationCode: "Invitation Code",
    Label_ExpirationDate: "Expiration Date",
    Label_Resend_Invitation: "Resend Invitation",
    ResponsibilityMatrix: {
      Label_StateMachineTaskName: "State Machine Task name",
      Label_StateMachineOutcomeKeys: "State Machine Outcome keys",
      Label_UseRequestFieldToken: "Use project request field as responsible",
      Label_RequestFieldName: "Request field internal name",
      Label_DurationForDueDate: "Duration for Due Date",
      Label_ShowTaskAs: "Show task as",
      Label_TaskDescription: "Task description",
      Label_ComplexTask: "Complex Approval",
      Label_SimpleTask: "Simple Approval",
      Label_RequestTab: "Tab"
    },
    Reports: {
      SectionHeading_Request_Report: "Request Report",
      Label_Duration_By_Request: "Duration By Request",
      Label_Duration_By_Task: "Duration By Task",
      Label_Start_Between: "Start Between",
      Label_Close_Between: "Close Between",
      Label_State: "State",
      Label_SpeedUp_ID: "SpeedUp ID",
    },
    ColorMatrix: {
      Label_Type: "Type",
      Label_Value: "Key",
      Label_Color: "Color",
      Label_ColorFormat: "Format",
    },
    HelpDocument: {
      Label_DocumentKey: "Document key",
      Label_FileName: "File name",
      Label_HelpDocument: "Help document",
      Label_Link: "Link"
    },
    CheckUserPermission:{
      Label_NoUserSelected:"Please select the user!",
      Label_NoGroupsForSelectedUser:"Selected user not the member of any group!"
    }
  },
  Dashboard: {
    Label_Request_Id: "Request Id",
    Label_Requester: "Requester",
    Label_Request_Title: "Request title",
    Label_Request_Type: "Request type",
    Label_Description: "Description",
    Label_Company: "Company",
    Label_Brand: "Brand",
    Label_Creation_Date: "Creation date",
    Label_Overall_Status: "Overall status",
    Label_Tasks: "Tasks",
    Label_TaskId: "Task Id",
    Label_TaskTitle: "Title",
    Label_TaskOwner: "Owner",
    Label_TaskAssignmentDate: "Assignment date",
    Label_ClearAdvanceSearchFilter: "Clear advance filters",
    Heading_AdvanceSearch: "Advance search",
    Error_Message_AtleastOneFilterRequired: "Please specify at least one parameter to search",
    DataNotAvailable_Message: "The search key '{searchkey}' was not found in any record"
  },
  Label_Save: "Save",
  Label_Apply: "Apply",
  Label_Clear: "Clear",
  Label_Clear_All: "Clear All",
  Label_Clear_All_Filter: "Clear All Filter",
  Label_Help: "Help",
  Label_FAQ: "FAQ",
  Label_CheckListComments: "Comments",
  Label_Comments: "Comments / Audit Logs",
  Label_Add: "Add",
  Label_Add_New: "Add New",
  Label_Add_Item: "Add Item",
  Label_Close: "Close",
  Label_Cancel: "Cancel",
  Label_Delete: "Delete",
  Label_Submit: "Submit",
  Label_Claim: "Claim",
  Label_Reject: "Reject",
  Label_Complete: "Complete",
  Label_Status: "Status",
  Label_Description: "Description",
  Label_View: "View",
  Label_Edit: "Edit",
  Label_ID: "ID",
  SELECT_TABLE_CONFIG: "Select Table Configuration",
  Common: {
    Label_CreatedBy: "Created By",
    Label_ModifiedBy: "Modified By",
    Label_CreatedOn: "Created On",
    Label_ModifiedOn: "Modified On",
    Label_Search: "Search",
    Label_Yes: "Yes",
    Label_No: "No",
    Label_Action: "Action",
    Label_Button_Ok: "OK",
    Label_Button_Save: "Save",
    Label_Button_Cancel: "Cancel",
    Label_Button_Close: "Close",
    Label_Button_Exit: "Exit",
    Label_Button_Edit: "Edit",
    Label_Button_Create: "Create",
    Label_Button_Update: "Update",
    Label_Button_Delete: "Delete",
    Label_Button_SaveAndContinue: "Save & Continue",
    Label_Button_LeaveWithoutSavingChanges: "Leave without saving",
    Label_Button_DeleteSelected: "Delete selected",
    Label_Button_Add: "Add",
    Label_Button_New: "New",
    Label_Button_Clear_Filter: "Clear filter",
    Label_Button_Export_Excel: "Export to Excel",
    Label_Button_Export_TXT: "Export to TXT",
    Label_Button_DeleteRows: "Delete row(s)",
    Label_Button_AddNewRow: "Add new row",
    Label_StartDate: "Start date",
    Label_EndDate: "End date",
    Label_DurationInDays: "Duration in days",
    Label_DurationInWeeks: "Duration in weeks",
    Label_Button_Refresh: "Refresh",
    Label_Button_CheckPermission: "Check User Permissions",

    Title_SaveChanges: "Save changes",
    Error_Message_Required: "Required",
    Error_Message_ExceedsMaxLength: "Max. {maxLength} characters are allowed.",
    Error_Message_InvalidInteger: "Only full numbers without decimal are allowed.",
    Error_Message_ExceedsMaxValue: "Value should be less than or equal to {maxValue}.",
    Error_Message_InvalidNumber: "Only numeric values are allowed.",
    Error_Message_LessThanMinValue: "Value should be greater than or equal to {minValue}.",
    Error_Message_DatePastMaxValue: "Date should be less than or equal to {fieldname}.",
    Error_Message_DateLessThanMinValue: "Date should be greater than or equal to {fieldname}.",
    Error_Message_YesNoAllowed: "Only Yes or No value is allowed.",
    Error_Message_UserNoValid: "User/Group not valid",
    Error_Message_FileNotValid: "File not valid",
    Error_Message_SessionStorageDisabled: "Session storage not enabled...",
    Error_Message_LocalStorageDisabled: "Local storage not enabled...",
    Error_Message_PageNotFound: "Sorry, the page you are looking for does not exists.",
    Error_Message_RequestNotFound: "Request does not exists.",
    Error_Message_FailedToFetch:"There was a possible internet disconnection. Please retry your action and if it is still not successfull try reloading page and then do the action again.",
    Warning_Message_SaveChanges:"You have unsaved changes on this page. Please save the changes.",

    Title_GotoErrorLocation: "Goto error location",

    Confirmation_Message_DeleteRows: "Delete rows ({rowsCount})",
    Confirmation_Message_NavigateUnsavedChanges:
      "You have some unsaved changes, do you want to save those changes before leaving the current step?",

    Message_LoadingData: "Loading data...",
    Tooltip_AddMaterialToEnableSection: "Add at least one material to enable this section"
  },
  AdminMenu: {
    Title_Button_Menu: "Administration",
    Heading_Menu: "Administration",
  },
  Layout: {
    Label_ApplicationName: "Speed Up™",
    Label_Straumann: "Straumann™",
    Label_CopyRights: `© ${new Date().getFullYear()} Institut Straumann AG`,
  },
  LoggedInUser: {
    Label_ProfileSettings: "profile settings",
    Label_Button_SignOut: "Sign out",
    Label_Button_EditProfile: "Edit profile",
    Label_Button_ChangePassword: "Change password",
  },
  HomePage: {
    Label_Legend_NoOfOpenTasks: "No. of open tasks",
    Label_Legend_LeadTimeInDays: "Lead time in days",
    Label_Legend_LeadTimeInWeeks: "Lead time in weeks",
    Label_Button_StartNewRequest: "Start new request",
    Label_Button_DisableStartNewRequest: "Are you a Global Product Manager? Then, request access at data.governance@straumann.com",
    Label_Button_MyPendingTasks: "My pending tasks",
    Label_Button_MyCompletedTasks: "My completed tasks",
    Label_Button_Home: "Home",
    Label_Button_MyRequests: "My requests",
    Label_Button_AllOpenRequests: "All open requests",
    Label_Button_AllCompletedRequests: "All completed requests",
    Label_Button_AllRequests: "All requests",
    Label_Button_Reports: "Reports",
    Heading_Tab_RequestOverview: "Request overview",
    Heading_Tab_TaskOverview: "Task overview",
    Heading_Tab_RequestsLeadTime: "Requests lead time",
    Heading_Tab_TasksAverageLeadTime: "Tasks average lead time",
    Heading_Tab_SpeedUpProcess: "SpeedUP process",
    Heading_Section_OpenRequestsOverview: "Open Requests Overview",
    Heading_Section_CompletedRequestsOverview: "Completed Requests Overview",
    Heading_Section_OpenTasksOverview: "Open Tasks Overview",
    Heading_Section_RequestsLeadTimeByCompanyBrands: "Requests Lead Time by Company / Brands",
    Heading_Section_TasksAverageLeadTime: "Tasks Average Lead Time",
    Heading_Chart_OpenOwnManufacturedRequestsByCompany: "Open Own Manufactured Requests by Company",
    Heading_Chart_OpenThirdPartyRequestsByBrand: "Open Third Party Requests by Brand",
    Heading_Chart_OwnManufacturedCompletedRequestsByCompany: "Own Manufactured Completed Requests by Company",
    Heading_Chart_ThirdPartyCompletedRequestsByBrand: "Third Party Completed Requests by Brand",
    Heading_Chart_OwnManufacturedOpenTasksByCompany: "Own Manufactured Open Tasks by Company",
    Heading_Chart_ThirdPartyOpenTasksByBrand: "Third Party Open Tasks by Brand",
    Heading_Chart_OwnManufacturedLeadTimeByCompany: "Own Manufactured Lead Time by Company",
    Heading_Chart_ThirdPartyLeadTimeByBrand: "Third Party Lead Time by Brand",
    Heading_Chart_OwnManufacturedTasksLeadTime: "Own Manufactured Tasks Lead Time",
    Heading_Chart_ThirdPartyTasksLeadTimeByBrand: "Third Party Tasks Lead Time by Brand",
    Heading_Chart_NoDataToDisplay: "No data to display",
    Heading_Chart_LoadingData: "Loading data...",
    Heading_Chart_LoadingDataDescription: "Loading data for ",
    Heading_Section_Tasks: "Tasks",
    Heading_Section_Requests: "Requests"
  },
  GroupList: {
    Label_Table_Column_Id: "Id",
    Label_Table_Column_Name: "Name",
    Label_Delete_Table_Column_Name:"Remove user from group",
    Label_Table_Column_IsAdminGroup: "Is admin group",
    Label_Table_Column_IsProcessAdminGroup: "Is process admin group",
    Label_Table_Column_IsDeveloperGroup: "Is developer group",
    Label_Table_Column_GroupMembers: "Group members",
    Heading_Section_GroupSettings: "Group Settings",
    Heading_Section_NewGroup: "New Group Settings",
    Message_LoadingGroup: "Loading group",
    Message_RefreshingGroupList: "Refreshing group list",
  },
  ManageGroupForm: {
    Label_GroupName: "Group name",
    Label_IsProcessAdminGroup: "Process admin group",
    Label_GroupMembers: "Group members",
    Heading_Section_GroupSettings: "Group settings",
    Heading_Section_NewGroup: "New Group settings",
    Heading_Section_ManageGroupMembership: "Manage Group Membership",
    Heading_Section_AddGroupMembers: "Add Group Members",
    Heading_Section_GroupMembers: "Group Members",
    Label_Table_Column_Id: "Id",
    Label_Table_Column_User: "User",
    Label_Button_DeleteGroup: "Delete group",
    Placeholder_AddUsersToGroup: "Select user(s) to add to group",
    Placeholder_GetGroups: "Select user to get the groups",
    Message_UpdatingGroup: "Updating group",
    Message_CreatingGroup: "Creating group",
    Message_AddingUsersToGroup: "Adding user(s) to the group",
    Message_RemovingUsersFromGroup: "Removing user(s) from group",
    Confirmation_Message_RemoveUsersFromGroup: "Are you sure you want to remove user(s) from this group?",
    Confirmation_Message_DeleteGroup: "Are you sure you want to delete this group?",
    Confirmation_Message_DeleteRequest:"Are you sure you want to delete this request?",
  },
  RequestNavigation: {
    New: "new",
    Steps: {
      [RequestStepReverseMapping.Start]: {
        Label_Root: RequestStep.Start,
        Tabs: {
          Label_ProjectInfo: "Project information",
          Label_ProductInfo: "Product information",
          Label_Translations: "Translations",
        },
      },
      [RequestStepReverseMapping[RequestStep.KickOff]]: {
        Label_Root: RequestStep.KickOff,
        Tabs: {
          Label_PLMData: "PLM data",
          Label_LogisticsData: "Logistics data",
          Label_CheckList: "Check list data",
        },
      },
      [RequestStepReverseMapping[RequestStep.ProductMasterDataText]]: {
        Label_Root: RequestStep.ProductMasterDataText,
        Tabs: {
          Label_Translations: "Translations",
        },
      },
      [RequestStepReverseMapping[RequestStep.BOMCreation]]: {
        Label_Root: RequestStep.BOMCreation,
        Tabs: {
          Label_BOMDataCollection: "Collect BOM data",
        },
      },
      [RequestStepReverseMapping[RequestStep.BOMSAPLoad]]: {
        Label_Root: RequestStep.BOMSAPLoad,
        Tabs: {
          Label_BOMSAPLoad: "BOM SAP load",
        },
      },
      [RequestStepReverseMapping[RequestStep.ItemCreation]]: {
        Label_Root: RequestStep.ItemCreation,
        Tabs: {
          Label_ItemCreation: RequestStep.ItemCreation,
        },
      },
      [RequestStepReverseMapping[RequestStep.ExternalInput]]: {
        Label_Root: RequestStep.ExternalInput,
        Tabs: {
          Label_PackagingAndHandling: "Packaging & Handling",
          Label_DangerousGoods: "Dangerous goods",
          Label_RA: "RA",
          Label_Customs: "Customs",
        },
      },
      [RequestStepReverseMapping[RequestStep.TechnicalReadiness]]: {
        Label_Root: RequestStep.TechnicalReadiness,
        Tabs: {
          Label_Overview: "Overview",
          Label_CAMDbRouting: ProjectRequestTask.CAMDbRouting,
          Label_Labeling: ProjectRequestTask.Labeling,
          Label_RAChecklist: ProjectRequestTask.RAChecklist,
          Label_EShop: ProjectRequestTask.EShop,
          Label_DangerousGoods: ProjectRequestTask.DangerousGoods,
          Label_Controlling: ProjectRequestTask.Controlling,
          Label_Logistics: ProjectRequestTask.Logistics,
          Label_Customs: ProjectRequestTask.Customs,
          Label_Picture: ProjectRequestTask.Picture,
          Label_RADB: ProjectRequestTask.RADB,
          Label_TemperatureSensitive: ProjectRequestTask.TemperatureSensitive,
          Label_PM: ProjectRequestTask.PM,
          Label_ProductMasterDataCheck: ProjectRequestTask.ProductMasterDataCheck,
          Label_PurchasingClassification: ProjectRequestTask.PurchasingClassification,
        },
      },
      [RequestStepReverseMapping[RequestStep.CountryTasks]]: {
        Label_Root: RequestStep.CountryTasks,
        Tabs: {
          Label_Overview: "Overview",
        },
      },
    },
  },
  TaskDetails: {
    Label_TaskTitle: "Title",
    Label_TaskHistory: "Task history",
    Label_CompletedOn: "Completed on",
    Label_StartOn: "Started on",
    Label_Status: "Status",
    Label_Outcome: "Outcome",
    Label_Responsible: "Responsible",
    Label_DueDate: "Due date",
    Label_Status_NotStarted: "Not yet started"
  }
};
