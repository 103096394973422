import { AuthenticationResult, BrowserAuthError, PopupRequest } from "@azure/msal-browser";
import { useMsal } from "@azure/msal-react";
import {
  ActionButton,
  Callout,
  CompoundButton,
  DefaultButton,
  DirectionalHint,
  IButtonStyles,
  Persona,
  PersonaPresence,
  PersonaSize,
  Stack,
  Text,
} from "@fluentui/react";
import { useBoolean, useId } from "@fluentui/react-hooks";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { clearCurrentUser, setCurrentUser, showErrorMessage, updateCurrentUser } from "../index";
import { b2cPolicies, loginRequest } from "authConfig/AuthConfig";
import { IUser } from "interfaces";
import { CommonService } from "services";
import { RootState } from "store";
import { hideErrorMessage } from "../errorMessage/errorMessageSlice";
import styles from "./LoggedInUser.module.scss";
import { ErrorRegions, TextConstants } from "constant";

export interface ILoggedInUser {
  user?: IUser;
}

const actionButtonStyles: IButtonStyles = {
  label: {
    margin: 0,
  },
};

export const LoggedInUser: React.FunctionComponent<ILoggedInUser> = (props: ILoggedInUser) => {
  const dispatch = useDispatch();
  const currentUser = useSelector((state: RootState) => state.currentUser.value);
  const { instance, accounts } = useMsal();
  const [isCalloutVisible, { toggle: toggleIsCalloutVisible }] = useBoolean(false);
  const [userInitial, setUserInitial] = useState<string>();
  const buttonId = useId("loggedInUserButton");

  useEffect(() => {
    setUserInitial(CommonService.getFullNameInitials(currentUser?.displayName ?? ""));
  }, [currentUser]);

  const onClickMeButton = () => {
    if (currentUser?.email) {
      toggleIsCalloutVisible();
    } else {
      instance
        .loginRedirect(loginRequest)
        // .then((result: AuthenticationResult | null) => {
        //   if (result) {
        //     const currentUser: IUser = CommonService.mapIdTokenClaimsToUserObject(result.idTokenClaims);
        //     dispatch(setCurrentUser(currentUser));
        //     dispatch(hideErrorMessage(ErrorRegions.Login));
        //   }
        // })
        .catch((error) => {
          if (error instanceof BrowserAuthError) {
            console.error(error);
            dispatch(
              showErrorMessage({
                error,
                errorOriginationKey: ErrorRegions.Login,
              })
            );
          } else if (error) {
            console.error(error);
            dispatch(
              showErrorMessage({
                error,
                errorOriginationKey: ErrorRegions.Login,
              })
            );
          }
        });
    }
  };

  const openEditProfile = () => {
    const editProfileRequest: PopupRequest = {
      authority: b2cPolicies.authorities.editProfile.authority,
      account: accounts[0],
      scopes: loginRequest.scopes,
    };

    toggleIsCalloutVisible();

    instance
      .loginPopup(editProfileRequest)
      .then((result) => {
        if (result) {
          const currentUser: IUser = CommonService.mapIdTokenClaimsToUserObject(result.idTokenClaims);
          dispatch(updateCurrentUser(currentUser));
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const openChangePassword = () => {
    const editProfileRequest: PopupRequest = {
      authority: b2cPolicies.authorities.changePassword.authority,
      account: accounts[0],
      scopes: loginRequest.scopes,
    };

    toggleIsCalloutVisible();

    instance.loginPopup(editProfileRequest).catch((error) => {
      console.log(error);
    });
  };

  const signOutUser = () => {
    const logoutRequest = {
      account: accounts[0],
      postLogoutRedirectUri: "/",
    };

    toggleIsCalloutVisible();

    instance.logoutRedirect(logoutRequest).then((_) => {
      dispatch(clearCurrentUser());
    });
  };

  return (
    <div>
      <CompoundButton id={buttonId} onClick={onClickMeButton} className={styles.meButton}>
        <Persona
          className={styles.persona}
          initialsColor={"#222425"}
          coinProps={{
            className: styles.personaInitial,
            styles: {
              initials: {
                border: "1px solid #fff",
                fontWeight: 400,
                "&:hover": {
                  background: "#0078D7",
                },
              },
            },
          }}
          presence={currentUser.email ? PersonaPresence.online : undefined}
          imageInitials={userInitial}
          text={currentUser.displayName}
          secondaryText={currentUser.email}
          hidePersonaDetails={true}
          imageAlt={`${currentUser.displayName} ${TextConstants.LoggedInUser.Label_ProfileSettings}`}
          size={PersonaSize.size32}
        />
      </CompoundButton>
      {isCalloutVisible && (
        <Callout
          role="dialog"
          className={styles.meCallout}
          gapSpace={0}
          target={`#${buttonId}`}
          isBeakVisible={false}
          onDismiss={toggleIsCalloutVisible}
          directionalHint={DirectionalHint.bottomRightEdge}
          setInitialFocus
        >
          <Stack horizontal horizontalAlign="end" verticalAlign="center" tokens={{ childrenGap: 10 }}>
            {/* <Text className={styles.loggenInLabel}>{"Logged in as:"}</Text> */}
            <DefaultButton
              text={TextConstants.LoggedInUser.Label_Button_SignOut}
              onClick={signOutUser}
              className={styles.signOutButton}
            />
          </Stack>
          <Stack horizontalAlign="start" className={styles.userInfoAndAction} tokens={{ childrenGap: 10 }}>
            <Text className={styles.name}>{currentUser.displayName}</Text>
            <Text className={styles.email}>{currentUser.email}</Text>
            {/* <ActionButton styles={actionButtonStyles} className={styles.link} onClick={openEditProfile}>
              {TextConstants.LoggedInUser.Label_Button_EditProfile}
            </ActionButton> */}
            {currentUser?.isLocalUser && (
              <ActionButton styles={actionButtonStyles} className={styles.link} onClick={openChangePassword}>
                {TextConstants.LoggedInUser.Label_Button_ChangePassword}
              </ActionButton>
            )}
          </Stack>
        </Callout>
      )}
    </div>
  );
};
