import { createSlice } from "@reduxjs/toolkit";

const DEFAULT_LOADSPINNER_MESSAGE = "Processing...";

export const loadingSpinnerSlice = createSlice({
  name: "loadingSpinner",
  initialState: {
    value: {
      showSpinner: false,
      message: DEFAULT_LOADSPINNER_MESSAGE,
    },
  },
  reducers: {
    showLoadingSpinner: (state) => {
      state.value = {
        showSpinner: true,
        message: DEFAULT_LOADSPINNER_MESSAGE,
      };
    },
    showLoadingSpinnerWithMessage: (state, action) => {
      state.value = {
        showSpinner: true,
        message: action.payload ?? DEFAULT_LOADSPINNER_MESSAGE,
      };
    },
    hideLoadingSpinner: (state) => {
      state.value = {
        showSpinner: false,
        message: DEFAULT_LOADSPINNER_MESSAGE,
      };
    },
  },
});

export const { showLoadingSpinner, hideLoadingSpinner, showLoadingSpinnerWithMessage } = loadingSpinnerSlice.actions;

export const loadingSpinnerReducer = loadingSpinnerSlice.reducer;
