import moment from "moment";
export function convertDate(inputFormat: Date) {
  var mnth = ("0" + (inputFormat.getMonth() + 1)).slice(-2),
    day = ("0" + inputFormat.getDate()).slice(-2);
  return [mnth, day, inputFormat.getFullYear()].join("/");
}

export function formatDateToLocal(inputFormat: Date) {
  if (inputFormat) {
    return new Date(inputFormat).toLocaleString(navigator.language);
  }

  return "";
}

export function formatISODateTime(inputFormat: string | Date) {
  if (inputFormat) {
    let date = moment.utc(inputFormat).local();
    return date.format("DD-MM-YYYY HH:mm");
  }

  return "";
}

export function formatISODate(inputFormat: string | Date) {
  if (inputFormat) {
    let date = moment.utc(inputFormat).local();
    return date.format("DD-MM-YYYY");
  }

  return "";
}

export function convertDateToISO(inputFormat: string) {
  if (inputFormat) {
    const date = new Date(inputFormat);
    return date.toISOString();
  }

  return "";
}

export function getPreviouseMothsArray(range: number) {
  let monthNames = [];
  let monthName = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];
  var d = new Date();
  d.setMonth(d.getMonth() - 1);
  d.setDate(1);
  for (let i = 0; i < range; i++) {
    monthNames.push(monthName[d.getMonth()] + " " + d.getFullYear());
    d.setMonth(d.getMonth() - 1);
  }
  return monthNames.reverse();
}
