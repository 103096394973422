import { AuthenticationResult, EventMessage, EventType, InteractionRequiredAuthError, InteractionStatus } from "@azure/msal-browser";
import { useIsAuthenticated, useMsal } from "@azure/msal-react";
import { loginRequest } from "authConfig/AuthConfig";
import { useEffect, useState } from "react";
import { msalInstance } from "../index";
export class HttpService {
  public static abortControllerQueue: AbortController[] = [];

  private static async getAcessToken(): Promise<string> {
    let tokenResponse: AuthenticationResult;
    const account = msalInstance.getActiveAccount();

    if (!account) {
      window.location.href = window.location.href;
      // throw Error("No active account! Verify a user has been signed in and setActiveAccount has been called.");
    }

    const tokenLoginRequest = {
      ...loginRequest,
      account: account,
    };

    try {
      tokenResponse = await msalInstance.acquireTokenSilent(tokenLoginRequest);
    } catch (error) {
      if (error instanceof InteractionRequiredAuthError) {

        try {
          msalInstance.acquireTokenRedirect(tokenLoginRequest);
        } catch (ierror) {
          throw Error("Could not get access token.", {
            cause: ierror as Error,
          });
        }
      }
    }

    return tokenResponse?.accessToken ?? "";
  }

  private static async getCommonRequestHeaders() {
    const headers = new Headers();
    const token = await this.getAcessToken();
    const bearer = `Bearer ${token}`;
    headers.append("Authorization", bearer);
    headers.append("Accept", "application/json");

    return headers;
  }

  private static async mergeHeaders(headers: Headers | undefined): Promise<Headers> {
    const commonHeaders = await HttpService.getCommonRequestHeaders();
    if (headers) {
      headers.forEach((value, key) => {
        if (commonHeaders.has(key)) {
          commonHeaders.set(key, value);
        } else {
          commonHeaders.append(key, value);
        }
      });
    }

    return commonHeaders;
  }

  public static abortPendingRequests() {
    HttpService.abortControllerQueue.forEach(abortController => {
      abortController.abort();
    });
  }

  public static async get(url: string, isFile: boolean = false, headers?: Headers): Promise<[string, Blob] | any> {
    try {
      const abortController = new AbortController();
      const signal = abortController.signal;

      const position = HttpService.abortControllerQueue.push(abortController) - 1;

      headers = await HttpService.mergeHeaders(headers);

      const response = await fetch(url, {
        method: "GET",
        cache: "no-cache",
        headers: headers,
        signal: signal
      });

      HttpService.abortControllerQueue.splice(position, 1);

      return HttpService.handleResponse(response, isFile);
    } catch (error) {
      if ((error as any).name?.toLowerCase() === "aborterror") {
        console.log(error);
      }
      else {
        throw error;
      }
    }

  }

  public static async post(url: string, body: any, headers?: Headers): Promise<any> {
    const abortController = new AbortController();
    const signal = abortController.signal;

    const position = HttpService.abortControllerQueue.push(abortController) - 1;

    if (!headers) {
      headers = new Headers();
      headers.append("Content-Type", "application/json");
    }

    headers = await HttpService.mergeHeaders(headers);

    const response = await fetch(url, {
      method: "POST",
      headers: headers,
      body: JSON.stringify(body),
      signal: signal
    });

    HttpService.abortControllerQueue.splice(position, 1);

    return HttpService.handleResponse(response);
  }

  public static async postForm(url: string, body: FormData, headers?: Headers): Promise<any> {
    const abortController = new AbortController();
    const signal = abortController.signal;

    const position = HttpService.abortControllerQueue.push(abortController) - 1;

    if (!headers) {
      headers = new Headers();
    }

    headers = await HttpService.mergeHeaders(headers);

    const response = await fetch(url, {
      method: "POST",
      headers: headers,
      body: body,
      signal: signal
    });

    HttpService.abortControllerQueue.splice(position, 1);

    return HttpService.handleResponse(response);
  }

  public static async putForm(url: string, body: FormData, headers?: Headers): Promise<any> {
    const abortController = new AbortController();
    const signal = abortController.signal;

    const position = HttpService.abortControllerQueue.push(abortController) - 1;

    if (!headers) {
      headers = new Headers();
    }

    headers = await HttpService.mergeHeaders(headers);

    const response = await fetch(url, {
      method: "PUT",
      headers: headers,
      body: body,
      signal: signal
    });

    HttpService.abortControllerQueue.splice(position, 1);

    return HttpService.handleResponse(response);
  }

  public static async put(url: string, body: any, headers?: Headers): Promise<any> {
    const abortController = new AbortController();
    const signal = abortController.signal;

    const position = HttpService.abortControllerQueue.push(abortController) - 1;

    if (!headers) {
      headers = new Headers();
      headers.append("Content-Type", "application/json");
    }

    headers = await HttpService.mergeHeaders(headers);

    const response = await fetch(url, {
      method: "PUT",
      headers: headers,
      body: JSON.stringify(body),
      signal: signal
    });

    HttpService.abortControllerQueue.splice(position, 1);

    return HttpService.handleResponse(response);
  }

  public static async delete(url: string, body?: any, headers?: Headers): Promise<any> {
    let response: Response;

    const abortController = new AbortController();
    const signal = abortController.signal;

    const position = HttpService.abortControllerQueue.push(abortController) - 1;

    if (!headers) {
      headers = new Headers();
      headers.append("Content-Type", "application/json");
    }

    headers = await HttpService.mergeHeaders(headers);

    if (!!body) {
      response = await fetch(url, {
        method: "DELETE",
        headers: headers,
        body: JSON.stringify(body),
        signal: signal
      });
    } else {
      response = await fetch(url, {
        method: "DELETE",
        headers: headers,
        signal: signal
      });
    }

    HttpService.abortControllerQueue.splice(position, 1);

    return HttpService.handleResponse(response);
  }

  private static async handleResponse(response: Response, isFile: boolean = false) {
    if (response.ok) {
      try {
        if (response.redirected) {
          window.location.href = response.url;
        }
        else {
          if (isFile) {
            const disposition = response.headers.get('Content-Disposition');
            const filename = /filename\*?=([^']*'')?([^;]*)/.exec(disposition)[2];

            return [filename, await response.blob()];
          }
          else {
            return await response.clone().json();
          }
        }
      } catch (error) {
        return await response.text();
      }
    } else {

      if (response.status === 404) {
        const errorMessage = await response.json();
        throw {
          errorCode: response.status,
          error: errorMessage
        };
      }
      else {
        let errorString: string = "";
        let errorObj: any;
        try {
          errorString = await response.json();
          errorObj = JSON.parse(errorString);
        } catch (error) {
          if (!errorString) {
            errorString = `${response.status}: ${response.statusText}`;
          } else {
            if (typeof errorString !== "string") {
              throw errorString;
            }
          }
          throw Error(errorString);
        }

        throw Error(errorObj);
      }

    }
  }
}
