import { TextConstants } from "constant";
import { FieldInfoTyped, FieldType, IBase } from "interfaces";

export const BaseFieldInfo:{ [key in keyof IBase]: FieldInfoTyped<IBase> } = {
    id: {
      name: "id",
      label: TextConstants.Request.Label_RequestId,
      type: FieldType.Int
    },
    createdById: {
      name: "createdById",
      label: TextConstants.Request.Label_CreatedBy,
      type: FieldType.Int
    },
    createdBy: {
      name: "createdBy",
      label: TextConstants.Request.Label_CreatedBy,
      type: FieldType.User,
    },
    modifiedById: {
      name: "modifiedById",
      label: TextConstants.Request.Label_ModifiedBy,
      type: FieldType.Int
    },
    modifiedBy: {
      name: "modifiedBy",
      label: TextConstants.Request.Label_ModifiedBy,
      type: FieldType.User
    },
    createdOn: {
      name: "createdOn",
      label: TextConstants.Request.Label_CreatedOn,
      type: FieldType.DateTime
    },
    modifiedOn: {
      name: "modifiedOn",
      label: TextConstants.Request.Label_ModifiedOn,
      type: FieldType.DateTime
    },
  }