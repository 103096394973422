import {
  ObjectFieldInfoTyped, FieldType, IAuditLog, IMaterial, ITranslation, IUser, IUserGroup, RequestType, IProjectRequestStatus,
  ObjectFieldInfo, IBOMItem, IProjectRequest, RequestStatus, IUpdateProjectRequestPayload, IProjectTask, RequestStep,
  IProjectRequestStatusUpdate, IAttachment, IProjectRequestStatusUpdateResponse, RequestTaskType, ISimpleApprovalTask, RequestTaskStatus, ICountryTask, RequestStepReverseMapping,
  IProjectTaskExpanded, FieldInfoTyped, IProjectTaskAssignment, IResponsibilityMatrix, IProjectRequestPayload, IDataLockInfo, IAdvanceSearchFilters, ILanguageCode
} from "interfaces";
import { useDispatch } from "react-redux";
import { CommonService, HttpService, ValidationService, WorkflowAction } from "services";
import {
  ErrorRegions, StorageKeys, TextConstants, TransaltionLanguages, BaseFieldInfo, ApplicationRoutes
} from "constant";
import { showLoadingSpinner, hideLoadingSpinner, showErrorMessage, hideErrorMessage } from "components";
import { addAuditLog, resetCurrentRequest, setLastUsedTranslationId, setMaterialBOMItems, setProjectRequest, setProjectRequestBOMItems, updateProjectAttachments, updateProjectAuditLog } from "../reducers/projectRequestSlice";
import { IFileInfo } from "ccs-azure";
import { Util } from "../Util";
import { useNavigate } from "react-router-dom";
import { MaterialService } from "./MaterialService";

export class ProjectRequestService {
  public static apiEndpointProjectRequest = `/api/ProjectRequest`;
  public static apiEndpointMaterial = `/api/Material`;
  navigate = useNavigate();
  dispatch = useDispatch();

  public undoChangesInRequest(): void {
    let originalProjectRequest = CommonService.readSessionStorage(StorageKeys.ProjectRequest, true);
    originalProjectRequest = CommonService.fixTimeStampColumnOfObject(originalProjectRequest, ProjectRequestService.ProjectRequestFieldInfo);
    this.dispatch(resetCurrentRequest(Util.resetAllChangeFlagsInProjectRequest(originalProjectRequest)));
  }

  public static cloneProjectRequest(projectRequest: IProjectRequest): IProjectRequest {
    let updatedProjectRequest: IProjectRequest = CommonService.deepCloneUsingJSONParsing(projectRequest, ProjectRequestService.ProjectRequestFieldInfo);
    updatedProjectRequest.attachments = projectRequest.attachments?.map(x => ({ ...x }));
    return updatedProjectRequest;
  }

  public static getNewRequestDefaultData(currentUser: IUser, languageCodes: ILanguageCode[]): { projectRequest: IProjectRequest; lastUsedTranslationId: number; lastUsedMaterialId: number; } {
    let lastUsedTranslationId = 0;
    let materials: IMaterial[] = [];

    for (let i = 1; i <= 10; i++) {
      let { material, lastUsedTranslationId: lastUsedTranslationIdNew } = ProjectRequestService.createEmptyMaterial(
        -i,
        0,
        lastUsedTranslationId,
        languageCodes
      );
      lastUsedTranslationId = lastUsedTranslationIdNew;
      materials.push(material);
    }

    const projectRequest: IProjectRequest = {
      isChanged: false,
      isProjectMetadataChanged: false,
      requesterId: currentUser.id,
      requester: currentUser,
      status: RequestStatus.Draft,
      requestStep: RequestStep.Start,
      materials: materials
    };

    return { projectRequest, lastUsedTranslationId, lastUsedMaterialId: 10 };
  }

  public async getProjectRequest(id: number): Promise<void> {
    this.dispatch(showLoadingSpinner());
    let lastUsedTranslationId = 0;
    try {
      let projectRequest: IProjectRequest = await HttpService.get(`${ProjectRequestService.apiEndpointProjectRequest}/${id}`);
      projectRequest.isChanged = false;
      projectRequest.isProjectMetadataChanged = false;
      projectRequest = CommonService.fixTimeStampColumnOfObject(projectRequest, ProjectRequestService.ProjectRequestFieldInfo);

      projectRequest.materials.forEach((material) => {
        if (!material.translations?.length) {
          material.translations = [];
          TransaltionLanguages.forEach((languageCode) => {
            lastUsedTranslationId++;
            material.translations.push(
              ProjectRequestService.createEmptyTranslations(-lastUsedTranslationId, material, languageCode)
            );
          });
        }
      });

      if (projectRequest.stateMachineStatus?.statusValue) {
        projectRequest.stateMachineStatus.statusValue = JSON.parse(projectRequest.stateMachineStatus.statusValue);
      }

      this.dispatch(setProjectRequest(projectRequest));
      this.dispatch(setLastUsedTranslationId(lastUsedTranslationId));

      this.getBOMItemsForProjectRequest(id).then((bomItems: IBOMItem[]) => {
        const clonedProjRequest = ProjectRequestService.cloneProjectRequest(projectRequest);
        clonedProjRequest.materials.forEach((mat) => {
          mat.bomItems = bomItems.filter(i => i.materialId === mat.id);
        });
        CommonService.setSessionStorage(StorageKeys.ProjectRequest, clonedProjRequest, true);
      }).catch(err => {
        console.error(err);
        CommonService.setSessionStorage(StorageKeys.ProjectRequest, projectRequest, true);
      });
      this.dispatch(hideLoadingSpinner());
    } catch (error: any) {
      this.dispatch(hideLoadingSpinner());
      if (error?.errorCode === 404) {
        this.navigate(ApplicationRoutes.Request);
        this.dispatch(showErrorMessage({ error: error.error, errorOriginationKey: ErrorRegions.ProjectRequest }));
      }
      else {
        this.dispatch(showErrorMessage({ error, errorOriginationKey: ErrorRegions.ProjectRequest }));
      }

    }
  }

  public async resetProjectRequest(projectRequest: IProjectRequest): Promise<void> {
    try {
      this.dispatch(showLoadingSpinner());
      await HttpService.post(`${ProjectRequestService.apiEndpointProjectRequest}/${projectRequest.id}/resetRequest`, {});
      return this.getProjectRequest(projectRequest.id);
    } catch (error) {
      this.dispatch(hideLoadingSpinner());
      this.dispatch(showErrorMessage({ error, errorOriginationKey: ErrorRegions.ProjectRequest }));
    }
  }

  public async deleteProjectRequest(projectRequestId: any): Promise<void> {
    try {
      this.dispatch(showLoadingSpinner());
      await HttpService.delete(`${ProjectRequestService.apiEndpointProjectRequest}/${projectRequestId}`);
      this.dispatch(hideLoadingSpinner());
      this.navigate(
        `${ApplicationRoutes.Home}${ApplicationRoutes.RequestDashboard}?type=All&filter=Open&forceReload=true`
      );      
    } catch (error) {
      this.dispatch(hideLoadingSpinner());
      this.dispatch(showErrorMessage({ error, errorOriginationKey: ErrorRegions.ProjectRequest }));
    }
  }

  public async createProjectRequest(projectRequest: IProjectRequest): Promise<IProjectRequest> {
    const formData = new FormData();

    projectRequest.materials.forEach((x) => {
      x.id = 0;
      x.projectRequestId = 0;

      x.translations.forEach((y) => {
        y.id = 0;
        y.projectRequestId = 0;
        y.materialId = 0;
      });
    });

    const fileToAddItems: IAttachment[] = [];

    projectRequest.attachments?.forEach(file => {
      file.id = 0;
      file.projectRequestId = 0;
      formData.append("FilesToAdd", file.data);
      delete file.data;
      fileToAddItems.push(file);
    });

    if (fileToAddItems.length) {
      formData.append("FileToAddItems", JSON.stringify(fileToAddItems));
    }

    formData.append("ProjectRequest", JSON.stringify(projectRequest));

    projectRequest = await HttpService.postForm(`${ProjectRequestService.apiEndpointProjectRequest}`, formData);

    CommonService.fixTimeStampColumnOfObject(projectRequest, ProjectRequestService.ProjectRequestFieldInfo);

    return projectRequest;
  }

  public async updateProjectRequestData(updateProjectRequestPayload: IProjectRequestPayload): Promise<IProjectRequest> {
    const formData = new FormData();

    formData.append("Id", String(updateProjectRequestPayload.id));
    formData.append("CurrentStatus", updateProjectRequestPayload.currentStatus);

    if (updateProjectRequestPayload.editedSectionIds?.length) {
      formData.append("EditedSectionIds", JSON.stringify(updateProjectRequestPayload.editedSectionIds));
    }

    if (updateProjectRequestPayload.filesToAdd?.length) {
      updateProjectRequestPayload.filesToAdd.forEach(file => {
        formData.append("FilesToAdd", file);
      });
    }

    if (updateProjectRequestPayload.projectRequestComposite) {
      formData.append("ProjectRequestComposite", JSON.stringify(updateProjectRequestPayload.projectRequestComposite));
    }

    if (updateProjectRequestPayload.materialsToDelete?.length) {
      formData.append("MaterialsToDelete", JSON.stringify(updateProjectRequestPayload.materialsToDelete));
    }

    if (updateProjectRequestPayload.filesToDelete?.length) {
      formData.append("FilesToDelete", JSON.stringify(updateProjectRequestPayload.filesToDelete));
    }

    if (updateProjectRequestPayload.dataLocksToDelete?.length) {
      formData.append("DataLocksToDelete", JSON.stringify(updateProjectRequestPayload.dataLocksToDelete));
    }

    const projectRequest: IProjectRequest = await HttpService.putForm(`${ProjectRequestService.apiEndpointProjectRequest}`, formData);

    CommonService.fixTimeStampColumnOfObject(projectRequest, ProjectRequestService.ProjectRequestFieldInfo);

    return projectRequest;
  }

  public static createEmptyMaterial(id: number, projectRequestId: number, lastUsedTranslationId: number, languageCodes: ILanguageCode[]): { material: IMaterial, lastUsedTranslationId: number } {
    const material: IMaterial = {
      id: id,
      projectRequestId: projectRequestId,
      materialNumber: null,
      productDescription: null,
      hasExpiryDate: null,
      legalManufacturer: null,
      workpackageOrbit: null,
      projectNumber: null,
      productHierarchy: null,
      gtin: null,
      hibc: null,
    };

    material.translations = [];

    languageCodes.forEach((languageCode) => {
      lastUsedTranslationId++;
      material.translations.push(ProjectRequestService.createEmptyTranslations(-lastUsedTranslationId, material, languageCode.language));
    });

    return { material, lastUsedTranslationId };
  }

  public static createEmptyBOMItem(projectRequestId: number, materialId: number, id: number, seq: number): IBOMItem {
    const strSeq = `${seq}`.padStart(4, "0");
    return {
      id: id,
      projectRequestId: projectRequestId,
      materialId: materialId,
      item: strSeq,
      itemCategory: "L",
      fixedQty: "",
      component: null,
      shortText: null,
      componentQty: 1,
      baseUnitOfMeasure: "ST",
      productionRelevant: null,
      constructionRelevant: null,
      costingRelevant: null,
      isUpdated: false,
    };
  }

  public static createEmptyTranslations(id: number, material: IMaterial, languageCode: string): ITranslation {
    return {
      id: id,
      // material: material,
      projectRequestId: material.projectRequestId,
      materialId: material.id,
      productMasterText: null,
      shortDescription: null,
      masterDescriptionLine1: null,
      masterDescriptionLine2: null,
      masterDescriptionLine3: null,
      masterDescriptionLine4: null,
      languageCode: languageCode,
    };
  }

  public static async deleteLanguageTranslations(projectRequestId: number, translationIds: number[]): Promise<boolean> {
    return await HttpService.delete(`${ProjectRequestService.apiEndpointProjectRequest}/${projectRequestId}/DeleteTranslations`, translationIds);
  }

  public async getAllProjectRequests(): Promise<IProjectRequest[]> {
    this.dispatch(showLoadingSpinner());
    let projectRequests: IProjectRequest[] = [];
    try {
      projectRequests = await HttpService.get(`${ProjectRequestService.apiEndpointProjectRequest}`);
      for (let index = 0; index < projectRequests.length; index++) {
        projectRequests[index] = CommonService.fixTimeStampColumnOfObject(projectRequests[index], ProjectRequestService.ProjectRequestFieldInfo);
      }
      this.dispatch(hideLoadingSpinner());
    } catch (error) {
      this.dispatch(hideLoadingSpinner());
      this.dispatch(showErrorMessage({ error, errorOriginationKey: ErrorRegions.ProjectRequest }));
      setTimeout(() => {
        this.dispatch(hideErrorMessage(ErrorRegions.ProjectRequest));
      }, 5000);
    }
    return projectRequests;
  }

  public async getAllProjectRequestsByFilter(filter: string, forceReload: boolean): Promise<IProjectRequest[]> {
    this.dispatch(showLoadingSpinner());
    let projectRequests: IProjectRequest[] = [];
    try {
      projectRequests = await HttpService.get(`${ProjectRequestService.apiEndpointProjectRequest}/GetProjectRequestsByFilter/${filter}${(forceReload ? "/Refresh" : "")}`);
      // for (let index = 0; index < projectRequests.length; index++) {
      //   projectRequests[index] = CommonService.fixTimeStampColumnOfObject(projectRequests[index], ProjectRequestService.ProjectRequestFieldInfo);
      // }
      this.dispatch(hideLoadingSpinner());
    } catch (error) {
      this.dispatch(hideLoadingSpinner());
      this.dispatch(showErrorMessage({ error, errorOriginationKey: ErrorRegions.ProjectRequest }));
      setTimeout(() => {
        this.dispatch(hideErrorMessage(ErrorRegions.ProjectRequest));
      }, 5000);
    }
    return projectRequests;
  }

  public async getAllProjectRequestsUsingAdvancedSearch(advanceSearchFilters: IAdvanceSearchFilters): Promise<IProjectRequest[]> {
    this.dispatch(showLoadingSpinner());
    let projectRequests: IProjectRequest[] = [];
    try {
      projectRequests = await HttpService.post(`${ProjectRequestService.apiEndpointProjectRequest}/AdvanceSearch`, advanceSearchFilters);
      this.dispatch(hideLoadingSpinner());
    } catch (error) {
      this.dispatch(hideLoadingSpinner());
      this.dispatch(showErrorMessage({ error, errorOriginationKey: ErrorRegions.ProjectRequest }));
      setTimeout(() => {
        this.dispatch(hideErrorMessage(ErrorRegions.ProjectRequest));
      }, 5000);
    }
    return projectRequests;
  }

  public async getMyProjectRequestsByFilter(userId: number, forceReload: boolean): Promise<IProjectRequest[]> {
    this.dispatch(showLoadingSpinner());
    let projectRequests: IProjectRequest[] = [];
    try {
      projectRequests = await HttpService.get(`${ProjectRequestService.apiEndpointProjectRequest}/GetMyRequests/${userId}${(forceReload ? "/Refresh" : "")}`);
      this.dispatch(hideLoadingSpinner());
    } catch (error) {
      this.dispatch(hideLoadingSpinner());
      this.dispatch(showErrorMessage({ error, errorOriginationKey: ErrorRegions.ProjectRequest }));
      setTimeout(() => {
        this.dispatch(hideErrorMessage(ErrorRegions.ProjectRequest));
      }, 5000);
    }
    return projectRequests;
  }

  public static instanceOfUser(object: IUser | IUserGroup): object is IUser {
    return 'isStraumannUser' in object;
  }

  public static convertResponsiblesToTaskAssignees(matrix: IResponsibilityMatrix | ISimpleApprovalTask | ICountryTask, projectRequest: IProjectRequest): IProjectTaskAssignment[] {
    return 'projectRequestFieldToken' in matrix && matrix.projectRequestFieldToken ?
      ProjectRequestService.extractResponsible(projectRequest, matrix.projectRequestFieldToken as keyof IProjectRequest)
        .map(userOrGroup => {
          if (ProjectRequestService.instanceOfUser(userOrGroup)) {
            return {
              userId: userOrGroup.id
            };
          } else {
            return {
              groupId: userOrGroup.id,
            }
          }
        }) : matrix.responsibles.map(r => {
          return {
            userId: r.userId,
            groupId: r.groupId,
          }
        })
  }

  public static extractResponsible(projectRequest: IProjectRequest, fieldName: keyof IProjectRequest): Array<IUser | IUserGroup> {
    if (projectRequest) {
      const field: FieldInfoTyped<IProjectRequest> = ProjectRequestService.ProjectRequestFieldInfo[fieldName];

      switch (field.type) {
        case FieldType.User:
          return [projectRequest[fieldName as keyof IProjectRequest] as IUser];
        case FieldType.MultiUser:
          return projectRequest[fieldName as keyof IProjectRequest] as IUser[];
        case FieldType.Group:
          return [projectRequest[fieldName as keyof IProjectRequest] as IUserGroup];
        case FieldType.MultiGroup:
          return projectRequest[fieldName as keyof IProjectRequest] as IUserGroup[];
      }
    }

    return [];
  }

  public async getAllProjectTasks(): Promise<IProjectTask[]> {
    this.dispatch(showLoadingSpinner());
    let projectRequests: any[] = [];
    try {
      projectRequests = await HttpService.get(`${ProjectRequestService.apiEndpointProjectRequest}/ProjectTasks`);
      for (let index = 0; index < projectRequests.length; index++) {
        projectRequests[index] = CommonService.fixTimeStampColumnOfObject(projectRequests[index], ProjectRequestService.ProjectRequestFieldInfo);
      }
      this.dispatch(hideLoadingSpinner());
    } catch (error) {
      this.dispatch(hideLoadingSpinner());
      this.dispatch(showErrorMessage({ error, errorOriginationKey: ErrorRegions.ProjectRequest }));
      setTimeout(() => {
        this.dispatch(hideErrorMessage(ErrorRegions.ProjectRequest));
      }, 5000);
    }
    return projectRequests;
  }

  public async getProjectTasks(projectRequestId:number): Promise<IProjectTask[]> {
    let projectTasks: any[] = [];
    try{
      projectTasks = await HttpService.get(`${ProjectRequestService.apiEndpointProjectRequest}/${projectRequestId}/tasks`);
      console.log(projectTasks);
    }
    catch(error){
      this.dispatch(hideLoadingSpinner());
      this.dispatch(showErrorMessage({ error, errorOriginationKey: ErrorRegions.ProjectRequest }));
      setTimeout(() => {
        this.dispatch(hideErrorMessage(ErrorRegions.ProjectRequest));
      }, 5000);
    }
    return projectTasks;
  }

  // public async getAllTasksForCurrentUser(): Promise<IProjectTaskExpanded[]> {
  //   this.dispatch(showLoadingSpinner());
  //   let task: any[] = [];
  //   try {
  //     task = await HttpService.get(`${ProjectRequestService.apiEndpointProjectRequest}/MyTasks`);
  //     // for (let index = 0; index < task.length; index++) {
  //     //   task[index] = CommonService.fixTimeStampColumnOfObject(task[index], ProjectRequestService.ProjectRequestFieldInfo);
  //     // }
  //     this.dispatch(hideLoadingSpinner());
  //   } catch (error) {
  //     this.dispatch(hideLoadingSpinner());
  //     this.dispatch(showErrorMessage({ error, errorOriginationKey: ErrorRegions.ProjectRequest }));
  //   }
  //   return task;
  // }

  public async getAllTasksForCurrentUserBasedOnFilter(filter: string): Promise<IProjectTaskExpanded[]> {
    this.dispatch(showLoadingSpinner());
    let task: any[] = [];
    try {
      task = await HttpService.get(`${ProjectRequestService.apiEndpointProjectRequest}/MyTasks/${filter}`);
      // for (let index = 0; index < task.length; index++) {
      //   task[index] = CommonService.fixTimeStampColumnOfObject(task[index], ProjectRequestService.ProjectRequestFieldInfo);
      // }
      this.dispatch(hideLoadingSpinner());
    } catch (error) {
      this.dispatch(hideLoadingSpinner());
      this.dispatch(showErrorMessage({ error, errorOriginationKey: ErrorRegions.ProjectRequest }));
    }
    return task;
  }

  public async getTasksForUser(userId: number): Promise<IProjectTaskExpanded[]> {
    this.dispatch(showLoadingSpinner());
    let task: any[] = [];
    try {
      task = await HttpService.get(`${ProjectRequestService.apiEndpointProjectRequest}/tasksForUser/${userId}`);
      for (let index = 0; index < task.length; index++) {
        task[index] = CommonService.fixTimeStampColumnOfObject(task[index], ProjectRequestService.ProjectRequestFieldInfo);
      }
      this.dispatch(hideLoadingSpinner());
    } catch (error) {
      this.dispatch(hideLoadingSpinner());
      this.dispatch(showErrorMessage({ error, errorOriginationKey: ErrorRegions.ProjectRequest }));
    }
    return task;
  }

  public async getSpecificTask(taskId: number): Promise<IProjectTask> {
    let task = await HttpService.get(`${ProjectRequestService.apiEndpointProjectRequest}/task/${taskId}`);
    CommonService.fixTimeStampColumnOfObject(task, ProjectRequestService.ProjectRequestFieldInfo);
    return task;
  }

  public async loadProjectAuditLog(projRequestId: number): Promise<IAuditLog[]> {
    // no spinner, since this will be executed in the background
    try {
      let auditLog: IAuditLog[] = await HttpService.get(
        `${ProjectRequestService.apiEndpointProjectRequest}/${projRequestId}/AuditLog`
      );
      this.dispatch(updateProjectAuditLog(auditLog));
      return auditLog;
    } catch (error) {
      this.dispatch(showErrorMessage({ error, errorOriginationKey: ErrorRegions.ProjectRequest }));
      return [];
    }
  }

  public async loadProjectAttachments(projRequestId: number): Promise<void> {
    // no spinner, since this will be executed in the background
    try {
      let attachments: IAttachment[] = await HttpService.get(
        `${ProjectRequestService.apiEndpointProjectRequest}/${projRequestId}/Attachments`
      );
      this.dispatch(updateProjectAttachments(attachments));
    } catch (error) {
      this.dispatch(showErrorMessage({ error, errorOriginationKey: ErrorRegions.ProjectRequest }));
    }
  }

  public async addProjectAuditLog(auditEntry: IAuditLog): Promise<IAuditLog> {
    try {
      const newEntry = await HttpService.post(
        `${ProjectRequestService.apiEndpointProjectRequest}/${auditEntry.projectRequestId}/AuditLog`,
        auditEntry
      );
      this.dispatch(addAuditLog(newEntry));
      return newEntry;
    } catch (error: any) {
      this.dispatch(showErrorMessage({ error, errorOriginationKey: ErrorRegions.ProjectRequest }));
      return Promise.reject(error.message);
    }
  }

  public async getBOMItemsForProjectRequest(projectRequestId: number): Promise<any> {
    try {
      console.log(`%cFetching BOMItems for Project '${projectRequestId}'`, "color:yellow");
      const bomItems = await HttpService.get(`${ProjectRequestService.apiEndpointProjectRequest}/${projectRequestId}/bomItems`);
      console.log(`%cGot ${bomItems?.length ? bomItems.length : 0} BOMItems for Project '${projectRequestId}'`, "color:yellow");
      this.dispatch(setProjectRequestBOMItems(bomItems));
      return bomItems;
    } catch (error) {
      this.dispatch(showErrorMessage({ error, errorOriginationKey: ErrorRegions.ProjectRequest }));
    }
  }

  public async getBOMItemsForMaterial(materialId?: number): Promise<void> {
    try {
      const bomItems = await HttpService.get(`${ProjectRequestService.apiEndpointMaterial}/${materialId}/bomItems`);
      this.dispatch(setMaterialBOMItems({ matId: materialId, items: bomItems }));
    } catch (error) {
      this.dispatch(showErrorMessage({ error, errorOriginationKey: ErrorRegions.ProjectRequest }));
    }
  }

  public async saveBOMItems(matId: number, items: IBOMItem[]): Promise<any> {
    try {
      return await HttpService.post(`${ProjectRequestService.apiEndpointMaterial}/${matId}/bomItems`, items);
    } catch (error) {
      this.dispatch(showErrorMessage({ error, errorOriginationKey: ErrorRegions.ProjectRequest }));
      throw error;
    }
  }

  public static async setProjectRequestStatus(newStatus: IProjectRequestStatus, taskUpdates: IProjectTask[], newRequestStep: string, newRequestStatus?: RequestStatus, statusComment?: string): Promise<IProjectRequestStatusUpdateResponse> {
    try {
      newStatus.statusValue = JSON.stringify(newStatus.statusValue);
      delete newStatus.createdBy;
      delete newStatus.modifiedBy;
      taskUpdates.forEach(t => {
        delete t.createdBy;
        delete t.modifiedBy;
        t.assignees.forEach(a => {
          delete a.createdBy;
          delete a.modifiedBy;
          delete a.user;
          delete a.group;
        });
      });
      const reqBody = {
        newStatus: newStatus,
        taskUpdates: taskUpdates,
        newRequestStep: newRequestStep,
        newRequestState: newRequestStatus,
        statusComment: statusComment
      } as IProjectRequestStatusUpdate;
      return await HttpService.post(`${ProjectRequestService.apiEndpointProjectRequest}/${newStatus.projectRequestId}/state`, reqBody);
    } catch (error) {
      throw error;
    }
  }

  public static async getProjectRequestStatus(prjRequestId: number): Promise<IProjectRequestStatus> {
    let stateObj: IProjectRequestStatus = await HttpService.get(`${ProjectRequestService.apiEndpointProjectRequest}/${prjRequestId}/state`);
    if (stateObj?.statusValue) {
      stateObj.statusValue = JSON.parse(stateObj.statusValue);
    }
    return stateObj

  }

  public static createSimpleApprovalTasks(projectRequest: IProjectRequest, currentUser: IUser, simpleApprovalTasks: ISimpleApprovalTask[]): IProjectTask[] {
    let arrNewTasks: IProjectTask[] = [];
    simpleApprovalTasks.forEach((simple: ISimpleApprovalTask) => {
      let taskExists = projectRequest.projectTasks?.some(t => t.requestStep === simple.requestStep && t.title === simple.task);
      taskExists = taskExists || arrNewTasks.some(t => t.requestStep === simple.requestStep && t.title === simple.task);
      if (!taskExists) {
        const task: IProjectTask = {
          assignmentDate: new Date(),
          title: simple.task,
          requestStep: simple.requestStep,
          requestTab: simple.task,
          projectRequestId: projectRequest.id,
          taskType: RequestTaskType.Simple,
          status: RequestTaskStatus.Open,
          dueDate: CommonService.addDaysToDate(new Date(), simple.durationForDueDate),
          possibleOutcomeKeys: [WorkflowAction.Confirm],
          taskHistory: [
            {
              user: currentUser.displayName,
              timestamp: new Date(),
              event: "Task created"
            }
          ],
          assignees: ProjectRequestService.convertResponsiblesToTaskAssignees(simple, projectRequest)
        } as IProjectTask;

        arrNewTasks.push(task);
      }
    });
    return arrNewTasks;
  }

  public static createCountryTasks(projectRequest: IProjectRequest, currentUser: IUser, countryTasks: ICountryTask[]): IProjectTask[] {
    let arrNewTasks: IProjectTask[] = [];
    countryTasks.forEach((ct: ICountryTask) => {
      //const taskTitle = `Country Task: ${ct.country}`;
      // check if task exists already
      let taskExists = projectRequest.projectTasks?.some(t => t.requestStep === RequestStepReverseMapping[RequestStep.CountryTasks] && t.title === ct.country);
      taskExists = taskExists || arrNewTasks.some(t => t.requestStep === RequestStepReverseMapping[RequestStep.CountryTasks] && t.title === ct.country);
      if (!taskExists) {
        const task: IProjectTask = {
          assignmentDate: new Date(),
          title: ct.country,
          requestStep: RequestStepReverseMapping[RequestStep.CountryTasks],
          requestTab: ct.country,
          projectRequestId: projectRequest.id,
          taskType: RequestTaskType.Country,
          status: RequestTaskStatus.Open,
          dueDate: CommonService.addDaysToDate(new Date(), ct.durationForDueDate),
          possibleOutcomeKeys: [WorkflowAction.Confirm],
          taskHistory: [
            {
              user: currentUser.displayName,
              timestamp: new Date(),
              event: "Task created"
            }
          ],
          assignees: ProjectRequestService.convertResponsiblesToTaskAssignees(ct, projectRequest)
        } as IProjectTask;

        arrNewTasks.push(task);
      }

    });
    return arrNewTasks;
  }

  public static async updateSimpleApprovalTask(task: IProjectTask): Promise<IProjectTask[]> {
    delete task.createdBy;
    delete task.modifiedBy;
    task.assignees.forEach(a => {
      delete a.createdBy;
      delete a.modifiedBy;
      delete a.group;
      delete a.user;
    });
    delete task.completedBy;
    delete task.currentAssignment;
    return HttpService.put(`${ProjectRequestService.apiEndpointProjectRequest}/SimpleApproval`, task);
  }

  public static async completeCountryTask(task: IProjectTask): Promise<IProjectRequest> {
    delete task.createdBy;
    delete task.modifiedBy;
    task.assignees.forEach(a => {
      delete a.createdBy;
      delete a.modifiedBy;
      delete a.group;
      delete a.user;
    });
    delete task.completedBy;
    delete task.currentAssignment;
    const projectRequest: IProjectRequest = await HttpService.put(`${ProjectRequestService.apiEndpointProjectRequest}/CountryTask`, task);
    CommonService.fixTimeStampColumnOfObject(projectRequest, ProjectRequestService.ProjectRequestFieldInfo);
    return projectRequest;
  }

  public static async updateTaskConfig(task: IProjectTask): Promise<IProjectTask> {
    return HttpService.post(`${ProjectRequestService.apiEndpointProjectRequest}/updateTaskConfig`, task);
  }

  public static async downloadAttachment(file: IFileInfo): Promise<void> {
    const [filename, blob] = (await HttpService.get(`${ProjectRequestService.apiEndpointProjectRequest}/Attachments/${Number(file.fileId)}`, true)) as [string, Blob];
    CommonService.downloadFile(blob, file.fileName);
  }

  //#region Data Lock Fields
  public static DataLockFieldInfo: ObjectFieldInfoTyped<IDataLockInfo> = {
    ...BaseFieldInfo,
    projectRequestId: {
      name: "projectRequestId",
      label: TextConstants.Request.Attachment.Label_ProjectRequestId,
      type: FieldType.Int
    },
    lockedById: {
      name: "lockedById",
      label: TextConstants.Request.SectionLock.Label_LockedBy,
      type: FieldType.Int,
    },
    lockedBy: {
      name: "lockedBy",
      label: TextConstants.Request.SectionLock.Label_LockedBy,
      type: FieldType.User,
    },
    lockedSince: {
      name: "lockedSince",
      label: TextConstants.Request.SectionLock.Label_LockedSince,
      type: FieldType.DateTime
    },
    expires: {
      name: "expires",
      label: TextConstants.Request.SectionLock.Label_LockedUntill,
      type: FieldType.DateTime
    },
    entityId: {
      name: "expires",
      label: TextConstants.Request.SectionLock.Label_EntityId,
      type: FieldType.Int
    },
    sectionId: {
      name: "sectionId",
      label: TextConstants.Request.SectionLock.Label_SectionId,
      type: FieldType.Text
    }
  }
  //#endregion

  //#region Project Fields
  public static ProjectRequestFieldInfo: ObjectFieldInfoTyped<IProjectRequest> = {
    ...BaseFieldInfo,
    requestType: {
      name: "requestType",
      label: TextConstants.Request.Label_RequestType,
      type: FieldType.Text,
    },
    requesterId: {
      name: "requesterId",
      label: TextConstants.Request.Label_Requester,
      type: FieldType.Int,
    },
    requester: {
      name: "requester",
      label: TextConstants.Request.Label_Requester,
      type: FieldType.User,
      validationRules: {
        isRequired: true,
      },
    },
    requestTitle: {
      name: "requestTitle",
      label: TextConstants.Request.Label_RequestTitle,
      type: FieldType.Text,
      validationRules: {
        isRequired: true,
      },
    },
    requestNumber: {
      name: "requestNumber",
      label: TextConstants.Request.Label_RequestNumber,
      type: FieldType.Text,
      validationRules: {
        isRequired: true,
      },
    },
    description: {
      name: "description",
      label: TextConstants.Request.Label_Description,
      type: FieldType.MultiLine,
    },
    status: {
      name: "status",
      label: TextConstants.Request.Label_Status,
      type: FieldType.Text,
    },
    stateMachineStatus: {
      name: "stateMachineStatus",
      label: "State Machine Status",
      type: FieldType.MultiLine,
    },
    // preKickOffDate: {
    //   name: "preKickOffDate",
    //   label: TextConstants.Request.Label_PreKickOffDate,
    //   type: FieldType.Date,
    //   validationRules: {
    //     isRequired: false,
    //     minValue: CommonService.getTodaysDate(),
    //   },
    // },
    // kickOffDate: {
    //   name: "kickOffDate",
    //   label: TextConstants.Request.Label_KickOffDate,
    //   type: FieldType.Date,
    //   validationRules: {
    //     isRequired: true,
    //     customValidator: {
    //       validator: ValidationService.validateFromMinDate,
    //       validatorArguments: ["{{self}}", "{{preKickOffDate}}", true],
    //       errorMessage: TextConstants.Common.Error_Message_DateLessThanMinValue.replace(
    //         "fieldname",
    //         TextConstants.Request.Label_PreKickOffDate
    //       ),
    //     },
    //   },
    // },
    companyId: {
      name: "companyId",
      label: TextConstants.Request.Label_Company,
      type: FieldType.Int,
      validationRules: {
        customValidatorIsRequired: {
          validator: (typeOfRequest: RequestType) => typeOfRequest === RequestType.OwnManufactured,
          validatorArguments: ["{{requestType}}"],
        },
      },
    },
    company: {
      name: "company",
      label: TextConstants.Request.Label_Company,
      type: FieldType.LookUp,
      lookupField: "name",
      // validationRules: {
      //   customValidatorIsRequired: {
      //     validator: (typeOfRequest: RequestType) => typeOfRequest === RequestType.OwnManufactured,
      //     validatorArguments: ["{{requestType}}"],
      //   },
      // },
    },
    division: {
      name: "division",
      label: TextConstants.Request.Label_Division,
      type: FieldType.Text,
      validationRules: {
        isRequired: true,
      },
    },
    isEShopInvolved: {
      name: "isEShopInvolved",
      label: TextConstants.Request.Label_IsEShopInvolved,
      type: FieldType.Boolean,
      validationRules: {
        isRequired: true,
      },
    },
    hasPotentialDangerousGoods: {
      name: "hasPotentialDangerousGoods",
      label: TextConstants.Request.Label_HasPotentialDangerousGoods,
      type: FieldType.Boolean,
      validationRules: {
        isRequired: true,
      },
    },
    hasCourseMaterials: {
      name: "hasCourseMaterials",
      label: TextConstants.Request.Label_HasCourseMaterials,
      type: FieldType.Boolean,
      validationRules: {
        customValidatorIsRequired: {
          validator: (typeOfRequest: RequestType) => typeOfRequest === RequestType.OwnManufactured,
          validatorArguments: ["{{requestType}}"],
        },
      },
    },
    isCamDBUsed: {
      name: "isCamDBUsed",
      label: TextConstants.Request.Label_IsCamDBUsed,
      type: FieldType.Boolean,
      validationRules: {
        isRequired: true,
      },
    },
    camDBProcessId: {
      name: "camDBProcessId",
      label: TextConstants.Request.Label_CamDBProcessId,
      type: FieldType.Text,
      validationRules: {
        customValidatorIsRequired: {
          validator: (isCamDBUsed: boolean) => isCamDBUsed,
          validatorArguments: ["{{isCamDBUsed}}"],
        },
      },
    },
    isLabelingNeeded: {
      name: "isLabelingNeeded",
      label: TextConstants.Request.Label_IsLabelingNeeded,
      type: FieldType.Boolean,
      validationRules: {
        customValidatorIsRequired: {
          validator: (typeOfReuest: RequestType) => typeOfReuest === RequestType.OwnManufactured,
          validatorArguments: ["{{requestType}}"],
        },
      },
    },
    isBOMNeeded: {
      name: "isBOMNeeded",
      label: TextConstants.Request.Label_IsBOMNeeded,
      type: FieldType.Boolean,
      validationRules: {
        customValidatorIsRequired: {
          validator: (typeOfReuest: RequestType) => typeOfReuest === RequestType.OwnManufactured,
          validatorArguments: ["{{requestType}}"],
        },
      },
    },
    requestStep: {
      name: "requestStep",
      label: TextConstants.Request.Label_RequestStep,
      type: FieldType.Text,
    },
    isTranslationNeeded: {
      name: "isTranslationNeeded",
      label: TextConstants.Request.Label_IsTranslationNeeded,
      type: FieldType.Boolean,
      validationRules: {
        customValidatorIsRequired: {
          validator: (typeOfReuest: RequestType) => typeOfReuest === RequestType.ThirdParty,
          validatorArguments: ["{{requestType}}"],
        },
      },
    },
    auditLogs: {
      name: "auditLogs",
      label: "Audit logs",
      type: FieldType.CustomArray,
    },
    completed: {
      name: "completed",
      label: TextConstants.Request.Label_Completed,
      type: FieldType.DateTime,
    },
    completedWithCountries: {
      name: "completedWithCountries",
      label: TextConstants.Request.Label_CompletedWithCountries,
      type: FieldType.DateTime,
    },
    checkList: {
      name: "checkList",
      label: TextConstants.Request.Label_CheckList,
      type: FieldType.CustomArray,
    },
    plmDesignOwner: {
      name: "plmDesignOwner",
      label: TextConstants.Request.Label_PLMDesignOwner,
      type: FieldType.Text,
    },
    plmChangeNumber: {
      name: "plmChangeNumber",
      label: TextConstants.Request.Label_PLMChangeNumber,
      type: FieldType.Int,
    },
    logisticFlow: {
      name: "logisticFlow",
      label: TextConstants.Request.Label_LogisticFlow,
      type: FieldType.Text,
      documentLinkKey: "Kick-off Logistics",
      validationRules: {
        isRequired: true,
      },
    },
    distributionType: {
      name: "distributionType",
      label: TextConstants.Request.Label_DistributionType,
      type: FieldType.Text,
      validationRules: {
        isRequired: true,
      },
    },
    attachments: {
      name: "attachments",
      label: TextConstants.Request.Label_Attachments,
      type: FieldType.Attachment,
    },
    temperatureSensitive: {
      name: "temperatureSensitive",
      label: TextConstants.Request.Label_TemperatureSensitive,
      type: FieldType.Boolean,
      validationRules: {
        isRequired: true,
      },
    },
    brand: {
      name: "brand",
      label: TextConstants.Request.Label_Brand,
      type: FieldType.Text,
      validationRules: {
        customValidatorIsRequired: {
          validator: (typeOfReuest: RequestType) => typeOfReuest === RequestType.ThirdParty,
          validatorArguments: ["{{requestType}}"],
        },
      },
    },
    materials: {
      name: "materials",
      type: FieldType.CustomArray,
      label: "Materials",
      fieldInfo: MaterialService.MaterialFieldInfo as ObjectFieldInfo,
    },
    hasLogisticTask: {
      name: "hasLogisticTask",
      label: TextConstants.Request.Label_HasLogisticTask,
      type: FieldType.Boolean,
      validationRules: {
        isRequired: true,
      },
    },
    requestSubmissionTime: {
      name: "requestSubmissionTime",
      label: TextConstants.Request.Label_RequestSubmissionTime,
      type: FieldType.DateTime,
    },
    requestReSubmissionTime: {
      name: "requestReSubmissionTime",
      label: TextConstants.Request.Label_RequestReSubmissionTime,
      type: FieldType.DateTime,
    },
    dataLocks: {
      name: "dataLocks",
      type: FieldType.CustomArray,
      label: "Data Locks",
      fieldInfo: ProjectRequestService.DataLockFieldInfo as ObjectFieldInfo,
    }
  };
  //#endregion

  public static AttachmentFieldInfo: ObjectFieldInfoTyped<IAttachment> = {
    ...BaseFieldInfo,
    projectRequestId: {
      name: "projectRequestId",
      label: TextConstants.Request.Attachment.Label_ProjectRequestId,
      type: FieldType.Int
    },
    data: {
      name: "data",
      label: TextConstants.Request.Attachment.Label_Data,
      type: FieldType.File,
    },
    fileName: {
      name: "fileName",
      label: TextConstants.Request.Attachment.Label_FileName,
      type: FieldType.Text
    },
    requestStep: {
      name: "requestStep",
      label: TextConstants.Request.Attachment.Label_RequestStep,
      type: FieldType.Text
    },
    category: {
      name: "category",
      label: TextConstants.Request.Attachment.Label_Category,
      type: FieldType.Text
    },
    contentType: {
      name: "contentType",
      label: TextConstants.Request.Attachment.Label_ContentType,
      type: FieldType.Text
    },
  }
}
