import { IStyle, mergeStyleSets } from "@fluentui/react";
import { ITooltipParams, } from "ag-grid-community";
import stylingVariables from "variables.module.scss";

const classNames = mergeStyleSets({
    root: {
        padding: '5px',
        fontSize: '0.8rem',
        backgroundColor: stylingVariables.straumannBlackColor,
        color: 'white',
        zIndex: 3,
        boxShadow: '2px 2px 10px 0px #4f5256',
        border: '1px solid',
        borderColor: stylingVariables.straumannBlackColor,
        maxWidth: 'min(calc(100vw/10), 480px)',
        borderRadius: '2px',
        '& span':{
            whiteSpace: 'pre-line'
        }
    },
});

export const GridTooltip: React.FC<ITooltipParams & { style: React.CSSProperties }> = (props: ITooltipParams & { style: React.CSSProperties }) => {

    return (
        <div className={classNames.root} style={props.style}>
            <span>{props.value}</span>
        </div>
    );
};