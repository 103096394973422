import { Dialog, DialogType, Spinner, SpinnerSize, Stylesheet } from "@fluentui/react";
import React from "react";
import { useSelector } from "react-redux";
import { RootState } from "store";

export const LoadingSpinner: React.FunctionComponent<any> = (props: any) => {
  const loaderState = useSelector((state: RootState) => state.loadingSpinner.value);

  return loaderState.showSpinner ? (
    <Dialog
      hidden={!loaderState.showSpinner}
      dialogContentProps={{
        type: DialogType.normal,
        styles: {
          title: {
            boxSizing: "content-box",
          },
          topButton: {
            display: "none",
          },
        },
      }}
      styles={{
        main: {
          backgroundColor: "transparent",
          boxShadow: "none",
        },
        root: { zIndex: 999999 },
      }}
      modalProps={{
        isModeless: false,
        isBlocking: true,
      }}
    >
      <Spinner
        size={SpinnerSize.large}
        //label={loaderState.message}
        styles={{
          label: {
            fontWeight: "bold",
            fontSize: "14px",
            color: "white",
          },
          circle: {
            height: "60px",
            width: "60px",
            borderWidth: "10px",
          },
        }}
      />
    </Dialog>
  ) : (
    <></>
  );
};
