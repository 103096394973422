import { Label, Spinner, Text } from "@fluentui/react";
import React from "react";
import {
  Chart as ChartJS,
  LinearScale,
  CategoryScale,
  BarElement,
  PointElement,
  LineElement,
  Legend,
  Tooltip,
  Filler,
  ChartOptions,
} from "chart.js";
import styles from "./Chart.module.scss";
import { Chart } from "react-chartjs-2";
import { TextConstants } from "constant";

ChartJS.register(LinearScale, CategoryScale, BarElement, PointElement, LineElement, Legend, Tooltip, Filler);
interface IMultiTypeChartSet {
  dataSet: string;
  data: number[];
  color: string;
  type: string;
}
interface IMultiTypeChartData {
  multiChartData: {
    labels: string[];
    data: IMultiTypeChartSet[];
    showLoading: boolean;
  };
}

export const MultiTypeChart = (props: IMultiTypeChartData) => {
  const options:ChartOptions = {
    responsive: true,
    interaction: {
      mode: "index" as const,
      intersect: false,
    },
    // stacked: false,
    scales: {
      y: {
        type: "linear" as const,
        display: true,
        position: "left" as const,
        title:{
          display: true,
          text: TextConstants.HomePage.Label_Legend_NoOfOpenTasks,
          align: "center"
        }
      },
      y1: {
        type: "linear" as const,
        display: true,
        position: "right" as const,
        grid: {
          drawOnChartArea: false,
        },
        title:{
          display: true,
          text: TextConstants.HomePage.Label_Legend_LeadTimeInWeeks,
          align: "center"
        }
      }
    }
  };

  const data = {
    labels: props?.multiChartData?.labels?.length > 0 ? props.multiChartData.labels : [],
    datasets:
      props?.multiChartData?.data?.length > 0
        ? props.multiChartData.data.map((x) => {
            if (x.type === "line") {
              return {
                type: "line" as const,
                label: x.dataSet,
                borderColor: x.color,
                borderWidth: 4,
                fill: false,
                data: x.data,
                pointRadius: 0,
                yAxisID: "y",
              };
            } else if (x.type === "linefill") {
              return {
                type: "line" as const,
                pointRadius: 0,
                label: x.dataSet,
                borderColor: x.color,
                backgroundColor: x.color,
                fill: true,
                data: x.data,
                yAxisID: "y",
              };
            } else if (x.type === "bar") {
              return {
                type: "bar" as const,
                label: x.dataSet,
                borderColor: x.color,
                backgroundColor: x.color,
                data: x.data,
                borderWidth: 2,
                yAxisID: "y1",
              };
            } else return;
          })
        : [],
  };

  return (
    <div className={styles.multiChartWrapperSize}>
      <Label>{TextConstants.HomePage.Heading_Tab_SpeedUpProcess}</Label>
      <div className={styles.multiChartSize}>
        {props?.multiChartData?.showLoading ? (
          <Spinner
            className={styles.spinnerMargin}
            label={TextConstants.HomePage.Heading_Chart_LoadingData}
            ariaLive="assertive"
            labelPosition="right"
          />
        ) : props?.multiChartData?.data?.length > 0 ? (
          <Chart type="bar" data={data} options={options} />
        ) : (
          <Text style={{ display: "block", textAlign: "center" }} className={styles.spinnerMargin}>{TextConstants.HomePage.Heading_Chart_NoDataToDisplay}</Text>
        )}
      </div>
    </div>
  );
};
