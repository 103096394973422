import { ICompany } from "interfaces";
import { useDispatch } from "react-redux";
import { HttpService } from "services";
import { showLoadingSpinner, hideLoadingSpinner, showErrorMessage, hideErrorMessage } from "components";
import { ErrorRegions } from "constant";

export class CompanyService {
  public apiEndpointCompany = `/api/Company`;
  dispatch = useDispatch();

  public async getAllCompanies(): Promise<ICompany[]> {
    // this.dispatch(showLoadingSpinner());
    let companies: ICompany[] = [];

    try {
      companies = await HttpService.get(`${this.apiEndpointCompany}`);
    } catch (error) {
      this.dispatch(showErrorMessage({ error, errorOriginationKey: ErrorRegions.Company }));
      setTimeout(() => {
        this.dispatch(hideErrorMessage(ErrorRegions.Company));
      }, 5000);
    }
    // this.dispatch(hideLoadingSpinner());
    return companies;
  }

  public async addNewCompany(newProjReq: any): Promise<any> {
    this.dispatch(showLoadingSpinner());
    try {
      const response = await HttpService.post(`${this.apiEndpointCompany}`, newProjReq);
      this.dispatch(hideLoadingSpinner());
    } catch (error) {
      this.dispatch(hideLoadingSpinner());
      this.dispatch(showErrorMessage({ error, errorOriginationKey: ErrorRegions.Company }));
      setTimeout(() => {
        this.dispatch(hideErrorMessage(ErrorRegions.Company));
      }, 5000);
    }

    return;
  }
}
