import { useDispatch } from "react-redux";
import { HttpService } from "services";
import { IResponsibilityMatrix, IResponsibilityMatrixAssignment, RequestType } from "interfaces";
import { showLoadingSpinner, hideLoadingSpinner, showErrorMessage, hideErrorMessage } from "components";
import { ErrorRegions } from "constant";

export default class ResponsibilityMatrixService {
  private static apiEndpointMatrix: string = `/api/ResponsibilityMatrix`;
  private dispatch = useDispatch();

  public static async getAllResponsibilityMatrixData(): Promise<IResponsibilityMatrix[]> {
    let allResponsibilityMatrices: IResponsibilityMatrix[] = [];
    try {
      allResponsibilityMatrices = await HttpService.get(`${this.apiEndpointMatrix}`);
    } catch (error) {
      console.error(error);
    }
    return allResponsibilityMatrices;
  }

  public static async getResponsibilityMatrixBasedonRequestType(requestType: RequestType, company?: string): Promise<IResponsibilityMatrix[]> {
    let responsibilityMatrices: IResponsibilityMatrix[] = [];
    try {
      let url = `${this.apiEndpointMatrix}/${requestType}`;
      if (company) {
        url = `${url}/${company}`;
      }
      responsibilityMatrices = await HttpService.get(url);
    } catch (error) {
      console.error(error);
    }
    return responsibilityMatrices;
  }

  public async createResponsibilityMatrixEntry(responsibilityMatrix: IResponsibilityMatrix): Promise<IResponsibilityMatrix> {
    return HttpService.post(`${ResponsibilityMatrixService.apiEndpointMatrix}`, responsibilityMatrix);
  }

  public async updateResponsibilityMatrixEntry(responsibilityMatrix: IResponsibilityMatrix) {
    const addedResponsibles: IResponsibilityMatrixAssignment[] = [];
    const deletedResponsibles: number[] = [];

    responsibilityMatrix.responsibles?.forEach(responsible => {
      if (responsible.id <= 0) {
        addedResponsibles.push(responsible);
      }
      else{
        if(responsible.isDeleted){
          deletedResponsibles.push(responsible.id);
        }
      }
    });

    const payload = {
      responsibilityMatrix:responsibilityMatrix,
      deletedResponsibles: deletedResponsibles,
      addedResponsibles: addedResponsibles
    }

    return await HttpService.put(`${ResponsibilityMatrixService.apiEndpointMatrix}`, payload);
  }

  public async deleteResponsibilityMatrixEntry(ids: number[]) {
    this.dispatch(showLoadingSpinner());
    try {
      await HttpService.delete(`${ResponsibilityMatrixService.apiEndpointMatrix}`, ids);
      this.dispatch(hideLoadingSpinner());
      return true;
    } catch (error) {
      this.dispatch(hideLoadingSpinner());
      this.dispatch(showErrorMessage({ error, errorOriginationKey: ErrorRegions.ResponsibilityMatrix }));
      setTimeout(() => {
        this.dispatch(hideErrorMessage(ErrorRegions.ResponsibilityMatrix));
      }, 5000);
      return false;
    }
  }
}
