export const ItemSAPExportHeaders = [
  "Matnr",
  "KT EN",
  "Mtart",
  "ConfTyp",
  "AENNR",
  "Mstae",
  "Matkl",
  "Prdh",
  "Prj no",
  "PM",
  "Spart",
  "Meins",
  "EKWSL",
  "XCHPF",
  "KT DE",
  "KT FR",
  "KT IT",
  "KT ES",
  "KT PT",
  "KT JA",
  "KT KO",
  "Class",
  "COGS Plant",
  "Min. Rem. Shelf Life",
  "Product Gr",
  "GMDN",
  "Ref",
  "",
  "",
  "",
  "",
  "Workpackage",
  "",
  "",
  "",
  "",
  "PLM Design Owner",
  "Active for Hybris",
  "Product Category",
  "Legal Manufact",
];

export const SalesTextExportHeaders = ["Material number", "Language code", "Sales Text"];
export const ShortTextExportHeaders = ["Material number", "Language code", "Short Text"];

export const ItemGTINExportHeaders = ["MATNR", "EAN11"];
