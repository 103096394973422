export enum ErrorRegions {
  Login = "Login",
  GroupList = "GroupList",
  ProjectRequest = "ProjectRequest",
  Company = "Company",
  Choice = "Choice",
  HelpPage = "HelpPage",
  FAQ = "FAQ",
  MetadataConfig = "MetadataConfig",
  ResponsibilityMatrix = "ResponsibilityMatrix",
  Invitation = "Invitation",
  HomePage = "HomePage",
  ColorSchemeMatrix = "ColorSchemeMatrix",
  UserPermissionManagement="UserPermissionManagement"
}
