import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { IAuditLog, IBOMItem, ICheckListItem, IMaterial, IDataLockInfo, ITranslation, IProjectRequestStatus, IProjectRequest, ProjectRequestFormValidationState, IAttachment, IProjectTask, SelectedRequestStepTab, RequestStepType, RequestStepTab, DataLockSectionType } from "interfaces";
import { Util } from "../Util";

const validationState: ProjectRequestFormValidationState = undefined;
const projectRequest: IProjectRequest = undefined;

export interface IBOMItemSequence {
  [matId: string]: number;
}

export const projectRequestSlice = createSlice({
  name: "currentProjectRequest",
  initialState: {
    projectRequest: projectRequest,
    validationState: validationState,
    lastUsedMaterialId: 0,
    lastUsedBOMItemId: 0,
    bomItemSequences: {} as IBOMItemSequence,
    lastUsedTranslationId: 0,
    currentlyEditedSectionInfo: {} as SelectedRequestStepTab,
  },
  reducers: {
    resetCurrentProjectRequestStore: (state) => {
      state.projectRequest = undefined;
      state.validationState = undefined;
      state.lastUsedMaterialId = 0;
      state.lastUsedBOMItemId = 0;
      state.bomItemSequences = {} as IBOMItemSequence;
      state.lastUsedTranslationId = 0;
      //This is an example of how you should not assign state
      // state = {
      //   projectRequest: undefined,
      //   validationState: undefined,
      //   lastUsedMaterialId: 0,
      //   lastUsedBOMItemId: 0,
      //   bomItemSequences: {} as IBOMItemSequence,
      //   lastUsedTranslationId: 0
      // };
    },
    setLastUsedMaterialId: (state, action: PayloadAction<number>) => {
      state.lastUsedMaterialId = action.payload;
    },
    setLastUsedTranslationId: (state, action: PayloadAction<number>) => {
      state.lastUsedTranslationId = action.payload;
    },
    incrementLastUsedMaterialId: (state) => {
      state.lastUsedMaterialId = state.lastUsedMaterialId + 1;
    },
    incrementLastUsedBOMItemId: (state) => {
      state.lastUsedBOMItemId++;
    },
    incrementLastUsedTranslationId: (state) => {
      state.lastUsedTranslationId = state.lastUsedTranslationId + 1;
    },
    updateValidationState: (state, action: PayloadAction<ProjectRequestFormValidationState>) => {
      state.validationState = { ...state.validationState, ...action.payload };
    },
    resetCurrentRequest: (state, action: PayloadAction<IProjectRequest>) => {
      state.projectRequest = action.payload;
    },
    setProjectRequest: (state, action: PayloadAction<Partial<IProjectRequest>>) => {
      state.projectRequest = action.payload;
    },
    updateSimpleApprovalTasks: (state, action: PayloadAction<IProjectTask[]>) => {
      state.projectRequest = Util.updateSimpleApprovalTasksInProjectRequest(state.projectRequest, action.payload);
      // action.payload.forEach(st => {
      //   const idx = state.projectRequest.projectTasks.findIndex(t => t.id === st.id);
      //   state.projectRequest.projectTasks[idx] = st;
      // });
    },
    updateProjectRequestTask: (state, action: PayloadAction<IProjectTask>) => {
      if (state.projectRequest) {
        state.projectRequest = Util.updateProjectRequestTask(state.projectRequest, action.payload);
      }
    },
    setProjectRequestWithExistingBOMItems: (state, action: PayloadAction<Partial<IProjectRequest>>) => {
      state.projectRequest = Util.setProjectRequestWithExistingBOMItems(state.projectRequest, action.payload);
    },
    updateProjectRequest: (state, action: PayloadAction<Partial<IProjectRequest>>) => {
      console.log(`%cUpdating loaded Project in store`, "color:yellow");
      state.projectRequest = { ...state.projectRequest, ...action.payload };
    },
    updateProjectAuditLog: (state, action: PayloadAction<IAuditLog[]>) => {
      if (state.projectRequest) {
        state.projectRequest.auditLogs = [...action.payload];
      }
    },
    addMaterialsToProjectRequest: (state, action: PayloadAction<IMaterial[]>) => {
      if (state.projectRequest) {
        let currentMaterials = state.projectRequest.materials ?? [];
        currentMaterials = currentMaterials.concat(action.payload);
        state.projectRequest.materials = currentMaterials;
      }
    },
    updateMaterialInProjectRequest: (state, action: PayloadAction<IMaterial>) => {
      if (state.projectRequest) {
        state.projectRequest = Util.updateMaterialInProjectRequest(state.projectRequest, action.payload);
      }
    },
    updateBOMItemInMaterial: (state, action: PayloadAction<IBOMItem>) => {
      if (state.projectRequest) {
        const mat = state.projectRequest.materials.find(m => m.id === action.payload.materialId);
        if (mat.bomItems.length) {
          let currentBOMItems = mat.bomItems;
          const itemIdx = currentBOMItems.findIndex(x => x.id === action.payload.id);
          if (itemIdx !== -1) {
            currentBOMItems[itemIdx] = action.payload;
          }
          mat.bomItems = currentBOMItems;
        }
      }
    },
    removeMaterialFromProjectRequest: (state, action: PayloadAction<number>) => {
      if (state.projectRequest) {
        state.projectRequest = Util.removeMaterialFromProjectRequest(state.projectRequest, action.payload);
      }
    },
    removeMaterialsFromProjectRequest: (state, action: PayloadAction<number[]>) => {
      if (state.projectRequest) {
        state.projectRequest = Util.removeMaterialsFromProjectRequest(state.projectRequest, action.payload);
      }
    },
    fixMaterialLockDates: (state, action: PayloadAction<IProjectRequest>) => {
      if (state.projectRequest) {
        state.projectRequest.dataLocks = state.projectRequest.dataLocks?.map<IDataLockInfo>(l => ({ ...l, lockedSince: new Date(l.lockedSince) }));
      }
    },
    setSectionLock: (state, action: PayloadAction<IDataLockInfo>) => {
      if (state.projectRequest) {
        state.projectRequest = Util.setSectionLock(state.projectRequest, action.payload);
      }
    },
    removeSectionLock: (state, action: PayloadAction<number>) => {
      if (state.projectRequest) {
        state.projectRequest = Util.removeSectionLock(state.projectRequest, action.payload);
      }
    },
    updateTranslationInMaterial: (state, action: PayloadAction<ITranslation>) => {
      if (state.projectRequest) {
        state.projectRequest = Util.updateTranslationInMaterial(state.projectRequest, action.payload);
      }
    },
    addBOMItem: (state, action: PayloadAction<IBOMItem>) => {
      if (state.projectRequest) {
        const matIdx = state.projectRequest.materials.findIndex(m => m.id === action.payload.materialId);
        const material = state.projectRequest.materials[matIdx];
        const currentBOMItems = material.bomItems ?? [];
        currentBOMItems.push(action.payload);
        state.projectRequest.materials[matIdx].bomItems = currentBOMItems;
      }
    },
    setBOMItem: (state, action: PayloadAction<{ matIdx: number, itemIdx: number, item: IBOMItem }>) => {
      if (state.projectRequest) {
        const oldItem = state.projectRequest.materials[action.payload.matIdx].bomItems[action.payload.itemIdx];
        state.projectRequest.materials[action.payload.matIdx].bomItems[action.payload.itemIdx] = { ...oldItem, ...action.payload.item };
      }
    },
    setMaterialBOMItems: (state, action: PayloadAction<{ matId: number, items: IBOMItem[] }>) => {
      if (state.projectRequest) {
        const matIdx = state.projectRequest.materials.findIndex(m => m.id === action.payload.matId);
        state.projectRequest.materials[matIdx].bomItems = [...action.payload.items];
      }
    },
    setProjectRequestBOMItems: (state, action: PayloadAction<IBOMItem[]>) => {
      if (state.projectRequest) {
        if ((!state?.projectRequest?.materials || state?.projectRequest?.materials?.length === 0) && action.payload.length !== 0) {
          alert("DEBUGGING: Trying to load BOMItems, but Materials are not loaded yet!");
        }
        let updatedMats: IMaterial[] = [];
        state.projectRequest.materials.forEach((mat, idx) => {
          mat.bomItems = action.payload.filter(i => i.materialId === mat.id);
          updatedMats.push({ ...mat });
        });
        state.projectRequest.materials = updatedMats;
      }
    },
    addAuditLog: (state, action: PayloadAction<IAuditLog>) => {
      if (state.projectRequest) {
        state.projectRequest.auditLogs = [...state.projectRequest.auditLogs, action.payload];
      }
    },
    removeBOMItemFromMaterial: (state, action: PayloadAction<IBOMItem>) => {
      if (state.projectRequest) {
        const material = state.projectRequest.materials?.find(m => m.id === action.payload.materialId);
        const itemIdx = material.bomItems.findIndex(i => i.id === action.payload.id);

        let currentItems = material.bomItems ?? [];
        currentItems.splice(itemIdx, 1);
        material.bomItems = currentItems;
      }
    },
    removeSelectedBOMItems: (state, action: PayloadAction<IBOMItem[]>) => {
      if (state.projectRequest) {
        if (action.payload?.length) {
          const matId = action.payload[0].materialId;
          const material = state.projectRequest.materials?.find(m => m.id === matId);

          if (material?.bomItems) {
            let newItems = [...material.bomItems];
            action.payload.forEach((item) => {
              const itemIdx = newItems.findIndex(i => i.id === item.id);
              newItems.splice(itemIdx, 1);
            });
            material.bomItems = newItems;
          }
        }
      }
    },
    setBOMItemSequence: (state, action: PayloadAction<{ material: IMaterial, num: number }>) => {
      if (state.projectRequest) {
        const newSequenceDict = { ...state.bomItemSequences };
        const sequenceExists = Object.hasOwn(newSequenceDict, `${action.payload.material.id}`);
        if (!sequenceExists) {
          newSequenceDict[`${action.payload.material.id}`] = 0;
        }
        newSequenceDict[`${action.payload.material.id}`] = action.payload.num;
        state.bomItemSequences = newSequenceDict;
      }
    },
    setBOMMainComponent: (state, action: PayloadAction<{ item: IBOMItem, value: boolean }>) => {
      if (state.projectRequest) {
        const mat = state.projectRequest.materials.find(m => m.id === action.payload.item.materialId);
        if (mat.bomItems.length) {
          let currentBOMItems = [...mat.bomItems];
          const itemIdx = currentBOMItems.findIndex(x => x.id === action.payload.item.id)
          if (itemIdx !== -1) {
            currentBOMItems[itemIdx] = { ...action.payload.item };
            currentBOMItems[itemIdx].isMainComponent = action.payload.value;
            if (action.payload.value) {
              currentBOMItems[itemIdx].isLMS = false;
              currentBOMItems[itemIdx].isIFU = false;
            }
          }
          mat.bomItems = currentBOMItems;
        }
      }
    },
    setBOMLMS: (state, action: PayloadAction<{ item: IBOMItem, value: boolean }>) => {
      if (state.projectRequest) {
        const mat = state.projectRequest.materials.find(m => m.id === action.payload.item.materialId);
        if (mat.bomItems.length) {
          let currentBOMItems = [...mat.bomItems];
          const itemIdx = currentBOMItems.findIndex(x => x.id === action.payload.item.id)
          if (itemIdx !== -1) {
            currentBOMItems[itemIdx] = { ...action.payload.item };
            currentBOMItems[itemIdx].isLMS = action.payload.value;
            if (action.payload.value) {
              currentBOMItems[itemIdx].isMainComponent = false;
              currentBOMItems[itemIdx].isIFU = false;
            }
          }
          mat.bomItems = currentBOMItems;
        }
      }
    },
    setBOMIFU: (state, action: PayloadAction<{ item: IBOMItem, value: boolean }>) => {
      if (state.projectRequest) {
        const mat = state.projectRequest.materials.find(m => m.id === action.payload.item.materialId);
        if (mat.bomItems.length) {
          let currentBOMItems = [...mat.bomItems];
          const itemIdx = currentBOMItems.findIndex(x => x.id === action.payload.item.id)
          if (itemIdx !== -1) {
            currentBOMItems[itemIdx] = { ...action.payload.item };
            currentBOMItems[itemIdx].isIFU = action.payload.value;
            if (action.payload.value) {
              currentBOMItems[itemIdx].isMainComponent = false;
              currentBOMItems[itemIdx].isLMS = false;
            }
          }
          mat.bomItems = currentBOMItems;
        }
      }
    },
    updateStateMachineStatus: (state, action: PayloadAction<IProjectRequestStatus>) => {
      if (state.projectRequest) {
        state.projectRequest.stateMachineStatus = action.payload;
      }
    },
    setCheckListInProjectRequest: (state, action: PayloadAction<ICheckListItem[]>) => {
      if (state.projectRequest) {
        state.projectRequest.checkList = [...action.payload];
        state.projectRequest.isProjectMetadataChanged = true;
      }
    },
    addCheckListItemInProjectRequest: (state, action: PayloadAction<ICheckListItem>) => {
      if (state.projectRequest) {
        const checkList = [...state.projectRequest.checkList];
        checkList.push(action.payload);
        state.projectRequest.isProjectMetadataChanged = true;
        state.projectRequest.checkList = checkList;
      }
    },
    updateCheckListItemInProjectRequest: (state, action: PayloadAction<ICheckListItem>) => {
      if (state.projectRequest) {
        const checkList = [...state.projectRequest.checkList];
        const item = checkList.find(x => x.id === action.payload.id);
        for (const key in action.payload) {
          item[key as keyof typeof item] = action.payload[key as keyof typeof action.payload] as never;
        }
        state.projectRequest.isProjectMetadataChanged = true;
        state.projectRequest.checkList = checkList;
      }
    },
    deleteCheckListItemInProjectRequest: (state, action: PayloadAction<ICheckListItem>) => {
      if (state.projectRequest) {
        let checkList = [...state.projectRequest.checkList];
        checkList = checkList.filter(x => x.id !== action.payload.id)
        state.projectRequest.isProjectMetadataChanged = true;
        state.projectRequest.checkList = checkList;
      }
    },
    resetProjectRequestChangeFlags: (state) => {
      state.projectRequest = Util.resetProjectRequestChangeFlags(state.projectRequest);
    },
    resetMaterialChangeFlags: (state) => {
      state.projectRequest = Util.resetMaterialChangeFlags(state.projectRequest);
    },
    resetTranslationChangeFlags: (state) => {
      state.projectRequest = Util.resetTranslationChangeFlags(state.projectRequest);
    },
    resetBOMItemsChangeFlags: (state) => {
      state.projectRequest = Util.resetBOMItemsChangeFlags(state.projectRequest);
    },
    resetAllChangeFlagsInProjectRequest: (state) => {
      state.projectRequest = Util.resetAllChangeFlagsInProjectRequest(state.projectRequest);
    },
    updateProjectAttachments: (state, action: PayloadAction<IAttachment[]>) => {
      if (state.projectRequest) {
        state.projectRequest.attachments = [...action.payload];
      }
    },
    addCurrentlyEditedSectionInfo: (state, action: PayloadAction<{ [key in RequestStepType]?: RequestStepTab }>) => {
      for (const key in action.payload) {
        let step = key as RequestStepType;
        if (state.currentlyEditedSectionInfo[step]) {
          if (state.currentlyEditedSectionInfo[step].indexOf(action.payload[step]) === -1) {
            state.currentlyEditedSectionInfo[step].push(action.payload[step]);
          }
        }
        else {
          state.currentlyEditedSectionInfo[step] = [action.payload[step]];
        }
      }
    },
    removeCurrentlyEditedStepTab: (state, action: PayloadAction<{ [key in RequestStepType]?: RequestStepTab[] }>) => {
      for (const key in action.payload) {
        let step = key as RequestStepType;

        if (state.currentlyEditedSectionInfo[step]) {
          const currentTabs = [...state.currentlyEditedSectionInfo[step]];

          for (const tab of action.payload[step]) {
            const index = currentTabs.indexOf(tab);
            currentTabs.splice(index, 1);
          }

          state.currentlyEditedSectionInfo[step] = currentTabs;
        }
      }
    },
    resetCurrentlyEditedSectionInfo: (state) => {
      state.currentlyEditedSectionInfo = {};
    }
  }
});

export const {
  updateProjectRequest, setProjectRequest, resetCurrentRequest, updateValidationState, fixMaterialLockDates,
  setSectionLock, removeSectionLock, addMaterialsToProjectRequest, updateMaterialInProjectRequest,
  removeMaterialFromProjectRequest, removeMaterialsFromProjectRequest, setLastUsedMaterialId, incrementLastUsedBOMItemId,
  setLastUsedTranslationId, incrementLastUsedMaterialId, incrementLastUsedTranslationId, updateTranslationInMaterial, addBOMItem,
  setBOMItem, addAuditLog, removeBOMItemFromMaterial, setMaterialBOMItems, setProjectRequestBOMItems, removeSelectedBOMItems,
  updateBOMItemInMaterial, setBOMItemSequence, setBOMMainComponent, setBOMLMS, setBOMIFU, updateProjectAuditLog,
  updateStateMachineStatus, setCheckListInProjectRequest, addCheckListItemInProjectRequest, updateCheckListItemInProjectRequest,
  deleteCheckListItemInProjectRequest, resetProjectRequestChangeFlags, resetMaterialChangeFlags, resetTranslationChangeFlags,
  resetBOMItemsChangeFlags, resetAllChangeFlagsInProjectRequest, setProjectRequestWithExistingBOMItems, updateProjectAttachments,
  updateProjectRequestTask, updateSimpleApprovalTasks, addCurrentlyEditedSectionInfo, removeCurrentlyEditedStepTab,
  resetCurrentProjectRequestStore, resetCurrentlyEditedSectionInfo
} = projectRequestSlice.actions;

export const projectRequestReducer = projectRequestSlice.reducer;
