import { createSlice } from "@reduxjs/toolkit";
import { IUserGroup } from "interfaces";

const initialStateVal: IUserGroup[] = null;

export const groupSlice = createSlice({
  name: "allGroups",
  initialState: {
    value: initialStateVal,
  },
  reducers: {
    updateGroupList: (state, action) => {
      state.value = [...action.payload];
    }
  },
});

export const { updateGroupList } = groupSlice.actions;

export const groupReducer = groupSlice.reducer;
