import { configureStore } from "@reduxjs/toolkit";
import {
  loadingSpinnerReducer, successMessageReducer, errorMessageReducer, faqReducer, helpPageReducer,  
   currentUserReducer, groupReducer
} from "components";
import { checkListReducer } from "components/request/kickOff/checkListSlice";
import { languageCodeReducer } from "components/request/reducers/languageCodeSlice";
import { masterDataReducer } from "components/request/reducers/masterDataSlice";
import { projectRequestReducer } from "components/request/reducers/projectRequestSlice";
import { responsibilityMatrixReducer } from "components/request/reducers/responsibilityMatrixSlice";

const store = configureStore({
  reducer: {
    currentUser: currentUserReducer,
    currentProjectRequest: projectRequestReducer,
    checkList: checkListReducer,
    faqData: faqReducer,
    helperData: helpPageReducer,
    loadingSpinner: loadingSpinnerReducer,
    errorMessage: errorMessageReducer,
    successMessage: successMessageReducer,
    groups: groupReducer,
    masterData: masterDataReducer,
    responsibilityMatrix: responsibilityMatrixReducer,
    languageCode: languageCodeReducer
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }),
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;

export default store;
