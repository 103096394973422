import {
    ConstrainMode, DetailsListLayoutMode, DialogType, IColumn, IconButton, IDetailsHeaderProps, IDetailsListStyles, IDetailsRowProps, IDialogProps, IRenderFunction, ITooltipHostProps, Panel, PanelType, PrimaryButton, SelectionMode,
    ShimmeredDetailsList,
    TooltipHost, Stack,DefaultButton
  } from "@fluentui/react";
  import PeoplePicker from "components/common/PeoplePicker";
  import { showErrorMessage, hideLoadingSpinner, showLoadingSpinnerWithMessage } from "components";
  import { ErrorRegions, TextConstants } from "constant";
  import { IUserGroup,IUser } from "interfaces";
  import React, { useCallback, useEffect, useMemo, useState } from "react";
  import { useDispatch, useSelector } from "react-redux";
  import { GroupService } from "./GroupService";
  import styles from "./GroupManagement.module.scss";
  import adminStyles from "../Admin.module.scss";
  import { ManageGroupForm } from "./ManageGroupForm";
  import { useBoolean } from "@fluentui/react-hooks";
  import {
    CommonService, renderClickableRowWithMultipleSelectionEnabled, PrincipalTypes
  } from "services";
  import { ErrorMessage } from "components/common";
  import { updateGroupList } from "./groupSlice";
  import { RootState } from "store";
  import { useWindowResize } from "hooks";
  import { group } from "console";
  
  const columns: IColumn[] = [
    {
      key: "name",
      name: TextConstants.GroupList.Label_Table_Column_Name,
      fieldName: "name",
      isSorted: false,
      isSortedDescending: false,
      minWidth: 200,
      maxWidth:320,
      isResizable: true,
      className: styles.listColumn,
    },
    {
      key: "delete",
      name: TextConstants.GroupList.Label_Delete_Table_Column_Name,
      fieldName: "delete",
      isSorted: false,
      isSortedDescending: false,
      minWidth: 50,
      isResizable: false,
      className: styles.listColumn,
    },
  ];

  export interface IUserGroupList {
    dismissPanel?: () => void;  
  }
  
  export const UserGroupList: React.FC<IUserGroupList> = (props: IUserGroupList) => {
    const dispatch = useDispatch();
    const [selectedGroup, setSelectedGroup] = useState<IUserGroup | null>(null);
    const [isPanelVisible, { toggle: togglePanelVisiblity }] = useBoolean(false);
    const [selectedUserGroups , setGroups] = useState<IUserGroup[] | []>([]);
    const { width, height } = useWindowResize();
    const userGroups: IUserGroup[] = useSelector((store: RootState) => store.groups.value);
    const [selectedUser, setUsersToGetGroups] = useState<IUser[]>(null);  
    const [reCall, setReCall] = useState<string>(null);  
    
    const gridStyles: Partial<IDetailsListStyles> = useMemo(() => ({
      root: {
        overflowX: 'auto',
        selectors: {
          '& [role=grid]': {
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'start',
            height: `${(height - 203)}px`,
            overflowX: 'auto',
          },
        },
      },
      headerWrapper: {
        flex: '0 0 auto',
      },
      contentWrapper: {
        flex: '1 1 auto',
        overflowY: 'auto',
        overflowX: 'hidden',
        maxWidth: '100%',
      },
    }), [height]);

    useEffect(() => {
          getAllGroups();
      }, [selectedUser,reCall]); 
    

    const getAllGroups = async () => {
    try {
        dispatch(showLoadingSpinnerWithMessage(TextConstants.ManageGroupForm.Message_RemovingUsersFromGroup));  
        let _userGroups:any=[];
        if(selectedUser && selectedUser.length > 0){
         _userGroups = await GroupService.getUserGroups(selectedUser[0].email);  
        }
        setGroups(_userGroups); 
        setReCall("Ok");
        dispatch(hideLoadingSpinner());      
    } catch (error) {
        dispatch(
        showErrorMessage({
            error,
            errorOriginationKey: ErrorRegions.GroupList,
        })
        );
    }
    }
  
    const closePanelAndRefreshList = useCallback(async () => {
      togglePanelVisiblity();
      dispatch(showLoadingSpinnerWithMessage(TextConstants.GroupList.Message_RefreshingGroupList));
      await getAllGroups();
      dispatch(hideLoadingSpinner());
    }, [getAllGroups])
  
    const openGroupForm = useCallback((formType: "New" | "Edit", group?: IUserGroup) => () => {
      if (formType === "New") {
        setSelectedGroup(null);
      } else {
        setSelectedGroup(group);
      }
  
      togglePanelVisiblity();
    }, []);
  
    const onClickRow = useCallback(async (event: Event, item: IUserGroup) => {
      if ((event.target as HTMLDivElement).className.indexOf(styles.deleteButton) === -1) {
        dispatch(showLoadingSpinnerWithMessage(TextConstants.GroupList.Message_LoadingGroup));
        try {
          const groupMemberships = await GroupService.getGroupMembership(item.id);
          const existingGroup = { ...item, groupMemberships };
          setSelectedGroup(existingGroup);
          togglePanelVisiblity();
          dispatch(hideLoadingSpinner());
        } catch (error) {
          dispatch(
            showErrorMessage({
              error,
              errorOriginationKey: ErrorRegions.GroupList,
            })
          );
        }
      }
    }, []);
  
    const deleteUserMembership = useCallback(async (userMembershipId: number) => {
      try {
        dispatch(showLoadingSpinnerWithMessage(TextConstants.ManageGroupForm.Message_RemovingUsersFromGroup));
  
        const result = await GroupService.removeGroupMembership(userMembershipId);
        if (result) {
            setReCall(null);
        }
      } catch (error) {
        dispatch(
          showErrorMessage({
            error: error,
          })
        );
      }
      dispatch(hideLoadingSpinner());
    }, [])
  
    const onClickDeleteUserMembership = useCallback((userMembershipId: number) => () => {
      const dialogProps: IDialogProps = {
        dialogContentProps: {
          type: DialogType.normal,
          title: TextConstants.ManageGroupForm.Confirmation_Message_RemoveUsersFromGroup,
        },
        modalProps: {
          isBlocking: false,
        },
      };
  
      const onConfirmCallback = () => {
        deleteUserMembership(userMembershipId);
      };
  
      CommonService.showConfirmationDialog(
        { ...dialogProps },
        TextConstants.Common.Label_Yes,
        TextConstants.Common.Label_No,
        onConfirmCallback
      );
    }, []);
  
    const onRenderRow = useCallback((props: IDetailsRowProps, defaultRender?: IRenderFunction<IDetailsRowProps>): JSX.Element => {
      return renderClickableRowWithMultipleSelectionEnabled(props, onClickRow, defaultRender);
    }, [onClickRow]);
  
    const onRenderColumn = useCallback((item?: IUserGroup, index?: number, column?: IColumn) => {
      const fieldName: keyof IUserGroup & "delete" = column.fieldName as keyof IUserGroup & "delete";
  
      switch (fieldName) {
        case "delete":
          return (
            <IconButton
              className={styles.deleteButton}
              iconProps={{ iconName: "UserRemove",className: styles.deleteButton  }}
              onClick={onClickDeleteUserMembership(item.groupMemberships[0].id)}
            />
          );
  
        default:
          return item[column.fieldName as keyof IUserGroup];
      }
    }, [onClickDeleteUserMembership])
  
    const onRenderDetailsHeader = useCallback((props: IDetailsHeaderProps, defaultRender: (props?: IDetailsHeaderProps) => JSX.Element | null) => {
      if (!props) {
        return null;
      }
      const onRenderColumnHeaderTooltip: IRenderFunction<ITooltipHostProps> = tooltipHostProps => (
        <TooltipHost {...tooltipHostProps} />
      );
      return (
        <div className={styles.stickyHeaderTable}>
          {defaultRender!({
            ...props,
            onRenderColumnHeaderTooltip,
          })}
        </div>
      );
    }, [])
  
    return (
        <div className={styles.manageGroupForm}>
          <Stack className={styles.addMemberContainer} horizontal horizontalAlign="space-between" tokens={{ childrenGap: 20 }}>
                <PeoplePicker
                  principalTypes={[PrincipalTypes.ExternalPeople, PrincipalTypes.InternalPeople]}
                  selectedUsers={selectedUser ?? []}
                  itemLimit={1}
                  width={"100%"}
                  placeholder={TextConstants.ManageGroupForm.Placeholder_GetGroups}
                  onChange={(items:any) => {
                    setUsersToGetGroups(items);                   
                  }}
                />               
              </Stack>
          <div style={{ maxHeight: 'calc(100vh - 210px)' }} className={styles.listContainer} data-is-scrollable="true">
            <ShimmeredDetailsList
              className={styles.list}
              items={selectedUserGroups}
              columns={columns}
              detailsListStyles={{
                headerWrapper: {
                  position: "sticky",
                  top: 0,
                  zIndex: 1
                }
              }}
              constrainMode={ConstrainMode.unconstrained}
              onRenderDetailsHeader={onRenderDetailsHeader}
              // selection={selection}
              enableShimmer={!userGroups}
              selectionMode={SelectionMode.none}
              layoutMode={DetailsListLayoutMode.justified}
              selectionPreservedOnEmptyClick={true}
              checkboxCellClassName={styles.listSelectionChekbox}
              onRenderRow={onRenderRow}
              onRenderItemColumn={onRenderColumn}
            />
            { (selectedUser == null || selectedUser.length == 0) && (<div style={{textAlign:"center"}}>
                <span>{`${TextConstants.Admin.CheckUserPermission.Label_NoUserSelected}`}</span>
            </div>
            )}
            { (selectedUserGroups == null || selectedUserGroups.length == 0) && (selectedUser != null && selectedUser.length > 0) && (<div style={{textAlign:"center"}}>
                <span>{`${TextConstants.Admin.CheckUserPermission.Label_NoGroupsForSelectedUser}`}</span>
            </div>
            )}
          </div>
          <Stack className={styles.saveActionBar} horizontal horizontalAlign="end" tokens={{ childrenGap: 10 }}>
          <DefaultButton
              iconProps={{ iconName: "Cancel" }}
              text={TextConstants.Common.Label_Button_Cancel}
              onClick={() => {                
                  props.dismissPanel?.();
              }}
            />    
         </Stack>
          <Panel
            className={styles.settingsPanel}
            headerText={
              selectedGroup?.name
                ? TextConstants.GroupList.Heading_Section_GroupSettings
                : TextConstants.GroupList.Heading_Section_NewGroup
            }
            isOpen={isPanelVisible}
            onDismiss={(ev?: React.SyntheticEvent<HTMLElement, Event> | KeyboardEvent) => {
              const srcElement = (ev as React.SyntheticEvent).nativeEvent.target as Element | null;
              if (srcElement && (srcElement.className.indexOf('ms-Button--icon') !== -1 || srcElement.className.indexOf('ms-Button-flexContainer') !== -1 || srcElement.className.indexOf('ms-Button-icon') !== -1)) {
                console.log('Close button clicked.');
                togglePanelVisiblity();
              }
            }}
            closeButtonAriaLabel="Close"
            isLightDismiss={false}
            isBlocking={true}
            type={PanelType.medium}
            popupProps={{}}
            focusTrapZoneProps={{
              forceFocusInsideTrap: false,
            }}
          >
            {/* <ErrorMessage useReduxState={true} /> */}
            <ManageGroupForm existingGroup={selectedGroup} dismissPanel={togglePanelVisiblity} dismissPanelWithUpdate={closePanelAndRefreshList} refreshGroupList={getAllGroups} />
          </Panel>
      </div>
  
    );
  };
  