import React from "react";
import styles from "./Layout.module.scss";
import logo from "images/str-group-white.svg";
import logoSpeedUp from "images/Logo_SpeedUP_Product-service.svg";

import { css, Stack } from "@fluentui/react";
import { ErrorMessage, LinkToHome, LoadingSpinner, SuccessMessage, LoggedInUser, AdminMenu } from "..";
import { Link } from "react-router-dom";
import { TextConstants } from "constant";

export const Layout: React.FunctionComponent<any> = (props: any) => {
  const year = new Date().getFullYear();

  return (
    <>
      <header className={styles.header}>
        <div className={styles.appNameContainer}>
          <Link className={styles.appName} to="/">
            <img className={styles.appLogo} src={logoSpeedUp} alt={TextConstants.Layout.Label_ApplicationName}></img>
          </Link>
          <img className={styles.companyLogo} src={logo} alt={TextConstants.Layout.Label_Straumann}></img>
        </div>
        <div className={styles.headerActionBar}>
          <AdminMenu />
          <LoggedInUser />
        </div>
      </header>
      <main className={css("main", styles.mainContent)} data-is-scrollable="true">
        {/* <LinkToHome showForRoute={[new RegExp(/^\/admin/gi), new RegExp(/^\/dashboard/gi), new RegExp(/^\/request/gi),]} /> */}
        <ErrorMessage useReduxState={true} />
        <SuccessMessage useReduxState={true} />

        {props.children}
        <LoadingSpinner />
      </main>
      <footer className={styles.footer}>
        <Stack horizontal horizontalAlign="start" verticalAlign="center">
          {TextConstants.Layout.Label_CopyRights}
        </Stack>
      </footer>
    </>
  );
};
