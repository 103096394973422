import {
  CommandBar, ConstrainMode, DetailsListLayoutMode, DialogType, IColumn, IconButton, IDetailsHeaderProps, IDetailsListStyles, 
  IDetailsRowProps, IDialogProps, IRenderFunction, ITooltipHostProps, Panel, PanelType, PrimaryButton, SelectionMode, ShimmeredDetailsList,
  TooltipHost
} from "@fluentui/react";
import { showErrorMessage, hideLoadingSpinner, showLoadingSpinnerWithMessage, AdminPageContainer } from "components";
import { ErrorRegions, TextConstants } from "constant";
import { IUserGroup } from "interfaces";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { GroupService } from "./GroupService";
import styles from "./GroupManagement.module.scss";
import adminStyles from "../Admin.module.scss";
import { ManageGroupForm } from "./ManageGroupForm";
import { UserGroupList } from "./UserPermissionManagement";
import { useBoolean } from "@fluentui/react-hooks";
import {
  CommonService, renderClickableRowWithMultipleSelectionEnabled,
} from "services";
import { ErrorMessage } from "components/common";
import { updateGroupList } from "./groupSlice";
import { RootState } from "store";
import { useWindowResize } from "hooks";
interface IGroupList { }

const columns: IColumn[] = [
  {
    key: "name",
    name: TextConstants.GroupList.Label_Table_Column_Name,
    fieldName: "name",
    isSorted: false,
    isSortedDescending: false,
    minWidth: 200,
    isResizable: true,
    className: styles.listColumn,
  },
  {
    key: "isAdminGroup",
    name: TextConstants.GroupList.Label_Table_Column_IsAdminGroup,
    fieldName: "isAdminGroup",
    isSorted: false,
    isSortedDescending: false,
    minWidth: 150,
    maxWidth: 150,
    isResizable: true,
    className: styles.listColumn,
    isCollapsible: true,
  },
  {
    key: "isProcessAdminGroup",
    name: TextConstants.GroupList.Label_Table_Column_IsProcessAdminGroup,
    fieldName: "isProcessAdminGroup",
    isSorted: false,
    isSortedDescending: false,
    minWidth: 150,
    maxWidth: 150,
    isResizable: true,
    className: styles.listColumn,
    isCollapsible: true,
  },
  {
    key: "isDeveloperGroup",
    name: TextConstants.GroupList.Label_Table_Column_IsDeveloperGroup,
    fieldName: "isDeveloperGroup",
    isSorted: false,
    isSortedDescending: false,
    minWidth: 150,
    maxWidth: 150,
    isResizable: true,
    className: styles.listColumn,
    isCollapsible: true,
  },
  {
    key: "delete",
    name: undefined,
    fieldName: "delete",
    isSorted: false,
    isSortedDescending: false,
    minWidth: 50,
    isResizable: false,
    className: styles.listColumn,
  },
];

export const GroupList: React.FC<IGroupList> = (props: IGroupList) => {
  const dispatch = useDispatch();
  const [selectedGroup, setSelectedGroup] = useState<IUserGroup | null>(null);
  const [isPanelVisible, { toggle: togglePanelVisiblity }] = useBoolean(false);
  const userGroups: IUserGroup[] = useSelector((store: RootState) => store.groups.value);
  const { width, height } = useWindowResize();
  const[checkUserPermission,setCheckUserPermission] = useState<string | null>(null);

  useEffect(() => {
    getAllGroups();
  }, []);

  const gridStyles: Partial<IDetailsListStyles> = useMemo(() => ({
    root: {
      overflowX: 'auto',
      selectors: {
        '& [role=grid]': {
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'start',
          height: `${(height - 203)}px`,
          overflowX: 'auto',
        },
      },
    },
    headerWrapper: {
      flex: '0 0 auto',
    },
    contentWrapper: {
      flex: '1 1 auto',
      overflowY: 'auto',
      overflowX: 'hidden',
      maxWidth: '100%',
    },
  }), [height]);

  const getAllGroups = useCallback(async () => {
    try {
      const groups = await GroupService.getAllGroups();
      dispatch(updateGroupList(groups));
    } catch (error) {
      dispatch(
        showErrorMessage({
          error,
          errorOriginationKey: ErrorRegions.GroupList,
        })
      );
    }
  }, []);

  const closePanelAndRefreshList = useCallback(async () => {
    togglePanelVisiblity();
    dispatch(showLoadingSpinnerWithMessage(TextConstants.GroupList.Message_RefreshingGroupList));
    await getAllGroups();
    dispatch(hideLoadingSpinner());
  }, [getAllGroups])

  const openGroupForm = useCallback((formType: "New" | "Edit", group?: IUserGroup) => () => {
    if (formType === "New") {
      setSelectedGroup(null);
    } else {
      setSelectedGroup(group);
    }

    togglePanelVisiblity();
  }, []);

  const openCheckUserPermissionForm = useCallback((formType) => () => {
    setCheckUserPermission(formType);    

    togglePanelVisiblity();
  }, []);

  const onClickRow = useCallback(async (event: Event, item: IUserGroup) => {
    if ((event.target as HTMLDivElement).className.indexOf(styles.deleteButton) === -1) {
      dispatch(showLoadingSpinnerWithMessage(TextConstants.GroupList.Message_LoadingGroup));
      try {
        const groupMemberships = await GroupService.getGroupMembership(item.id);
        const existingGroup = { ...item, groupMemberships };
        setSelectedGroup(existingGroup);
        togglePanelVisiblity();
        dispatch(hideLoadingSpinner());
      } catch (error) {
        dispatch(
          showErrorMessage({
            error,
            errorOriginationKey: ErrorRegions.GroupList,
          })
        );
      }
    }
  }, []);

  const deleteGroup = useCallback(async (groupId: number) => {
    try {
      dispatch(showLoadingSpinnerWithMessage(TextConstants.ManageGroupForm.Message_RemovingUsersFromGroup));

      const result = await GroupService.deleteGroup(groupId);
      if (result) {
        const updatedGroups = userGroups.filter(x => x.id !== groupId);
        dispatch(updateGroupList(updatedGroups));
      }
    } catch (error) {
      dispatch(
        showErrorMessage({
          error: error,
        })
      );
    }
    dispatch(hideLoadingSpinner());
  }, [])

  const onClickDeleteGroup = useCallback((groupId: number) => () => {
    const dialogProps: IDialogProps = {
      dialogContentProps: {
        type: DialogType.normal,
        title: TextConstants.ManageGroupForm.Confirmation_Message_DeleteGroup,
      },
      modalProps: {
        isBlocking: false,
      }
    };

    const onConfirmCallback = () => {
      deleteGroup(groupId);
    };

    CommonService.showConfirmationDialog(
      { ...dialogProps },
      TextConstants.Common.Label_Yes,
      TextConstants.Common.Label_No,
      onConfirmCallback
    );
  }, [])

  //   const onColumnHeaderClick = useCallback((ev?: React.MouseEvent<HTMLElement, MouseEvent>, column?: IColumn) => {
  //     const items = currentUserMemberships;
  //     const newColumns: IColumn[] = columns.slice();
  //     const currColumn: IColumn = newColumns.filter(currCol => column.key === currCol.key)[0];
  //     newColumns.forEach((newCol: IColumn) => {
  //         if (newCol === currColumn) {
  //             currColumn.isSortedDescending = !currColumn.isSortedDescending;
  //             currColumn.isSorted = true;
  //         } else {
  //             newCol.isSorted = false;
  //             newCol.isSortedDescending = true;
  //         }
  //     })
  //     const newItems = _copyAndSort(items, currColumn.fieldName!, currColumn.isSortedDescending);
  //     setColumns(newColumns);
  //     setCurrentUserMemberships(newItems);
  // }, [currentUserMemberships]);

  const onRenderRow = useCallback((props: IDetailsRowProps, defaultRender?: IRenderFunction<IDetailsRowProps>): JSX.Element => {
    return renderClickableRowWithMultipleSelectionEnabled(props, onClickRow, defaultRender);
  }, [onClickRow]);

  const onRenderColumn = useCallback((item?: IUserGroup, index?: number, column?: IColumn) => {
    const fieldName: keyof IUserGroup & "delete" = column.fieldName as keyof IUserGroup & "delete";

    switch (fieldName) {
      case "isAdminGroup":
      case "isDeveloperGroup":
      case "isProcessAdminGroup":
        return item[column.fieldName as keyof IUserGroup] ? "Yes" : "No";

      case "delete":
        if (item.isAdminGroup || item.isDeveloperGroup) {
          return "";
        }

        return (
          <IconButton
            className={styles.deleteButton}
            iconProps={{ iconName: "Delete", className: styles.deleteButton }}
            onClick={onClickDeleteGroup(item.id)}
          />
        );

      default:
        return item[column.fieldName as keyof IUserGroup];
    }
  }, [onClickDeleteGroup])

  const onRenderDetailsHeader = useCallback((props: IDetailsHeaderProps, defaultRender: (props?: IDetailsHeaderProps) => JSX.Element | null) => {
    if (!props) {
      return null;
    }
    const onRenderColumnHeaderTooltip: IRenderFunction<ITooltipHostProps> = tooltipHostProps => (
      <TooltipHost {...tooltipHostProps} />
    );
    return (
      <div className={styles.stickyHeaderTable}>
        {defaultRender!({
          ...props,
          onRenderColumnHeaderTooltip,
        })}
      </div>
    );
  }, [])

  return (
    <AdminPageContainer pageTitle={TextConstants.Admin.SectionHeading_ManageGroups} >
        <div className={styles.actionBar}>
          <CommandBar
            styles={{
              root: {
                padding: 0,
                marginTop: '10px'
              }
            }}
            items={[
              {
                key: "newGroup",
                text: TextConstants.Common.Label_Button_New,
                iconProps: { iconName: "Add" },
                onClick: openGroupForm("New"),
              },
              {
                key: "permissions",
                text: TextConstants.Common.Label_Button_CheckPermission,
                iconProps: { iconName: "Permissions" },
                onClick: openCheckUserPermissionForm("Permissions"),
              }
              // {
              //   key: "DeleteGroups",
              //   text: "Delete",
              //   iconProps: { iconName: "Delete" },
              //   onClick: () => console.log("New"),
              // },
            ]}
            ariaLabel="Use left and right arrow keys to navigate between commands"
          />
        </div>
        <div style={{ maxHeight: 'calc(100vh - 299px)' }} className={styles.listContainer} data-is-scrollable="true">
          <ShimmeredDetailsList
            className={styles.list}
            items={userGroups}
            columns={columns}
            detailsListStyles={{
              headerWrapper: {
                position: "sticky",
                top: 0,
                zIndex: 1
              }
            }}
            constrainMode={ConstrainMode.unconstrained}
            onRenderDetailsHeader={onRenderDetailsHeader}
            // selection={selection}
            enableShimmer={!userGroups}
            selectionMode={SelectionMode.none}
            layoutMode={DetailsListLayoutMode.justified}
            selectionPreservedOnEmptyClick={true}
            checkboxCellClassName={styles.listSelectionChekbox}
            onRenderRow={onRenderRow}
            onRenderItemColumn={onRenderColumn}
          />
        </div>
        <Panel
          className={styles.settingsPanel}
          headerText={
            checkUserPermission ? TextConstants.Admin.SectionHeading_ManageGroupUsers : selectedGroup?.name
              ? TextConstants.GroupList.Heading_Section_GroupSettings
              : TextConstants.GroupList.Heading_Section_NewGroup
          }
          isOpen={isPanelVisible}
          onDismiss={(ev?: React.SyntheticEvent<HTMLElement, Event> | KeyboardEvent) => {
            const srcElement = (ev as React.SyntheticEvent).nativeEvent.target as Element | null;
            if (srcElement && (srcElement.className.indexOf('ms-Button--icon') !== -1 || srcElement.className.indexOf('ms-Button-flexContainer') !== -1 || srcElement.className.indexOf('ms-Button-icon') !== -1)) {
              console.log('Close button clicked.');
              setCheckUserPermission(null);    
              togglePanelVisiblity();
            }
          }}
          closeButtonAriaLabel="Close"
          isLightDismiss={false}
          isBlocking={true}
          type={PanelType.medium}
          popupProps={{}}
          focusTrapZoneProps={{
            forceFocusInsideTrap: false,
          }}
        >
          {/* <ErrorMessage useReduxState={true} /> */}
          { checkUserPermission ? (<UserGroupList dismissPanel={()=>{
            setCheckUserPermission(null);    
            togglePanelVisiblity();
          }}></UserGroupList>
          ) : <ManageGroupForm existingGroup={selectedGroup} dismissPanel={togglePanelVisiblity} dismissPanelWithUpdate={closePanelAndRefreshList} refreshGroupList={getAllGroups} /> }
        </Panel>
    </AdminPageContainer>

  );
};
