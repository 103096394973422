import { RequestStatus } from "./IProjectRequest";

export enum RequestStep {
  Start = "Start",
  KickOff = "Kick-off",
  ProductMasterDataText = "Product master data text",
  BOMCreation = "BOM creation",
  BOMSAPLoad = "BOM SAP load",
  ItemCreation = "Item creation",
  ExternalInput = "External input",
  TechnicalReadiness = "Technical readiness",
  CountryTasks = "Country tasks",
  // Completed = "Completed"
}

export const NO_REQUESTSTEP: string = "NULL";

export type RequestStepType = keyof typeof RequestStep;

export interface ITabSection {
  [name: string]: {
    [subname: string]: string;
  };
}

export const RequestStepTabs = {
  Start: {
    ProjectInfo: "ProjectInfo",
    ProductInfo: "ProductInfo",
    Translations: "Translations",
  },
  KickOff: {
    PLMData: "PLMData",
    LogisticsData: "LogisticsData",
    CheckList: "CheckList",
  },
  ProductMasterDataText: {
    Translations: "Translations",
  },
  BOMCreation: {
    BOMDataCollection: "BOMDataCollection",
  },
  BOMSAPLoad: {
    BOMSAPLoad: "BOMSAPLoad",
  },
  ItemCreation: {
    ItemCreation: "ItemCreation",
  },
  ExternalInput: {
    PackagingAndHandling: "PackagingAndHandling",
    // PackagingAndHandlingReview: "PackagingAndHandlingReview",
    DangerousGoods: "DangerousGoods",
    // DangerousGoodsReview: "DangerousGoodsReview",
    RA: "RA",
    // RAReview: "RAReview",
    Customs: "Customs",
    // CustomsReview: "CustomsReview",
  },
  TechnicalReadiness: {
    Overview: "Overview",
    CAMDbRouting: "CAMDbRouting",
    Labeling: "Labeling",
    EShop: "EShop",
    DangerousGoods: "DangerousGoods",
    Controlling: "Controlling",
    Logistics: "Logistics",
    Customs: "Customs",
    Picture: "Picture",
    TemperatureSensitive: "TemperatureSensitive",
    PM: "PM",
    ProductMasterDataCheck: "ProductMasterDataCheck",
    RADB: "RADB",
    RAChecklist: "RAChecklist",
    PurchasingClassification:"PurchasingClassification",
  },
  CountryTasks: {
    Overview: "Overview",
  }
};

export const SimpleApprovalTasks =[RequestStepTabs.TechnicalReadiness.CAMDbRouting,RequestStepTabs.TechnicalReadiness.DangerousGoods,RequestStepTabs.TechnicalReadiness.EShop,RequestStepTabs.TechnicalReadiness.Labeling,RequestStepTabs.TechnicalReadiness.Logistics,RequestStepTabs.TechnicalReadiness.Picture,RequestStepTabs.TechnicalReadiness.TemperatureSensitive,RequestStepTabs.TechnicalReadiness.RAChecklist,RequestStepTabs.TechnicalReadiness.PM]

export type RequestStepTabCombinationKey = `${RequestStepType}-${RequestStepTab}`;

export type SelectedRequestStepTab = {
  [key in RequestStepType]?: RequestStepTab[];
}

export type RequestStepTabData<K extends RequestStepTab, T> = {
  [key in RequestStepType]?: {
    [key in K]?: T
  }
}

export enum ProjectRequestTask {
  BOMSAPLoad = "BOM SAP Load",
  CAMDbRouting = "CamDB Routing",
  Controlling = "Controlling",
  Customs = "Customs",
  CustomsEI = "Customs Ext",
  CustomsEIReview = "Customs Review",
  DangerousGoods = "Dangerous Goods",
  DangerousGoodsEI = "Dangerous Goods Ext",
  DangerousGoodsEIReview = "Dangerous Goods Review",
  EShop = "E-shop",
  EngineeringCollection = "Engineering Collection",
  IFUCollection = "IFU Collection",
  ItemCreation = "Item Creation",
  KickOff = "Kick-off",
  Labeling = "Labeling",
  LabelingCollection = "Labeling Collection",
  Logistics = "Logistics",
  PM = "PM",
  PackagingCollection = "Packaging Collection",
  PackagingAndHandling = "Packaging & Handling",
  PackagingAndHandlingReview = "Packaging & Handling Review",
  Picture = "Picture",
  ProductMasterDataCheck = "Product Master Text Check",
  RA = "RA",
  RADB = "RADB",
  RAChecklist = "RA Checklist",
  RAReview = "RA Review",
  Resubmit = "Resubmit",
  TemperatureSensitive = "Temperature sensitive",
  Translations = "Translations",
  PurchasingClassification = "Purchasing Classification"
}

export const RequestStepTasks: {
  [key in RequestStepType]?: {
    Tasks?: {
      [key in keyof typeof ProjectRequestTask]?: ProjectRequestTask;
    },
    TabTasks?: {
      [key in RequestStepTab]?: {
        [key in keyof typeof ProjectRequestTask]?: ProjectRequestTask;
      }
    }
  }
} = {
  Start: {
    Tasks: {
      Resubmit: ProjectRequestTask.Resubmit
    }
  },
  KickOff: {
    Tasks: {
      KickOff: ProjectRequestTask.KickOff,
    }
  },
  ProductMasterDataText: {
    TabTasks: {
      Translations: {
        Translations: ProjectRequestTask.Translations,
      }
    }
  },
  ItemCreation: {
    TabTasks: {
      ItemCreation: {
        ItemCreation: ProjectRequestTask.ItemCreation,
      }
    }
  },
  BOMCreation: {
    TabTasks: {
      BOMDataCollection: {
        EngineeringCollection: ProjectRequestTask.EngineeringCollection,
        LabelingCollection: ProjectRequestTask.LabelingCollection,
        PackagingCollection: ProjectRequestTask.PackagingCollection,
        IFUCollection: ProjectRequestTask.IFUCollection,
      }
    }
  },
  BOMSAPLoad: {
    TabTasks: {
      BOMSAPLoad: {
        BOMSAPLoad: ProjectRequestTask.BOMSAPLoad
      }
    }
  },
  ExternalInput: {
    TabTasks: {
      PackagingAndHandling: {
        PackagingAndHandling: ProjectRequestTask.PackagingAndHandling,
        PackagingAndHandlingReview: ProjectRequestTask.PackagingAndHandlingReview,
      },
      DangerousGoods: {
        DangerousGoodsEI: ProjectRequestTask.DangerousGoodsEI,
        DangerousGoodsEIReview: ProjectRequestTask.DangerousGoodsEIReview,
      },
      RA: {
        RA: ProjectRequestTask.RA,
        RAReview: ProjectRequestTask.RAReview,
      },
      Customs: {
        CustomsEI: ProjectRequestTask.CustomsEI,
        CustomsEIReview: ProjectRequestTask.CustomsEIReview,
      },
    }
  },
  TechnicalReadiness: {
    TabTasks: {
      Controlling: {
        Controlling: ProjectRequestTask.Controlling,
      },
      RADB: {
        RADB: ProjectRequestTask.RADB,
      },
      Customs: {
        Customs: ProjectRequestTask.Customs,
      },
      Labeling: {
        Labeling: ProjectRequestTask.Labeling,
      },
      DangerousGoods: {
        DangerousGoods: ProjectRequestTask.DangerousGoods,
      },
      Picture: {
        Picture: ProjectRequestTask.Picture,
      },
      CAMDbRouting: {
        CAMDbRouting: ProjectRequestTask.CAMDbRouting,
      },
      EShop: {
        EShop: ProjectRequestTask.EShop,
      },
      Logistics: {
        Logistics: ProjectRequestTask.Logistics,
      },
      TemperatureSensitive: {
        TemperatureSensitive: ProjectRequestTask.TemperatureSensitive,
      },
      PM: {
        PM: ProjectRequestTask.PM,
      },
      ProductMasterDataCheck: {
        ProductMasterDataCheck: ProjectRequestTask.ProductMasterDataCheck,
      },
      RAChecklist: {
        RAChecklist: ProjectRequestTask.RAChecklist,
      },
      PurchasingClassification:{
        PurchasingClassification:ProjectRequestTask.PurchasingClassification,
      },
    }
  },
  CountryTasks: {}
};

export type RequestStepTab =
  | keyof typeof RequestStepTabs.Start
  | keyof typeof RequestStepTabs.KickOff
  | keyof typeof RequestStepTabs.ProductMasterDataText
  | keyof typeof RequestStepTabs.ItemCreation
  | keyof typeof RequestStepTabs.BOMCreation
  | keyof typeof RequestStepTabs.BOMSAPLoad
  | keyof typeof RequestStepTabs.ExternalInput
  | keyof typeof RequestStepTabs.TechnicalReadiness
  | keyof typeof RequestStepTabs.CountryTasks;

export const RequestStepReverseMapping: { [key in RequestStep]: keyof typeof RequestStep } = {
  [RequestStep.Start]: "Start",
  [RequestStep.KickOff]: "KickOff",
  [RequestStep.ProductMasterDataText]: "ProductMasterDataText",
  [RequestStep.BOMCreation]: "BOMCreation",
  [RequestStep.BOMSAPLoad]: "BOMSAPLoad",
  [RequestStep.ItemCreation]: "ItemCreation",
  [RequestStep.ExternalInput]: "ExternalInput",
  [RequestStep.TechnicalReadiness]: "TechnicalReadiness",
  [RequestStep.CountryTasks]: "CountryTasks",
  // [RequestStep.Completed]: "Completed"
};

export const getRequestStepKeyFromRequestStepName = (stepName: string) => {
  switch (stepName.toLowerCase()) {
    case RequestStep.Start.toLowerCase():
      return RequestStepReverseMapping.Start;

    case RequestStep.KickOff.toLowerCase():
      return RequestStepReverseMapping[RequestStep.KickOff];

    case RequestStep.ProductMasterDataText.toLowerCase():
      return RequestStepReverseMapping[RequestStep.ProductMasterDataText];

    case RequestStep.BOMCreation.toLowerCase():
      return RequestStepReverseMapping[RequestStep.BOMCreation];

    case RequestStep.BOMSAPLoad.toLowerCase():
      return RequestStepReverseMapping[RequestStep.BOMSAPLoad];

    case RequestStep.ExternalInput.toLowerCase():
      return RequestStepReverseMapping[RequestStep.ExternalInput];

    case RequestStep.TechnicalReadiness.toLowerCase():
      return RequestStepReverseMapping[RequestStep.TechnicalReadiness];

    default:
      return undefined;
  }
};

export const getFormattedRequestStepKey = (stepKey: string): keyof typeof RequestStep => {
  switch (stepKey?.toLowerCase()) {
    case RequestStepReverseMapping.Start.toLowerCase():
      return RequestStepReverseMapping.Start;

    case RequestStepReverseMapping[RequestStep.KickOff].toLowerCase():
      return RequestStepReverseMapping[RequestStep.KickOff];

    case RequestStepReverseMapping[RequestStep.ProductMasterDataText].toLowerCase():
      return RequestStepReverseMapping[RequestStep.ProductMasterDataText];

    case RequestStepReverseMapping[RequestStep.BOMCreation].toLowerCase():
      return RequestStepReverseMapping[RequestStep.BOMCreation];

    case RequestStepReverseMapping[RequestStep.BOMSAPLoad].toLowerCase():
      return RequestStepReverseMapping[RequestStep.BOMSAPLoad];

    case RequestStepReverseMapping[RequestStep.ItemCreation].toLowerCase():
      return RequestStepReverseMapping[RequestStep.ItemCreation];

    case RequestStepReverseMapping[RequestStep.ExternalInput].toLowerCase():
      return RequestStepReverseMapping[RequestStep.ExternalInput];

    case RequestStepReverseMapping[RequestStep.TechnicalReadiness].toLowerCase():
      return RequestStepReverseMapping[RequestStep.TechnicalReadiness];

    default:
      return stepKey as keyof typeof RequestStep;
  }
};

export const getFormattedRequestStepTabKey = (stepKey: RequestStepType, tabKey: string): RequestStepTab => {
  if (tabKey) {
    for (const key in RequestStepTabs[stepKey]) {
      const value = RequestStepTabs[stepKey][key as keyof object] as RequestStepTab;
      if (value.toLowerCase() === tabKey.toLowerCase()) {
        return value;
      }
    }
    return tabKey as RequestStepTab;
  } else return null;
};

//Value Step+Tab
export const StepTabsCanExportData: { [step in RequestStepType]?: { [tab in RequestStepTab]?: boolean } } = {
  Start: {
    ProjectInfo: false,
    ProductInfo: true,
    Translations: true,
  },
  KickOff: {
    PLMData: true,
    LogisticsData: true,
    CheckList: false,
  },
  ProductMasterDataText: {
    Translations: true,
  },
  BOMCreation: {
    BOMDataCollection: false,
  },
  BOMSAPLoad: {
    BOMSAPLoad: false,
  },
  ItemCreation: {
    ItemCreation: false,
  },
  ExternalInput: {
    PackagingAndHandling: true,
    DangerousGoods: true,
    RA: true,
    Customs: true,
  },
  TechnicalReadiness: {
    Overview: false,
    Controlling: true,
    Customs: true,
    RADB: true,
    EShop:true,
    Labeling: true,
    DangerousGoods:true,
    Picture: true,
    CAMDbRouting: true,
    Logistics: true,
    TemperatureSensitive:true,
    PM: true,
    ProductMasterDataCheck:true,
    RAChecklist: true,
    PurchasingClassification:true
  },
  CountryTasks: {
    Overview: false,
  }
}

export const StepTabsCanShowSaveButton: { [step in RequestStepType]?: { [tab in RequestStepTab]?: boolean } } = {
  Start: {
    ProjectInfo: true,
    ProductInfo: true,
    Translations: true,
  },
  KickOff: {
    PLMData: true,
    LogisticsData: true,
    CheckList: true,
  },
  ProductMasterDataText: {
    Translations: true,
  },
  BOMCreation: {
    BOMDataCollection: false,
  },
  BOMSAPLoad: {
    BOMSAPLoad: false,
  },
  ItemCreation: {
    ItemCreation: false,
  },
  ExternalInput: {
    PackagingAndHandling: true,
    DangerousGoods: true,
    RA: true,
    Customs: true,
  },
  TechnicalReadiness: {
    Overview: false,
    Controlling: true,
    Customs: true,
    RADB: true,
  },
  CountryTasks: {
    Overview: false,
  }
}

export const canExportOnCurrentPage = (step: RequestStepType, tab: RequestStepTab, status: RequestStatus) => {
  return [RequestStatus.Completed, RequestStatus.InProgress, RequestStatus.Rejected]
    .includes(status) && (StepTabsCanExportData?.[step]?.[tab] ?? false);
};

export const showSaveButton = (step: RequestStepType, tab: RequestStepTab, status: RequestStatus) => {
  return [RequestStatus.Draft, RequestStatus.InProgress, RequestStatus.Rejected].includes(status) && (StepTabsCanShowSaveButton?.[step]?.[tab] ?? false);
};

