export function random(number: number) {
  return Math.floor(Math.random() * number);
}

export function getRandomColor() {
  return "rgb(" + random(255) + "," + random(255) + "," + random(255) + ")";
}

export function getRandomColorArray(count: number) {
  let out = [];
  for (let i = 0; i < count; i++) {
    out.push("rgb(" + random(255) + "," + random(255) + "," + random(255) + ")");
  }
  return out;
}

export function getWhiteColorArray(count: number) {
  return Array(count).fill("rgb(255,255,255)");
}

export function rgb2hex(rgb: any) {
  return `#${rgb
    .match(/^rgb\((\d+),\s*(\d+),\s*(\d+)\)$/)
    .slice(1)
    .map((n: any) => parseInt(n, 10).toString(16).padStart(2, "0"))
    .join("")}`;
}
