import { createSlice } from "@reduxjs/toolkit";
import { ISuccessMessageComponentProps } from "./SuccessMessage";

const initialState: ISuccessMessageComponentProps = {
  showSuccessMessage: false,
  successMessage: undefined,
};

export const SuccessMessageSlice = createSlice({
  name: "successMessage",
  initialState: {
    value: { ...initialState },
  },
  reducers: {
    showSuccessMessage: (state, action) => {
      state.value = {
        showSuccessMessage: true,
        successMessage: action.payload.successMessage,
      };
    },
    hideSuccessMessage: (state) => {
      state.value = { ...initialState };
    },
  },
});

export const { showSuccessMessage, hideSuccessMessage } = SuccessMessageSlice.actions;

export const successMessageReducer = SuccessMessageSlice.reducer;
