import { RequestStep, RequestStepReverseMapping, RequestStepTabs } from "interfaces";

export const ApplicationRoutes = {
  Home: "/",
  MetadataConfiguration: "/admin/metadataconfig",
  ResponsibilityMatrix: "/admin/responsibilitymatrix",
  ManageFAQ: "/admin/manage-faq",
  ManageHelpPages: "/admin/manage-helper",
  FilterExport: "/admin/filterexport",
  ColorSchemeMatrix: "/admin/colormatrix",
  RequestDashboard: "dashboard/requests",
  TaskDashboard: "dashboard/tasks",
  ManageGroups: "/admin/groups",
  Invitation: "/admin/invitation",
  Faq: "/FAQ",
  ManageHelpDocuments: "/admin/manageHelpDocument",
  HelpPageEditor: "/admin/helper-edit",
  HelpPagePreview: "/admin/helper-preview",
  Request: "/requests",
  NewRequest: "new",
  RequestWithId: ":id",
  RequestWithIdStep: ":id/:step",
  RequestWithIdStepTab: ":id/:step/:tab",
  RequestSteps: {
    [RequestStepReverseMapping.Start]: {
      Root: `/${RequestStepReverseMapping.Start}`,
      ChildRoutes: [
        {
          Name: RequestStepTabs.Start.ProjectInfo,
          Path: `/${RequestStepTabs.Start.ProjectInfo}`,
        },
        {
          Name: RequestStepTabs.Start.ProductInfo,
          Path: `/${RequestStepTabs.Start.ProductInfo}`,
        },
        {
          Name: RequestStepTabs.Start.Translations,
          Path: `/${RequestStepTabs.Start.Translations}`,
        },
      ],
    },
    [RequestStepReverseMapping[RequestStep.KickOff]]: {
      Root: `/${RequestStepReverseMapping[RequestStep.KickOff]}`,
      ChildRoutes: [
        {
          Name: RequestStepTabs.KickOff.PLMData,
          Path: `/${RequestStepTabs.KickOff.PLMData}`,
        },
        {
          Name: RequestStepTabs.KickOff.LogisticsData,
          Path: `/${RequestStepTabs.KickOff.LogisticsData}`,
        },
        {
          Name: RequestStepTabs.KickOff.CheckList,
          Path: `/${RequestStepTabs.KickOff.CheckList}`,
        },
      ],
    },
    [RequestStepReverseMapping[RequestStep.ProductMasterDataText]]: {
      Root: `/${RequestStepReverseMapping[RequestStep.ProductMasterDataText]}`,
      ChildRoutes: [
        {
          Name: RequestStepTabs.ProductMasterDataText.Translations,
          Path: `/${RequestStepTabs.ProductMasterDataText.Translations}`,
        },
      ],
    },
    [RequestStepReverseMapping[RequestStep.ItemCreation]]: {
      Root: `/${RequestStepReverseMapping[RequestStep.ItemCreation]}`,
      ChildRoutes: [
        {
          Name: RequestStepTabs.ItemCreation.ItemCreation,
          Path: `/${RequestStepTabs.ItemCreation.ItemCreation}`,
        },
      ],
    },    
    [RequestStepReverseMapping[RequestStep.BOMCreation]]: {
      Root: `/${RequestStepReverseMapping[RequestStep.BOMCreation]}`,
      ChildRoutes: [
        {
          Name: RequestStepTabs.BOMCreation.BOMDataCollection,
          Path: `/${RequestStepTabs.BOMCreation.BOMDataCollection}`,
        }
      ],
    },
    [RequestStepReverseMapping[RequestStep.BOMSAPLoad]]: {
      Root: `/${RequestStepReverseMapping[RequestStep.BOMSAPLoad]}`,
      ChildRoutes: [
        {
          Name: RequestStepTabs.BOMSAPLoad.BOMSAPLoad,
          Path: `/${RequestStepTabs.BOMSAPLoad.BOMSAPLoad}`,
        },
      ],
    },    
    [RequestStepReverseMapping[RequestStep.ExternalInput]]: {
      Root: `/${RequestStepReverseMapping[RequestStep.ExternalInput]}`,
      ChildRoutes: [
        {
          Name: RequestStepTabs.ExternalInput.Customs,
          Path: `/${RequestStepTabs.ExternalInput.Customs}`,
        },
        {
          Name: RequestStepTabs.ExternalInput.DangerousGoods,
          Path: `/${RequestStepTabs.ExternalInput.DangerousGoods}`,
        },
        {
          Name: RequestStepTabs.ExternalInput.PackagingAndHandling,
          Path: `/${RequestStepTabs.ExternalInput.PackagingAndHandling}`,
        },
        {
          Name: RequestStepTabs.ExternalInput.RA,
          Path: `/${RequestStepTabs.ExternalInput.RA}`,
        },
      ],
    },
    [RequestStepReverseMapping[RequestStep.TechnicalReadiness]]: {
      Root: `/${RequestStepReverseMapping[RequestStep.TechnicalReadiness]}`,
      ChildRoutes: [
        // {
        //   Name: RequestStepTabs.TechnicalReadiness.Overview,
        //   Path: `/${RequestStepTabs.TechnicalReadiness.Overview}`,
        // },
        {
          Name: RequestStepTabs.TechnicalReadiness.Controlling,
          Path: `/${RequestStepTabs.TechnicalReadiness.Controlling}`,
        },
        {
          Name: RequestStepTabs.TechnicalReadiness.Customs,
          Path: `/${RequestStepTabs.TechnicalReadiness.Customs}`,
        },
        {
          Name: RequestStepTabs.TechnicalReadiness.RADB,
          Path: `/${RequestStepTabs.TechnicalReadiness.RADB}`,
        },
        {
          Name: RequestStepTabs.TechnicalReadiness.CAMDbRouting,
          Path: `/${RequestStepTabs.TechnicalReadiness.CAMDbRouting}`,
        },
        {
          Name: RequestStepTabs.TechnicalReadiness.DangerousGoods,
          Path: `/${RequestStepTabs.TechnicalReadiness.DangerousGoods}`,
        },
        {
          Name: RequestStepTabs.TechnicalReadiness.EShop,
          Path: `/${RequestStepTabs.TechnicalReadiness.EShop}`,
        },
        {
          Name: RequestStepTabs.TechnicalReadiness.Labeling,
          Path: `/${RequestStepTabs.TechnicalReadiness.Labeling}`,
        },
        {
          Name: RequestStepTabs.TechnicalReadiness.Logistics,
          Path: `/${RequestStepTabs.TechnicalReadiness.Logistics}`,
        },
        {
          Name: RequestStepTabs.TechnicalReadiness.Picture,
          Path: `/${RequestStepTabs.TechnicalReadiness.Picture}`,
        },
        {
          Name: RequestStepTabs.TechnicalReadiness.PM,
          Path: `/${RequestStepTabs.TechnicalReadiness.PM}`,
        },
        {
          Name: RequestStepTabs.TechnicalReadiness.ProductMasterDataCheck,
          Path: `/${RequestStepTabs.TechnicalReadiness.ProductMasterDataCheck}`,
        },
        {
          Name: RequestStepTabs.TechnicalReadiness.RAChecklist,
          Path: `/${RequestStepTabs.TechnicalReadiness.RAChecklist}`,
        },
        {
          Name: RequestStepTabs.TechnicalReadiness.TemperatureSensitive,
          Path: `/${RequestStepTabs.TechnicalReadiness.TemperatureSensitive}`,
        }
      ],
    },
    CountryTasks: {
      Root: `/${RequestStepReverseMapping[RequestStep.CountryTasks]}`,
      ChildRoutes: [
        {
          Name: "Overview",
          Path: `/Overview`,
        }
      ]
    }
  },
};

export const getRootStepURL = (id: string, step: string) => {
  let prefixAbsoluteUrl = ApplicationRoutes.Request;

  if (id) {
    prefixAbsoluteUrl = `${prefixAbsoluteUrl}/${id}`;
  }

  return `${prefixAbsoluteUrl}${ApplicationRoutes.RequestSteps[step as keyof typeof ApplicationRoutes.RequestSteps].Root}`;
};
