import { ICCSPersonaProps } from "ccs-azure";
import { HttpService } from "services";
import { IPeopleSearchResult } from "../interfaces/IPeopleSearchResult";
import { IUser } from "../interfaces/IUser";
import { IUserGroup } from "../interfaces/IUserGroup";

export enum PrincipalTypes {
  InternalPeople = 1,
  ExternalPeople = 2,
  SpeedUpGroups = 4,
}

export abstract class UserService {
  private static BASE_ENDPOINT = "/api/User";
  private static SEARCH_INTERNAL_ENDPOINT = `${UserService.BASE_ENDPOINT}/searchInternal`;
  private static SEARCH_EXTERNAL_ENDPOINT = `${UserService.BASE_ENDPOINT}/searchExternal`;
  private static SEARCH_GROUPS_ENDPOINT = `${UserService.BASE_ENDPOINT}/searchGroups`;
  private static ENSURE_USER_ENDPOINT = `${UserService.BASE_ENDPOINT}/ensureUser`;

  public static ensureUsers(peoplePickerData: IUser[]): Promise<IUser[]> {
    let arrPromises: Promise<IUser>[] = [];
    peoplePickerData.forEach((entry) => {
      arrPromises.push(UserService.ensureUser(entry, true));
    });
    return Promise.allSettled(arrPromises).then((results) => {
      return results.filter(x => x.status === "fulfilled").map((u: any) => {
        return u.value as IUser;
      });
    });
  }

  public static searchUsers = (searchTerm: string, principals: PrincipalTypes[]): Promise<ICCSPersonaProps[]> => {
    if (searchTerm && searchTerm.trim().length !== 0) {
      let resultInternalPromise: Promise<any>;
      let resultExternalPromise: Promise<any>;
      let resultGroupsPromise: Promise<any>;

      const arrProm = [];
      if (principals.includes(PrincipalTypes.InternalPeople)) {
        resultInternalPromise = HttpService.get(`${UserService.SEARCH_INTERNAL_ENDPOINT}?searchTerm=${searchTerm}`);
        arrProm.push(resultInternalPromise);
      }
      if (principals.includes(PrincipalTypes.ExternalPeople)) {
        resultExternalPromise = HttpService.get(`${UserService.SEARCH_EXTERNAL_ENDPOINT}?searchTerm=${searchTerm}`);
        arrProm.push(resultExternalPromise);
      }
      if (principals.includes(PrincipalTypes.SpeedUpGroups)) {
        resultGroupsPromise = HttpService.get(`${UserService.SEARCH_GROUPS_ENDPOINT}?searchTerm=${searchTerm}`);
        arrProm.push(resultGroupsPromise);
      }

      return Promise.allSettled(arrProm).then((results) => {
        //console.log("Result", [resultInternal, resultExternal]);
        let resInternal: ICCSPersonaProps[] = [];
        let resExternal: ICCSPersonaProps[] = [];
        let resGroups: ICCSPersonaProps[] = [];

        results.forEach((result, index) => {
          if (result.status === "fulfilled") {
            if (index === 0) {
              if (resultInternalPromise) {
                resInternal = this.mapPeopleSearchResultsToCCSPersonaProp(result.value);
              }
              else if (resultExternalPromise) {
                resExternal = this.mapExternalUserResultsToCCSPersonaProp(result.value);
              }
              else {
                resGroups = this.mapGroupResultsToCCSPersonaProp(result.value);
              }
            }
            else if (index === 1) {
              if (resultExternalPromise) {
                resExternal = this.mapExternalUserResultsToCCSPersonaProp(result.value);
              }
              else {
                resGroups = this.mapGroupResultsToCCSPersonaProp(result.value);
              }
            }
            else {
              resGroups = this.mapGroupResultsToCCSPersonaProp(result.value);
            }

          }
        });

        return [...resInternal, ...resExternal, ...resGroups];
      });
    }
    return Promise.resolve([]);
  };

  private static ensureUser = (user: IUser, addToAD: boolean): Promise<IUser> => {
    return HttpService.post(
      UserService.ENSURE_USER_ENDPOINT,
      {
        user: {
          ...user,
        },
        addToAD: addToAD,
      }
    );
  };

  private static mapPeopleSearchResultsToCCSPersonaProp(searchResults: IPeopleSearchResult[]): ICCSPersonaProps[] {
    return searchResults.map(x => this.mapPeopleSearchResultToCCSPersonaProp(x))
  }

  private static mapPeopleSearchResultToCCSPersonaProp(searchResult: IPeopleSearchResult): ICCSPersonaProps {
    return {
      id: searchResult.id,
      text: searchResult.displayName,
      secondaryText: "Internal User",
      tertiaryText: searchResult.email,
      itemID: searchResult.id,
      itemType: "ADUser",
      payload: searchResult,
    };
  }

  private static mapExternalUserResultsToCCSPersonaProp(searchResults: IUser[]): ICCSPersonaProps[] {
    return searchResults.map(x => this.mapExternalUserResultToCCSPersonaProp(x))
  }

  private static mapExternalUserResultToCCSPersonaProp(searchResult: IUser): ICCSPersonaProps {
    return {
      id: `${searchResult.id}`,
      text: searchResult.displayName,
      secondaryText: "External User",
      tertiaryText: searchResult.email,
      itemID: searchResult.objectId,
      itemType: "DBUser",
      payload: searchResult,
    };
  }

  private static mapGroupResultsToCCSPersonaProp(searchResults: IUserGroup[]): ICCSPersonaProps[] {
    return searchResults.map(x => this.mapGroupResultToCCSPersonaProp(x))
  }

  private static mapGroupResultToCCSPersonaProp(searchResult: IUserGroup): ICCSPersonaProps {
    return {
      id: `${searchResult.id}`,
      text: searchResult.name,
      secondaryText: "Group",
      tertiaryText: undefined,
      itemID: `${searchResult.id}`,
      itemType: "DBGroup",
      payload: searchResult,
    };
  }
}
