import { DefaultButton, PrimaryButton, Stack, TextField, css, IDialogProps, DialogType, Checkbox, } from "@fluentui/react";
import { hideLoadingSpinner, showLoadingSpinnerWithMessage, showErrorMessage } from "components/common";
import PeoplePicker from "components/common/PeoplePicker";
import { TextConstants } from "constant";
import { IAddUsersToGroupResult, IUser, IUserGroup } from "interfaces";
import { useCallback, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { CommonService, PrincipalTypes } from "services";
import styles from "./GroupManagement.module.scss";
import { GroupMemberList } from "./GroupMemberList";
import { GroupService } from "./GroupService";

export interface IManageGroupForm {
  existingGroup?: IUserGroup;
  dismissPanel?: () => void;
  dismissPanelWithUpdate?: () => void;
  refreshGroupList?: () => void;
}

export const ManageGroupForm: React.FC<IManageGroupForm> = ({ existingGroup, dismissPanel, dismissPanelWithUpdate, refreshGroupList }: IManageGroupForm) => {
  const dispatch = useDispatch();

  const [usersToAddToGroup, setUsersToAddToGroup] = useState<IUser[]>(null);

  const [group, setGroup] = useState(existingGroup);
  const [groupName, setGroupName] = useState(existingGroup?.name);
  const [isProcessAdminGroup, setIsProcessAdminGroup] = useState(existingGroup?.isProcessAdminGroup ?? false);

  useEffect(() => {
    setGroup(existingGroup);
    setGroupName(existingGroup?.name);
  }, [existingGroup]);

  const onChangeGroupName = useCallback((event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>, newValue?: string) => {
    setGroupName(newValue);
  }, []);

  const onChangeCheckBox = useCallback((ev?: React.FormEvent<HTMLInputElement | HTMLElement>, checked?: boolean) => {
    setIsProcessAdminGroup(checked ?? false);
  }, []);

  const onClickSaveGroup = useCallback(async () => {
    if (group?.id) {

      try {
        dispatch(showLoadingSpinnerWithMessage(TextConstants.ManageGroupForm.Message_UpdatingGroup));
        let updatedGroup = { ...group };
        updatedGroup.name = groupName;
        updatedGroup.isProcessAdminGroup = isProcessAdminGroup;
        const result = await GroupService.updateGroup(updatedGroup);
        if (result) {
          setGroup(updatedGroup);
        }
        refreshGroupList();
      } catch (error) {
        dispatch(
          showErrorMessage({
            error: error,
          })
        );
      }
      dispatch(hideLoadingSpinner());
    }
    else {

      try {
        dispatch(showLoadingSpinnerWithMessage(TextConstants.ManageGroupForm.Message_CreatingGroup));
        const newGroup: IUserGroup = {
          name: groupName,
          isProcessAdminGroup: isProcessAdminGroup
        };

        const updatedGroup = await GroupService.createGroup(newGroup);
        setGroup(updatedGroup);
        refreshGroupList();
      } catch (error) {
        dispatch(
          showErrorMessage({
            error: error,
          })
        );
      }
      dispatch(hideLoadingSpinner());
    }
  }, [group, isProcessAdminGroup, groupName, refreshGroupList]);

  const addUsersToGroup = useCallback(async () => {
    const currentGroup = { ...group };

    if (usersToAddToGroup.length) {
      let newGroupMemberships = usersToAddToGroup.filter(x => currentGroup.groupMemberships.findIndex(y => x.email.toLowerCase() === y.user.email.toLowerCase()) === -1)

      if (newGroupMemberships.length) {
        try {
          dispatch(showLoadingSpinnerWithMessage(TextConstants.ManageGroupForm.Message_AddingUsersToGroup));

          const addUsersToGroupResult: IAddUsersToGroupResult = await GroupService.addUsersToAGroup(currentGroup.id, newGroupMemberships);
          currentGroup.groupMemberships = addUsersToGroupResult.userGroupMemberships;
          setGroup(currentGroup);
          setUsersToAddToGroup(null);
        } catch (error) {
          dispatch(
            showErrorMessage({
              error: error,
            })
          );
        }
        dispatch(hideLoadingSpinner());
      }
      else {
        setUsersToAddToGroup(null);
      }
    }
  }, [group, usersToAddToGroup]);

  const deleteGroup = useCallback(async () => {
    try {
      dispatch(showLoadingSpinnerWithMessage(TextConstants.ManageGroupForm.Message_RemovingUsersFromGroup));

      const result = await GroupService.deleteGroup(group.id);
    } catch (error) {
      dispatch(
        showErrorMessage({
          error: error,
        })
      );
    }

    if (dismissPanelWithUpdate) {
      dismissPanelWithUpdate();
    }
    else {
      dismissPanel?.();
    }
    dispatch(hideLoadingSpinner());
  }, [group, dismissPanel, dismissPanelWithUpdate]);

  const onClickDeleteGroup = useCallback(() => {
    const dialogProps: IDialogProps = {
      dialogContentProps: {
        type: DialogType.normal,
        title: TextConstants.ManageGroupForm.Confirmation_Message_DeleteGroup,
      },
      modalProps: {
        isBlocking: false,
      },
    };

    const onConfirmCallback = () => {
      deleteGroup();
    };

    CommonService.showConfirmationDialog(
      { ...dialogProps },
      TextConstants.Common.Label_Yes,
      TextConstants.Common.Label_No,
      onConfirmCallback
    );
  }, []);

  return (
    <div className={styles.manageGroupForm}>
      <TextField
        disabled={group?.isAdminGroup}
        className={styles.groupNameField}
        label={TextConstants.ManageGroupForm.Label_GroupName}
        value={groupName}
        autoFocus
        onChange={onChangeGroupName}
      />
      {
        !group?.isAdminGroup &&
        <Checkbox
          className={styles.isProcessAdminGroupField}
          label={TextConstants.ManageGroupForm.Label_IsProcessAdminGroup}
          checked={isProcessAdminGroup}
          onChange={onChangeCheckBox}
        />
      }

      {
        !group?.isAdminGroup &&
        <Stack className={styles.saveActionBar} horizontal horizontalAlign="end" tokens={{ childrenGap: 10 }}>
          <PrimaryButton
            iconProps={{ iconName: "Save" }}
            text={group?.id ? TextConstants.Common.Label_Button_Update : TextConstants.Common.Label_Button_Create}
            onClick={onClickSaveGroup}
          />
          {!group?.id &&
            <DefaultButton
              iconProps={{ iconName: "Cancel" }}
              text={TextConstants.Common.Label_Button_Cancel}
              onClick={() => {
                if (!existingGroup?.id && group?.id) {
                  if (dismissPanelWithUpdate) {
                    dismissPanelWithUpdate();
                  }
                  else {
                    dismissPanel?.();
                  }
                }
                else {
                  dismissPanel?.();
                }

              }}
            />
          }
        </Stack>
      }


      {!!group?.id && (
        <>
          <Stack className={styles.manageGroupMembershipSection}>
            <h2 className={css("heading", styles.manageGroupMembershipHeading)}>
              {TextConstants.ManageGroupForm.Heading_Section_ManageGroupMembership}
            </h2>

            <Stack horizontalAlign="start" className={styles.addGroupMembersContainer}>
              <Stack className={styles.addMemberContainer} horizontal horizontalAlign="space-between" tokens={{ childrenGap: 20 }}>
                <PeoplePicker
                  principalTypes={[PrincipalTypes.ExternalPeople, PrincipalTypes.InternalPeople]}
                  selectedUsers={usersToAddToGroup ?? []}
                  itemLimit={10}
                  width={"100%"}
                  placeholder={TextConstants.ManageGroupForm.Placeholder_AddUsersToGroup}
                  onChange={(items?: IUser[]) => {
                    if (items.length) {
                      setUsersToAddToGroup(items.map(x => ({
                        ...x,
                        givenName: (x as any).firstName
                      })));
                    }
                  }}
                />
                <PrimaryButton
                  disabled={!usersToAddToGroup?.length}
                  text={TextConstants.Common.Label_Button_Add}
                  iconProps={{ iconName: "CirclePlus" }}
                  onClick={addUsersToGroup}
                />
              </Stack>
            </Stack>
            <GroupMemberList
              userGroupMemberships={group?.groupMemberships ?? []}
              onUpdateMembership={(updatedUserMemberships) => {
                const currentGroup = { ...group };
                currentGroup.groupMemberships = [...updatedUserMemberships];
                setGroup(currentGroup);
              }}
            />
          </Stack>

          <Stack className={styles.footerActionBar} horizontal horizontalAlign="space-between" tokens={{ childrenGap: 10 }}>
            <div>
              {!group?.isAdminGroup && (
                <DefaultButton
                  className={styles.deleteButton}
                  iconProps={{ iconName: "Delete", className: styles.deleteButton }}
                  text={TextConstants.ManageGroupForm.Label_Button_DeleteGroup}
                  onClick={onClickDeleteGroup}
                />
              )}
            </div>
            <Stack horizontal horizontalAlign="end" tokens={{ childrenGap: 10 }}>
              <DefaultButton
                iconProps={{ iconName: "Cancel" }}
                text={TextConstants.Common.Label_Button_Cancel}
                onClick={() => {
                  if (!existingGroup?.id && group.id) {
                    if (dismissPanelWithUpdate) {
                      dismissPanelWithUpdate();
                    }
                    else {
                      dismissPanel?.();
                    }
                  }
                  else {
                    dismissPanel?.();
                  }

                }}
              />
            </Stack>
          </Stack>
        </>
      )}


    </div>
  );
};
