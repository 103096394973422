import { mergeStyleSets, MessageBar, MessageBarType } from "@fluentui/react";
import { ErrorRegions } from "constant";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { CommonService } from "services";
import { RootState } from "store";
import { hideErrorMessage } from "./errorMessageSlice";

const messagePanelClassNames = mergeStyleSets({
  root: {
    display: 'block',
    marginTop: '10px'
  },
})

export interface IErrorMessageComponentProps {
  useReduxState?: boolean;
  showError?: boolean;
  errorMessage?: JSX.Element | string | undefined;
  errorOriginationKey?: ErrorRegions | undefined;
}

export const ErrorMessage: React.FC<IErrorMessageComponentProps> =
  ({ useReduxState = false, errorMessage = undefined, showError = false }: IErrorMessageComponentProps) => {
    const dispatch = useDispatch();
    let componentState: IErrorMessageComponentProps = {
      useReduxState,
      errorMessage,
      showError,
    };

    componentState = useSelector(
      (state: RootState) =>
        useReduxState
          ? state.errorMessage.value
          : { useReduxState, errorMessage, showError },
      (prevState, currentState) => {
        if (useReduxState) {
          return prevState === currentState;
        } else {
          return true;
        }
      }
    );

    useEffect(() => {
      if(componentState.showError){
        CommonService.showErrorToast(componentState.errorMessage as string, false, {
          onClose: ()=>{
            dispatch(hideErrorMessage(null));
          }
        });
      }
    }, [componentState.errorMessage, componentState.showError])

    // useEffect(() => {
    //   if (componentState.errorMessage) {
    //     const element = document.querySelector(`.${messagePanelClassNames.root}`);
    //     if (element) {
    //       setTimeout(() => element.scrollIntoView(true), 100);
    //     }
    //   }
    // }, [componentState.errorMessage])

    return null;
    // return componentState.showError ? (
    //   <div className={messagePanelClassNames.root}>
    //     <MessageBar
    //       messageBarType={MessageBarType.error}
    //       isMultiline={false}
    //       truncated={true}
    //       overflowButtonAriaLabel="See more"
    //     >
    //       {componentState.errorMessage}
    //     </MessageBar>
    //   </div>
    // ) : (
    //   <></>
    // );
  };
