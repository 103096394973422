import React from "react";
import { Checkbox, DefaultButton, IColumn, PrimaryButton, Stack } from "@fluentui/react";

import styles from "./Dashboard.module.scss";
import { TextConstants } from "constant";
import { getValuesForFilterByOption } from "services";
import Select from 'react-select';
import { placeholderCSS } from "react-select/dist/declarations/src/components/Placeholder";
import { ValueContainer } from "react-select/dist/declarations/src/components/containers";
interface IFilterPanelProps {
  data: any[];
  filterBy: IColumn;
  selectedFilterOptions: any[];
  onCancel: () => void;
  onApply: (filterValues: string[]) => void;
}

const filterStackTokens = { childrenGap: 10 };

export const FilterPanel = (props: IFilterPanelProps) => {
  const [filterByValues, setFilterByValues] = React.useState([]);
  const [selectedFilterValues, setSelectedFilterValues] = React.useState<string[]>([]);
  const [isApplyButtonEnabled, setIsApplyButtonEnabled] = React.useState(false);
  const [filterByValuesToSearch, setFilterByValuesToSearch] = React.useState([]);

  const isApplyButtonEnabledonLoad = React.useRef<boolean>(false);
  React.useEffect(() => {
    (async () => {
      let enableApplyButton = false;
      const response: any = getValuesForFilterByOption(props.data, props.filterBy);
      let prevFilterValues: string[] = [];
      if (props.selectedFilterOptions) {
        if (props.selectedFilterOptions.length > 0) {
          props.selectedFilterOptions.forEach((option) => {
            if (option.column === props.filterBy.fieldName) {
              option.values.forEach((value: any) => {
                prevFilterValues.push(value);
              });
            }
          });
        }
      }
      if (prevFilterValues.length > 0) {
        enableApplyButton = true;
      }
      setFilterByValues(response);
      setSelectedFilterValues(prevFilterValues);

      //Set search filter values
      let searchFilterValues:any=[];
      response.forEach((option:any,idx:any) => {
        searchFilterValues.push({label:option,value:option,controlid:`checkbox${idx}`});
      });
      setFilterByValuesToSearch(searchFilterValues);
      //End of search filter values

      isApplyButtonEnabledonLoad.current = enableApplyButton;
      setIsApplyButtonEnabled(enableApplyButton);
    })();
  }, []);

  const handleCheckBoxChange = (ev?: React.FormEvent<HTMLElement | HTMLInputElement>, checked?: boolean, f?: string) => {
    const selectedFilterValuesClone = [...selectedFilterValues];
    let enableApplyButton = false;
    if (checked) {
      !!f && selectedFilterValuesClone.push(f);
      enableApplyButton = true;
    } else {
      const index = !!f ? selectedFilterValuesClone.indexOf(f, 0) : -1;
      if (index > -1) {
        selectedFilterValuesClone.splice(index, 1);
      }
      if (isApplyButtonEnabledonLoad.current) {
        enableApplyButton = true;
      } else {
        if (selectedFilterValuesClone.length === 0) {
          enableApplyButton = false;
        } else {
          enableApplyButton = true;
        }
      }
    }

    setSelectedFilterValues(selectedFilterValuesClone);
    setIsApplyButtonEnabled(enableApplyButton);
  };

  const handleApplyAction = () => {
    props.onApply(selectedFilterValues);
  };

  const handleClearAll = () => {
    let enableApplyButton = false;

    if (isApplyButtonEnabledonLoad.current) {
      enableApplyButton = true;
    }

    setSelectedFilterValues([]);
    setIsApplyButtonEnabled(enableApplyButton);
  };

  return (
    <>
      {filterByValues !== undefined ? (
        <div className={styles.editListFilter}>
          <div className={styles.editListFilterControls}>
            <Select options={filterByValuesToSearch}
            isMulti
            value={[]}
            onChange={(options:any) =>{
                  if(options.length > 0){
                    options.forEach((option:any) => {
                      let chkBoxInpt:any=document.getElementById(`${option.controlid}`);
                      if(!chkBoxInpt.checked)  {  
                        chkBoxInpt.click();  
                      }                 
                    });                    
                  }                                 
            }}
            
            styles={{
              indicatorsContainer:(styles:any) => ({ ...styles, display: "none" }),
              placeholder:(styles:any) => ({ ...styles, display: "none" }),
              multiValue:(styles:any) => ({ ...styles, display: "none" }),
              container:(styles:any) => ({ ...styles, marginBottom: "10px", marginRight:"10px" }),
              menu:(styles:any) => ({ ...styles, marginTop: "0px" }),
            }}
            ></Select>
            <Stack tokens={filterStackTokens}>
              {filterByValues.map((f,idx) => {
                return (
                  <Checkbox
                    label={f !== "" && f !== undefined && f != null ? f : "Empty"}
                    id={`checkbox${idx}`}
                    checked={selectedFilterValues.length > 0 && selectedFilterValues.indexOf(f) > -1 ? true : false}
                    onChange={(ev, checked) => handleCheckBoxChange(ev, checked, f)}
                    key={f}
                  />
                );
              })}
            </Stack>
          </div>
          <div className={styles.actionsListFilter}>
            <PrimaryButton
              text={TextConstants.Label_Apply}
              disabled={isApplyButtonEnabled ? false : true}
              onClick={handleApplyAction}
            ></PrimaryButton>
            <DefaultButton text={TextConstants.Label_Clear_All} onClick={handleClearAll}></DefaultButton>
          </div>
        </div>
      ) : null}
    </>
  );
};
