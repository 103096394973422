import { css } from "@fluentui/react";
import { LinkToHome } from "components";
import styles from "./Admin.module.scss";

export interface IAdminPageContainerProps {
    pageTitle: string;
    contentClassName?: string;
    headerClassName?: string;
}

export const AdminPageContainer: React.FC<IAdminPageContainerProps> = ({ pageTitle, children, headerClassName, contentClassName }) => {

    return (
        <div className={styles.adminPageContainer}>
            <div className={css(styles.header, headerClassName)}>
                <LinkToHome forceShowButton={true} />
                <div><h2 className={styles.heading}>{pageTitle}</h2></div>
            </div>
            <div className={css(styles.content, contentClassName)}>
                {children}
            </div>

        </div>
    )
}