import React, { useMemo } from "react";
import { Pie } from "react-chartjs-2";
import { Chart as ChartJS, ArcElement, Tooltip, Legend, ChartData } from "chart.js";
import styles from "./Chart.module.scss";
import { getRandomColorArray, getWhiteColorArray } from "functions/rgbColorsGenerator";
import { Label, ProgressIndicator, Spinner, SpinnerSize, Text } from "@fluentui/react";
import { TextConstants } from "constant";

ChartJS.register(ArcElement, Tooltip, Legend);

interface IPieChartProps {
  piData: {
    header: string;
    labels: string[];
    data: number[];
    colors: string[];
    showLoading: boolean;
  };
}

export const PieChart = (props: IPieChartProps) => {

  const data: ChartData<"pie", number[], string> = useMemo(() => ({
    labels: props.piData.labels,
    datasets: [
      {
        label: props.piData.header,
        data: props.piData.data,
        backgroundColor:
          props.piData.colors.length === props.piData.data.length
            ? props.piData.colors
            : getRandomColorArray(props.piData.data.length),
        borderColor: getWhiteColorArray(props.piData.data.length),
        borderWidth: 1,
      },
    ],

  }), [props.piData]);

  return (
    <div className={styles.pieChartBlockContainer}>
      <div className={styles.pieChartWrapper}>
        <Label className={styles.pieChartLabel}>{props.piData.header}</Label>
        <div className={styles.pieChart}>
          {
            props.piData.showLoading ? (
              <>
                <Spinner
                  className={styles.spinnerMargin30}
                  label={TextConstants.HomePage.Heading_Chart_LoadingData}
                  ariaLive="assertive"
                  labelPosition="right"
                />
              </>
            ) : props.piData.data.length > 0 ? (
              <Pie data={data} options={{ plugins: { legend: { position: "bottom" } } }} />
            ) : (
              <Text style={{ display: "block", textAlign: "center" }} className={styles.spinnerMargin30}>{TextConstants.HomePage.Heading_Chart_NoDataToDisplay}</Text>
            )
          }
        </div>
      </div>
    </div>
  );
};
