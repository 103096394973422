import React from "react";
import ReactDOM from "react-dom";
import "./main.css";
import { AppProvider } from "./App";
import reportWebVitals from "./reportWebVitals";
import { BrowserRouter } from "react-router-dom";
import { Provider } from "react-redux";
import store from "./store";
import { msalConfig } from "./authConfig/AuthConfig";
import { ThemeProvider, } from "@fluentui/react";
import { AuthenticationResult, EventMessage, EventType, PublicClientApplication } from "@azure/msal-browser";
import { HubConnection, HubConnectionBuilder, IHttpConnectionOptions, JsonHubProtocol, LogLevel } from "@microsoft/signalr";
import { ToastContainer } from "react-toastify";
import { GlobalNotificationDisplay } from "GlobalNotificationDisplay";
import { initializeFileTypeIcons } from "@fluentui/react-file-type-icons";
import { AppTheme } from "theme";
import { CommonService } from "services";
import { LicenseManager } from "ag-grid-enterprise";

initializeFileTypeIcons(undefined);

export const msalInstance = new PublicClientApplication(msalConfig);
// Default to using the first account if no account is active on page load
if (!msalInstance.getActiveAccount() && msalInstance.getAllAccounts().length > 0) {
  // Account selection logic is app dependent. Adjust as needed for different use cases.
  msalInstance.setActiveAccount(msalInstance.getAllAccounts()[0]);
}

msalInstance.enableAccountStorageEvents();

msalInstance.addEventCallback((event: EventMessage) => {
  if ((event.eventType === EventType.LOGIN_SUCCESS || event.eventType === EventType.SSO_SILENT_SUCCESS) && event?.payload) {
    const payload = event.payload as AuthenticationResult;
    const account = payload.account;
    msalInstance.setActiveAccount(account);
  }
});

const isDev = process.env.NODE_ENV === "development";

const options: IHttpConnectionOptions = {
  logMessageContent: isDev,
  logger: isDev ? LogLevel.Warning : LogLevel.Error,
};

const connectHubUrl = process.env.REACT_APP_SIGNALRHUB;
console.log("ENV: ", process.env);

console.log("%cCreating signalR Connection...", "color:red");
export const connection = new HubConnectionBuilder()
  .withUrl(connectHubUrl, options)
  .withAutomaticReconnect([1000, 2000, 5000, 30000])
  .withHubProtocol(new JsonHubProtocol())
  .configureLogging(LogLevel.Information)
  .build();

// export const toastError = (msg: string, autoClose: number | false = false) =>
//   toast(<div style={{ color: stylingVariable.straumannErrorColor }}>{msg}</div>, {
//     position: "top-center",
//     hideProgressBar: true,
//     type: "error",
//     autoClose: autoClose,
//   });

// export const toastSuccess = (msg: string, autoClose: number | false = false) =>
//   toast(<div style={{ color: "black" }}>{msg}</div>, {
//     position: "top-center",
//     hideProgressBar: true,
//     type: "success",
//     autoClose: autoClose,
//   });

// export const toastComment = (content: React.ReactElement | string) =>
//   toast(<div>{content}</div>, {
//     position: "bottom-right",
//     hideProgressBar: true,
//     type: "info",
//     autoClose: 10000,
//   });

// export const toastInfo = (content: React.ReactElement | string, autoClose: number | false = false) =>
//   toast(<div>{content}</div>, {
//     position: "bottom-right",
//     hideProgressBar: true,
//     type: "info",
//     autoClose: autoClose,
//   });

console.log("%cStarting signalR Connection...", "color:red");

connection
  .start()
  .then(() => {
    console.log("%cHub Connection started", "color:purple;");
  })
  .catch((error) => {
    CommonService.showErrorToast(`Hub Connection: ${error}`);
  });

export const SignalRContext = React.createContext<HubConnection>(null);

LicenseManager.setLicenseKey("CompanyName=Institut Straumann AG,LicensedGroup=Global Collaboration & Custom Apps,LicenseType=MultipleApplications,LicensedConcurrentDeveloperCount=2,LicensedProductionInstancesCount=1,AssetReference=AG-032829,SupportServicesEnd=12_September_2023_[v2]_MTY5NDQ3MzIwMDAwMA==dba9e5db6b93881173711040142c79c6");

ReactDOM.render(
  <React.StrictMode>
    <Provider store={store}>
      <BrowserRouter>
        <SignalRContext.Provider value={connection}>
          <ThemeProvider className="root" applyTo="body" theme={AppTheme}>
            <GlobalNotificationDisplay />
            <ToastContainer />
            <AppProvider msalInstance={msalInstance} />
          </ThemeProvider>
        </SignalRContext.Provider>
      </BrowserRouter>
    </Provider>
  </React.StrictMode>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
