import { Icon, Label, Stack, TooltipDelay, TooltipHost } from "@fluentui/react";
import { useId } from "@fluentui/react-hooks";
import stylingVariables from "variables.module.scss";
export interface ILabelWithDescription {
  label: string;
  description?: string;
  htmlFor?: string;
  required?: boolean;
}

export const LabelWithDescription = ({ label, description, htmlFor, required }: ILabelWithDescription): JSX.Element => {
  const tooltipId = useId('tooltip');

  return (
    <Stack horizontal verticalAlign="center" tokens={{ childrenGap: required ? -4 : 8 }}>
      <Label required={required ?? false} htmlFor={htmlFor}>{label}</Label>
      {
        description ?
          <TooltipHost
            content={description}
            id={tooltipId}
            delay={TooltipDelay.zero}
            calloutProps={{
              gapSpace: 5,
              preventDismissOnLostFocus: false,
              styles: {
                root: {
                  background: stylingVariables.straumannBlackColor,
                  color: '#fff'
                },
                beak: {
                  background: stylingVariables.straumannBlackColor,
                  color: '#fff'
                },
                calloutMain: {
                  background: stylingVariables.straumannBlackColor,
                  color: '#fff'
                },
                beakCurtain: {
                  background: stylingVariables.straumannBlackColor,
                  color: '#fff'
                },
                container: {
                  background: stylingVariables.straumannBlackColor,
                  color: '#fff',
                  '& *': {
                    background: stylingVariables.straumannBlackColor,
                    color: '#fff',
                    whiteSpace: 'pre-line',
                    wordBreak: 'break-word'
                  }
                }
              },
            }}
            styles={{ root: { display: 'flex', fontSize: '14px', justifyContent: 'center' } }}
          >
            <Icon
              iconName='Info'
              ariaLabel="Info"
              styles={{ root: { cursor: 'pointer', color: stylingVariables.straumannBlueColor, fontWeight: 'bold' } }}
            />
          </TooltipHost>
          : null
      }

    </Stack >
  );
};