import { mergeStyleSets, MessageBar, MessageBarType } from "@fluentui/react";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { CommonService } from "services";
import { RootState } from "store";
import { hideSuccessMessage } from "..";

const messagePanelClassNames = mergeStyleSets({
  root: {
    display: 'block',
    marginTop: '10px'
  },
})
export interface ISuccessMessageComponentProps {
  useReduxState?: boolean;
  showSuccessMessage?: boolean;
  successMessage?: JSX.Element | string | undefined;
}

export const SuccessMessage: React.FunctionComponent<ISuccessMessageComponentProps> = ({
  useReduxState = false,
  successMessage = undefined,
  showSuccessMessage = false,
}: ISuccessMessageComponentProps) => {
  const dispatch = useDispatch();
  let componentState: ISuccessMessageComponentProps;

  componentState = useSelector(
    (state: RootState) => (useReduxState ? state.successMessage.value : { useReduxState, successMessage, showSuccessMessage }),
    (prevState, currentState) => {
      if (useReduxState) {
        return prevState === currentState;
      } else {
        return true;
      }
    }
  );

  useEffect(() => {
    if (componentState.showSuccessMessage) {
      CommonService.showSuccessToast(componentState.successMessage as string, false, {
        onClose: () => {
          dispatch(hideSuccessMessage());
        }
      });
    }
  }, [componentState.successMessage, componentState.showSuccessMessage])

  return null;

  // return componentState.showSuccessMessage ? (
  //   <div className={messagePanelClassNames.root}>
  //   <MessageBar messageBarType={MessageBarType.success} isMultiline={true} truncated={false} overflowButtonAriaLabel="See more">
  //     {componentState.successMessage}
  //   </MessageBar>
  //   </div>
  // ) : (
  //   <></>
  // );
};
