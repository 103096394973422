import React, { ReactElement, useEffect, useState } from "react";
import { ICCSPeoplePickerProps, ICCSPersonaProps, IPersonaProps } from "ccs-azure";
import { UserService, PrincipalTypes } from "services/UserService";
import { IPeoplePickerProps, NormalPeoplePicker } from "@fluentui/react";

export interface IRequestPeoplePickerProps {
  key?: string | number;
  /**
   * specify which types of principals can be searched/chosen
   */
  pickerProps?: Partial<IPeoplePickerProps>;
  principalTypes: PrincipalTypes[];
  selectedUsers?: any[];
  itemLimit?: number;
  placeholder?: string;
  width?: string;
  disabled?: boolean;
  onChange: (items?: any[]) => void;
}

export const PeoplePicker = (props: IRequestPeoplePickerProps) => {
  const [filterText, setFilterText] = useState("");

  const overridePickerPros: IPeoplePickerProps = {
    ...props.pickerProps,
    onResolveSuggestions: (filter: string, selectedItems?: IPersonaProps[] | undefined) => {
      setFilterText(filter);
      return UserService.searchUsers(filter, props.principalTypes);
    },
    onEmptyResolveSuggestions: (selectedItems?: IPersonaProps[] | undefined): IPersonaProps[] | PromiseLike<IPersonaProps[]> => {
      console.log(`onEmptyResolveSuggestions`);
      return [];
    },
  };

  const renderNoResults = (): ReactElement<any, any> => {
    if (filterText.indexOf("@") !== -1) {
      return (
        <div style={{ margin: "10px" }}>
          User <span style={{ fontWeight: "bold" }}>'{filterText}'</span> does not exist in database.
          <br />
          <br />
          If this is a new external user, please ask the Administrator to invite the user to SpeedUp.
        </div>
      );
    }
    if (filterText && filterText.length !== 0) {
      return <div style={{ margin: "10px", fontStyle: "italic" }}>Sorry, nobody found with this name or email</div>;
    }
    return <></>;
  };

  const onSelectionChange = (items?: ICCSPersonaProps[] | undefined) => {
    //console.log("PeoplePicker onChange", items);
    setSelectedPersonas(items);
    if (props.onChange) {
      props.onChange(items.map((personaUser) => personaUser.payload));
    }
  };

  const [selectedPersonas, setSelectedPersonas] = useState([] as IPersonaProps[]);

  useEffect(() => {
    const personaUsers = props.selectedUsers
      ? props.selectedUsers.map((appUser) => {
          return {
            text: appUser.displayName || appUser.name,
            secondaryText: !!appUser.displayName ? "Internal User" : "Group",
            tertiaryText: appUser.email,
            payload: appUser,
          } as ICCSPersonaProps;
        })
      : [];
    //console.log(`PeoplePicker: settings value`, personaUsers);
    setSelectedPersonas(personaUsers);
  }, [props.selectedUsers]);

  //console.log("Picker data", selectedPersonas);

  return (
    <NormalPeoplePicker
      key={props.key}
      {...overridePickerPros}
      disabled={props.disabled}
      onChange={onSelectionChange}
      getTextFromItem={(item: IPersonaProps, currentValue?: string | undefined): string => (item && item.text ? item.text : "")}
      resolveDelay={500}
      selectedItems={selectedPersonas}
      pickerSuggestionsProps={{
        loadingText: "searching for users",
        noResultsFoundText: "No results found",
        searchingText: "Searching...",
        onRenderNoResultFound: renderNoResults,
      }}
      styles={{ root: { width: props.width ? props.width : undefined } }}
      itemLimit={props.itemLimit}
      inputProps={{ placeholder: props.placeholder, width: props.width }}
    />
  );
};

export default PeoplePicker;
