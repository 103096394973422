import { DefaultButton, Dialog, DialogFooter, DialogType } from "@fluentui/react";
import React, { useCallback, useEffect, useState } from "react";
import { connection } from "index";

export const GlobalNotificationDisplay: React.FunctionComponent = () => {
    const [isDialogOpen, setIsDialogOpen] = useState(false);
    const [notificationContent, setNotificationContent] = useState<string>(null)

    const modalPropsStyles = { main: { maxWidth: 450 } };
    const dialogContentProps = {
        type: DialogType.largeHeader,
        title: 'Global Notification',
        subText: notificationContent,
    };

    const modalProps = React.useMemo(
        () => ({
            isBlocking: true,
            styles: modalPropsStyles
        }),
        [],
    );

    useEffect(() => {
        connection.on("globalInfo", (info: string) => {
            setNotificationContent(info);
        });

        return () => {
            connection.off("globalInfo");
        };
    }, []);

    useEffect(() => {
        if (Boolean(notificationContent)) {
            setIsDialogOpen(true);
        } else {
            setIsDialogOpen(false);
        }
    }, [notificationContent]);

    const dismissDialog = useCallback(() => {
        setNotificationContent(null);
        setIsDialogOpen(false);
    }, []);

    return (
        <Dialog
            hidden={!isDialogOpen}
            dialogContentProps={dialogContentProps}
            modalProps={modalProps}
            onDismiss={dismissDialog}
        >
            <DialogFooter>
                <DefaultButton onClick={dismissDialog} text="Dismiss" />
            </DialogFooter>
        </Dialog>
    );
};