import { ConfirmBox, hideLoadingSpinner, showErrorMessage, showLoadingSpinner, MetadataService, AdminPageContainer } from "components";
import { ErrorRegions, TextConstants } from "constant";
import { useBoolean } from "@fluentui/react-hooks";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import {
    CommandBar, ConstrainMode, DetailsList, DetailsListLayoutMode, IColumn, ICommandBarItemProps, IDetailsHeaderProps, IDropdownOption, 
    IRenderFunction, ITooltipHostProps, MarqueeSelection, Panel, PanelType, Selection, SelectionMode, Stack, TooltipHost,
} from "@fluentui/react";
import adminStyles from "../Admin.module.scss";
import styles from "./ManageHelpDocument.module.scss";
import { IHelpDocument } from "interfaces";
import { HelpDocumentForm } from "./HelpDocumentForm";
import { useDispatch } from "react-redux";
import { formatISODateTime } from "functions";

const columns: IColumn[] = [
    {
        key: "documentKey",
        name: TextConstants.Admin.HelpDocument.Label_DocumentKey,
        fieldName: "documentKey",
        minWidth: 100,
        maxWidth: 200,
        isResizable: true,
        data: "string",
        isPadded: true
    },
    {
        key: "link",
        name: TextConstants.Admin.HelpDocument.Label_Link,
        fieldName: "link",
        minWidth: 200,
        isResizable: true,
        data: "string",
        isPadded: true
    },
    {
        key: "createdBy",
        name: TextConstants.Common.Label_CreatedBy,
        fieldName: "createdBy",
        minWidth: 150,
        maxWidth: 400,
        isResizable: true,
        isCollapsible: true,
        data: "string",
        isPadded: true,
        onRender: (item: IHelpDocument) => {
            return <span>{item?.createdBy?.displayName}</span>;
        },
    },
    {
        key: "createdOn",
        name: TextConstants.Common.Label_CreatedOn,
        fieldName: "createdOn",
        minWidth: 150,
        maxWidth: 400,
        isResizable: true,
        isCollapsible: true,
        data: "string",
        isPadded: true,
        onRender: (item: IHelpDocument) => {
            return <span>{formatISODateTime(item.createdOn)}</span>;
        },
    },
    {
        key: "modifiedBy",
        name: TextConstants.Common.Label_ModifiedBy,
        fieldName: "modifiedBy",
        minWidth: 150,
        maxWidth: 400,
        isResizable: true,
        isCollapsible: true,
        data: "string",
        isPadded: true,
        onRender: (item: IHelpDocument) => {
            return <span>{item.modifiedBy?.displayName}</span>;
        },
    },
    {
        key: "modifiedOn",
        name: TextConstants.Common.Label_ModifiedOn,
        fieldName: "modifiedOn",
        minWidth: 150,
        maxWidth: 400,
        isResizable: true,
        isCollapsible: true,
        data: "string",
        isPadded: true,
        onRender: (item: IHelpDocument) => {
            return <span>{formatISODateTime(item.modifiedOn)}</span>;
        },
    }
];

export const ManageHelpDocument = () => {
    let metaDataService = new MetadataService();
    const dispatch = useDispatch();

    const [isOpen, { setTrue: openPanel, setFalse: dismissPanel }] = useBoolean(false);
    const [selectedHelpDocument, setSelectedHelpDocument] = useState<IHelpDocument>(Object);
    const [rowData, setRowData] = useState<IHelpDocument[]>([]);
    const [helpDocumentsKeyOptions, setHelpDocumentsKeyOptions] = useState<IDropdownOption[]>([]);
    const [selectedItemsToDelete, setSelectedItemsToDelete] = useState<number[]>();

    const selection = new Selection({
        onSelectionChanged: () => {
            setSelectedItemsToDelete(selection.getSelection().map((item: any) => (item as IHelpDocument).id));
        },
        selectionMode: SelectionMode.multiple,
    });
    const [hideDialog, { toggle: toggleHideDialog }] = useBoolean(true);
    const [deleteDialogResult, setDeleteDialogResult] = useState(false);

    const addNewItem = () => {
        setSelectedHelpDocument({
            id: 0,
        });
        openPanel();
    };

    const setSelectedItem = (i: IHelpDocument) => {
        setSelectedHelpDocument(i);
        openPanel();
    };

    const _cmditems: ICommandBarItemProps[] = [
        {
            key: "newItem",
            text: TextConstants.Label_Add_Item,
            iconProps: { iconName: "Add" },
            ariaLabel: "New",
            onClick: addNewItem,
        },
        {
            key: "deleteItem",
            text: TextConstants.Label_Delete,
            iconProps: { iconName: "Delete" },
            ariaLabel: "Delete",
            onClick: toggleHideDialog,
            disabled: !selectedItemsToDelete?.length,
        },
    ];

    const saveHelpDocument = useCallback(async (helpDocument: IHelpDocument) => {
        dispatch(showLoadingSpinner());

        try {
            await metaDataService.createUpdateHelpDocumentEntry(helpDocument);

            getAllHelpDocuments();
            dismissPanel();
        } catch (error) {
            dispatch(showErrorMessage({ error, errorOriginationKey: ErrorRegions.ResponsibilityMatrix }));
        }
        finally {
            dispatch(hideLoadingSpinner());
        }

    }, [selectedHelpDocument])

    const getAllHelpDocuments = useCallback(async () => {
        const data = await metaDataService.getAllMasterTableData<IHelpDocument>("HelpDocument");
        if (data?.length > 0) {
            setRowData(data);
        }
    }, [])

    const getAllDocumentKeys = useCallback(async () => {
        const data = await metaDataService.getAllMasterDataFromDropdownList(["HelpDocumentKeys"]);

        if (data) {
            setHelpDocumentsKeyOptions(data["HelpDocumentKeys"].sort().map(x => ({ key: x, text: x })));
        }
    }, [])

    useEffect(() => {
        getAllHelpDocuments();
        getAllDocumentKeys();
    }, []);

    useEffect(() => {
        const deleteMultipleData = async () => {
            if (deleteDialogResult && selectedItemsToDelete?.length) {
                let result = await metaDataService.deleteMultipleMasterDataEntry("HelpDocument", selectedItemsToDelete);
                if (result) {
                    let tempdata = [...rowData];
                    tempdata = tempdata.filter((value) => !selectedItemsToDelete.includes(value.id));
                    setRowData(tempdata);
                }
                setSelectedItemsToDelete([]);
                setDeleteDialogResult(false);
            }
        }
        deleteMultipleData();
    }, [deleteDialogResult]);

    const onRenderDetailsHeader = useCallback((props: IDetailsHeaderProps, defaultRender: (props?: IDetailsHeaderProps) => JSX.Element | null) => {
        if (!props) {
            return null;
        }
        const onRenderColumnHeaderTooltip: IRenderFunction<ITooltipHostProps> = tooltipHostProps => (
            <TooltipHost {...tooltipHostProps} />
        );
        return (
            <div className={styles.stickyHeaderTable}>
                {defaultRender!({
                    ...props,
                    onRenderColumnHeaderTooltip,
                })}
            </div>
        );
    }, [])

    return (
        <AdminPageContainer pageTitle={TextConstants.Admin.SectionHeading_ManageHelpDocuments} >
            <ConfirmBox
                title={TextConstants.Label_Delete}
                subtext={TextConstants.Admin.Delete_Message_Multiple_Generic}
                open={hideDialog}
                setModalOpen={toggleHideDialog}
                setDeleteDialogResult={setDeleteDialogResult}
            />

            <Stack horizontal horizontalAlign="space-between" verticalAlign="center" tokens={{ childrenGap: 10 }}>
                <CommandBar
                    styles={{ root: { padding: 0 } }}
                    items={_cmditems}
                    ariaLabel="Use left and right arrow keys to navigate between commands"
                />
            </Stack>
            <div style={{ maxHeight: 'calc(100vh - 289px)' }} className={adminStyles.detailListContainer} data-is-scrollable="true">

                <MarqueeSelection selection={selection}>
                    <DetailsList
                        items={rowData}
                        compact={true}
                        styles={{
                            headerWrapper: {
                                position: "sticky",
                                top: 0,
                                zIndex: 1
                            }
                        }}
                        columns={columns}
                        getKey={(i) => i.key}
                        constrainMode={ConstrainMode.unconstrained}
                        layoutMode={DetailsListLayoutMode.justified}
                        isHeaderVisible={true}
                        onRenderDetailsHeader={onRenderDetailsHeader}
                        onItemInvoked={(i) => setSelectedItem(i)}
                        selection={selection}
                        selectionPreservedOnEmptyClick={true}
                    />
                </MarqueeSelection>
            </div>
            {
                isOpen &&
                <Panel
                    headerText={`${!!selectedHelpDocument.id ? "Edit" : "Add New"} Item `}
                    isOpen={isOpen}
                    onDismiss={dismissPanel}
                    focusTrapZoneProps={{
                        forceFocusInsideTrap: false,
                      }}
                    type={PanelType.custom}
                    customWidth="50%"
                    closeButtonAriaLabel={TextConstants.Label_Close}
                    isFooterAtBottom={true}
                    headerClassName={styles.panelHeaderClass}
                    styles={{
                        commands: {
                            backgroundColor: "#fff !important"
                        }
                    }}
                >
                    <HelpDocumentForm
                        documentKeyDropdownOptions={helpDocumentsKeyOptions}
                        dismissPanel={dismissPanel}
                        helpDocument={selectedHelpDocument}
                        saveHelpDocument={saveHelpDocument}
                    />
                </Panel>
            }
        </AdminPageContainer>
    );
};
