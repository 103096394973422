export const BOMSAPExportHeaders = [
  "Matnr ",
  "Werk ",
  "Verw ",
  "Alt ",
  "A-Text ",
  "AEno ",
  "Valid from ",
  "Rev ",
  "DelMarkHeader ",
  "Position ",
  "Pos ",
  "Com ",
  "Amount ",
  "BaseUOM ",
  "Kon ",
  "Fer ",
  "Ver ",
  "Kal ",
  "Schütt ",
  "DelMarkItem",
  "fix value"
];
