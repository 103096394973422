import { KeyValue } from "interfaces";

// Admin-Menu options values are in alphabetical order
export const AdminMenuOptions: KeyValue<string> = {
  ColorSchemeMatrix: "Color scheme matrix",
  Invitation: "External user invitation",
  ManageFAQ: "FAQ",
  ManageGroups: "Groups",
  ManageHelpPages: "Help pages",
  MetadataConfiguration: "Metadata configuration",
  ResponsibilityMatrix: "Responsibility matrix",
  ManageHelpDocuments: "Manage help documents"
};
