import React, { useEffect } from "react";
import { Label, Text } from "@fluentui/react";
import { useSelector } from "react-redux";
import styles from "../Admin.module.scss";
import FAQService from "./FAQService";
import { TextConstants } from "constant";
import { CollapsibleSection } from "ccs-azure";
import { RootState } from "store";
import { IFAQDict } from "interfaces/IFAQDict";
import { AdminPageContainer } from "../AdminPageContainer";

export const FAQView = () => {
  let faqService = new FAQService();

  const faqdata: IFAQDict = [...useSelector((state: RootState) => state.faqData.value)].reduce((val: IFAQDict, faq) => {
    const { grouping } = faq;
    val[grouping] = val[grouping] ?? [];
    val[grouping].push(faq);
    return val;
  }, {});

  async function fetchFAQData() {
    await faqService.getFAQ();
  }

  useEffect(() => {
    fetchFAQData();
  }, []);

  useEffect(() => {
    console.log(faqdata);
  }, [faqdata]);

  return (
    // <AdminPageContainer pageTitle={TextConstants.Label_FAQ} >
      <div>
        {Object.keys(faqdata).map((key) => (
          <CollapsibleSection key={key} title={key} className={styles.faqAccordionPanel}>
            <div className="padding10">
              {
                faqdata[key].map((x: any, i: number) => (
                  <div style={{ margin: "10px 0 15px" }} key={i} className={`${styles.marginTopBottom}`}>
                    <Label>{x.question}</Label>
                    <Text className={`ms-fontSize-12 ${styles.faqPreLine}`} block>
                      {x.answer}
                    </Text>
                  </div>
                ))
              }
            </div>

          </CollapsibleSection>
        ))}
      </div>
    // </AdminPageContainer>
  );
};

export const FAQPage = () => {
  return (
    <AdminPageContainer pageTitle={TextConstants.Label_FAQ} >
      <FAQView />
    </AdminPageContainer>
  );
};
