import { useTheme } from "@fluentui/react";
import { TextConstants } from "constant";

export const PageNotFound: React.FC<any> = (props: any) => {
    const theme = useTheme();
    return (
        <div style={{ display: "flex", justifyContent: "center", alignItems: "center", width: "100%", margin: "auto 0" }}>
            <h1 style={{ color: theme.semanticColors.errorText }}>{TextConstants.Common.Error_Message_PageNotFound}</h1>
        </div>
    );
}