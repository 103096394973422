import { IDetailsColumnStyles, ISearchBoxStyles } from "@fluentui/react";

export const detailsListStyle = {
  root: {
    selectors: {
      "& [role=grid]": {
        display: "flex",
        flexDirection: "column",
        alignItems: "start",
        minHeight: "40vh",
      },
      "& [role=columnHeader]": {
        "& i": {
          color: "black",
        },
      },
    },
  },

  headerWrapper: {
    flex: "0 0 auto",
  },
  contentWrapper: {
    flex: "1 1 auto",
    overflowY: "auto",
    overflowX: "hidden",
  },
};

export const cellStyle: Partial<IDetailsColumnStyles> = {
  root: {
    padding: "0",
  },
  cellTitle: {
    fontWeight: 700,
    fontSize: "13px",
    alignItems: "center",
  },
  cellName: {
    fontWeight: 700,
    wordBreak: "break-word",
    fontSize: "13px",
  },
};

export const searchBoxStyles: Partial<ISearchBoxStyles> = {
  root: {
    width: 200,
    float: "right",
    marginRight: "10px",
  },
};

export const choiceGroupStyle = {
  root: {
    display: "inline-block",
    marginRight: 10,
  },
};
