import {
  ChoiceGroup, CommandBar, css, DefaultButton, DetailsList, DetailsListLayoutMode, IChoiceGroupOption, IColumn, ICommandBarItemProps,
  IDetailsColumnRenderTooltipProps, IDetailsHeaderProps, IPanelProps, IRenderFunction, Panel, PanelType, PrimaryButton, SearchBox, SelectionMode,
  Separator, Sticky, StickyPositionType, Text, TextField, TooltipHostBase,
} from "@fluentui/react";
import { TextConstants } from "constant";
import { formatISODateTime } from "functions/dateFunctions";
import React, { useEffect, useRef, useState } from "react";
import UserInvitationService from "./UserInvitationService";
import { useBoolean } from "@fluentui/react-hooks";
import styles from "../Admin.module.scss";
import { cellStyle, choiceGroupStyle, searchBoxStyles } from "components/dashboard";
import { copyAndSort, getFilteredItemsForSearchTerm, onColumnClick } from "services";
import { IInvitation } from "interfaces";
import { AdminPageContainer } from "../AdminPageContainer";

export const UserInvitation = () => {
  let invitationService = new UserInvitationService();
  const [rowData, setRowData] = useState([]);
  const [filteredItems, setFilteredItems] = useState<any[]>([]);
  const [filteredActionItems, setFilteredActionItems] = useState<any[]>([]);
  const [isOpen, { setTrue: openPanel, setFalse: dismissPanel }] = useBoolean(false);
  const [invitation, setInvitation] = useState<IInvitation>();
  const [columns, setColumns] = useState<IColumn[]>([]);
  const [actionColumns, setActionColumns] = useState<IColumn[]>([]);
  const [currentSortedColumn, setCurrentSortedColumn] = useState<IColumn>(Object);
  const [currentActionSortedColumn, setCurrentActionSortedColumn] = useState<IColumn>(Object);
  const refColumns = useRef(columns);
  const refItems = useRef(filteredItems);
  const refActionColumns = useRef(actionColumns);
  const refActionItems = useRef(filteredActionItems);
  const [searchTerm, setSearchTerm] = useState<string>("");
  const [searchActionTerm, setSearchActionTerm] = useState<string>("");
  const [choiceGroupOption, setChoiceGroupOption] = useState<string>("Expired");


  const setListData = (rowData: any[], currentSelectedFilter: string) => {
    let filteredItemsClone: any[] = [];
    filteredItemsClone = [...rowData];

    if (currentSelectedFilter === "Expired") {
      filteredItemsClone = filteredItemsClone.filter((x) => new Date(x.expirationDate) < new Date());
    } else if (currentSelectedFilter === "NotExpired") {
      filteredItemsClone = filteredItemsClone.filter((x) => new Date(x.expirationDate) >= new Date());
    }

    if (currentSortedColumn != null) {
      filteredItemsClone = copyAndSort(
        filteredItemsClone,
        currentSortedColumn.fieldName!,
        currentSortedColumn.isSortedDescending
      );
    }

    refItems.current = filteredItemsClone;
    setFilteredItems(filteredItemsClone);
  }

  const fetchInvitationData = async () => {
    let data = await invitationService.getAllInvitationData();
    if (data?.length > 0) {
      setRowData(data);
      setListData(data, choiceGroupOption);
      const notRedeemedData = data.filter((x) => !x.isRedeemed);
      setFilteredActionItems(notRedeemedData);
      refActionItems.current = notRedeemedData;
    }
  }

  const addNewItem = () => {
    setInvitation({});
    openPanel();
  };

  const setInputData = (value: any, fieldName: any) => {
    setInvitation((prevState: IInvitation) => ({
      ...prevState,
      [fieldName]: value,
    }));
  };

  const _cmditems: ICommandBarItemProps[] = [
    {
      key: "newItem",
      text: TextConstants.Admin.Label_CreateInvitation,
      iconProps: { iconName: "Add" },
      ariaLabel: "create",
      onClick: addNewItem,
    },
  ];

  const createInvitation = async () => {
    await sendInvitation(invitation);
  }

  const sendInvitation = async (invitation: IInvitation) => {
    await invitationService.createInvitationEntry(invitation);
    fetchInvitationData();
    dismissPanel();
  }

  const _onColumnClick = (ev: React.MouseEvent<HTMLElement>, column: IColumn): void => {
    const newColumns: IColumn[] = refColumns.current.slice();
    const currColumn: IColumn = newColumns.filter((currCol) => column.key === currCol.key)[0];
    const newItems = onColumnClick(refColumns, column, refItems);
    refItems.current = newItems;
    setFilteredItems(newItems);
    setColumns(newColumns);
    setCurrentSortedColumn(currColumn);
  };

  const _onActionColumnClick = (ev: React.MouseEvent<HTMLElement>, column: IColumn): void => {
    const newColumns: IColumn[] = refActionColumns.current.slice();
    const currColumn: IColumn = newColumns.filter((currCol) => column.key === currCol.key)[0];
    const newItems = onColumnClick(refActionColumns, column, refActionItems);
    refActionItems.current = newItems;
    setFilteredActionItems(newItems);
    setActionColumns(newColumns);
    setCurrentActionSortedColumn(currColumn);
  };

  useEffect(() => {
    fetchInvitationData();
    let col = [
      {
        key: "column1",
        name: TextConstants.Admin.Label_InvitationCode,
        fieldName: "invitationCode",
        styles: cellStyle,
        minWidth: 200,
        maxWidth: 340,
        isResizable: true,
        data: "string",
        onColumnClick: _onColumnClick,
      },
      {
        key: "column2",
        name: TextConstants.Admin.Label_DisplayName,
        fieldName: "userDisplayName",
        styles: cellStyle,
        minWidth: 200,
        maxWidth: 340,
        isResizable: true,
        data: "string",
        isPadded: true,
        onColumnClick: _onColumnClick,
      },
      {
        key: "column3",
        name: TextConstants.Admin.Label_InvitationEmail,
        fieldName: "invitationEmail",
        styles: cellStyle,
        minWidth: 200,
        maxWidth: 340,
        isResizable: true,
        isCollapsible: true,
        data: "string",
        isPadded: true,
        onColumnClick: _onColumnClick,
      },
      {
        key: "column4",
        name: TextConstants.Admin.Label_IsRedeemed,
        fieldName: "isRedeemed",
        styles: cellStyle,
        minWidth: 90,
        maxWidth: 140,
        isResizable: true,
        isCollapsible: true,
        data: "string",
        isPadded: true,
        onRender: (item: any) => {
          return item.isRedeemed ? <span>Yes</span> : <span>No</span>;
        },
        onColumnClick: _onColumnClick,
      },
      {
        key: "column5",
        name: TextConstants.Admin.Label_ExpirationDate,
        fieldName: "expirationDate",
        styles: cellStyle,
        minWidth: 70,
        maxWidth: 90,
        isResizable: true,
        isCollapsible: true,
        data: "string",
        isPadded: true,
        onRender: (item: any) => {
          return <span>{formatISODateTime(item["expirationDate"])}</span>;
        },
        onColumnClick: _onColumnClick,
      },
      {
        key: "column6",
        name: TextConstants.Common.Label_CreatedOn,
        fieldName: "createdOn",
        styles: cellStyle,
        minWidth: 70,
        maxWidth: 90,
        isResizable: true,
        isCollapsible: true,
        data: "string",
        isPadded: true,
        onRender: (item: any) => {
          return <span>{formatISODateTime(item["createdOn"])}</span>;
        },
        onColumnClick: _onColumnClick,
      },
      {
        key: "column7",
        name: TextConstants.Common.Label_ModifiedOn,
        fieldName: "modifiedOn",
        styles: cellStyle,
        minWidth: 70,
        maxWidth: 90,
        isResizable: true,
        isCollapsible: true,
        data: "string",
        isPadded: true,
        onRender: (item: any) => {
          return <span>{formatISODateTime(item["modifiedOn"])}</span>;
        },
        onColumnClick: _onColumnClick,
      },
      {
        key: "column8",
        name: TextConstants.Common.Label_Action,
        styles: cellStyle,
        minWidth: 150,
        maxWidth: 190,
        isResizable: true,
        isCollapsible: true,
        data: "string",
        isPadded: true,
        onRender: (item: any) => {
          return item.isRedeemed ? (
            <span></span>
          ) : (
            <DefaultButton
              disabled={item.isRedeemed}
              style={{ border: "none" }}
              text={TextConstants.Admin.Label_Resend_Invitation}
              onClick={() => sendInvitation(item)}
              iconProps={{ iconName: "Send" }}
            />
          );
        },
      },
    ];

    setColumns(col);
    refColumns.current = col;
    col = col.map((x) => {
      let coll = { ...x };
      coll["onColumnClick"] = _onActionColumnClick;
      return coll;
    });
    setActionColumns(col);
    refActionColumns.current = col;
  }, []);

  const onRenderDetailsHeader: IRenderFunction<IDetailsHeaderProps> = (props, defaultRender) => {
    if (!props) {
      return null;
    }
    const onRenderColumnHeaderTooltip: IRenderFunction<IDetailsColumnRenderTooltipProps> = (tooltipHostProps) => (
      <TooltipHostBase {...tooltipHostProps} />
    );
    return (
      <Sticky stickyPosition={StickyPositionType.Header} isScrollSynced>
        {defaultRender!({
          ...props,
          onRenderColumnHeaderTooltip,
        })}
      </Sticky>
    );
  };

  const onClearSearchFilters = (forTable: string) => {
    let filteredItemsClone: any[] = [];
    filteredItemsClone = [...rowData];

    if (forTable === "actionRequired") {
      if (currentActionSortedColumn != null) {
        filteredItemsClone = copyAndSort(
          filteredItemsClone,
          currentActionSortedColumn.fieldName!,
          currentActionSortedColumn.isSortedDescending
        );
      }
      refActionItems.current = filteredItemsClone;
      setFilteredActionItems(filteredItemsClone);
      setSearchActionTerm("");
    } else {
      if (currentSortedColumn != null) {
        filteredItemsClone = copyAndSort(
          filteredItemsClone,
          currentSortedColumn.fieldName!,
          currentSortedColumn.isSortedDescending
        );
      }
      refItems.current = filteredItemsClone;
      setFilteredItems(filteredItemsClone);
      setSearchTerm("");
    }
  };

  const onSearchValueChange = (forTable: string, event?: React.ChangeEvent<HTMLInputElement>, newValue?: string) => {
    let filteredItemsClone: any[] = [];
    filteredItemsClone = [...rowData];
    if (!!newValue) {
      filteredItemsClone = getFilteredItemsForSearchTerm(newValue, filteredItemsClone, rowData);
    }

    if (forTable === "actionRequired") {
      !!newValue ? setSearchActionTerm(newValue) : setSearchActionTerm("");
      filteredItemsClone = filteredItemsClone.filter((x) => new Date(x.expirationDate) < new Date() && !x.isRedeemed);
      if (currentActionSortedColumn != null) {
        filteredItemsClone = copyAndSort(
          filteredItemsClone,
          currentActionSortedColumn.fieldName!,
          currentActionSortedColumn.isSortedDescending
        );
      }
      refActionItems.current = filteredItemsClone;
      setFilteredActionItems(filteredItemsClone);
    } else {
      !!newValue ? setSearchTerm(newValue) : setSearchTerm("");
      if (choiceGroupOption === "Expired") {
        filteredItemsClone = filteredItemsClone.filter((x) => new Date(x.expirationDate) < new Date());
      } else if (choiceGroupOption === "NotExpired") {
        filteredItemsClone = filteredItemsClone.filter((x) => new Date(x.expirationDate) >= new Date());
      }
      if (currentSortedColumn != null) {
        filteredItemsClone = copyAndSort(
          filteredItemsClone,
          currentSortedColumn.fieldName!,
          currentSortedColumn.isSortedDescending
        );
      }
      refItems.current = filteredItemsClone;
      setFilteredItems(filteredItemsClone);
    }
  };

  const handleRequestStatusChange = (ev?: React.FormEvent<HTMLInputElement | HTMLElement>, option?: IChoiceGroupOption) => {
    !!option && setChoiceGroupOption(option.key);
    setSearchTerm("");

    setListData(rowData, option.key);
  };

  return (
    <AdminPageContainer pageTitle={TextConstants.Admin.SectionHeading_External_Invitation} contentClassName={css(styles.noBackground, styles.noPadding)}>
      {rowData.filter((x) => new Date(x.expirationDate) < new Date() && !x.isRedeemed).length > 0 && (
        <div className={styles.expiredInvitationContainer}>
          <div className={styles.detailListContainer}>
            <Text variant="xLarge" style={{ color: "red" }} className={styles.marginTopBottom}>
              {TextConstants.Admin.SectionHeading_Action_Required_Invitations}
            </Text>
            <div className={styles.cmdBarWithSearch}>
              <div></div>
              <SearchBox
                styles={searchBoxStyles}
                onChange={(e, val) => onSearchValueChange("actionRequired", e, val)}
                onEscape={() => onClearSearchFilters("actionRequired")}
                value={searchActionTerm}
                onClear={() => onClearSearchFilters("actionRequired")}
                placeholder="Search"
              ></SearchBox>
            </div>
            <DetailsList
              key="actionRequired"
              className={styles.tableWithFilters}
              items={filteredActionItems}
              compact={true}
              columns={actionColumns}
              getKey={(i) => i.key}
              layoutMode={DetailsListLayoutMode.justified}
              isHeaderVisible={true}
              selectionPreservedOnEmptyClick={true}
              onRenderDetailsHeader={onRenderDetailsHeader}
              selectionMode={SelectionMode.none}
            />
          </div>
        </div>
      )}
      <div style={{ display: "flex", flex: "1 1 auto", backgroundColor: "#fff" }}>
        <div className={styles.detailListContainer}>
          <Separator alignContent="start">
            <Text variant="xLarge" className={styles.marginTopBottom}>
              {TextConstants.Admin.SectionHeading_All_Invitations}
            </Text>
          </Separator>
          <div className={styles.cmdBarWithSearch}>
            <CommandBar
              styles={{ root: { padding: 0 } }}
              items={_cmditems}
              ariaLabel="Use left and right arrow keys to navigate between commands"
            />
            <div>
              <ChoiceGroup
                options={[
                  {
                    key: "All",
                    text: "All",
                    styles: { root: { display: "inline-block", marginRight: 10 } },
                  },
                  {
                    key: "Expired",
                    text: "Expired",
                    styles: { root: { display: "inline-block", marginRight: 10 } },
                  },
                  {
                    key: "NotExpired",
                    text: "Not Expired",
                    styles: { root: { display: "inline-block", marginRight: 10 } },
                  },
                ]}
                styles={choiceGroupStyle}
                onChange={handleRequestStatusChange}
                selectedKey={choiceGroupOption}
              ></ChoiceGroup>
              <SearchBox
                styles={searchBoxStyles}
                onChange={(e, val) => onSearchValueChange("allItems", e, val)}
                onEscape={() => onClearSearchFilters("allItems")}
                value={searchTerm}
                onClear={() => onClearSearchFilters("allItems")}
                placeholder="Search"
              ></SearchBox>
            </div>
          </div>
          <DetailsList
            key="allItems"
            className={styles.tableWithFilters}
            items={filteredItems}
            compact={true}
            columns={columns}
            getKey={(i) => i.key}
            layoutMode={DetailsListLayoutMode.justified}
            isHeaderVisible={true}
            selectionPreservedOnEmptyClick={true}
            onRenderDetailsHeader={onRenderDetailsHeader}
            selectionMode={SelectionMode.none}
          />
        </div>
        {
          isOpen &&
          <Panel
            headerText={TextConstants.Admin.Label_CreateInvitation}
            isOpen={isOpen}
            onDismiss={dismissPanel}
            type={PanelType.medium}
            focusTrapZoneProps={{
              forceFocusInsideTrap: false,
            }}
            closeButtonAriaLabel={TextConstants.Label_Close}
            isFooterAtBottom={true}
            onRenderFooterContent={(props: IPanelProps) => {
              return (
                <div className={styles.editPanelActions}>
                  <div>
                    <PrimaryButton
                      text={TextConstants.Common.Label_Button_Create}
                      disabled={!invitation?.invitationEmail || !invitation.userDisplayName}
                      onClick={createInvitation}
                      className={styles.marginRight}
                      iconProps={{ iconName: "AddFriend" }}
                    />
                    <DefaultButton text={TextConstants.Label_Cancel} onClick={dismissPanel} iconProps={{ iconName: "Cancel" }} />
                  </div>
                </div>
              );
            }}
          >
            <div className={styles.editPanelContainer}>
              <div className={styles.editPanelFormControls}>
                <TextField
                  label={TextConstants.Admin.Label_DisplayName}
                  value={invitation.userDisplayName}
                  onChange={(e: any) => setInputData(e.target.value, "userDisplayName")}
                />
                <TextField
                  label={TextConstants.Admin.Label_InvitationEmail}
                  value={invitation.invitationEmail}
                  onChange={(e: any) => setInputData(e.target.value, "invitationEmail")}
                />
              </div>

            </div>
          </Panel>
        }

      </div>
    </AdminPageContainer>
  );
};

