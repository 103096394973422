import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { MetadataService } from "components";
import { ILanguageCode } from "interfaces";
import { CommonService } from "../../../services";

export interface ILanguageCodeStoreState {
    loadingLanguageCodeMasterStatus: 'idle' | 'loading' | 'succeeded' | 'failed';
    languageCodeMaster: ILanguageCode[];
    errorLoadingLanguageMaster: any;
}

const initialState = {
    languageCodeMaster: [],
    loadingLanguageCodeMasterStatus: 'idle',
    errorLoadingLanguageMaster: null,
} as ILanguageCodeStoreState;

export const loadLanguageCodeMasterAsync = createAsyncThunk('languageCode/loadLanguageMaster', (metaDataService: MetadataService) => {
    return metaDataService.getAllMasterTableData<ILanguageCode>("LangauageCodeMapping").then(master => {
        return master.sort((a, b) => CommonService.sortByFieldInObject(a, b, "sortOrder"));
    });
});

export const languageCodeSlice = createSlice({
    name: "languageCode",
    initialState: initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder.addCase(loadLanguageCodeMasterAsync.fulfilled, (state, action) => {
            state.loadingLanguageCodeMasterStatus = 'succeeded';
            state.languageCodeMaster = action.payload;
        });
        builder.addCase(loadLanguageCodeMasterAsync.pending, (state, action) => {
            state.loadingLanguageCodeMasterStatus = 'loading';
        });
        builder.addCase(loadLanguageCodeMasterAsync.rejected, (state, action) => {
            state.loadingLanguageCodeMasterStatus = 'failed';
            state.languageCodeMaster = [];
            if (action.error) {
                state.errorLoadingLanguageMaster = action.error.message;
            }
        });
    }
});

export const {

} = languageCodeSlice.actions;

export const languageCodeReducer = languageCodeSlice.reducer;
