import { ICompany } from "interfaces";
import { useDispatch } from "react-redux";
import { HttpService } from "services";
import { showLoadingSpinner, hideLoadingSpinner, showErrorMessage, hideErrorMessage } from "components";
import { ErrorRegions } from "constant";

export class ChoiceService {
  public apiEndpointMetaData = `/api/Metadata`;
  dispatch = useDispatch();

  public async getDataListByChoice(choice: string): Promise<any[]> {
    this.dispatch(showLoadingSpinner());
    let choices: any = [];
    try {
      const data = await HttpService.post(`${this.apiEndpointMetaData}/GetMasterDataForFields`, [choice]);
      if (data?.length > 0) {
        if (data[0].choiceKey === choice) {
          choices = data[0].choiceData.split("\n");
        }
      }
    } catch (error) {
      this.dispatch(showErrorMessage({ error, errorOriginationKey: ErrorRegions.Choice }));
      setTimeout(() => {
        this.dispatch(hideErrorMessage(ErrorRegions.Choice));
      }, 5000);
    }
    this.dispatch(hideLoadingSpinner());
    return choices;
  }
}
