import { Checkbox, Dropdown, IDropdownOption, Label, Spinner, Text } from "@fluentui/react";
import { Chart as ChartJS, CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend, ChartOptions, LegendItem, ChartData } from "chart.js";
import React, { useMemo, useRef, useState } from "react";
import { Line } from "react-chartjs-2";
import { getRandomColor } from "functions/rgbColorsGenerator";
import styles from "./Chart.module.scss";
import { getPreviouseMothsArray } from "functions/dateFunctions";
import { TextConstants } from "constant";
import { CommonService } from "services";
import { useId } from "@fluentui/react-hooks";
import cloneDeep from "lodash.clonedeep";
export interface ILineChartDataSet {
  dataSet: string;
  data: any[];
  color: string;
}

export interface ILineChartProps {
  chartOptions: ChartOptions;
  lineChartData: {
    header: string;
    labels: string[];
    data: ILineChartDataSet[];
    showLoading: boolean;
  };
  filterBy?: string[];
}

ChartJS.register(CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend);

export const DefaultChartOptions: ChartOptions<"line"> = {
  responsive: true,
  plugins: {
    legend: {
      display: false,
      // position: "bottom",
      // align: "start",
      // labels:{
      //   // padding: 20,
      //   sort: (a: LegendItem, b: LegendItem, data: ChartData): number=>{
      //     return CommonService.sortByText(a.text, b.text);
      //   }
      // }
      // onClickv: () => {},
    },
  }
};

export const LineChart = (props: ILineChartProps) => {
  const chartRef = useRef<ChartJS<"line", number[], string>>(null);
  const [selectedFilter, setSelectedFilter] = useState(props?.filterBy?.length > 0 ? props?.filterBy[0] : "");
  const [selectedLabels, setSelectedLabels] = useState(getPreviouseMothsArray(12));
  const legendId = useId('legend-container');

  const data = {
    labels: props.lineChartData.labels.filter((l) => selectedLabels.includes(l)),
    datasets: props.lineChartData.data.map((d) => {
      let color = !!d.color ? d.color : getRandomColor();
      let bgcolor = color;
      let lbldata = d.data;
      if (!!selectedFilter) {
        if (lbldata.filter((x) => x.key === selectedFilter)?.length > 0) {
          let obj = lbldata.filter((x) => x.key === selectedFilter)[0];
          lbldata = obj?.data;
          //bgcolor = obj?.color;
        }
      }
      return {
        label: d.dataSet,
        data: lbldata.filter((v, i) => selectedLabels.includes(props.lineChartData.labels[i])),
        backgroundColor: bgcolor,
        borderColor: color,
        lineTension: 0.2,
      };
    }),
    borderWidth: 4,
  };

  function _onCheckboxClick(dataSetId: string, isChecked: any) {
    const ci = chartRef.current;
    const datasetItem = ci?.legend?.legendItems?.filter((x) => x.text === dataSetId);
    if (!!datasetItem?.length) {
      if (ci?.isDatasetVisible(datasetItem[0].datasetIndex)) {
        ci.hide(datasetItem[0].datasetIndex);
      } else {
        ci?.show(datasetItem[0].datasetIndex);
      }
    }
  }

  const dropDownOptions: IDropdownOption[] = [
    { key: 12, text: "Last 12 Months", selected: true },
    { key: 6, text: "Last 6 Months" },
    { key: 3, text: "Last 3 Months" },
  ];

  const handleDropDownChange = (item: any) => {
    setSelectedLabels(getPreviouseMothsArray(item));
  };

  const lineChartOptions: ChartOptions<any> = useMemo(() => {
    const copyOfDefaultOptions: ChartOptions<"line"> = cloneDeep(DefaultChartOptions);
    copyOfDefaultOptions.plugins["htmlLegend" as keyof typeof copyOfDefaultOptions.plugins] = {
      containerID: legendId,
    } as never;

    return { ...copyOfDefaultOptions, ...props.chartOptions }
  }, [props.chartOptions, legendId]);

  return (
    <div className={styles.lineChartWrapperSize}>
      <Label>{props.lineChartData.header}</Label>
      {props.lineChartData.showLoading ? (
        <Spinner
          className={styles.spinnerMargin}
          label={TextConstants.HomePage.Heading_Chart_LoadingData}
          ariaLive="assertive"
          labelPosition="right"
        />
      ) : props.lineChartData.data.length > 0 ? (
        <>
          <div className={styles.chartddlFilters}>
            <Dropdown
              placeholder="Select an option"
              label=""
              options={dropDownOptions}
              className={styles.ddlWidth}
              onChange={(e, o) => handleDropDownChange(o?.key)}
            />
            {props.filterBy && (
              <Dropdown
                placeholder="Select an option"
                label=""
                options={props.filterBy.map((d) => ({
                  key: d,
                  text: d,
                }))}
                defaultSelectedKey={selectedFilter}
                className={styles.ddlWidth}
                onChange={(event: React.FormEvent<HTMLDivElement>, option?: IDropdownOption<any>) =>
                  setSelectedFilter(String(option?.key))
                }
              />
            )}
          </div>

          <div className={styles.lineChartSize}>
            <Line ref={chartRef} plugins={[htmlLegendPlugin]} options={lineChartOptions} data={data} />
          </div>
          <div id={legendId} className={styles.lineChartLegend}></div>
          {/* <div className={styles.chartFilters}>
            {props.lineChartData.data.map((d) => (
              <Checkbox
                key={d.dataSet}
                label={d.dataSet}
                defaultChecked
                onChange={(e, isChecked) => _onCheckboxClick(d.dataSet, isChecked)}
              />
            ))}
          </div> */}
        </>
      ) : (
        <div className={styles.lineChartSize}>
          <Text style={{ display: "block", textAlign: "center" }} className={styles.spinnerMargin}>{TextConstants.HomePage.Heading_Chart_NoDataToDisplay}</Text>
        </div>
      )}
    </div>
  );
};


const getOrCreateLegendList = (chart: any, id: any) => {
  const legendContainer = document.getElementById(id);
  let listContainer = legendContainer.querySelector('ul');

  if (!listContainer) {
    listContainer = document.createElement('ul');
    listContainer.className = styles.legendItemContainer;
    // listContainer.style.display = 'flex';
    // listContainer.style.flexDirection = 'row';
    // listContainer.style.margin = '0';
    // listContainer.style.padding = '0';

    legendContainer.appendChild(listContainer);
  }

  return listContainer;
};

const htmlLegendPlugin = {
  id: 'htmlLegend',
  afterUpdate: (chart: any, args: any, options: any) => {
    const ul = getOrCreateLegendList(chart, options.containerID);

    // Remove old legend items
    while (ul.firstChild) {
      ul.firstChild.remove();
    }

    // Reuse the built-in legendItems generator
    const items = chart.options.plugins.legend.labels.generateLabels(chart);

    items.sort((a: any, b: any) => CommonService.sortByTextFieldInObject(a, b, "text")).forEach((item: any) => {
      const li = document.createElement('li');
      li.className = styles.legendItem;
      // li.style.alignItems = 'center';
      // li.style.cursor = 'pointer';
      // li.style.display = 'flex';
      // li.style.flexDirection = 'row';
      // li.style.marginLeft = '10px';

      li.onclick = () => {
        const { type } = chart.config;
        if (type === 'pie' || type === 'doughnut') {
          // Pie and doughnut charts only have a single dataset and visibility is per item
          chart.toggleDataVisibility(item.index);
        } else {
          chart.setDatasetVisibility(item.datasetIndex, !chart.isDatasetVisible(item.datasetIndex));
        }
        chart.update();
      };

      // Color box
      const boxSpan = document.createElement('span');
      boxSpan.className = styles.legendColorBox;
      boxSpan.style.background = item.fillStyle;
      boxSpan.style.borderColor = item.strokeStyle;
      boxSpan.style.borderWidth = item.lineWidth + 'px';
      // boxSpan.style.display = 'inline-block';
      // boxSpan.style.height = '20px';
      // boxSpan.style.marginRight = '10px';
      // boxSpan.style.width = '20px';

      // Text
      const textContainer: HTMLParagraphElement = document.createElement('p');
      textContainer.className = styles.legendText;
      textContainer.style.color = item.fontColor;
      // textContainer.style.margin = '0';
      // textContainer.style.padding = '0';
      textContainer.style.textDecoration = item.hidden ? 'line-through' : '';

      const text = document.createTextNode(item.text);
      textContainer.appendChild(text);

      li.appendChild(boxSpan);
      li.appendChild(textContainer);
      ul.appendChild(li);
    });
  }
};
