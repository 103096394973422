import { AuthenticationResult, BrowserAuthError } from "@azure/msal-browser";
import { useMsal } from "@azure/msal-react";
import { IconButton, Panel } from "@fluentui/react";
import { useBoolean, useId } from "@fluentui/react-hooks";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setCurrentUser, showErrorMessage } from "../index";
import { loginRequest } from "authConfig/AuthConfig";
import { IUser } from "interfaces";
import { CommonService } from "services";
import { RootState } from "store";
import { hideErrorMessage } from "../errorMessage/errorMessageSlice";
import styles from "./AdminMenu.module.scss";
import { TextConstants, AdminMenuOptions, ApplicationRoutes, ErrorRegions } from "constant";
import { Link } from "react-router-dom";

type AppLink = {
  text: string;
  href: string;
};
export interface IAdminMenu {
  user?: IUser;
}

export const AdminMenu: React.FunctionComponent<IAdminMenu> = (props: IAdminMenu) => {
  const dispatch = useDispatch();
  const currentUser = useSelector((state: RootState) => state.currentUser.value);
  const { instance } = useMsal();
  const [isPanelVisible, { toggle: togglePanelVisiblity }] = useBoolean(false);
  const buttonId = useId("adminMenuButton");
  const [administrationLinks, setAdministrationLinks] = useState<AppLink[]>([]);

  useEffect(() => {
    const links: AppLink[] = [];

    for (const key in AdminMenuOptions) {
      links.push({
        text: AdminMenuOptions[key],
        href: ApplicationRoutes[key as keyof object],
      });
    }
    setAdministrationLinks(links);
  }, []);

  const onClickMeButton = () => {
    if (currentUser?.email) {
      togglePanelVisiblity();
    } else {
      instance
        .loginPopup(loginRequest)
        .then((result: AuthenticationResult | null) => {
          if (result) {
            const currentUser: IUser = CommonService.mapIdTokenClaimsToUserObject(result.idTokenClaims);
            dispatch(setCurrentUser(currentUser));
            dispatch(hideErrorMessage(ErrorRegions.Login));
          }
        })
        .catch((error) => {
          if (error instanceof BrowserAuthError) {
            console.error(error);
            dispatch(
              showErrorMessage({
                error,
                errorOriginationKey: ErrorRegions.Login,
              })
            );
          } else if (error) {
            console.error(error);
            dispatch(
              showErrorMessage({
                error,
                errorOriginationKey: ErrorRegions.Login,
              })
            );
          }
        });
    }
  };

  if (currentUser && currentUser.isAdmin) {
    return (
      <div>
        <IconButton
          id={buttonId}
          title={TextConstants.Admin.Title_Button_Menu}
          onClick={onClickMeButton}
          className={styles.settingsButton}
          iconProps={{ iconName: "Settings", className: styles.settingsIcon }}
        />
        {
          <Panel
            className={styles.settingsPanel}
            headerText={TextConstants.Admin.Heading_Menu}
            isOpen={isPanelVisible}
            onDismiss={togglePanelVisiblity}
            closeButtonAriaLabel="Close"
            isLightDismiss={true}
            isBlocking={false}
            focusTrapZoneProps={{
              firstFocusableTarget: styles.adminLinksList,
            }}
            overlayProps={{}}
          >
            <ul className={styles.adminLinksList}>
              {administrationLinks.map((link, index) => (
                <li className={styles.adminLink} key={`link${index}`}>
                  <Link onClick={() => togglePanelVisiblity()} className={styles.link} to={link.href}>
                    {link.text}
                  </Link>
                </li>
              ))}
              {/* <li className={styles.adminLink}>
                <AppLink className={styles.link} to={ApplicationRoutes.MetadataConfiguration}>
                  {AdminMenuOptions.MetadataConfiguration}
                </AppLink>
              </li>
              <li className={styles.adminLink}>
                <AppLink className={styles.link} to={ApplicationRoutes.ManageFAQ}>
                  {AdminMenuOptions.ManageFAQ}
                </AppLink>
              </li>
              <li className={styles.adminLink}>
                <AppLink className={styles.link} to={ApplicationRoutes.ManageHelpPages}>
                  {AdminMenuOptions.ManageHelpPages}
                </AppLink>
              </li> */}
            </ul>
          </Panel>
        }
      </div>
    );
  }
  return null;
};
