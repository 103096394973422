import { IApprovalResult, IOutcomeValue } from "ccs-azure";
import {
  IBase, ICompany, IMaterial, IUser, IUserGroup, FieldValidationState, FieldInfoTyped, IDataLockInfo, ITranslation, IAuditLog,
  ICheckListItem, RequestStepTab, RequestStepType, IUserGroupAssignment, ProjectRequestTask
} from "interfaces";
import { IBOMItem } from "./IMaterial";
import { IProjectRequestStatus } from "./IProjectRequestStatus";

export enum RequestStatus {
  Draft = "Draft",
  InProgress = "In Progress",
  Completed = "Completed",
  Rejected = "Rejected",
  Withdrawn = "Withdrawn"
}
export enum RequestType {
  ThirdParty = "Third Party",
  OwnManufactured = "Own Manufactured",
}
export interface IProjectRequest extends IBase {
  isChanged?: boolean;
  isProjectMetadataChanged?: boolean;
  requesterId?: number;
  requester?: IUser;
  requestTitle?: string;
  requestNumber?: string;
  description?: string;
  status?: RequestStatus;
  statusComment?: string;
  stateMachineStatus?: IProjectRequestStatus;
  // preKickOffDate?: Date;
  // kickOffDate?: Date;
  companyId?: number;
  company?: ICompany;
  brand?: string;
  division?: string;
  isEShopInvolved?: boolean | null;
  hasPotentialDangerousGoods?: boolean | null;
  hasCourseMaterials?: boolean | null;
  isCamDBUsed?: boolean | null;
  camDBProcessId?: string;
  isLabelingNeeded?: boolean | null;
  isBOMNeeded?: boolean | null;
  requestStep?: string;
  isTranslationNeeded?: boolean;
  auditLogs?: IAuditLog[];
  completed?: Date;
  completedWithCountries?: Date;
  checkList?: ICheckListItem[];
  plmDesignOwner?: string;
  plmChangeNumber?: number;
  logisticFlow?: string;
  distributionType?: string;
  requestType?: RequestType;
  temperatureSensitive?: boolean;
  hasLogisticTask?: boolean;
  requestSubmissionTime?: Date;
  requestReSubmissionTime?: Date;
  attachments?: IAttachment[];
  materials?: IMaterial[];
  dataLocks?: IDataLockInfo[];
  projectTasks?: IProjectTask[];
  translations?: ITranslation[];
  bomItems?: IBOMItem[];

  // used on clientside only
  hasSimpleApprovalTasks?: boolean;
}

export type ProjectRequestFields = keyof IProjectRequest;

export interface IAttachment extends IBase {
  projectRequestId: number;
  data: File;
  fileName: string;
  requestStep: string;
  category: AttachmentCategory;
  contentType: string;
  isDeleted?: boolean;
}

export type AttachmentFields = keyof IAttachment;

export enum AttachmentCategory {
  Attachments = "Attachments",
  ExampleofLabel = "Example of label"
}

export enum RequestTaskType {
  Simple = 1,
  Complex,
  Country
}

export enum RequestTaskStatus {
  Open = "Open",
  Completed = "Completed"
}

export type ProjectRequestTitleType = ProjectRequestTask | string;
export interface IProjectTask extends IBase {
  projectRequestId?: number;
  title?: ProjectRequestTitleType;
  requestStep?: RequestStepType;
  requestTab?: RequestStepTab;
  outcome?: string;
  outcomeObject?: IOutcomeValue;
  assignmentDate?: Date;
  completionDate?: Date;
  completionComment?: string;
  currentAssignmentId?: number;
  currentAssignment?: IProjectTaskAssignment;
  assignmentType?: "Delegation" | "Claim";
  assignees?: IProjectTaskAssignment[];
  taskType: RequestTaskType;
  taskHistory?: IProjectTaskHistory[];
  status: RequestTaskStatus;
  leadTime?: number;
  completedById?: number;
  completedBy?: IUser;
  dueDate?: Date;
  possibleOutcomeKeys: string[];
}

export interface IProjectTaskExpanded extends IProjectTask {
  projectRequestTitle: string;
  companyTitle: string;
  requester: string;
  currentApprovers: string;
}

export interface IProjectTaskAssignment extends IUserGroupAssignment {
  taskId?: number;
}

export interface IProjectTaskHistory {
  user?: string;
  timestamp?: Date;
  event?: string;
}

export type ProjectRequestFieldInfoType = { [key in keyof IProjectRequest]: FieldInfoTyped<IProjectRequest> };
export type TranslationValidationStateType = {
  [field in keyof ITranslation | string]: FieldValidationState | number | string | boolean;
};
export type MaterialValidationStateType = {
  [field in keyof IMaterial | string]: FieldValidationState | number | string | boolean;
};

export type TabValidationState = {
  hasError: boolean;
  projectRequest: {
    [field in keyof IProjectRequest]?: FieldValidationState;
  };
  materials?: MaterialValidationStateType[];
  translations?: {
    [key in string]?: TranslationValidationStateType[];
  };
  bomItems?: {
    [field in keyof IBOMItem | string]: FieldValidationState | number | string | boolean;
  }[];
};

export type ProjectRequestFormValidationState = {
  [step in RequestStepType]?: {
    [tab in RequestStepTab]?: TabValidationState;
  };
};

export interface ITaskOverview {
  taskType: RequestTaskType;
  task: string;
  currentResponsible: Array<IUser | IUserGroup>;
  delegatedResponsible: IUser | IUserGroup;
  responsibles: Array<IUser | IUserGroup>;
  status: string;
  completedOn?: Date;
  taskDescription?: string;
  taskDetails?: IProjectTask;
  isCurrentUserApprover: boolean;
  approvalResult: IApprovalResult;
  approvers: (string | IUser)[];
  currentApprover: ISpeedupApprovalAssignment;
}


export interface ISpeedupApprovalAssignment {
  assignee: IUser | string;
  by: "Delegation" | "Claim";
  on: Date;
}

export interface IProjectRequestComposite {
  submitRequestInfo?: Partial<IProjectRequest>;
  projectRequestInfoStart?: Partial<IProjectRequest>;
  projectRequestInfoPLMData?: Partial<IProjectRequest>;
  projectRequestInfoLogisticsData?: Partial<IProjectRequest>;
  projectRequestInfoCheckList?: Partial<IProjectRequest>;
  attachments?: Partial<IAttachment>[];
  startMaterials?: Partial<IMaterial>[];
  plmDataMaterials?: Partial<IMaterial>[];
  logisticsDataMaterials?: Partial<IMaterial>[];
  packagingAndHandlingMaterials?: Partial<IMaterial>[];
  dangerousGoodsMaterials?: Partial<IMaterial>[];
  customsMaterials?: Partial<IMaterial>[];
  raMaterials?: Partial<IMaterial>[];
  controllingMaterials?: Partial<IMaterial>[];
  radbMaterials?: Partial<IMaterial>[];
  projectTasks?: Partial<IProjectTask>[];
  // dataLocks?: IDataLockInfo[];
  auditLogs?: Partial<IAuditLog>[];
  // bomitems?: Partial<IBOMItem>[];
  startTranslations?: Partial<ITranslation>[];
  productMasterDataTextTranslations?: Partial<ITranslation>[];
}

export enum Entity {
  ProjectRequest = "projectRequest",
  Material = "material",
  Translation = "translation",
  Attachment = "attachment",
  BomItem = "bomItem"
}

export type EntityType = keyof typeof Entity;