import JSONInput from "react-json-editor-ajrm";
const locale: any = require("react-json-editor-ajrm/locale/en");

export const JsonEditor: React.FC<any> = ({ setInputData, fieldname, data }) => {
  const handleChange = (event: any) => {
    console.log(event);
    console.log("JSON:", event.json);
    let jsonString: string;
    if (event.jsObject) {
      jsonString = JSON.stringify(event.jsObject);
    }
    else {
      jsonString = event.plainText;
    }
    setInputData(jsonString, fieldname);
  }

  return (
    <JSONInput id="JsonEditor_ID" placeholder={data} locale={locale} height="500px" width="100%" onChange={handleChange} />
  );
};
