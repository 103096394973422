import { TextConstants } from "constant";
import { FieldInfoTyped, FieldType, FieldValidationState, IAttachment, IUser, IUserGroup, ObjectFieldInfoTyped, ValidationCheckTypes } from "interfaces";
import { CommonService } from "./CommonService";

export class ValidationService {

    public static validateObjectField<T>(object: T, fieldInfo: ObjectFieldInfoTyped<T>[keyof T], checksToSkip?: ValidationCheckTypes[]) {
        const clonedFieldInfo: FieldInfoTyped<T> = { ...fieldInfo };
        if (clonedFieldInfo?.validationRules) {
            clonedFieldInfo.validationRules = { ...fieldInfo.validationRules };

            if (clonedFieldInfo.validationRules.customValidator) {
                clonedFieldInfo.validationRules.customValidator = { ...fieldInfo.validationRules.customValidator };

                clonedFieldInfo.validationRules.customValidator.validatorArguments =
                    clonedFieldInfo.validationRules.customValidator.validatorArguments.map((variable) =>
                        CommonService.convertTokenToFunctionArgument(variable, clonedFieldInfo, object)
                    );
            }

            if (clonedFieldInfo.validationRules.customMinValueGetter) {
                clonedFieldInfo.validationRules.customMinValueGetter = { ...fieldInfo.validationRules.customMinValueGetter };

                clonedFieldInfo.validationRules.customMinValueGetter.getterArguments =
                    clonedFieldInfo.validationRules.customMinValueGetter.getterArguments.map((variable) =>
                        CommonService.convertTokenToFunctionArgument(variable, clonedFieldInfo, object)
                    );
            }

            if (clonedFieldInfo.validationRules.customMaxValueGetter) {
                clonedFieldInfo.validationRules.customMaxValueGetter = { ...fieldInfo.validationRules.customMaxValueGetter };

                clonedFieldInfo.validationRules.customMaxValueGetter.getterArguments =
                    clonedFieldInfo.validationRules.customMaxValueGetter.getterArguments.map((variable) =>
                        CommonService.convertTokenToFunctionArgument(variable, clonedFieldInfo, object)
                    );
            }

            if (clonedFieldInfo.validationRules.customValidatorIsRequired) {
                clonedFieldInfo.validationRules.customValidatorIsRequired = { ...fieldInfo.validationRules.customValidatorIsRequired };

                clonedFieldInfo.validationRules.customValidatorIsRequired.validatorArguments =
                    clonedFieldInfo.validationRules.customValidatorIsRequired.validatorArguments.map((variable) =>
                        CommonService.convertTokenToFunctionArgument(variable, clonedFieldInfo, object)
                    );
            }

            return ValidationService.validateField(clonedFieldInfo, object, checksToSkip);
        }

        return {
            isValid: true,
            error: undefined,
        };
    }

    public static validateField<T>(fieldInfo: FieldInfoTyped<T>, dataObject: any, checksToSkip?: ValidationCheckTypes[]): FieldValidationState {

        switch (fieldInfo.type) {
            case FieldType.Text:
            case FieldType.MultiLine:
                return ValidationService.validateTextField(fieldInfo, dataObject, checksToSkip);

            case FieldType.Int:
                return ValidationService.validateNumericField(fieldInfo, dataObject, true, checksToSkip);

            case FieldType.Decimal:
                return ValidationService.validateNumericField(fieldInfo, dataObject, false, checksToSkip);

            case FieldType.Boolean:
                return ValidationService.validateBooleanField(fieldInfo, dataObject, checksToSkip);

            case FieldType.Date:
                return ValidationService.validateDateOnlyField(fieldInfo, dataObject, checksToSkip);

            case FieldType.DateTime:
                return ValidationService.validateDateTimeField(fieldInfo, dataObject, checksToSkip);

            case FieldType.User:
                return ValidationService.validateUserField(fieldInfo, dataObject, checksToSkip);

            case FieldType.MultiUser:
                return ValidationService.validateMultiUserField(fieldInfo, dataObject, checksToSkip);

            case FieldType.Attachment:
                return ValidationService.validateAttachmentField(fieldInfo, dataObject, checksToSkip);

            case FieldType.MultiAttachment:
                return ValidationService.validateMultiAttachmentField(fieldInfo, dataObject, checksToSkip);

        }

        return {
            isValid: true,
            error: undefined
        }
    }

    public static validateTextField<T>(fieldInfo: FieldInfoTyped<T>, dataObject: any, checksToSkip?: ValidationCheckTypes[]): FieldValidationState {
        const { name, validationRules } = fieldInfo;

        if (!this.checksToSkipHasValue("required", checksToSkip)) {
            let isRequired = validationRules.isRequired;

            if ((isRequired === null || isRequired === undefined) && validationRules.customValidatorIsRequired) {
                isRequired = validationRules.customValidatorIsRequired?.validator(...validationRules.customValidatorIsRequired.validatorArguments);
            }

            if (isRequired && !dataObject[name]) {
                return {
                    isValid: false,
                    error: validationRules?.customValidatorIsRequired?.errorMessage ?? TextConstants.Common.Error_Message_Required
                };
            }
        }

        if (!this.checksToSkipHasValue("maxLength", checksToSkip)) {
            if (validationRules.maxLength && dataObject[name]?.length > validationRules.maxLength) {
                return {
                    isValid: false,
                    error: TextConstants.Common.Error_Message_ExceedsMaxLength.replace("{maxLength}", String(validationRules.maxLength))
                };
            }
        }

        if (!this.checksToSkipHasValue("formatValidator", checksToSkip)) {
            if (dataObject[name] && validationRules.formatValidator) {
                if (
                    (validationRules.formatValidator instanceof RegExp && !validationRules.formatValidator.test(dataObject[name])) ||
                    (typeof validationRules.formatValidator === "function" && !validationRules.formatValidator(dataObject[name]))
                ) {
                    return {
                        isValid: false,
                        error: validationRules.formatValidationErrorMessage
                    };
                }
            }
        }

        if (!this.checksToSkipHasValue("custom", checksToSkip)) {
            if (dataObject[name] && validationRules.customValidator && !validationRules.customValidator.validator(...validationRules.customValidator.validatorArguments)) {
                return {
                    isValid: false,
                    error: validationRules.customValidator.errorMessage
                };
            }
        }

        return {
            isValid: true,
            error: undefined
        }
    }

    public static validateNumericField<T>(fieldInfo: FieldInfoTyped<T>, dataObject: any, isIntegerCheck: boolean, checksToSkip?: ValidationCheckTypes[]): FieldValidationState {
        const { name, validationRules } = fieldInfo;
        const isFieldNull = dataObject[name] === null || dataObject[name] === undefined;

        if (!this.checksToSkipHasValue("required", checksToSkip)) {
            let isRequired = validationRules.isRequired;

            if ((isRequired === null || isRequired === undefined) && validationRules.customValidatorIsRequired) {
                isRequired = validationRules.customValidatorIsRequired?.validator(...validationRules.customValidatorIsRequired.validatorArguments);
            }

            if (isRequired && isFieldNull) {
                return {
                    isValid: false,
                    error: validationRules?.customValidatorIsRequired?.errorMessage ?? TextConstants.Common.Error_Message_Required
                };
            }
        }

        const validationFunction = isIntegerCheck ? ValidationService.isValidIntergerField : ValidationService.isValidNumberField;
        if (!validationFunction(dataObject[name])) {
            return {
                isValid: false,
                error: isIntegerCheck ? TextConstants.Common.Error_Message_InvalidInteger : TextConstants.Common.Error_Message_InvalidNumber
            };
        }

        if (!isFieldNull) {
            if (!this.checksToSkipHasValue("minValue", checksToSkip)) {
                if (!!validationRules.minValue && dataObject[name] < validationRules.minValue) {
                    return {
                        isValid: false,
                        error: TextConstants.Common.Error_Message_LessThanMinValue.replace("{minValue}", String(validationRules.minValue))
                    };
                }

                if (!!validationRules.customMinValueGetter) {
                    const minValue = validationRules.customMinValueGetter.getter(...validationRules.customMinValueGetter.getterArguments);

                    if (dataObject[name] < minValue) {
                        return {
                            isValid: false,
                            error: TextConstants.Common.Error_Message_LessThanMinValue.replace("{minValue}", String(minValue))
                        };
                    }
                }
            }

            if (!this.checksToSkipHasValue("maxValue", checksToSkip)) {
                if (!!validationRules.maxValue && dataObject[name] > validationRules.maxValue) {
                    return {
                        isValid: false,
                        error: TextConstants.Common.Error_Message_ExceedsMaxValue.replace("{maxValue}", String(validationRules.maxValue))
                    };
                }

                if (!!validationRules.customMaxValueGetter) {
                    const maxValue = validationRules.customMaxValueGetter.getter(...validationRules.customMaxValueGetter.getterArguments);

                    if (dataObject[name] > maxValue) {
                        return {
                            isValid: false,
                            error: TextConstants.Common.Error_Message_ExceedsMaxValue.replace("{maxValue}", String(maxValue))
                        };
                    }
                }
            }

            if (!this.checksToSkipHasValue("custom", checksToSkip)) {
                if (!!validationRules.customValidator && !validationRules.customValidator.validator(...validationRules.customValidator.validatorArguments)) {
                    return {
                        isValid: false,
                        error: validationRules.customValidator.errorMessage
                    };
                }
            }
        }


        return {
            isValid: true,
            error: undefined
        }
    }

    public static validateBooleanField<T>(fieldInfo: FieldInfoTyped<T>, dataObject: any, checksToSkip?: ValidationCheckTypes[]): FieldValidationState {
        const { name, validationRules } = fieldInfo;
        const isFieldNull = dataObject[name] === null || dataObject[name] === undefined;

        if (!this.checksToSkipHasValue("required", checksToSkip)) {
            let isRequired = validationRules.isRequired;

            if ((isRequired === null || isRequired === undefined) && validationRules.customValidatorIsRequired) {
                isRequired = validationRules.customValidatorIsRequired?.validator(...validationRules.customValidatorIsRequired.validatorArguments);
            }

            if (isRequired && isFieldNull) {
                return {
                    isValid: false,
                    error: validationRules?.customValidatorIsRequired?.errorMessage ?? TextConstants.Common.Error_Message_Required
                };
            }
        }

        return {
            isValid: true,
            error: undefined
        }
    }

    public static validateDateOnlyField<T>(fieldInfo: FieldInfoTyped<T>, dataObject: any, checksToSkip?: ValidationCheckTypes[]): FieldValidationState {
        const { name } = fieldInfo;
        const isFieldNull = dataObject[name] === null || dataObject[name] === undefined;
        const newDate: Date = !isFieldNull ? dataObject[name] as Date : null;

        if (!isFieldNull) {
            newDate.setHours(0, 0, 0, 0);
        }

        return ValidationService.validateDateField(fieldInfo, isFieldNull, newDate, checksToSkip);
    }

    public static validateDateTimeField<T>(fieldInfo: FieldInfoTyped<T>, dataObject: any, checksToSkip?: ValidationCheckTypes[]): FieldValidationState {
        const { name } = fieldInfo;
        const isFieldNull = dataObject[name] === null || dataObject[name] === undefined;
        const newDate: Date = !isFieldNull ? dataObject[name] as Date : null;

        return ValidationService.validateDateField(fieldInfo, isFieldNull, newDate, checksToSkip);
    }

    public static validateDateField<T>(fieldInfo: FieldInfoTyped<T>, isFieldNull: boolean, newDate: Date, checksToSkip?: ValidationCheckTypes[]): FieldValidationState {
        const { validationRules } = fieldInfo;

        if (!this.checksToSkipHasValue("required", checksToSkip)) {
            let isRequired = validationRules.isRequired;

            if ((isRequired === null || isRequired === undefined) && validationRules.customValidatorIsRequired) {
                isRequired = validationRules.customValidatorIsRequired?.validator(...validationRules.customValidatorIsRequired.validatorArguments);
            }

            if (isRequired && isFieldNull) {
                return {
                    isValid: false,
                    error: validationRules?.customValidatorIsRequired?.errorMessage ?? TextConstants.Common.Error_Message_Required
                };
            }
        }

        if (!this.checksToSkipHasValue("minValue", checksToSkip)) {
            if (!!validationRules.minValue && !isFieldNull && newDate.getTime() < (validationRules.minValue as Date).getTime()) {
                return {
                    isValid: false,
                    error: TextConstants.Common.Error_Message_LessThanMinValue.replace("{minValue}", String(validationRules.minValue))
                };
            }

            if (!!validationRules.customMinValueGetter && !isFieldNull) {
                const minValue = validationRules.customMinValueGetter.getter(...validationRules.customMinValueGetter.getterArguments) as Date;

                if (newDate.getTime() < minValue.getTime()) {
                    return {
                        isValid: false,
                        error: TextConstants.Common.Error_Message_LessThanMinValue.replace("{minValue}", String(minValue))
                    };
                }
            }
        }

        if (!this.checksToSkipHasValue("maxValue", checksToSkip)) {
            if (!!validationRules.maxValue && !isFieldNull && newDate.getTime() > (validationRules.maxValue as Date).getTime()) {
                return {
                    isValid: false,
                    error: TextConstants.Common.Error_Message_ExceedsMaxValue.replace("{maxValue}", String(validationRules.maxValue))
                };
            }

            if (!!validationRules.customMaxValueGetter && !isFieldNull) {
                const maxValue = validationRules.customMaxValueGetter.getter(...validationRules.customMaxValueGetter.getterArguments) as Date;

                if (newDate.getTime() > maxValue.getTime()) {
                    return {
                        isValid: false,
                        error: TextConstants.Common.Error_Message_ExceedsMaxValue.replace("{maxValue}", String(maxValue))
                    };
                }
            }
        }

        if (!this.checksToSkipHasValue("custom", checksToSkip)) {
            if (!isFieldNull && !!validationRules.customValidator && !validationRules.customValidator.validator(...validationRules.customValidator.validatorArguments)) {
                return {
                    isValid: false,
                    error: validationRules.customValidator.errorMessage
                };
            }
        }

        return {
            isValid: true,
            error: undefined
        }
    }

    public static validateUserField<T>(fieldInfo: FieldInfoTyped<T>, dataObject: any, checksToSkip?: ValidationCheckTypes[]): FieldValidationState {
        const { name, validationRules } = fieldInfo;
        const isFieldNull = dataObject[name] === null || dataObject[name] === undefined;

        if (!this.checksToSkipHasValue("required", checksToSkip)) {
            let isRequired = validationRules.isRequired;

            if ((isRequired === null || isRequired === undefined) && validationRules.customValidatorIsRequired) {
                isRequired = validationRules.customValidatorIsRequired?.validator(...validationRules.customValidatorIsRequired.validatorArguments);
            }

            if (isRequired && isFieldNull) {
                return {
                    isValid: false,
                    error: validationRules?.customValidatorIsRequired?.errorMessage ?? TextConstants.Common.Error_Message_Required
                };
            }

        }

        if (!isFieldNull && !ValidationService.isValidUserField(dataObject[name])) {
            return {
                isValid: false,
                error: TextConstants.Common.Error_Message_UserNoValid
            };
        }

        return {
            isValid: true,
            error: undefined
        }
    }

    public static validateMultiUserField<T>(fieldInfo: FieldInfoTyped<T>, dataObject: any, checksToSkip?: ValidationCheckTypes[]): FieldValidationState {
        const { name, validationRules } = fieldInfo;
        const isFieldNull = dataObject[name] === null || dataObject[name] === undefined;

        if (!this.checksToSkipHasValue("required", checksToSkip)) {
            let isRequired = validationRules.isRequired;

            if ((isRequired === null || isRequired === undefined) && validationRules.customValidatorIsRequired) {
                isRequired = validationRules.customValidatorIsRequired?.validator(...validationRules.customValidatorIsRequired.validatorArguments);
            }

            if (isRequired && isFieldNull) {
                return {
                    isValid: false,
                    error: validationRules?.customValidatorIsRequired?.errorMessage ?? TextConstants.Common.Error_Message_Required
                };
            }
        }

        if (!isFieldNull && !ValidationService.isValidMultiUserField(dataObject[name])) {
            return {
                isValid: false,
                error: TextConstants.Common.Error_Message_UserNoValid
            };
        }

        return {
            isValid: true,
            error: undefined
        }
    }

    public static validateAttachmentField<T>(fieldInfo: FieldInfoTyped<T>, dataObject: any, checksToSkip?: ValidationCheckTypes[]): FieldValidationState {
        const { name, validationRules } = fieldInfo;
        const isFieldNull = dataObject[name] === null || dataObject[name] === undefined;

        if (!this.checksToSkipHasValue("required", checksToSkip)) {
            let isRequired = validationRules.isRequired;

            if ((isRequired === null || isRequired === undefined) && validationRules.customValidatorIsRequired) {
                isRequired = validationRules.customValidatorIsRequired?.validator(...validationRules.customValidatorIsRequired.validatorArguments);
            }

            if (isRequired && isFieldNull) {
                return {
                    isValid: false,
                    error: validationRules?.customValidatorIsRequired?.errorMessage ?? TextConstants.Common.Error_Message_Required
                };
            }

        }

        if (!isFieldNull && !this.isValidAttachment(dataObject[name])) {
            return {
                isValid: false,
                error: TextConstants.Common.Error_Message_FileNotValid
            };
        }

        return {
            isValid: true,
            error: undefined
        }
    }

    public static validateMultiAttachmentField<T>(fieldInfo: FieldInfoTyped<T>, dataObject: any, checksToSkip?: ValidationCheckTypes[]): FieldValidationState {
        const { name, validationRules } = fieldInfo;
        const isFieldNull = dataObject[name] === null || dataObject[name] === undefined;

        if (!this.checksToSkipHasValue("required", checksToSkip)) {
            if (validationRules.isRequired && isFieldNull) {
                return {
                    isValid: false,
                    error: TextConstants.Common.Error_Message_Required
                };
            }
        }

        if (!isFieldNull && !this.isValidMultiAttachmentField(dataObject[name])) {
            return {
                isValid: false,
                error: TextConstants.Common.Error_Message_FileNotValid
            };
        }

        return {
            isValid: true,
            error: undefined
        }
    }

    public static isValidMultiAttachmentField(files: IAttachment[]) {
        if (files?.length) {
            for (const file of files) {
                if (!ValidationService.isValidAttachment(file)) {
                    return false;
                }
            }
        }

        return true;
    }

    public static isValidAttachment(file: IAttachment) {
        return !file?.data ? false : true;
    }

    public static isValidMultiUserField(persons: IUser[]) {
        if (persons.length) {
            for (const person of persons) {
                if (!ValidationService.isValidUserField(person)) {
                    return false;
                }
            }

            return true;
        }
        else {
            return true;
        }
    }

    public static isValidUserField(person: IUser | IUserGroup) {
        if ("name" in person) {
            if (!person.name) {
                return false;
            }
        }
        else {
            if (!person?.id && !person?.email) {
                return false;
            }
        }

        return true;
    }

    public static isValidNumberField(number: any) {
        if (number === null || number === undefined || number === "") {
            return true;
        }
        else {
            if (isNaN(Number(number))) {
                return false;
            }
            else {
                return true;
            }
        }
    }

    public static isValidIntergerField(number: any) {
        if (number !== null && number !== undefined && number !== "") {

            if (isNaN(Number(number))) {
                return false;
            }
            else {
                if (parseInt(number, 10) === Number(number)) {
                    return true;
                }
                else {
                    return false;
                }
            }
        }
        else {
            return true;
        }
    }

    public static validateFromMinDate(dateToChecked: Date, minDate: Date, allowMinDate: boolean) {
        if (minDate) {
            const dateToCheckedNew = new Date(dateToChecked.toString());
            dateToCheckedNew.setHours(0, 0, 0, 0);
            const minDateNew = new Date(minDate.toString());
            minDateNew.setHours(0, 0, 0, 0);

            if (allowMinDate) {
                if (dateToCheckedNew.getTime() < minDateNew.getTime()) {
                    return false;
                }
                else {
                    return true;
                }
            }
            else {
                if (dateToCheckedNew.getTime() <= minDateNew.getTime()) {
                    return false;
                }
                else {
                    return true;
                }
            }
        }

        return true;
    }

    public static validateFromMaxDate(dateToChecked: Date, maxDate: Date, allowMaxDate: boolean) {
        const dateToCheckedNew = new Date(dateToChecked.toString());
        dateToCheckedNew.setHours(0, 0, 0, 0);
        const maxDateNew = new Date(maxDate.toString());
        maxDateNew.setHours(0, 0, 0, 0);

        if (allowMaxDate) {
            if (dateToCheckedNew.getTime() > maxDateNew.getTime()) {
                return false;
            }
            else {
                return true;
            }
        }
        else {
            if (dateToCheckedNew.getTime() >= maxDateNew.getTime()) {
                return false;
            }
            else {
                return true;
            }
        }

    }

    public static validateFromMaxValue(valueToCheck: number, maxValue: number) {
        if (valueToCheck > maxValue) {
            return false;
        }
        else {
            return true;
        }
    }

    private static checksToSkipHasValue(check: ValidationCheckTypes, checksToSkip: ValidationCheckTypes[]) {
        return checksToSkip?.length ? checksToSkip.indexOf(check) >= 0 : false;
    }
}
