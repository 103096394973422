import { ProjectRequestService } from "components/request/services/ProjectRequestService";
import { IDataLockInfo, DataLockSectionType } from "interfaces";
import { CommonService, HttpService } from "services";

export abstract class DataLockService {
  private static BASE_ENDPOINT = "/api/DataLock";
  private static LOCK_ENDPOINT = `${DataLockService.BASE_ENDPOINT}/lockData`;
  private static UNLOCK_ENDPOINT = `${DataLockService.BASE_ENDPOINT}/unlockData`;

  public static lockAcquisitionsInProgressBySection: { [key in DataLockSectionType]?: boolean } = {};
  public static lockUpdateIntervalTimeoutReference: { [key in DataLockSectionType]?: number } = {};

  // public static lockupdateIntervalReference: any = null;

  public static clearAllLockingTimeouts() {
    if (DataLockService.lockUpdateIntervalTimeoutReference) {
      for (const key in DataLockService.lockUpdateIntervalTimeoutReference) {
        window.clearTimeout(DataLockService.lockUpdateIntervalTimeoutReference[key as keyof typeof DataLockService.lockUpdateIntervalTimeoutReference]);
      }
    }
  }

  public static async lockData(lockInfo: IDataLockInfo): Promise<IDataLockInfo> {
    delete lockInfo.lockedBy;
    const updatedDataLockInfo: IDataLockInfo = await HttpService.post(`${DataLockService.LOCK_ENDPOINT}`, lockInfo);
    return CommonService.fixTimeStampColumnOfObject<IDataLockInfo>(updatedDataLockInfo, ProjectRequestService.DataLockFieldInfo);
  }

  public static unlockData(lockInfo: IDataLockInfo): Promise<boolean> {
    return HttpService.post(`${DataLockService.UNLOCK_ENDPOINT}`, lockInfo).then((result) => {
      console.log(`%cUnlock Response`, "color:purple", result);
      return true;
    });
  }

  public static getAllLocksForRequest(requestId: number): Promise<IDataLockInfo[]> {
    return HttpService.get(`${DataLockService.BASE_ENDPOINT}/GetAllLocksForRequest/${requestId}`);
  }
}

