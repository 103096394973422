import { Dropdown, IDropdownProps, ITextFieldProps, TextField } from "@fluentui/react";
import { EntityNameType, FieldInfoTyped, ObjectFieldInfoType } from "interfaces";
import { PropsWithChildren } from "react";

export interface IDropDownWithTextFieldProps<T> extends IDropdownProps {
    showAsTextField?: boolean;
    isYesNoDropdown?: boolean;
    fieldName: string;
    getValueFromDropdownOptions?: boolean;
    fieldInfo?: FieldInfoTyped<T>;
    tooltipData?: ObjectFieldInfoType;
    entityName?: EntityNameType;
    onRenderLabelTextField?: (fieldName: string) => <T extends ITextFieldProps | IDropdownProps, >(props?: T, defaultRender?: (props?: T) => JSX.Element | null) => JSX.Element | null;
    onRenderLabelTextFieldWithLink?: <T, >(fieldInfo: FieldInfoTyped<T>, tooltipData: ObjectFieldInfoType, entityName: EntityNameType) => <T extends ITextFieldProps | IDropdownProps, >(props?: T, defaultRender?: (props?: T) => JSX.Element | null) => JSX.Element | null;
}

export const DropDownWithTextField = <T,>(props: PropsWithChildren<IDropDownWithTextFieldProps<T>>) => {
    const {
        className, disabled, label, showAsTextField, selectedKeys, selectedKey, isYesNoDropdown, defaultSelectedKeys,
        defaultSelectedKey, fieldInfo, tooltipData, entityName, getValueFromDropdownOptions,
        onRenderLabelTextFieldWithLink, onRenderLabelTextField
    } = props;

    if (showAsTextField) {
        let textValue: string = "";

        if (selectedKeys?.length) {
            textValue = props.selectedKeys.join(", ");
        }
        else if (selectedKey) {
            if (isYesNoDropdown) {
                if (selectedKey === "true") {
                    textValue = "Yes";
                } else if (selectedKey === "false") {
                    textValue = "No";
                }
            }
            else {
                textValue = String(selectedKey);
            }

        }
        else if (defaultSelectedKeys?.length) {
            textValue = props.defaultSelectedKeys.join(", ");
        }
        else if (defaultSelectedKey) {
            textValue = String(props.defaultSelectedKey);
        }

        if (getValueFromDropdownOptions && props.options?.length) {
            textValue = props.options?.find(x => String(x.key) === textValue)?.text ?? "";
        }


        return (
            <TextField
                className={className}
                readOnly={disabled}
                disabled={disabled}
                label={label}
                value={textValue}
                onRenderLabel={onRenderLabelTextFieldWithLink ?
                    onRenderLabelTextFieldWithLink(fieldInfo, tooltipData, entityName) :
                    onRenderLabelTextField(props.fieldName)
                }
            />
        );
    }

    return <Dropdown {...props} />;
}