import { IBase } from "interfaces";

export enum GlobalSettingKey {
    CountryTaskDescription = "Country Task Description",
    ShowNewRequestButtonToGroups = "Show New Request Button to Groups",
    SaveChangesSetIntervalTime= "Save Changes SetInterval Time"
}

export interface IGlobalSetting extends IBase {
    key: GlobalSettingKey;
    value: string;
}

export type GlobalSetting = { [key in GlobalSettingKey]?: string };