import { createSlice } from "@reduxjs/toolkit";
import { IHelperText } from "interfaces/IHelperText";

const initialStateVal = [] as IHelperText[];

export const helpPageSlice = createSlice({
  name: "helperData",
  initialState: {
    value: initialStateVal,
  },
  reducers: {
    getHelperData: (state, action) => {
      state.value = action.payload;
    },
    addupdateHelperDataItem: (state, action) => {
      let currentHelperData = [...state.value];
      let index = currentHelperData.findIndex((x) => x.id === action.payload.id);
      if (index !== -1) {
        currentHelperData[index].text = action.payload.text;
        state.value = currentHelperData;
      } else {
        state.value = [...currentHelperData, action.payload];
      }
    },
    deleteHelperData: (state, action) => {
      let currentHelperData = [...state.value];
      let index = currentHelperData.findIndex((x) => x.id === action.payload);
      currentHelperData.splice(index, 1);
      state.value = currentHelperData;
    },
    deleteMultipleHelperData: (state, action) => {
      let currentHelperData = [...state.value];
      currentHelperData = currentHelperData.filter((value) => !action.payload.includes(value.id));
      state.value = currentHelperData;
    },
  },
});

export const { getHelperData, addupdateHelperDataItem, deleteHelperData, deleteMultipleHelperData } = helpPageSlice.actions;

export const helpPageReducer = helpPageSlice.reducer;
