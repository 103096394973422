import { IDropdownOption } from "@fluentui/react";
import { ColDef } from "ag-grid-community";
import {
    ICountryTask, ILabelsAndTooltip, ICheckListItem, ISimpleApprovalTask, IRequestEditPermissionsMatrix, ILogisticRef,
    ILanguageCode, IPMSAPCodeMapping, ILegalManufacturer, IGlobalSetting, GlobalSetting, HelpDocumentLinks, IProductCategoryMapping,ILabOffice
} from "interfaces";

export type KeyValue<T> = {
    [key: string]: T;
};

export interface IValidationRule {
    isRequired?: boolean;
    customValidatorIsRequired?: {
        validator: (...args: any) => boolean;
        validatorArguments: any[];
        errorMessage?: string;
    },
    formatValidator?: RegExp | ((value: any) => boolean);
    formatValidationErrorMessage?: string;
    minLength?: number;
    maxLength?: number;
    minValue?: number | Date;
    customMinValueGetter?: {
        getter: ((...args: any) => number) | ((...args: any) => Date);
        getterArguments: any[];
    },
    maxValue?: number | Date;
    customMaxValueGetter?: {
        getter: ((...args: any) => number) | ((...args: any) => Date);
        getterArguments: any[];
    },
    customValidator?: {
        validator: (...args: any) => boolean;
        validatorArguments: any[];
        errorMessage: string;
    }
}

export type ValidationCheckTypes = "required" | "minLength" | "maxLength" | "minValue" | "maxValue" | "custom" | "formatValidator";

export enum FieldType {
    Text,
    MultiLine,
    Int,
    Decimal,
    User,
    MultiUser,
    Boolean,
    Date,
    DateTime,
    Attachment,
    MultiAttachment,
    LookUp,
    Custom,
    CustomArray,
    Group,
    MultiGroup,
    File
}

export type FieldInfo = {
    name: string;
    type: FieldType;
    label: string;
    validationRules?: IValidationRule;
    lookupField?: string;
    fieldInfo?: ObjectFieldInfo;
}

export type FieldInfoTyped<T> = {
    name: keyof T;
    type: FieldType;
    label: string;
    validationRules?: IValidationRule;
    lookupField?: string;
    fieldInfo?: ObjectFieldInfo;
    documentLinkKey?: string;
}

export type ObjectFieldInfoTyped<T> = { [key in keyof T]: FieldInfoTyped<T> };
export type ObjectFieldInfo = { [key: string]: FieldInfo };

export declare type FieldValidationState = {
    isValid: boolean;
    error: string;
}

export type ObjectFieldValidationState<T> = {
    [field in keyof T]: FieldValidationState
}

export type DropdownMasterDataType = {
    [key: string]: Array<string | IDropdownOption | any> | GlobalSetting;
    labelAndTooltipData: ILabelsAndTooltip[];
    checkListItems: ICheckListItem[];
    simpleApprovalTasks: ISimpleApprovalTask[];
    countryTasks: ICountryTask[];
    editPermissions: IRequestEditPermissionsMatrix[];
    logisticRefs: ILogisticRef[];
    pmSAPCodeMappings: IPMSAPCodeMapping[];
    legalManufacturer: ILegalManufacturer[];
    globalSettings: GlobalSetting;
    helpDocuments: HelpDocumentLinks;
    productCategoryMappings: IProductCategoryMapping[];
    labOfficeListData:ILabOffice[];
}

export type CustomColDef<T> = ColDef<T> & {
    isRequired: boolean,
    isDropDown?: boolean;
    isYesNoDropDown?: boolean;
    isStringNumberColumn?: boolean;
}
