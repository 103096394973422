import { BaseFieldInfo, TextConstants } from "constant";
import {
    FieldType, IAddUsersToGroupResult, IUser, IUserGroup, IUserGroupMembership, UserGroupFieldInfoType,
    UserGroupMembershipFieldInfoType
} from "interfaces";
import { CommonService, HttpService } from "services";

export class GroupService {
    public static UserGroupFieldInfo: UserGroupFieldInfoType = {
        ...BaseFieldInfo,
        name: {
            name: "name",
            type: FieldType.Text,
            label: TextConstants.ManageGroupForm.Label_GroupName
        },
        isAdminGroup: {
            name: "isAdminGroup",
            type: FieldType.Text,
            label: "Is admin group"
        },
        users: {
            name: "users",
            type: FieldType.LookUp,
            label: "Users"
        },
        groupMemberships: {
            name: "groupMemberships",
            type: FieldType.LookUp,
            label: "Group Memberships"
        }
    }

    public static UserGroupMembershipFieldInfo: UserGroupMembershipFieldInfoType = {
        ...BaseFieldInfo,
        userId: {
            name: "userId",
            type: FieldType.Int,
            label: "User Id"
        },
        groupId: {
            name: "groupId",
            type: FieldType.Int,
            label: "Group Id"
        },
        user: {
            name: "user",
            type: FieldType.LookUp,
            label: "User"
        },
        group: {
            name: "group",
            type: FieldType.LookUp,
            label: "Group"
        }
    }

    private static BASE_ENDPOINT = "/api/Groups";
    private static GET_ALL_USERS_OF_A_GROUP = `${GroupService.BASE_ENDPOINT}/GetAllUsersOfAGroup`;
    private static ADD_USERS_TO_A_GROUP = `${GroupService.BASE_ENDPOINT}/AddUsersToAGroup`;
    private static BASE_ENDPOINT_GROUP_MEMBERSHIPS = `${GroupService.BASE_ENDPOINT}/GroupMemberships`;
    private static BASE_ENDPOINT_GROUPS_OF_USERS = `${GroupService.BASE_ENDPOINT}/GetUserGroups`;

    public static async getAllGroups(): Promise<IUserGroup[]> {
        const userGroups: IUserGroup[] = await HttpService.get(GroupService.BASE_ENDPOINT);
        userGroups.forEach(userGroup => {
            userGroup = CommonService.fixTimeStampColumnOfObject(userGroup, GroupService.UserGroupFieldInfo);
        });

        return userGroups;
    }

    //Get users groups by email
    public static async getUserGroups(email:string): Promise<IUserGroup[]> {
        const userGroups: IUserGroup[] = await HttpService.get(`${GroupService.BASE_ENDPOINT_GROUPS_OF_USERS}/${email}`);
        userGroups.forEach(userGroup => {
            userGroup = CommonService.fixTimeStampColumnOfObject(userGroup, GroupService.UserGroupFieldInfo);
        });

        return userGroups;
    }

    public static getAllUsersOfAGroup(group: string): Promise<IUser[]> {
        return HttpService.get(`${GroupService.GET_ALL_USERS_OF_A_GROUP}/${group}`);
    }

    public static async createGroup(userGroup: IUserGroup): Promise<IUserGroup> {
        userGroup = await HttpService.post(GroupService.BASE_ENDPOINT, userGroup);
        return CommonService.fixTimeStampColumnOfObject(userGroup, this.UserGroupFieldInfo);
    }

    public static async updateGroup(userGroup: IUserGroup): Promise<boolean> {
        const updateRequest = { ...userGroup };
        delete updateRequest.groupMemberships;
        return await HttpService.put(GroupService.BASE_ENDPOINT, updateRequest);
    }

    public static deleteGroup(userGroupId: number): Promise<boolean> {
        return HttpService.delete(`${GroupService.BASE_ENDPOINT}/${userGroupId}`);
    }

    public static deleteGroups(userGroupIds: number[]): Promise<boolean> {
        return HttpService.delete(`${GroupService.BASE_ENDPOINT}`, userGroupIds);
    }

    public static async getGroupMembership(groupId: number): Promise<IUserGroupMembership[]> {
        const userGroupMemberships: IUserGroupMembership[] = await HttpService.get(`${GroupService.BASE_ENDPOINT_GROUP_MEMBERSHIPS}/${groupId}`);

        userGroupMemberships.forEach(userGroupMembership => {
            userGroupMembership = CommonService.fixTimeStampColumnOfObject(userGroupMembership, GroupService.UserGroupMembershipFieldInfo);
        });

        return userGroupMemberships;
    }

    public static async addUsersToAGroup(groupId: number, users: IUser[]): Promise<IAddUsersToGroupResult> {
        const request = {
            groupId: groupId,
            users: users
        };

        const addUsersToGroupResult: IAddUsersToGroupResult = await HttpService.post(`${GroupService.ADD_USERS_TO_A_GROUP}`, request);

        addUsersToGroupResult.userGroupMemberships?.forEach(userGroupMembership => {
            userGroupMembership = CommonService.fixTimeStampColumnOfObject(userGroupMembership, GroupService.UserGroupMembershipFieldInfo);
        });

        return addUsersToGroupResult;
    }

    public static async addUserToAGroup(groupMembership: IUserGroupMembership): Promise<IUserGroupMembership> {
        const userGroupMembership: IUserGroupMembership = await HttpService.post(`${GroupService.BASE_ENDPOINT_GROUP_MEMBERSHIPS}`, groupMembership);
        return CommonService.fixTimeStampColumnOfObject(userGroupMembership, GroupService.UserGroupMembershipFieldInfo);
    }

    public static removeGroupMembership(id: number): Promise<boolean> {
        return HttpService.delete(`${GroupService.BASE_ENDPOINT_GROUP_MEMBERSHIPS}/${id}`);
    }

    // public static removeGroupMembership(groupMembership: IUserGroupMembership): Promise<boolean> {
    //     return HttpService.delete(`${GroupService.BASE_ENDPOINT_GROUP_MEMBERSHIPS}`, groupMembership);
    // }

    public static removeGroupMemberships(groupMembershipIds: number[]): Promise<boolean> {
        return HttpService.delete(`${GroupService.BASE_ENDPOINT_GROUP_MEMBERSHIPS}`, groupMembershipIds);
    }
}