import { useDispatch } from "react-redux";
import { HttpService } from "services";
import { deleteFAQData, deleteMultipleFAQData, getFAQData, updateFAQData } from "./faqSlice";
import { IFAQList } from "interfaces";
import { showLoadingSpinner, hideLoadingSpinner, showErrorMessage, hideErrorMessage } from "components";
import { ErrorRegions } from "constant";

export default class FAQService {
  public apiEndpointFAQ = `/api/FAQ`;
  dispatch = useDispatch();

  public async getFAQ(): Promise<IFAQList[]> {
    this.dispatch(showLoadingSpinner());
    let order: IFAQList[] = [];
    try {
      order = await HttpService.get(`${this.apiEndpointFAQ}`);
      this.dispatch(getFAQData(order));
      this.dispatch(hideLoadingSpinner());
    } catch (error) {
      this.dispatch(hideLoadingSpinner());
      this.dispatch(showErrorMessage({ error, errorOriginationKey: ErrorRegions.FAQ }));
      setTimeout(() => {
        this.dispatch(hideErrorMessage(ErrorRegions.FAQ));
      }, 5000);
    }
    return order;
  }

  public async createFAQ(newfaq: IFAQList) {
    this.dispatch(showLoadingSpinner());
    try {
      const response = await HttpService.post(`${this.apiEndpointFAQ}`, newfaq);
      this.dispatch(updateFAQData(response));
      this.dispatch(hideLoadingSpinner());
    } catch (error) {
      this.dispatch(hideLoadingSpinner());
      this.dispatch(showErrorMessage({ error, errorOriginationKey: ErrorRegions.FAQ }));
      setTimeout(() => {
        this.dispatch(hideErrorMessage(ErrorRegions.FAQ));
      }, 5000);
    }
  }

  public async updateFAQ(newfaq: IFAQList): Promise<any> {
    this.dispatch(showLoadingSpinner());
    try {
      await HttpService.put(`${this.apiEndpointFAQ}`, newfaq);
      this.dispatch(updateFAQData(newfaq));
      this.dispatch(hideLoadingSpinner());
    } catch (error) {
      this.dispatch(hideLoadingSpinner());
      this.dispatch(showErrorMessage({ error, errorOriginationKey: ErrorRegions.FAQ }));
      setTimeout(() => {
        this.dispatch(hideErrorMessage(ErrorRegions.FAQ));
      }, 5000);
    }
  }

  public async deleteFAQ(id: number) {
    this.dispatch(showLoadingSpinner());
    try {
      await HttpService.delete(`${this.apiEndpointFAQ}/${id}`);
      this.dispatch(deleteFAQData(id));
      this.dispatch(hideLoadingSpinner());
    } catch (error) {
      this.dispatch(hideLoadingSpinner());
      this.dispatch(showErrorMessage({ error, errorOriginationKey: ErrorRegions.FAQ }));
      setTimeout(() => {
        this.dispatch(hideErrorMessage(ErrorRegions.FAQ));
      }, 5000);
    }
  }

  public async deleteMultipleFAQ(ids: number[]) {
    this.dispatch(showLoadingSpinner());
    try {
      console.log(`${this.apiEndpointFAQ}`);
      await HttpService.delete(`${this.apiEndpointFAQ}`, ids);
      this.dispatch(deleteMultipleFAQData(ids));
      this.dispatch(hideLoadingSpinner());
    } catch (error) {
      this.dispatch(hideLoadingSpinner());
      this.dispatch(showErrorMessage({ error, errorOriginationKey: ErrorRegions.FAQ }));
      setTimeout(() => {
        this.dispatch(hideErrorMessage(ErrorRegions.FAQ));
      }, 5000);
    }
  }
}
