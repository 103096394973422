export interface IUser {
  id?: number;
  objectId?: string; // GUID
  isStraumannUser?: boolean;
  givenName?: string;
  lastName?: string;
  displayName?: string;
  userName?: string;
  email?: string;
  groups?: string[];
  isLocalUser?: boolean;
  isAdmin?: boolean | null;
  isDeveloper?: boolean | null;
  isProcessAdmin?: boolean | null;
  canUserCreateRequest?: boolean | null;
}

export enum AuthenticationSource {
  LocalAccountAuthentication = "localAccountAuthentication",
  SocialIdpAuthentication = "socialIdpAuthentication",
}
