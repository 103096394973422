import { lazy, Suspense, useEffect, useRef, useState } from "react";
import { Routes, Route, useLocation, useNavigate, Navigate, matchPath, PathMatch } from "react-router-dom";
import {
  showErrorMessage, setCurrentUser, hideErrorMessage, Layout, GroupList, UserInvitation, ColorSchemeMatrix, ManageHelpDocument, 
  MetadataService, PageNotFound, HelpPagePreview
} from "components";
import cloneDeep from "lodash.clonedeep";
import styles from "./App.module.scss";
import { AuthenticatedTemplate, MsalProvider, useIsAuthenticated, useMsal, useMsalAuthentication } from "@azure/msal-react";
import {
  AuthenticationResult, AuthError, BrowserAuthError, EventMessage, EventType, InteractionRequiredAuthError, InteractionStatus,
  InteractionType, PublicClientApplication, RedirectRequest, SilentRequest
} from "@azure/msal-browser";
import { loginRequest } from "./authConfig/AuthConfig";
import { Stack } from "@fluentui/react/lib/Stack";
import { GlobalSetting, IGlobalSetting, IUser,GlobalSettingKey } from "./interfaces";
import { CommonService } from "./services";
import { useQuery } from "hooks";
import { ApplicationRoutes, ErrorRegions } from "constant";
import { useDispatch, useSelector } from "react-redux";
import { connection } from "index";
import { toast } from "react-toastify";
import { RootState } from "store";
import { ClientSideNavigation, CustomNavigationClient } from "utils";
import { useTheme } from "@fluentui/react";
import { setGlobalSettings } from "components/request/reducers/masterDataSlice";
import "react-toastify/dist/ReactToastify.min.css";

const HelpPageList = lazy(() => import(/* webpackChunkName: "HelpPageList" */'components/admin/helpPage/HelpPageList'));
const HelpPageEditor = lazy(() => import(/* webpackChunkName: "HelpPageEditor" */'components/admin/helpPage/HelpPageEditor'));
const MetadataConfig = lazy(() => import(/* webpackChunkName: "MetadataConfig" */'components/admin/metadata/MetadataConfig'));
const ResponsibilityMatrix = lazy(() => import(/* webpackChunkName: "ResponsibilityMatrix" */'components/admin/responsibilityMatrix/ResponsibilityMatrix'));
const FAQList = lazy(() => import(/* webpackChunkName: "FAQList" */'components/admin/faq/FAQList'));
const FilterExport = lazy(() => import(/* webpackChunkName: "FilterExport" */'components/admin/reports/filterExport/FilterExport'));
const RequestList = lazy(() => import(/* webpackChunkName: "RequestList"*/'components/dashboard/requestList/RequestList'));
const TasksList = lazy(() => import(/* webpackChunkName: "TasksList" */'components/dashboard/tasksList/TasksList'));
const HomePage = lazy(() => import(/* webpackChunkName: "HomePage" */'components/home/HomePage'));
const ProjectRequestForm = lazy(() => import(/* webpackPreload: true, webpackChunkName: "ProjectRequestForm" */'components/request/ProjectRequestForm'));
// const ProjectRequestForm = lazy(() => import(/* webpackPreload: true */ 'components/request/ProjectRequestForm'));

const notify = (status: string) =>
  toast(`SpeedUp connection: ${status}`, { autoClose: 5000, position: "bottom-right", hideProgressBar: true });

export const AppProvider = (props: { msalInstance: PublicClientApplication }) => {
  const { msalInstance } = props;
  const navigate = useNavigate();
  const navigationClient = new CustomNavigationClient(navigate);
  msalInstance.setNavigationClient(navigationClient);


  return (
    <ClientSideNavigation pca={msalInstance}>
      <MsalProvider instance={msalInstance}>
        <App />
      </MsalProvider>
    </ClientSideNavigation>
  );
};

export const App = () => {
  const metadataService: MetadataService = new MetadataService();
  let dispatch = useDispatch();
  let location = useLocation();
  const navigate = useNavigate();
  const query = useQuery();
  const theme = useTheme();
  const [isSignUp] = useState(query.has("id_token_hint") && query.has("signup"));
  const { globalSettings } = useSelector((store: RootState) => store.masterData);
  const currentUser = useSelector((store: RootState) => store.currentUser.value);
  const currentUserRef = useRef<Partial<IUser>>(currentUser);

  const { accounts, inProgress, instance } = useMsal();
  const [accessDeniedErrorMessage, setAccessDeniedErrorMessage] = useState<string>(null);

  const refreshToken = () => {
    if (accounts.length) {
      const renewIdTokenRequest: SilentRequest = {
        account: accounts[0],
        scopes: loginRequest.scopes,
        forceRefresh: true
      };

      instance.acquireTokenSilent(renewIdTokenRequest).then(response => {
        // send response.idToken to the backend
        const updatedCurrentUser: IUser = CommonService.mapIdTokenClaimsToUserObject(response.idTokenClaims);
        dispatch(setCurrentUser(updatedCurrentUser));        
      }).catch(error => {
        // if it is an InteractionRequired error, send the same request in an acquireToken call
        if (error instanceof InteractionRequiredAuthError) {
          instance.acquireTokenRedirect(renewIdTokenRequest);
        }
      });
    }
  }

  const getLoginRequestObject = (existingLoginRequest: RedirectRequest) => {
    const updatedloginRequest = { ...existingLoginRequest };

    if (isSignUp) {
      updatedloginRequest.extraQueryParameters = {
        id_token_hint: query.get("id_token_hint")!,
      };
    }

    if (accounts.length) {
      updatedloginRequest.account = accounts[0];
    }
    return updatedloginRequest;
  };

  const loginRequestNew = getLoginRequestObject(loginRequest);

  const { login, error } = useMsalAuthentication(InteractionType.Silent, loginRequestNew);

  //const { login, error } = useMsalAuthentication(InteractionType.Silent, {scopes: [msalConfig.auth.clientId]});

  const isAuthenticated = useIsAuthenticated();

  const redirectNonAdminUsersFromAdminPageLinks = (user: IUser) => {
    if (user && Object.keys(user).length) {
      const matchInfo: PathMatch<string> = matchPath("/admin*", location.pathname);
      console.log("[PATH MATCH]: ", matchInfo);
      if (matchInfo && !user.isAdmin && !user.isDeveloper) {
        navigate("/", { replace: true });
      }
    }
  }

  const redirectNonStraumannUsersToMyTaksView = (user: IUser) => {
    if (user && Object.keys(user).length) {
      const matchInfoRequest: PathMatch<string> = matchPath(`${ApplicationRoutes.Request}/*`, location.pathname);
      const matchInfoDashboard: PathMatch<string> = matchPath(`${ApplicationRoutes.TaskDashboard}?filter=Open`, location.pathname);
      if (!matchInfoRequest && !matchInfoDashboard && !user.isStraumannUser) {
        navigate(`${ApplicationRoutes.TaskDashboard}?filter=Open`, { replace: true });
      }
    }

  }

  useEffect(() => {
    redirectNonStraumannUsersToMyTaksView(currentUser);
  }, [location, currentUser])

  useEffect(() => {
    currentUserRef.current = currentUser;
  }, [currentUser])

  useEffect(() => {
    redirectNonAdminUsersFromAdminPageLinks(currentUser);
    // hookup all connection events
    connection.onclose((conError?: Error) => {
      notify(`closed ${error ? `(Error: ${conError.message})` : ""}`);
    });
    connection.onreconnected(() => {
      notify(`reconnected`);
    });

    connection.on("UserGroupModified", (userIds: number | number[]) => {
      let shouldRefreshToken = false;

      if (Array.isArray(userIds) && userIds.indexOf(currentUserRef.current.id) !== -1) {
        shouldRefreshToken = true;
      }
      else if (currentUserRef.current.id === userIds || userIds === 0) {
        shouldRefreshToken = true;
      }

      if (shouldRefreshToken) {
        console.log("[REFRESHING TOKEN...]", "color:red; font-size: 20px");
        //Need to try Custoem policy solution
        refreshToken();
      }
    });

    const msalEventCallbackId = instance.addEventCallback((event: EventMessage) => {
      if (event.eventType === EventType.LOGIN_FAILURE) {
        console.log("Event: ", event);
        const [errorCode, errorMessage] = (event.error as AuthError).errorMessage.split("\r\n")[0].split(":");
        if (errorCode.toLowerCase() === "AAD_Custom_NO_ACCESS".toLowerCase()) {
          setAccessDeniedErrorMessage(errorMessage);
        }
      }
    });

    refreshToken();

    return () => {
      instance.removeEventCallback(msalEventCallbackId);
      connection.off("UserPermissionsModified");
    }
  }, []);

  useEffect(() => {
    if (accounts.length > 0) {
      const user: IUser = CommonService.mapIdTokenClaimsToUserObject(accounts[0].idTokenClaims);

      dispatch(setCurrentUser(user));
      dispatch(hideErrorMessage(ErrorRegions.Login));
      redirectNonStraumannUsersToMyTaksView(user);
      redirectNonAdminUsersFromAdminPageLinks(user);
    }
  }, [accounts]);

  useEffect(() => {
    if (error) {
      if (error instanceof InteractionRequiredAuthError) {
        const newloginRequest = getLoginRequestObject(loginRequest);
        login(InteractionType.Redirect, newloginRequest).then((result: AuthenticationResult | null) => {
          if (result) {
            const user: IUser = CommonService.mapIdTokenClaimsToUserObject(result.idTokenClaims);
            dispatch(setCurrentUser(user));
            dispatch(hideErrorMessage(ErrorRegions.Login));
            redirectNonStraumannUsersToMyTaksView(user);
            redirectNonAdminUsersFromAdminPageLinks(user);
          }

          if (isSignUp) {
            navigate(location.pathname);
          }
        });
      } else if (error instanceof BrowserAuthError) {
        console.error("Browser Auth Error", error);
        dispatch(showErrorMessage({ error, errorOriginationKey: ErrorRegions.Login }));
      } else {
        console.error(error);
        const [errorCode, errorMessage] = (error as AuthError).errorMessage.split("\r\n")[0].split(":");
        if (errorCode.toLowerCase() !== "AAD_Custom_NO_ACCESS".toLowerCase()) {
          dispatch(showErrorMessage({ error, errorOriginationKey: ErrorRegions.Login }));
        }

      }
    }
  }, [error]);

  useEffect(() => {
    if (isAuthenticated && Object.keys(globalSettings).length === 0) {
      metadataService.getAllMasterTableData<IGlobalSetting>("GlobalSetting").then((settings: IGlobalSetting[]) => {
        const globalSettings: GlobalSetting = {};

        for (const setting of settings) {
          globalSettings[setting.key] = setting.value;
        }
        dispatch(setGlobalSettings(globalSettings));
      }).catch(error => {
        console.error(error);
      });
    }

  }, [isAuthenticated]);

  useEffect(() => {
    let canUserCreateRequest : boolean=false;
    if (Object.keys(globalSettings)?.length) {
        const showNewRequestButtonToGroups = globalSettings[GlobalSettingKey.ShowNewRequestButtonToGroups];

        if (showNewRequestButtonToGroups !== null && showNewRequestButtonToGroups !== undefined) {
            let showNewRequestButtonToGroupList = globalSettings[GlobalSettingKey.ShowNewRequestButtonToGroups]?.split(",")

            showNewRequestButtonToGroupList = showNewRequestButtonToGroupList.map(x => x.trim());

            if (currentUser.groups?.length) {
              canUserCreateRequest = currentUser.groups.some(x => showNewRequestButtonToGroupList.findIndex(y => y.toLowerCase() === x.toLowerCase()) >= 0);
            }
            else {
              canUserCreateRequest = false;
            }
        }
        else {
          canUserCreateRequest = true;
        }

        if(currentUser != undefined && currentUser.canUserCreateRequest == undefined){
          let updateCurrentUser :IUser = cloneDeep(currentUser);
          updateCurrentUser.canUserCreateRequest=canUserCreateRequest;
          dispatch(setCurrentUser(updateCurrentUser));
        }
    }    
  }, [globalSettings,currentUser]);  

  return (
    <>
      <Layout>
        <AuthenticatedTemplate>
          {
            currentUser &&

            <Suspense fallback={
              <Stack className={styles.loggingInContainer} horizontalAlign="center" verticalAlign="center" reversed={true}>
                <span>Working on it...</span>
                <div className={styles.loggingInLoader}>
                  <div></div>
                  <div></div>
                  <div></div>
                </div>
              </Stack>}
            >
              <Routes>
                <Route index element={<HomePage />} />
                <Route path={ApplicationRoutes.Request}>
                  <Route index element={<Navigate replace to={ApplicationRoutes.NewRequest} />} />
                  <Route path={ApplicationRoutes.RequestWithId} element={<ProjectRequestForm />} />
                  <Route path={ApplicationRoutes.RequestWithIdStep} element={<ProjectRequestForm />} />
                  <Route path={ApplicationRoutes.RequestWithIdStepTab} element={<ProjectRequestForm />} />
                  {/* <Route path={`*`} element={<Navigate replace to={`${ApplicationRoutes.Request}`} />} /> */}
                </Route>
                {/* <Route path={ApplicationRoutes.Faq} element={<FAQView />} /> */}
                <Route path={ApplicationRoutes.MetadataConfiguration} element={<MetadataConfig />} />
                <Route path={ApplicationRoutes.ManageFAQ} element={<FAQList />} />
                <Route path={ApplicationRoutes.ResponsibilityMatrix} element={<ResponsibilityMatrix />} />
                <Route path={ApplicationRoutes.ManageHelpPages} element={<HelpPageList />} />
                <Route path={ApplicationRoutes.HelpPageEditor} element={<HelpPageEditor />} />
                <Route path={ApplicationRoutes.HelpPagePreview} element={<HelpPagePreview />} />
                <Route path={ApplicationRoutes.ManageGroups} element={<GroupList />} />
                <Route path={ApplicationRoutes.RequestDashboard} element={<RequestList />} />
                <Route path={ApplicationRoutes.Invitation} element={<UserInvitation />} />
                <Route path={ApplicationRoutes.TaskDashboard} element={<TasksList />} />
                <Route path={ApplicationRoutes.FilterExport} element={<FilterExport />} />
                <Route path={ApplicationRoutes.ColorSchemeMatrix} element={<ColorSchemeMatrix />} />
                <Route path={ApplicationRoutes.ManageHelpDocuments} element={<ManageHelpDocument />} />
                <Route path={"*"} element={<PageNotFound />} />
              </Routes>
            </Suspense>
          }

        </AuthenticatedTemplate>

        {!isAuthenticated && (inProgress === InteractionStatus.SsoSilent || inProgress === InteractionStatus.Login) && (
          <Stack className={styles.loggingInContainer} horizontalAlign="center" verticalAlign="center" reversed={true}>
            <span>Trying to Log-in</span>
            <div className={styles.loggingInLoader}>
              <div></div>
              <div></div>
              <div></div>
            </div>
          </Stack>
        )}

        {
          !!accessDeniedErrorMessage && (isAuthenticated || (inProgress !== InteractionStatus.SsoSilent && inProgress !== InteractionStatus.Login)) &&
          <div className={styles.accessDeniedMessageContainer}>
            <h1 style={{ color: theme.semanticColors.errorText }}>{accessDeniedErrorMessage}</h1>
          </div>
        }
      </Layout>
    </>
  );
};


