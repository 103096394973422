import { DefaultButton, Dropdown, IDropdownOption, PrimaryButton, TextField } from "@fluentui/react";
import { MetadataService} from "components";
import { TextConstants } from "constant";
import {
    IHelpDocument, IUser
} from "interfaces";
import { useCallback, useState } from "react";
import { useDispatch } from "react-redux";
import adminStyles from "../Admin.module.scss";
import styles from "./ManageHelpDocument.module.scss";

export interface IHelpDocumentForm {
    helpDocument: IHelpDocument;
    documentKeyDropdownOptions: IDropdownOption[];
    dismissPanel: () => void;
    saveHelpDocument: (newResponsibilityMatrixItem: IHelpDocument) => Promise<void>;
}

export const HelpDocumentForm: React.FC<IHelpDocumentForm> = (props: IHelpDocumentForm) => {

    const { helpDocument, documentKeyDropdownOptions, dismissPanel, saveHelpDocument } = props;

    const [errorMessages, setErrorMessages] = useState<{ [key in keyof IHelpDocument]?: string }>();
    const [currentHelpDocument, setCurrentHelpDocument] = useState<IHelpDocument>(helpDocument);

    const validateForm = useCallback(() => {
        let isFormValid = true;
        let newErrorMessages: { [key in keyof IHelpDocument]?: string } = errorMessages ? { ...errorMessages } : {};

        if (!currentHelpDocument?.documentKey) {
            newErrorMessages.documentKey = TextConstants.Common.Error_Message_Required;
            isFormValid = false;
        }
        else {
            newErrorMessages.documentKey = undefined;
        }

        if (!currentHelpDocument?.link) {
            newErrorMessages.link = TextConstants.Common.Error_Message_Required;
            isFormValid = false;
        } else {
            newErrorMessages.link = undefined;
        }

        setErrorMessages(newErrorMessages);

        return isFormValid;
    }, [errorMessages, currentHelpDocument])

    const onClickSave = useCallback(() => {

        if (validateForm()) {
            saveHelpDocument(currentHelpDocument);
        }
    }, [currentHelpDocument])

    return (
        <div className={styles.editPanelContainer}>
            <div className={styles.editPanelFormControls}>
                <Dropdown
                    required
                    className={adminStyles.marginTopBottom}
                    placeholder="Select"
                    errorMessage={errorMessages?.documentKey}
                    label={TextConstants.Admin.HelpDocument.Label_DocumentKey}
                    options={documentKeyDropdownOptions}
                    defaultSelectedKey={currentHelpDocument?.documentKey}
                    onChange={(e, item) => {
                        setCurrentHelpDocument((prevState: IHelpDocument) => ({
                            ...prevState,
                            "documentKey": item.key as string,
                        }));
                    }}
                />

                <TextField
                    required
                    className={adminStyles.marginTopBottom}
                    errorMessage={errorMessages?.link}
                    label={TextConstants.Admin.HelpDocument.Label_Link}
                    onChange={(e, newValue) => {
                        setCurrentHelpDocument((prevState: IHelpDocument) => ({
                            ...prevState,
                            "link": newValue,
                        }));
                    }}
                    value={currentHelpDocument?.link}
                />

            </div>
            <div className={styles.editPanelActions}>
                <div>
                    <PrimaryButton
                        text={TextConstants.Label_Save}
                        disabled={!currentHelpDocument.documentKey || !currentHelpDocument.link}
                        onClick={onClickSave}
                        iconProps={{ iconName: "Save" }}
                        className={adminStyles.marginRight}
                    />
                    <DefaultButton onClick={dismissPanel} text={TextConstants.Common.Label_Button_Cancel} iconProps={{ iconName: "Cancel" }} />
                </div>
            </div>
        </div>
    );
}