import { TextConstants, BaseFieldInfo } from "constant";
import { validateLocalizedInteger, validateLocalizedNumber } from "functions";
import {
    FieldType, IBOMItem, IMaterial, ITranslation, MaterialFields, ObjectFieldInfoTyped, RequestStep,
    RequestStepReverseMapping, RequestStepTab, RequestStepType, RequestType,
} from "interfaces";
import { useDispatch } from "react-redux";
import { HttpService } from "services";
import { IBOMItemSequence } from "../reducers/projectRequestSlice";

const MaterialLabels = TextConstants.Request.Material;
const BOMItemLabels = TextConstants.Request.BOMItem;
const TranslationLabels = TextConstants.Request.Translations;
const ExcludedFieldsToCheckStartStepForRequiredField: Array<MaterialFields | "step" | "tab"> = [
    // "productDescription",
    // "hasExpiryDate",
    "isAnyTranslationUpdated",
    "translations",
    "bomItems",
    "id",
    "projectRequest",
    "projectRequestId",
    "step",
    "tab"
];
export class MaterialService {
    public static apiEndpointProjectRequest = `/api/ProjectRequest`;
    public static apiEndpointMaterial = `/api/Material`;
    public static apiEndpointMetaData = `/api/Metadata`;
    dispatch = useDispatch();

    public static lockupdateIntervalReference: any = null;

    public static getNextBOMItemSequence = (mat: IMaterial, bomItemSequences: IBOMItemSequence) => {
        let newSeqNum: number = 0;
        try {
            if (!Object.hasOwn(bomItemSequences, `${mat.id}`)) {
                // find largest existing sequence number
                if (mat.bomItems?.length) {
                    let sequences = mat.bomItems.map(b => {
                        try {
                            let num: number = parseInt(b.item);
                            if (!isNaN(num)) {
                                // round down to next multiple of 10 => increment by 10 happens below
                                num = Math.floor(num / 10) * 10;
                            }
                            return !isNaN(num) ? num : 0;
                        } catch (numerr) {
                            return 0;
                        }
                    });
                    sequences = sequences.sort((a, b) => b - a);
                    newSeqNum = sequences[0];
                }
            } else {
                newSeqNum = bomItemSequences[`${mat.id}`];
            }
        } catch (err) {
            newSeqNum = 0;
        }

        newSeqNum += 10;
        return newSeqNum;
    }

    public static isProductDescriptionRequired(material: IMaterial) {
        const isMaterialDescriptionNull = !material.productDescription;

        if (isMaterialDescriptionNull) {
            if (
                Object.entries(material).some(x =>
                    ExcludedFieldsToCheckStartStepForRequiredField.indexOf(x[0] as keyof IMaterial) === -1 &&
                    x[0] !== MaterialService.MaterialFieldInfo.productDescription.name &&
                    x[1] !== null && x[1] !== undefined)
            ) {
                return true;
            }
        }

        return false;
    }

    public static isHasExpiryDateRequired(material: IMaterial) {
        const isHasExpiryNull = !material.hasExpiryDate;

        if (isHasExpiryNull) {
            if (Object.entries(material).some(x =>
                ExcludedFieldsToCheckStartStepForRequiredField.indexOf(x[0] as keyof IMaterial) === -1 &&
                x[0] !== MaterialService.MaterialFieldInfo.hasExpiryDate.name &&
                x[1] !== null && x[1] !== undefined)
            ) {
                return true;
            }
        }

        return false;
    }

    public static isMaterialNumberRequired(step: RequestStepType, tab: RequestStepTab, material: IMaterial) {

        switch (step) {
            case RequestStepReverseMapping[RequestStep.Start]:
                if (material.projectRequest.requestType === RequestType.ThirdParty) {
                    const isMaterialNumberNull = !material.materialNumber;

                    if (isMaterialNumberNull) {
                        if (Object.entries(material).some(x =>
                            ExcludedFieldsToCheckStartStepForRequiredField.indexOf(x[0] as keyof IMaterial) === -1 &&
                            x[0] !== MaterialService.MaterialFieldInfo.hasExpiryDate.name &&
                            x[1] !== null && x[1] !== undefined)
                        ) {
                            return true;
                        }
                    }
                    return false;
                }
                else {
                    return false;
                }

            default:
                return true;
        }
    }

    public static isProductHierarchyRequired(material: IMaterial) {
        const isProductHierarchyNull = !material.productHierarchy;

        if (isProductHierarchyNull) {
            if (Object.entries(material).some(x =>
                ExcludedFieldsToCheckStartStepForRequiredField.indexOf(x[0] as keyof IMaterial) === -1 &&
                x[0] !== MaterialService.MaterialFieldInfo.productHierarchy.name &&
                x[1] !== null && x[1] !== undefined)
            ) {
                return true;
            }
        }

        return false;
    }

    public static isGTINAndHIBCRequired(step: RequestStepType, tab: RequestStepTab, material: IMaterial) {
        const isGtinNull = !material.gtin;
        const isHibcNull = !material.hibc;

        switch (step) {
            case RequestStepReverseMapping[RequestStep.Start]:
                if (material.projectRequest.requestType === RequestType.ThirdParty) {
                    if (isGtinNull && isHibcNull) {
                        if (Object.entries(material).some(x =>
                            ExcludedFieldsToCheckStartStepForRequiredField.indexOf(x[0] as keyof IMaterial) === -1 &&
                            x[0] !== MaterialService.MaterialFieldInfo.gtin.name &&  x[0] !== MaterialService.MaterialFieldInfo.hibc.name &&
                            x[1] !== null && x[1] !== undefined)
                        ) {
                            return true;
                        }
                    }
                    return false;
                }
                else {
                    return false;
                }

            default:
                return false;
        }
    }

    public async updateMaterialsInProjectRequest(materialsToUpdate: IMaterial[]): Promise<void> {

        materialsToUpdate.forEach(material => {
            delete material.translations;
            delete material.bomItems;
        });

        await HttpService.put(`${MaterialService.apiEndpointMaterial}`, materialsToUpdate);
    }

    //#region Material Fields
    public static MaterialFieldInfo: ObjectFieldInfoTyped<IMaterial> = {
        ...BaseFieldInfo,
        projectRequestId: {
            name: "projectRequestId",
            label: MaterialLabels.Label_ProjectRequestId,
            type: FieldType.Int
        },
        materialNumber: {
            name: "materialNumber",
            label: MaterialLabels.Label_MaterialNumber,
            type: FieldType.Text,
            validationRules: {
                maxLength: 13,
                customValidatorIsRequired: {
                    validator: MaterialService.isMaterialNumberRequired,
                    validatorArguments: ["{{step}}", "{{tab}}", "{{selfContainer}}"]
                }
            }
        },
        productDescription: {
            name: "productDescription",
            label: MaterialLabels.Label_ProductDescription,
            type: FieldType.Text,
            validationRules: {
                customValidatorIsRequired: {
                    validator: MaterialService.isProductDescriptionRequired,
                    validatorArguments: ["{{selfContainer}}"]
                }
            }
        },
        hasExpiryDate: {
            name: "hasExpiryDate",
            label: MaterialLabels.Label_HasExpiryDate,
            type: FieldType.Boolean,
            validationRules: {
                customValidatorIsRequired: {
                    validator: MaterialService.isHasExpiryDateRequired,
                    validatorArguments: ["{{selfContainer}}"]
                }
            }
        },
        legalManufacturer: {
            name: "legalManufacturer",
            label: MaterialLabels.Label_LegalManufacturer,
            type: FieldType.Text
        },
        workpackageOrbit: {
            name: "workpackageOrbit",
            label: MaterialLabels.Label_WorkpackageOrbit,
            type: FieldType.Text
        },
        projectNumber: {
            name: "projectNumber",
            label: MaterialLabels.Label_ProjectNumber,
            type: FieldType.Text
        },
        productHierarchy: {
            name: "productHierarchy",
            label: MaterialLabels.Label_ProductHierarchy,
            type: FieldType.Text,
            validationRules: {
                customValidatorIsRequired: {
                    validator: MaterialService.isProductHierarchyRequired,
                    validatorArguments: ["{{selfContainer}}"]
                }
            }
        },
        brand: {
            name: "brand",
            label: MaterialLabels.Label_Brand,
            type: FieldType.Text
        },
        gtin: {
            name: "gtin",
            label: MaterialLabels.Label_Gtin,
            type: FieldType.Int,
            validationRules: {
                customValidatorIsRequired: {
                    validator: MaterialService.isGTINAndHIBCRequired,
                    validatorArguments: ["{{step}}", "{{tab}}", "{{selfContainer}}"],
                    errorMessage: TextConstants.Request.Material.Error_Message_GTINorHIBCRequired
                }
            }
        },
        hibc: {
            name: "hibc",
            label: MaterialLabels.Label_Hibc,
            type: FieldType.Text,
            validationRules: {
                customValidatorIsRequired: {
                    validator: MaterialService.isGTINAndHIBCRequired,
                    validatorArguments: ["{{step}}", "{{tab}}", "{{selfContainer}}"],
                    errorMessage: TextConstants.Request.Material.Error_Message_GTINorHIBCRequired
                }
            }
        },
        materialType: {
            name: "materialType",
            label: MaterialLabels.Label_MaterialType,
            type: FieldType.Text,
            validationRules: {
                isRequired: true
            }
        },
        configType: {
            name: "configType",
            label: MaterialLabels.Label_ConfigType,
            type: FieldType.Text,
            validationRules: {
                isRequired: true
            }
        },
        phaseStatus: {
            name: "phaseStatus",
            label: MaterialLabels.Label_PhaseStatus,
            type: FieldType.Text,
            validationRules: {
                isRequired: true
            }
        },
        batchManaged: {
            name: "batchManaged",
            label: MaterialLabels.Label_BatchManaged,
            type: FieldType.Boolean,
            validationRules: {
                isRequired: true
            }
        },
        hasSerialNumber: {
            name: "hasSerialNumber",
            label: MaterialLabels.Label_HasSerialNumber,
            type: FieldType.Boolean,
            validationRules: {
                isRequired: true
            }
        },
        cogsPlant: {
            name: "cogsPlant",
            label: MaterialLabels.Label_CogsPlant,
            type: FieldType.Text,
            validationRules: {
                isRequired: true
            }
        },
        primaryPackage: {
            name: "primaryPackage",
            label: MaterialLabels.Label_PrimaryPackage,
            type: FieldType.Boolean
        },
        contains: {
            name: "contains",
            label: MaterialLabels.Label_Contains,
            type: FieldType.Text
        },
        grossWeight: {
            name: "grossWeight",
            label: MaterialLabels.Label_GrossWeight,
            type: FieldType.Text,
            validationRules:{
                customValidator:{
                    errorMessage: TextConstants.Common.Error_Message_InvalidNumber,
                    validatorArguments:["{{self}}", "en"],
                    validator: validateLocalizedNumber
                }
            }
        },
        length: {
            name: "length",
            label: MaterialLabels.Label_Length,
            type: FieldType.Text,
            validationRules:{
                customValidator:{
                    errorMessage: TextConstants.Common.Error_Message_InvalidNumber,
                    validatorArguments:["{{self}}", "en"],
                    validator: validateLocalizedNumber
                }
            }
        },
        width: {
            name: "width",
            label: MaterialLabels.Label_Width,
            type: FieldType.Text,
            validationRules:{
                customValidator:{
                    errorMessage: TextConstants.Common.Error_Message_InvalidNumber,
                    validatorArguments:["{{self}}", "en"],
                    validator: validateLocalizedNumber
                }
            }
        },
        height: {
            name: "height",
            label: MaterialLabels.Label_Height,
            type: FieldType.Text,
            validationRules:{
                customValidator:{
                    errorMessage: TextConstants.Common.Error_Message_InvalidNumber,
                    validatorArguments:["{{self}}", "en"],
                    validator: validateLocalizedNumber
                }
            }
        },
        volume: {
            name: "volume",
            label: MaterialLabels.Label_Volume,
            type: FieldType.Text,
            validationRules:{
                customValidator:{
                    errorMessage: TextConstants.Common.Error_Message_InvalidNumber,
                    validatorArguments:["{{self}}", "en"],
                    validator: validateLocalizedNumber
                }
            }
        },
        specialTemperatureRequired: {
            name: "specialTemperatureRequired",
            label: MaterialLabels.Label_SpecialTemperatureRequired,
            type: FieldType.Boolean
        },
        minStorageTemperature: {
            name: "minStorageTemperature",
            label: MaterialLabels.Label_MinStorageTemperature,
            type: FieldType.Decimal
        },
        maxStorageTemperature: {
            name: "maxStorageTemperature",
            label: MaterialLabels.Label_MaxStorageTemperature,
            type: FieldType.Decimal
        },
        maxTimeTransport: {
            name: "maxTimeTransport",
            label: MaterialLabels.Label_MaxTimeTransport,
            type: FieldType.Decimal
        },
        frostSensitive: {
            name: "frostSensitive",
            label: MaterialLabels.Label_FrostSensitive,
            type: FieldType.Boolean
        },
        otherRequirements: {
            name: "otherRequirements",
            label: MaterialLabels.Label_OtherRequirements,
            type: FieldType.Text
        },
        airFreightComp: {
            name: "airFreightComp",
            label: MaterialLabels.Label_AirFreightComp,
            type: FieldType.Boolean
        },
        temperatureLoggerRequired: {
            name: "temperatureLoggerRequired",
            label: MaterialLabels.Label_TemperatureLoggerRequired,
            type: FieldType.Boolean
        },
        limitedQty: {
            name: "limitedQty",
            label: MaterialLabels.Label_LimitedQty,
            type: FieldType.Boolean
        },
        dgClassADR: {
            name: "dgClassADR",
            label: MaterialLabels.Label_DgClassADR,
            type: FieldType.Text
        },
        unNumber: {
            name: "unNumber",
            label: MaterialLabels.Label_UnNumber,
            type: FieldType.Text
        },
        containsBattery: {
            name: "containsBattery",
            label: MaterialLabels.Label_ContainsBattery,
            type: FieldType.Boolean
        },
        medicalDevice: {
            name: "medicalDevice",
            label: MaterialLabels.Label_MedicalDevice,
            type: FieldType.Boolean
        },
        otc: {
            name: "otc",
            label: MaterialLabels.Label_Otc,
            type: FieldType.Boolean
        },
        rxOnly: {
            name: "rxOnly",
            label: MaterialLabels.Label_RxOnly,
            type: FieldType.Boolean
        },
        cndCode: {
            name: "cndCode",
            label: MaterialLabels.Label_CndCode,
            type: FieldType.Text
        },
        euRule: {
            name: "euRule",
            label: MaterialLabels.Label_EURule,
            type: FieldType.Text
        },
        euClass: {
            name: "euClass",
            label: MaterialLabels.Label_EUClass,
            type: FieldType.Text
        },
        ecCertificateNo: {
            name: "ecCertificateNo",
            label: MaterialLabels.Label_EcCertificateNo,
            type: FieldType.Text
        },
        ecDeclarationName: {
            name: "ecDeclarationName",
            label: MaterialLabels.Label_EcDeclarationName,
            type: FieldType.Text
        },
        gmdnCode: {
            name: "gmdnCode",
            label: MaterialLabels.Label_GmdnCode,
            type: FieldType.Text
        },
        validFrom: {
            name: "validFrom",
            label: MaterialLabels.Label_ValidFrom,
            type: FieldType.Text
        },
        techBHinicalFile: {
            name: "techBHinicalFile",
            label: MaterialLabels.Label_TechBHinicalFile,
            type: FieldType.Text
        },
        localRegistration: {
            name: "localRegistration",
            label: MaterialLabels.Label_LocalRegistration,
            type: FieldType.Boolean
        },
        sterile: {
            name: "sterile",
            label: MaterialLabels.Label_Sterile,
            type: FieldType.Boolean
        },
        forSingleUse: {
            name: "forSingleUse",
            label: MaterialLabels.Label_ForSingleUse,
            type: FieldType.Boolean
        },
        sterilizable: {
            name: "sterilizable",
            label: MaterialLabels.Label_Sterilizable,
            type: FieldType.Boolean
        },
        sterilizationMethod: {
            name: "sterilizationMethod",
            label: MaterialLabels.Label_SterilizationMethod,
            type: FieldType.Text
        },
        rawMaterials: {
            name: "rawMaterials",
            label: MaterialLabels.Label_RawMaterials,
            type: FieldType.Text
        },
        ifuNumber: {
            name: "ifuNumber",
            label: MaterialLabels.Label_IfuNumber,
            type: FieldType.Text
        },
        countryOfOrigin: {
            name: "countryOfOrigin",
            label: MaterialLabels.Label_CountryOfOrigin,
            type: FieldType.Text
        },
        customsTariffCode: {
            name: "customsTariffCode",
            label: MaterialLabels.Label_CustomsTariffCode,
            type: FieldType.Text
        },
        prH4: {
            name: "prH4",
            label: MaterialLabels.Label_Prh4,
            type: FieldType.Text
        },
        prH5: {
            name: "prH5",
            label: MaterialLabels.Label_Prh5,
            type: FieldType.Text
        },
        basicUDIDI: {
            name: "basicUDIDI",
            label: MaterialLabels.Label_BasicUDIDI,
            type: FieldType.Text
        },
        unitOfUseDI: {
            name: "unitOfUseDI",
            label: MaterialLabels.Label_UnitOfUseDI,
            type: FieldType.Text
        },
        gmdn: {
            name: "gmdn",
            label: MaterialLabels.Label_Gmdn,
            type: FieldType.Text
        },
        emdnCode: {
            name: "emdnCode",
            label: MaterialLabels.Label_EmdnCode,
            type: FieldType.Text
        },
        needSterilization: {
            name: "needSterilization",
            label: MaterialLabels.Label_NeedSterilization,
            type: FieldType.Boolean
        },
        mriStatus: {
            name: "mriStatus",
            label: MaterialLabels.Label_MriStatus,
            type: FieldType.Text
        },
        kit: {
            name: "kit",
            label: MaterialLabels.Label_Kit,
            type: FieldType.Boolean
        },
        comboProduct: {
            name: "comboProduct",
            label: MaterialLabels.Label_ComboProduct,
            type: FieldType.Boolean
        },
        dmApplicable: {
            name: "dmApplicable",
            label: MaterialLabels.Label_DmApplicable,
            type: FieldType.Boolean
        },
        dmApplied: {
            name: "dmApplied",
            label: MaterialLabels.Label_DmApplied,
            type: FieldType.Boolean
        },
        diameter: {
            name: "diameter",
            label: MaterialLabels.Label_Diameter,
            type: FieldType.Text,
            validationRules:{
                customValidator:{
                    errorMessage: TextConstants.Common.Error_Message_InvalidNumber,
                    validatorArguments:["{{self}}", "en"],
                    validator: validateLocalizedNumber
                }
            }
        },
        gingivaHeight: {
            name: "gingivaHeight",
            label: MaterialLabels.Label_GingivaHeight,
            type: FieldType.Text,
            validationRules:{
                customValidator:{
                    errorMessage: TextConstants.Common.Error_Message_InvalidNumber,
                    validatorArguments:["{{self}}", "en"],
                    validator: validateLocalizedNumber
                }
            }
        },
        abutmentHeight: {
            name: "abutmentHeight",
            label: MaterialLabels.Label_AbutmentHeight,
            type: FieldType.Text,
            validationRules:{
                customValidator:{
                    errorMessage: TextConstants.Common.Error_Message_InvalidNumber,
                    validatorArguments:["{{self}}", "en"],
                    validator: validateLocalizedNumber
                }
            }
        },
        angle: {
            name: "angle",
            label: MaterialLabels.Label_Angle,
            type: FieldType.Text,
            validationRules:{
                customValidator:{
                    errorMessage: TextConstants.Common.Error_Message_InvalidNumber,
                    validatorArguments:["{{self}}", "en"],
                    validator: validateLocalizedInteger
                }
            }
        },
        granuleSize: {
            name: "granuleSize",
            label: MaterialLabels.Label_GranuleSize,
            type: FieldType.Text,
            validationRules:{
                customValidator:{
                    errorMessage: TextConstants.Common.Error_Message_InvalidNumber,
                    validatorArguments:["{{self}}", "en"],
                    validator: validateLocalizedNumber
                }
            }
        },
        mdamdnCode: {
            name: "mdamdnCode",
            label: MaterialLabels.Label_MdaMdnCode,
            type: FieldType.Text
        },
        mdsCodeI: {
            name: "mdsCodeI",
            label: MaterialLabels.Label_MdsCodeI,
            type: FieldType.Text
        },
        mdsCodeII: {
            name: "mdsCodeII",
            label: MaterialLabels.Label_MdsCodeII,
            type: FieldType.Text
        },
        mdsCodeIII: {
            name: "mdsCodeIII",
            label: MaterialLabels.Label_MdsCodeIII,
            type: FieldType.Text
        },
        mdtCodeI: {
            name: "mdtCodeI",
            label: MaterialLabels.Label_MdtCodeI,
            type: FieldType.Text
        },
        mdtCodeII: {
            name: "mdtCodeII",
            label: MaterialLabels.Label_MdtCodeII,
            type: FieldType.Text
        },
        mdtCodeIII: {
            name: "mdtCodeIII",
            label: MaterialLabels.Label_MdtCodeIII,
            type: FieldType.Text
        },
        ecCertificateReceived: {
            name: "ecCertificateReceived",
            label: MaterialLabels.Label_ECCertificateReceived,
            type: FieldType.Boolean
        },
        euComplianceLegislation: {
            name: "euComplianceLegislation",
            label: MaterialLabels.Label_EUComplianceLegislation,
            type: FieldType.Text
        },
        itemCategoryApplies: {
            name: "itemCategoryApplies",
            label: MaterialLabels.Label_ItemCategoryApplies,
            type: FieldType.Text
        },
        safetyDataSheetReady: {
            name: "safetyDataSheetReady",
            label: MaterialLabels.Label_SafetyDataSheetReady,
            type: FieldType.Boolean
        },
        countriesAlreadyRegistered: {
            name: "countriesAlreadyRegistered",
            label: MaterialLabels.Label_CountriesAlreadyRegistered,
            type: FieldType.Text
        },
        countriesWhereUnderRegisteration: {
            name: "countriesWhereUnderRegisteration",
            label: MaterialLabels.Label_CountriesWhereUnderRegisteration,
            type: FieldType.Text
        },
        sterilizationByUser: {
            name: "sterilizationByUser",
            label: MaterialLabels.Label_SterilizationByUser,
            type: FieldType.Boolean
        },
        ifuAvailable: {
            name: "ifuAvailable",
            label: MaterialLabels.Label_IFUAvailable,
            type: FieldType.Boolean
        },
        labOffice: {
            name: "labOffice",
            label: MaterialLabels.Label_LabOfficeCode,
            type: FieldType.Text
        },
        translations: {
            name: "translations",
            label: MaterialLabels.Label_Translations,
            type: FieldType.CustomArray,
        }
    }
    //#endregion

    //#region BOMItem Fields
    public static BOMItemFieldInfo: ObjectFieldInfoTyped<IBOMItem> = {
        ...BaseFieldInfo,
        projectRequestId: {
            name: "projectRequestId",
            label: TextConstants.Request.BOMItem.Label_ProjectRequestId,
            type: FieldType.Int
        },
        materialId: {
            name: "materialId",
            label: TextConstants.Request.BOMItem.Label_MaterialId,
            type: FieldType.Int
        },
        item: {
            name: "item",
            label: TextConstants.Request.BOMItem.Label_Item,
            type: FieldType.Text
        },
        itemCategory: {
            name: "itemCategory",
            label: TextConstants.Request.BOMItem.Label_ItemCategory,
            type: FieldType.Text
        },
        fixedQty: {
            name: "fixedQty",
            label: TextConstants.Request.BOMItem.Label_FixedQty,
            type: FieldType.Text
        },
        component: {
            name: "component",
            label: TextConstants.Request.BOMItem.Label_Component,
            type: FieldType.Text
        },
        shortText: {
            name: "shortText",
            label: TextConstants.Request.BOMItem.Label_ShortText,
            type: FieldType.Text
        },
        componentQty: {
            name: "componentQty",
            label: TextConstants.Request.BOMItem.Label_ComponentQty,
            type: FieldType.Int
        },
        baseUnitOfMeasure: {
            name: "baseUnitOfMeasure",
            label: TextConstants.Request.BOMItem.Label_BaseUnitOfMeasure,
            type: FieldType.Text
        },
        productionRelevant: {
            name: "productionRelevant",
            label: TextConstants.Request.BOMItem.Label_ProductionRelevant,
            type: FieldType.Text
        },
        constructionRelevant: {
            name: "constructionRelevant",
            label: TextConstants.Request.BOMItem.Label_ConstructionRelevant,
            type: FieldType.Text
        },
        costingRelevant: {
            name: "costingRelevant",
            label: TextConstants.Request.BOMItem.Label_CostingRelevant,
            type: FieldType.Text
        },
    }
    //#endregion

    //#region Translation Fields
    public static TranslationFieldInfo: ObjectFieldInfoTyped<ITranslation> = {
        ...BaseFieldInfo,
        projectRequestId: {
            name: "projectRequestId",
            label: TranslationLabels.Label_ProjectRequestId,
            type: FieldType.Int
        },
        materialId: {
            name: "materialId",
            label: TranslationLabels.Label_MaterialId,
            type: FieldType.Int
        },
        material: {
            name: "material",
            label: TranslationLabels.Label_MaterialId,
            type: FieldType.Custom,
            fieldInfo: MaterialService.MaterialFieldInfo
        },
        productMasterText: {
            name: "productMasterText",
            label: TranslationLabels.Label_ProductMasterText,
            type: FieldType.Text,
            validationRules: {
                // isRequired: true,
                customValidatorIsRequired: {
                    validator: (translation: ITranslation) => {
                        if (translation.material.productDescription) {
                            return true;
                        }
                        else {
                            return false;
                        }
                    },
                    validatorArguments: ["{{selfContainer}}"]
                }
            }
        },
        shortDescription: {
            name: "shortDescription",
            label: TranslationLabels.Label_ShortDescription,
            type: FieldType.Text,
            validationRules: {
                // isRequired: true,
                customValidatorIsRequired: {
                    validator: (translation: ITranslation) => {
                        if (translation.material.productDescription) {
                            return true;
                        }
                        else {
                            return false;
                        }
                    },
                    validatorArguments: ["{{selfContainer}}"]
                },
                maxLength: 40
            }
        },
        masterDescriptionLine1: {
            name: "masterDescriptionLine1",
            label: TranslationLabels.Label_MasterDescriptionLine1,
            type: FieldType.Text
        },
        masterDescriptionLine2: {
            name: "masterDescriptionLine2",
            label: TranslationLabels.Label_MasterDescriptionLine2,
            type: FieldType.Text
        },
        masterDescriptionLine3: {
            name: "masterDescriptionLine3",
            label: TranslationLabels.Label_MasterDescriptionLine3,
            type: FieldType.Text
        },
        masterDescriptionLine4: {
            name: "masterDescriptionLine4",
            label: TranslationLabels.Label_MasterDescriptionLine4,
            type: FieldType.Text
        },
        languageCode: {
            name: "languageCode",
            label: TranslationLabels.Label_LanguageCode,
            type: FieldType.Text
        },
    }
    //#endregion  

}