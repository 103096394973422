import { IStyleFunctionOrObject, ITextFieldStyleProps, ITextFieldStyles, IDropdownStyleProps, IDropdownStyles, IBasePickerStyleProps, IBasePickerStyles, ISearchBoxStyleProps, ISearchBoxStyles, PartialTheme, IIconStyleProps, IIconStyles, IButtonProps, IButtonStyles, IPivotStyleProps, IPivotStyles, IChoiceGroupOptionStyleProps, IChoiceGroupOptionStyles, ICheckboxStyles, ICheckboxStyleProps,ISpinnerStyleProps,ISpinnerStyles} from "@fluentui/react";
import { DeepPartial } from '@fluentui/merge-styles';
import stylingVariables from "variables.module.scss";
import { choiceGroupStyle } from "components";

const TextFieldStyles: IStyleFunctionOrObject<ITextFieldStyleProps, ITextFieldStyles> = {
    fieldGroup: {
        borderWidth: '2px',
        borderRadius: 0,
        '&::after': {
            borderWidth: '2px',
            borderRadius: 0,
            inset: '-2px'
        }
    }
};

const DropdownStyles: IStyleFunctionOrObject<IDropdownStyleProps, IDropdownStyles> = {
    title: {
        borderWidth: '2px',
        borderRadius: 0                     
    },
    dropdown:{
        '&:hover':{
            borderColor: stylingVariables.straumannBlueHoverColor
        } 
    }
};

const NormalPeoplePickerStyles: IStyleFunctionOrObject<IBasePickerStyleProps, IBasePickerStyles> = {
    text: {
        borderWidth: '2px',
        borderRadius: 0,
    }
}

const IconStyles: IStyleFunctionOrObject<IIconStyleProps, IIconStyles> = {
    root: {
        color: stylingVariables.straumannBlueColor
    }
}

const CommandBarStyles: IStyleFunctionOrObject<IButtonProps, IButtonStyles> = {
    icon: {
        color: stylingVariables.straumannBlueColor,            
    },
    iconHovered:{
        color: stylingVariables.straumannBlueHoverColor                 
    },
    labelHovered: {
        color: stylingVariables.straumannBlueHoverColor                
    }
}

const SpinnerStyles: IStyleFunctionOrObject<ISpinnerStyleProps, ISpinnerStyles> = {
    label: {
        color: stylingVariables.straumannBlueColor,        
    },
    circle: {
        borderColor: `${stylingVariables.straumannBlueColor} ${stylingVariables.straumannBlueLightestColor} ${stylingVariables.straumannBlueLightestColor}`        
    }
}


const CheckStyles: IStyleFunctionOrObject<ICheckboxStyleProps, ICheckboxStyles> =
    (props: ICheckboxStyleProps): DeepPartial<ICheckboxStyles> => {
        if (props.checked) {
            return {
                root: {
                    '&::before': {
                        backgroundColor: stylingVariables.straumannBlueColor
                    }
                }
            };
        }
        else {
            return null;
        }
    };

const CheckBoxStyles: IStyleFunctionOrObject<ICheckboxStyleProps, ICheckboxStyles> =
    (props: ICheckboxStyleProps): DeepPartial<ICheckboxStyles> => {
        if (props.checked) {
            return {
                root: {
                    '&:hover .ms-Checkbox-checkbox': {
                        borderColor: stylingVariables.straumannBlueHoverColor,
                        backgroundColor: stylingVariables.straumannBlueHoverColor
                    }
                },
                checkbox: {
                    borderColor: stylingVariables.straumannBlueColor,
                    backgroundColor: stylingVariables.straumannBlueColor,
                    '&:hover': {
                        borderColor: stylingVariables.straumannBlueHoverColor,
                        backgroundColor: stylingVariables.straumannBlueHoverColor
                    }
                },
            };
        }
        else {
            return null;
        }
    };

const PivotStyles: IStyleFunctionOrObject<IPivotStyleProps, IPivotStyles> = {
    linkIsSelected: {
        '&::before': {
            backgroundColor: stylingVariables.straumannBlueColor
        }
    }
}

const ChoiceGroupOptionStyles: IStyleFunctionOrObject<IChoiceGroupOptionStyleProps, IChoiceGroupOptionStyles> = (props: IChoiceGroupOptionStyleProps): DeepPartial<IChoiceGroupOptionStyles> => {

    if (props.checked) {
        return {
            field: {
                '&::before': {
                    borderColor: stylingVariables.straumannBlueColor
                },
                '&::after': {
                    backgroundColor: stylingVariables.straumannBlueColor,
                    borderColor: stylingVariables.straumannBlueColor
                }
            },
        };
    }
    else {
        return null;
    }
};

const SearchBoxStyles: IStyleFunctionOrObject<ISearchBoxStyleProps, ISearchBoxStyles> = {
    root: {
        borderWidth: '2px',
        borderRadius: 0,
    },
    iconContainer: {
        color: stylingVariables.straumannBlueColor
    }
}

export const AppTheme: PartialTheme = {
    defaultFontStyle: { fontFamily: "Webfont" },
    palette: {
        neutralPrimary: stylingVariables.straumannLightBlackColor,
    },
    semanticColors: {
        bodyText: stylingVariables.straumannLightBlackColor,
        primaryButtonBackground: stylingVariables.straumannBlueColor,
        primaryButtonBackgroundHovered: stylingVariables.straumannBlueHoverAltColor,
        primaryButtonBackgroundPressed: stylingVariables.straumannBlueHoverAltColor,
        inputBorder: stylingVariables.straumannLighterGreyColor,
        inputBorderHovered: stylingVariables.straumannInputBorderHovered,
        inputFocusBorderAlt:  stylingVariables.straumannInputFocusBorderAlt,
        focusBorder: stylingVariables.straumannFocusBorder,
    },
    components: {
        TextField: {
            styles: TextFieldStyles
        },
        Dropdown: {
            styles: DropdownStyles
        },
        NormalPeoplePicker: {
            styles: NormalPeoplePickerStyles
        },
        SearchBox: {
            styles: SearchBoxStyles
        },
        IconButton: {
            styles: IconStyles
        },
        CommandBarButton: {
            styles: CommandBarStyles
        },
        Pivot: {
            styles: PivotStyles
        },
        Check: {
            styles: CheckStyles
        },
        Checkbox: {
            styles: CheckBoxStyles
        },
        ChoiceGroupOption: {
            styles: ChoiceGroupOptionStyles
        },
        ActionButton:{
            styles: CommandBarStyles
        },
        Spinner:{
            styles: SpinnerStyles
        }      
    }
};