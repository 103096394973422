import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { DropdownMasterDataType, GlobalSetting as IGlobalSetting, HelpDocumentLinks, ICheckListItem, ICountryTask, IHelpDocument, ILabelsAndTooltip, ILanguageCode, ILegalManufacturer, ILogisticRef, IPMSAPCodeMapping, IProductCategoryMapping, IRequestEditPermissionsMatrix, ISimpleApprovalTask } from "interfaces";

const intialMasterData: DropdownMasterDataType = {
    labelAndTooltipData: [] as ILabelsAndTooltip[],
    checkListItems: [] as ICheckListItem[],
    simpleApprovalTasks: [] as ISimpleApprovalTask[],
    countryTasks: [] as ICountryTask[],
    editPermissions: [] as IRequestEditPermissionsMatrix[],
    logisticRefs: [] as ILogisticRef[],
    pmSAPCodeMappings: [] as IPMSAPCodeMapping[],
    legalManufacturer: [] as ILegalManufacturer[],
    globalSettings: {} as IGlobalSetting,
    helpDocuments: {} as HelpDocumentLinks,
    productCategoryMappings: [] as IProductCategoryMapping[],
    labOfficeListData:[]
};

export const masterDataSlice = createSlice({
    name: "masterData",
    initialState: { ...intialMasterData },
    reducers: {
        resetMasterData: (state) => {
            state = { ...intialMasterData };
        },
        addUpdateMasterDataForParam: (state, action: PayloadAction<DropdownMasterDataType>) => {
            for (const key in action.payload) {
                state[key] = action.payload[key];
            }
        },
        addLabelAndTooltipData: (state, action: PayloadAction<ILabelsAndTooltip[]>) => {
            const labelAndTooltipData = [...state.labelAndTooltipData];
            state.labelAndTooltipData = labelAndTooltipData.concat(action.payload);
        },
        addCheckListItems: (state, action: PayloadAction<ICheckListItem[]>) => {
            const checkListItems = [...state.checkListItems];
            state.checkListItems = checkListItems.concat(action.payload);
        },
        addSimpleApprovalTasks: (state, action: PayloadAction<ISimpleApprovalTask[]>) => {
            const simpleApprovalTasks = [...state.simpleApprovalTasks];
            state.simpleApprovalTasks = simpleApprovalTasks.concat(action.payload);
        },
        addCountryTasks: (state, action: PayloadAction<ICountryTask[]>) => {
            const countryTasks = [...state.countryTasks];
            state.countryTasks = countryTasks.concat(action.payload);
        },
        setEditPermissions: (state, action: PayloadAction<IRequestEditPermissionsMatrix[]>) => {
            state.editPermissions = action.payload;
        },
        addLogisticRefItems: (state, action: PayloadAction<ILogisticRef[]>) => {
            const logisticRefs = [...state.logisticRefs];
            state.logisticRefs = logisticRefs.concat(action.payload);
        },
        addPMSAPCodeMappings: (state, action: PayloadAction<IPMSAPCodeMapping[]>) => {
            const pmSAPCodeMappings = [...state.pmSAPCodeMappings];
            state.pmSAPCodeMappings = pmSAPCodeMappings.concat(action.payload);
        },
        addLegalManufacturers: (state, action: PayloadAction<ILegalManufacturer[]>) => {
            const legalManufacturer = [...state.legalManufacturer];
            state.legalManufacturer = legalManufacturer.concat(action.payload);
        },
        setGlobalSettings: (state, action: PayloadAction<IGlobalSetting>) => {
            state.globalSettings = { ...action.payload };
        },
        setHelpDocuments: (state, action: PayloadAction<HelpDocumentLinks>) => {
            state.helpDocuments = { ...action.payload };
        },
        addProductCategoryMappings: (state, action: PayloadAction<IProductCategoryMapping[]>) => {
            const productCategoryMappings = [...state.productCategoryMappings];
            state.productCategoryMappings = productCategoryMappings.concat(action.payload);
        },
        setLabOfficeTableData: (state, action: any) => {
            state.labOfficeListData =  [...action.payload] ;
        },
    },
});

export const {
    addUpdateMasterDataForParam, addLabelAndTooltipData, addCheckListItems, addSimpleApprovalTasks, addCountryTasks,
    setEditPermissions, addLogisticRefItems, addPMSAPCodeMappings, resetMasterData, addLegalManufacturers, setGlobalSettings, 
    setHelpDocuments,addProductCategoryMappings,setLabOfficeTableData
} = masterDataSlice.actions;

export const masterDataReducer = masterDataSlice.reducer;
