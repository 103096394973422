import { createSlice } from "@reduxjs/toolkit";
import { IUser } from "interfaces";

let initialState: Partial<IUser> = {};

export const CurrentUserSlice = createSlice({
  name: "currentUser",
  initialState: {
    value: initialState,
  },
  reducers: {
    clearCurrentUser: (state) => {
      state.value = {};
    },
    setCurrentUser: (state, action) => {
      const newUserProfile: IUser = { ...action.payload };
      state.value = newUserProfile;
    },
    updateCurrentUser: (state, action) => {
      const updatedUserProfile: IUser = action.payload;
      const newUserProfile = { ...state.value };
      newUserProfile.displayName = updatedUserProfile.displayName ?? newUserProfile.displayName;
      newUserProfile.email = updatedUserProfile.email ?? newUserProfile.email;
      newUserProfile.givenName = updatedUserProfile.givenName ?? newUserProfile.givenName;
      newUserProfile.lastName = updatedUserProfile.lastName ?? newUserProfile.lastName;
      newUserProfile.objectId = updatedUserProfile.objectId ?? newUserProfile.objectId;
      newUserProfile.groups =
        updatedUserProfile.groups && updatedUserProfile.groups.length ? updatedUserProfile.groups : newUserProfile.groups;
      state.value = newUserProfile;
    },
  },
});

export const { updateCurrentUser, setCurrentUser, clearCurrentUser } = CurrentUserSlice.actions;

export const currentUserReducer = CurrentUserSlice.reducer;
