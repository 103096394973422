import {
  ActionButton, css, Icon,
} from "@fluentui/react";
import { ApplicationRoutes, TextConstants } from "constant";
import { useEffect, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import stylingVariables from "variables.module.scss";
import styles from "./LinkToHome.module.scss";

type ILinkToHomeProps = {
  showForRoute?: any[];
  forceShowButton?: boolean;
  className?: string;
};

export const LinkToHome = ({ showForRoute, forceShowButton, className }: ILinkToHomeProps) => {
  let { pathname } = useLocation();

  const [showButton, setShowButton] = useState(false);

  useEffect(() => {
    if (pathname && showForRoute) {
      for (const condition of showForRoute) {
        if (condition instanceof RegExp) {
          if (condition.test(pathname)) {
            setShowButton(true);
            return;
          }
        } else if (typeof condition === "string") {
          if (pathname.toLowerCase().startsWith(condition)) {
            setShowButton(true);
            return;
          }
        }
      }

      setShowButton(false);
      console.log(pathname);
    }
  }, [pathname]);

  return (
    <>
      {
        forceShowButton || (!forceShowButton && showButton) ?
          <Link to={ApplicationRoutes.Home} className={css(styles.homeLink, className)} ><Icon className={styles.icon} iconName="HomeSolid"></Icon><span className={styles.text}>{TextConstants.HomePage.Label_Button_Home}</span></Link>
          : null
      }
    </>
  );
};
