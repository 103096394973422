import { DefaultButton, Dialog, DialogFooter, IDialogProps, IGroup, PrimaryButton, ThemeProvider } from "@fluentui/react";
import {
    AuthenticationSource, FieldInfoTyped, ObjectFieldInfoTyped, FieldType, IAttachment, IUser, IUserGroup, ObjectFieldInfo,
    ObjectFieldInfoType, FieldInfo, EntityNameType, IBase, IResponsibilityMatrix, ProjectRequestTask, RequestStepTasks,
    RequestStepType, RequestStepTab,
} from "interfaces";
import ReactDOM from "react-dom";
import { toast, ToastOptions } from "react-toastify";
import { IUser as CCSUser, IUserGroup as CCSUserGroup } from "ccs-azure";
import { TextConstants } from "constant";
import cloneDeep from "lodash.clonedeep";
import moment from "moment";
import { formatISODate, formatISODateTime, random } from "functions";
import stylingVariable from "variables.module.scss";
import React, { useContext } from "react";
import { AppTheme } from "theme";

export class CommonService {
    public static downloadFile(blob: Blob, filename?: string) {
        const objurl = window.URL.createObjectURL(blob);

        const a = document.createElement("a");
        a.setAttribute("data-interception", "off");
        a.setAttribute("href", objurl);
        if (filename)
            a.setAttribute("download", filename);
        let event = new MouseEvent("click", {
            bubbles: true,
            cancelable: true,
            view: window,
            detail: 1,
        });

        a.dispatchEvent(event);
        window.URL.revokeObjectURL(objurl);
    };

    public static getTodaysDate(): Date {
        let d = new Date();
        d.setHours(0, 0, 0, 0);
        return d;
    }
    public static getFullNameInitials(fullName: string): string {
        if (fullName === null) {
            return fullName;
        }

        const words: string[] = fullName.split(" ");
        if (words.length === 0) {
            return "";
        } else if (words.length === 1) {
            return words[0].charAt(0);
        } else {
            return words[0].charAt(0) + words[1].charAt(0);
        }
    }

    public static mapIdTokenClaimsToUserObject(idTokenClaims: any): IUser {
        return {
            id: idTokenClaims.userId,
            displayName: idTokenClaims.name,
            email: idTokenClaims.email,
            givenName: idTokenClaims.given_name,
            isStraumannUser: idTokenClaims.isStraumannUser,
            lastName: idTokenClaims.family_name,
            objectId: idTokenClaims.sub,
            userName: "",
            groups: idTokenClaims.userGroups,
            isLocalUser: idTokenClaims.authenticationSource === AuthenticationSource.LocalAccountAuthentication,
            isAdmin: idTokenClaims.isAdmin,
            isDeveloper: idTokenClaims.isDeveloper,
            isProcessAdmin: idTokenClaims.isProcessAdmin
        };
    }

    public static fixTimeStampColumn<T extends IBase>(obj: T): T {
        obj.createdOn = new Date(obj.createdOn.toString());
        obj.modifiedOn = new Date(obj.modifiedOn.toString());
        return obj;
    }

    public static fixTimeStampColumnOfObject<T>(obj: T, fieldInfoList: ObjectFieldInfo): T {
        for (const key in obj) {
            if (obj[key as keyof T] !== null && obj[key as keyof T] !== undefined) {
                const fieldInfo = fieldInfoList[key];
                if (fieldInfo) {
                    const fieldType = fieldInfo.type;
                    if (fieldType === FieldType.Date || fieldType === FieldType.DateTime) {
                        obj[key as keyof T] = new Date(String(obj[key as keyof T])) as any;
                    } else if (fieldType === FieldType.Custom && fieldInfo.fieldInfo) {
                        obj[key as keyof T] = this.fixTimeStampColumnOfObject(obj[key as keyof T], fieldInfo.fieldInfo);
                    } else if (fieldType === FieldType.CustomArray && fieldInfo.fieldInfo) {
                        obj[key as keyof T] = (obj[key as keyof T] as unknown as Array<any>).map((element) =>
                            this.fixTimeStampColumnOfObject(element, fieldInfo.fieldInfo)
                        ) as any;
                    }
                }
            }
        }

        return obj;
    }

    public static showConfirmationDialog(
        dialogProps: IDialogProps,
        YesButtonTitle: string,
        NoButtonTitle: string,
        onConfirmCallback: Function,
        onCancelCallback?: Function
    ) {
        const divId = "popUpDivCD";
        let element = document.getElementById(divId);
        if (!element) {
            element = document.createElement("div");
            element.id = "popUpDivCD";
            document.body.appendChild(element);
        }

        const dismissModal = () => {
            document.body.removeChild(element);
            ReactDOM.unmountComponentAtNode(element);
        };

        dialogProps.hidden = false;

        ReactDOM.render(
            <ThemeProvider applyTo="element" theme={AppTheme}>
                <Dialog {...dialogProps}>
                    <DialogFooter>
                        <PrimaryButton
                            onClick={() => {
                                dismissModal();
                                onConfirmCallback();
                            }}
                            text={YesButtonTitle}
                        />
                        <DefaultButton
                            onClick={() => {
                                dismissModal();
                                if (onCancelCallback) {
                                    onCancelCallback();
                                }
                            }}
                            text={NoButtonTitle}
                        />
                    </DialogFooter>
                </Dialog>
            </ThemeProvider>,
            element
        );
    }

    public static showMessageDialog(dialogProps: IDialogProps, onClickOkCallback?: Function) {
        const divId = "popUpDivCD";

        let element = document.getElementById(divId);
        if (!element) {
            element = document.createElement("div");
            element.id = "popUpDivCD";
            document.body.appendChild(element);
        }

        const dismissModal = () => {
            document.body.removeChild(element);
            ReactDOM.unmountComponentAtNode(element);
        };

        dialogProps.hidden = false;

        if (dialogProps.modalProps) {
            dialogProps.modalProps.isModeless = true;
        }
        else {
            dialogProps.modalProps = {
                isModeless: true
            };
        }

        ReactDOM.render(
            <ThemeProvider applyTo="element" theme={AppTheme}>
                <Dialog {...dialogProps}>
                    <DialogFooter>
                        <PrimaryButton
                            onClick={() => {
                                dismissModal();
                                if (onClickOkCallback) {
                                    onClickOkCallback();
                                }
                            }}
                            text={TextConstants.Common.Label_Button_Ok}
                        />
                    </DialogFooter>
                </Dialog>
            </ThemeProvider>,
            element
        );
    }

    public static showComponentInDialog(dialogProps: IDialogProps, component: JSX.Element) {
        const divId = "popUpDivCD";

        let element = document.getElementById(divId);
        if (!element) {
            element = document.createElement("div");
            element.id = "popUpDivCD";
            document.body.appendChild(element);
        }

        const dismissModal = () => {
            document.body.removeChild(element);
            ReactDOM.unmountComponentAtNode(element);
        };

        dialogProps.hidden = false;
        dialogProps.onDismiss = dismissModal;

        ReactDOM.render(
            <ThemeProvider applyTo="element" theme={AppTheme}>
                <Dialog {...dialogProps}>
                    {component}
                </Dialog>
            </ThemeProvider>,
            element
        );
    }

    public static convertTokenToFunctionArgument<T>(variable: any, fieldInfo: FieldInfoTyped<T>, dataObject: T) {
        if (variable === "{{self}}") {
            return dataObject[fieldInfo.name];
        } else if (variable === "{{selfContainer}}") {
            return dataObject;
        } else if (typeof variable === "string" && /{{.+}}/i.test(variable)) {
            return dataObject[variable.replaceAll("{{", "").replaceAll("}}", "") as keyof T];
        } else {
            return variable;
        }
    }

    /**
     * Sorts items based on given column and grouping info
     * @param items Items to Sorts
     * @param columnKey Column on which sorting needs to be done
     * @param groups Group By info
     * @param requestFields List item field/column type information
     * @param isSortedDescending Should items be sorted in ascending order.
     */
    public static copyAndSortDetailsList<T>(
        items: T[],
        columnKey: string,
        groups: IGroup[],
        requestFields: { [key in keyof T]: FieldInfoTyped<T> },
        isSortedDescending?: boolean
    ): T[] {
        let sortedItems: T[] = [];
        const key = columnKey as keyof T;

        if (groups && groups.length) {
            groups.forEach((group) => {
                if (group.children && group.children.length) {
                    group.children.forEach((nestedGroup) => {
                        let tempSortedItems = CommonService.sortItems<T>(
                            items,
                            key,
                            isSortedDescending,
                            requestFields[key].type,
                            nestedGroup.startIndex,
                            nestedGroup.startIndex + nestedGroup.count,
                            requestFields[key].lookupField
                        );
                        sortedItems = sortedItems.concat(tempSortedItems);
                    });
                } else {
                    let tempSortedItems = CommonService.sortItems<T>(
                        items,
                        key,
                        isSortedDescending,
                        requestFields[key].type,
                        group.startIndex,
                        group.startIndex + group.count,
                        requestFields[key].lookupField
                    );
                    sortedItems = sortedItems.concat(tempSortedItems);
                }
            });

            return sortedItems;
        } else {
            return CommonService.sortItems<T>(
                items,
                key,
                isSortedDescending,
                requestFields[key].type,
                0,
                0,
                requestFields[key].lookupField
            );
        }
    }

    /**
     * Sorts items based on given columns and grouping info
     * @param items Items to Sorts
     * @param columnKeys Columns on which sorting needs to be done
     * @param groups Group By info
     * @param requestFields List item field/column type information
     */
    public static copyAndSortMultipleColumns<T>(
        items: T[],
        columnKeys: { name: keyof T; ascending: boolean }[],
        groups: IGroup[],
        requestFields: { [key in keyof T]: FieldInfoTyped<T> }
    ): T[] {
        let sortedItems: T[] = [];

        if (groups && groups.length) {
            groups.forEach((group) => {
                if (group.children && group.children.length) {
                    group.children.forEach((nestedGroup) => {
                        let tempSortedItems = CommonService.sortItemsMultipleParams<T>(
                            items,
                            columnKeys,
                            requestFields,
                            nestedGroup.startIndex,
                            nestedGroup.startIndex + nestedGroup.count
                        );
                        sortedItems = sortedItems.concat(tempSortedItems);
                    });
                } else {
                    let tempSortedItems = CommonService.sortItemsMultipleParams<T>(
                        items,
                        columnKeys,
                        requestFields,
                        group.startIndex,
                        group.startIndex + group.count
                    );
                    sortedItems = sortedItems.concat(tempSortedItems);
                }
            });

            return sortedItems;
        } else {
            return CommonService.sortItemsMultipleParams<T>(items, columnKeys, requestFields, 0);
        }
    }

    /**
     * Sorts items based on single parameter/Column
     * @param unsortedItems Items to Sorts
     * @param fieldName Name of property on which sorting should be done
     * @param isSortedDescending Should items be sorted in ascending order.
     * @param fieldType Type of the property
     * @param startIndex Starting index from where elements of List should be taken for sorting
     * @param endIndex Ending index till which elements of List should be taken for sorting
     */
    public static sortItems<T>(
        unsortedItems: T[],
        fieldName: keyof T,
        isSortedDescending: boolean,
        fieldType: FieldType,
        startIndex: number,
        endIndex?: number,
        lookupFieldName?: string
    ): T[] {
        if (endIndex === 0 || !endIndex) unsortedItems = unsortedItems.slice(startIndex);
        else unsortedItems = unsortedItems.slice(startIndex, endIndex);

        return unsortedItems.sort((a: T, b: T) => {
            let aValue = this.getFieldValueForTable(a[fieldName], fieldType, "", lookupFieldName);
            let bValue = this.getFieldValueForTable(b[fieldName], fieldType, "", lookupFieldName);

            if (typeof aValue === "string") {
                aValue = aValue.toLowerCase();
            }

            if (typeof bValue === "string") {
                bValue = bValue.toLowerCase();
            }

            if (isSortedDescending) {
                if (aValue > bValue) return -1;
                if (aValue < bValue) return 1;

                return 0;
            } else {
                if (aValue > bValue) return 1;
                if (aValue < bValue) return -1;

                return 0;
            }
        });
    }

    /**
     * Sorts items based on multiple parameters
     * @param unsortedItems List of unsorted items
     * @param fields List of fields on which sorting should be done.
     * @param requestFields List item field/column information object
     * @param startIndex Starting index from where elements of List should be taken for sorting
     * @param endIndex Ending index till which elements of List should be taken for sorting
     */
    public static sortItemsMultipleParams<T>(
        unsortedItems: T[],
        fields: { name: keyof T; ascending: boolean }[],
        requestFields: { [key in keyof T]: FieldInfoTyped<T> },
        startIndex: number,
        endIndex?: number
    ): T[] {
        if (endIndex === 0 || !endIndex) unsortedItems = unsortedItems.slice(startIndex);
        else unsortedItems = unsortedItems.slice(startIndex, endIndex);

        return unsortedItems.sort((a: T, b: T) => {
            let sortValue: any;

            fields.forEach((field) => {
                let aValue = this.getFieldValueForTable(
                    a[field.name],
                    requestFields[field.name].type,
                    "",
                    requestFields[field.name].lookupField
                );
                let bValue = this.getFieldValueForTable(
                    b[field.name],
                    requestFields[field.name].type,
                    "",
                    requestFields[field.name].lookupField
                );

                if (typeof aValue === "string") {
                    aValue = aValue.toLowerCase();
                }

                if (typeof bValue === "string") {
                    bValue = bValue.toLowerCase();
                }

                if (!field.ascending) {
                    if (aValue > bValue) sortValue = sortValue ? sortValue || -1 : -1;
                    if (aValue < bValue) sortValue = sortValue ? sortValue || 1 : 1;
                } else {
                    if (aValue > bValue) sortValue = sortValue ? sortValue || 1 : 1;
                    if (aValue < bValue) sortValue = sortValue ? sortValue || -1 : -1;
                }
            });

            return sortValue;
        });
    }

    /**
     * Get value of a field of list item in excel export format.
     * @param fieldValue Value of the list item field/column.
     * @param fieldType Type of field/column
     * @param defaultValueToShow Default value to assign if it is 'null' or 'undefined'
     */
    public static getFieldValueForTable(
        fieldValue: any,
        fieldType: FieldType,
        defaultValueToShow: any = "",
        lookupFieldName?: string
    ): any {
        switch (fieldType) {
            case FieldType.LookUp:
                if (lookupFieldName) {
                    return fieldValue?.[lookupFieldName] ?? defaultValueToShow;
                }
                return defaultValueToShow;

            case FieldType.MultiUser:
                return fieldValue?.length
                    ? fieldValue
                        .map((person: IUser | IUserGroup) => (person as IUser).displayName || (person as IUserGroup).name)
                        .join(", ")
                    : defaultValueToShow;

            case FieldType.User:
                return (fieldValue as IUser)?.displayName ?? defaultValueToShow;

            case FieldType.DateTime:
                return fieldValue ?? defaultValueToShow;

            case FieldType.Date:
                if (fieldValue) {
                    fieldValue.setHours(0, 0, 0, 0);
                    return fieldValue;
                }
                return defaultValueToShow;

            case FieldType.Boolean:
                if (fieldValue === null || fieldValue === undefined) {
                    return defaultValueToShow;
                } else {
                    return fieldValue ? "Yes" : "No";
                }

            case FieldType.MultiLine:
                if (fieldValue) {
                    if (typeof fieldValue === "object") {
                        return JSON.stringify(fieldValue);
                    } else {
                        return fieldValue;
                    }
                } else return defaultValueToShow;

            case FieldType.Text:
                return fieldValue ? fieldValue : defaultValueToShow;

            case FieldType.Decimal:
                return fieldValue ? parseFloat(fieldValue) : defaultValueToShow;

            case FieldType.Int:
                return fieldValue !== null && fieldValue !== undefined ? parseInt(fieldValue, 10) : defaultValueToShow;

            case FieldType.Attachment:
                return fieldValue && typeof fieldValue === "object" ? (fieldValue as IAttachment).fileName : defaultValueToShow;

            case FieldType.MultiAttachment:
                return fieldValue && typeof fieldValue === "object"
                    ? (fieldValue as IAttachment[]).map((x) => x.fileName).join(", ")
                    : defaultValueToShow;

            default:
                return this.getFieldValueAsString(fieldValue);
        }
    }

    /**
     * Get value of a property of a List Item in string format
     * @param fieldValue List item property which needs to be converted into string
     * @param fieldType Type of field
     */
    public static getRequestFieldValueAsString(fieldValue: any, fieldType: FieldType, lookupFieldName?: string): any {
        let element: any;

        if (fieldValue !== null && fieldValue !== undefined) {
            switch (fieldType) {
                case FieldType.LookUp:
                    element = fieldValue[lookupFieldName] ?? "";
                    break;

                case FieldType.MultiUser:
                    element = fieldValue?.length
                        ? fieldValue.map((person: IUser | IUserGroup) => (person as IUser).displayName ?? (person as IUserGroup).name)
                            .join(", ")
                        : "";
                    break;

                case FieldType.User:
                    element = (fieldValue as IUser).displayName ?? (fieldValue as IUserGroup).name;
                    break;

                case FieldType.DateTime:
                    element = formatISODateTime(fieldValue);
                    break;

                case FieldType.Date:
                    element = formatISODate(fieldValue);
                    break;

                case FieldType.Boolean:
                    element = fieldValue ? "Yes" : "No";
                    break;

                case FieldType.MultiLine:
                    element = fieldValue.replace(/\n/g, "<br>");
                    break;

                case FieldType.Text:
                    element = fieldValue;
                    break;

                case FieldType.Attachment:
                    element = typeof fieldValue === "object" ? (fieldValue as IAttachment).fileName : "";
                    break;

                case FieldType.MultiAttachment:
                    element = typeof fieldValue === "object" ? (fieldValue as IAttachment[]).map((x) => x.fileName).join(", ") : "";
                    break;

                default:
                    element = this.getFieldValueAsString(fieldValue);
            }
        } else {
            element = "";
        }

        return element.toString();
    }

    /**
     * Get value of a field of list item in excel export format.
     * @param fieldValue Value of the list item field/column.
     * @param fieldType Type of field/column
     * @param defaultValueToShow Default value to assign if it is 'null' or 'undefined'
     */
    public static getRequestFieldValueForExcelExport(fieldValue: any, fieldType: FieldType, defaultValueToShow: any = ""): any {
        switch (fieldType) {
            case FieldType.MultiUser:
                return fieldValue && fieldValue.length
                    ? fieldValue.map((person: IUser | IUserGroup) => (person as IUser).displayName ?? (person as IUserGroup).name)
                        .join(", ")
                    : defaultValueToShow;

            case FieldType.User:
                return fieldValue ? fieldValue["Title"] : defaultValueToShow;

            case FieldType.DateTime:
                return fieldValue ? fieldValue : defaultValueToShow;

            case FieldType.Date:
                if (fieldValue) {
                    fieldValue.setHours(0, 0, 0, 0);
                    return fieldValue;
                }
                return defaultValueToShow;

            case FieldType.Boolean:
                return fieldValue === null || fieldValue === undefined
                    ? defaultValueToShow : fieldValue ? "Yes" : "No";

            case FieldType.MultiLine:
                if (fieldValue) {
                    if (typeof fieldValue === "object") {
                        return JSON.stringify(fieldValue);
                    } else {
                        return fieldValue;
                    }
                } else return defaultValueToShow;

            case FieldType.Text:
                return fieldValue ? fieldValue : defaultValueToShow;

            case FieldType.Int:
            case FieldType.Decimal:
                return fieldValue ? parseFloat(fieldValue) : defaultValueToShow;
            default:
                return this.getFieldValueAsString(fieldValue);
        }
    }

    /**
     * Gets value of a property from an object in string format
     * @param fieldValue Object property which needs to be converted into string
     */
    public static getFieldValueAsString(fieldValue: any) {
        let element: any;

        if (fieldValue != null) {
            switch (typeof fieldValue) {
                case "object":
                    if (fieldValue.constructor === Array) {
                        if (fieldValue[0].constructor === Array) {
                            element = fieldValue.join(", ");
                        } else {
                            let objectArrayString = "";
                            fieldValue.forEach((ele, index) => {
                                let objectString = "";
                                for (const key in ele) {
                                    if (ele.hasOwnProperty(key)) {
                                        objectString += `${key}: ${ele[key]}\n`;
                                    }
                                }
                                objectString = `{ \n ${objectString} \n}`;
                                if (index === 0) objectArrayString = objectString;
                                else objectArrayString += `,\n ${objectString}`;
                            });
                            element = objectArrayString;
                        }
                    } else if (fieldValue.constructor === Date) {
                        if (fieldValue.getHours() === 0 && fieldValue.getMinutes() === 0 && fieldValue.getSeconds() === 0)
                            element = formatISODate(fieldValue);
                        else
                            element = formatISODateTime(fieldValue);
                    } else {
                        let objectString = "";
                        for (const key in fieldValue) {
                            if (fieldValue.hasOwnProperty(key)) {
                                objectString += `${key}: ${fieldValue[key]}\n`;
                            }
                        }
                        objectString = `{ \n ${objectString} \n}`;
                        element = objectString;
                    }
                    break;

                case "boolean":
                    element = fieldValue ? "Yes" : "No";
                    break;

                default:
                    element = fieldValue;
            }
        } else element = "";

        return element;
    }

    public static filterItems<T>(items: T[], itemFieldInfo: ObjectFieldInfoTyped<T>, filterText: string) {
        if (filterText) {
            filterText = filterText.toLowerCase();
            const filteredData = items.filter((item: T) => {
                return CommonService.findValueInObject(item, itemFieldInfo as ObjectFieldInfo, filterText);
            });

            return filteredData;
        }

        return items;
    }

    public static findValueInObject(item: any, itemFieldInfo: ObjectFieldInfo, filterText: string): boolean {
        let found = false;

        for (const prop in itemFieldInfo) {
            const fieldInfo: FieldInfo = itemFieldInfo[prop];
            if (item[fieldInfo.name] && fieldInfo) {
                if (fieldInfo.type === FieldType.Custom && fieldInfo.fieldInfo) {
                    found = CommonService.findValueInObject(item[fieldInfo.name], fieldInfo.fieldInfo, filterText);
                    if (found) break;
                } else {
                    const value = CommonService.getRequestFieldValueAsString(item[fieldInfo.name], fieldInfo.type, fieldInfo.lookupField);
                    found = value && value.toLowerCase().indexOf(filterText) > -1;
                    if (found) break;
                }
            }
        }

        return found;
    }

    public static showErrorToast(msg: string, autoClose: number | false = false, options?: ToastOptions<{}>) {
        let mergedOptions: ToastOptions<{}> = {
            position: "top-center",
            hideProgressBar: true,
            type: "error",
            autoClose: autoClose,
        };

        if (options) {
            mergedOptions = {
                ...options,
                ...mergedOptions
            };
        }

        if (msg.toLocaleLowerCase().indexOf("failed to fetch".toLocaleLowerCase()) > -1) {
            msg = TextConstants.Common.Error_Message_FailedToFetch;
        }

        toast(<div style={{ color: stylingVariable.straumannErrorColor, fontSize: '0.85rem', whiteSpace: 'pre-line', wordBreak: 'break-word' }}>{msg}</div>, mergedOptions);
    }

    public static showWarningToast(msg: string, autoClose: number | false = false, options?: ToastOptions<{}>) {
        let mergedOptions: ToastOptions<{}> = {
            position: "top-center",
            hideProgressBar: true,
            type: "warning",
            autoClose: autoClose,
        };

        if (options) {
            mergedOptions = {
                ...options,
                ...mergedOptions
            };
        }

        if (msg.toLocaleLowerCase().indexOf("failed to fetch".toLocaleLowerCase()) > -1) {
            msg = TextConstants.Common.Error_Message_FailedToFetch;
        }

        toast(<div style={{ color: stylingVariable.straumannErrorColor, fontSize: '0.85rem', whiteSpace: 'pre-line', wordBreak: 'break-word' }}>{msg}</div>, mergedOptions);
    }

    public static showCommentToast(content: React.ReactElement, options?: ToastOptions<{}>) {
        let mergedOptions: ToastOptions<{}> = {
            position: "bottom-right",
            hideProgressBar: true,
            type: "info",
            autoClose: 10000,
        };

        if (options) {
            mergedOptions = {
                ...options,
                ...mergedOptions
            };
        }

        toast(<div>{content}</div>, mergedOptions);
    }

    public static showSuccessToast(msg: string, autoClose: number | false = undefined, options?: ToastOptions<{}>) {
        let mergedOptions: ToastOptions<{}> = {
            position: "top-center",
            hideProgressBar: true,
            type: "success",
            autoClose: autoClose,
        };

        if (options) {
            mergedOptions = {
                ...options,
                ...mergedOptions
            };
        }

        toast(<div style={{ color: "#50cd89" }}>{msg}</div>, mergedOptions);
    }

    public static deepCloneUsingLodash<T>(obj: T): T {
        if (obj) {
            try {
                return cloneDeep(obj);
            } catch (error) {
                return obj;
            }
        }

        return obj;
    }

    public static deepCloneUsingJSONParsing<T>(obj: T, fieldInfoList: ObjectFieldInfo = null): T {
        if (obj) {
            try {
                const clonedObj = JSON.parse(JSON.stringify(obj));
                if (fieldInfoList) {
                    return CommonService.fixTimeStampColumnOfObject(clonedObj, fieldInfoList);
                }

                return clonedObj;
            } catch (error) {
                return obj;
            }
        }

        return obj;
    }

    public static getCurrentDateFormat() {
        const dateSample = Intl.DateTimeFormat().format();
    }

    public static convertSpeedUpUserToCCSUser(speedUpUser: IUser) {
        return {
            Id: `${speedUpUser.id}`,
            Email: speedUpUser.email,
            IsAdmin: speedUpUser.isAdmin,
            LoginName: speedUpUser.userName,
            Title: speedUpUser.displayName,
            ObjectType: speedUpUser.isStraumannUser ? "Internal User" : "External User", // TODO: Verify with Shashank if that's correct!
            Payload: speedUpUser,
            Groups: speedUpUser.groups.map((g) => this.convertSpeedUpGroupToCCSGroup({ id: -1, name: g } as IUserGroup)), // TODO: Properly get group with id here
        } as CCSUser;
    }

    public static convertSpeedUpGroupToCCSGroup(speedUpGroup: IUserGroup) {
        return {
            Id: `${speedUpGroup.id}`,
            Title: speedUpGroup.name,
        } as CCSUserGroup;
    }

    public static getFieldLabel(fieldName: string, defaultLabel: string, entityName: EntityNameType, tooltipData: ObjectFieldInfoType) {
        return tooltipData?.[entityName]?.[fieldName]?.label ? tooltipData?.[entityName]?.[fieldName]?.label : defaultLabel;
    }

    public static readSessionStorage(key: string, isObjectString: boolean = false): any {
        try {
            const data: string = sessionStorage.getItem(key);
            if (data) {
                return isObjectString ? JSON.parse(data) : data;
            }
            return data;
        } catch (err) {
            console.error(TextConstants.Common.Error_Message_SessionStorageDisabled);
            return null;
        }
    }

    public static setSessionStorage(key: string, data: any, isObject: boolean = false) {
        try {
            if (data) {
                sessionStorage.setItem(key, isObject ? JSON.stringify(data) : data);
            }
        } catch (error) {
            console.error(TextConstants.Common.Error_Message_SessionStorageDisabled);
        }
    }

    public static removeSessionStorage(key: string) {
        try {
            sessionStorage.removeItem(key);
        } catch (error) {
            console.error(TextConstants.Common.Error_Message_SessionStorageDisabled);
        }
    }

    public static readLocalStorage(key: string, isObjectString: boolean = false): any {
        try {
            const data: string = localStorage.getItem(key);
            if (data) {
                return isObjectString ? JSON.parse(data) : data;
            }
            return data;
        } catch (err) {
            console.error(TextConstants.Common.Error_Message_LocalStorageDisabled);
            return null;
        }
    }

    public static setLocalStorage(key: string, data: any, isObject: boolean = false) {
        try {
            if (data) {
                localStorage.setItem(key, isObject ? JSON.stringify(data) : data);
            }
        } catch (error) {
            console.error(TextConstants.Common.Error_Message_LocalStorageDisabled);
        }
    }

    public static removeLocalStorage(key: string) {
        try {
            localStorage.removeItem(key);
        } catch (error) {
            console.error(TextConstants.Common.Error_Message_SessionStorageDisabled);
        }
    }

    public static addDaysToDate(date: Date, days: number): Date {
        return moment(date).add("days", days).toDate();
    }

    public static sortByFieldInObject<T>(a: T, b: T, fieldName: keyof T) {
        return this.sort(a[fieldName], b[fieldName]);
    }

    public static sortByTextFieldInObject<T>(a: T, b: T, fieldName: keyof T) {
        return this.sortByText(String(a[fieldName]), String(b[fieldName]));
    }

    public static sort(a: any, b: any) {
        let x = a ?? "";
        let y = b ?? "";
        if (x < y) { return -1; }
        if (x > y) { return 1; }
        return 0;
    }

    public static sortByText(a: string, b: string) {
        let x = a ? a.toLowerCase() : "";
        let y = b ? b.toLowerCase() : "";
        if (x < y) { return -1; }
        if (x > y) { return 1; }
        return 0;
    }

    public static getTaskTitleFromParams(step: RequestStepType, tab: RequestStepTab, taskName: keyof typeof ProjectRequestTask): ProjectRequestTask {
        let taskTitle: ProjectRequestTask;

        if (RequestStepTasks[step].TabTasks?.[tab]?.[taskName]) {
            taskTitle = RequestStepTasks[step].TabTasks?.[tab]?.[taskName];
        }
        else {
            taskTitle = RequestStepTasks[step].Tasks?.[taskName];
        }

        if (!taskTitle) {
            const error = `Task not defined for Step: ${step}, Tab: ${tab} and Task: ${taskName}`;
            // CommonService.showErrorToast(error, 1000);
            console.error(error);

        }

        return taskTitle;
    }

    public static getTaskTitleFromRespMatrixItem(respMatrixItem: IResponsibilityMatrix): ProjectRequestTask {
        return CommonService.getTaskTitleFromParams(respMatrixItem.requestStep, respMatrixItem.requestTab, respMatrixItem.task);
    }

    public static getRandomColor() {
        return "rgb(" + random(255) + "," + random(255) + "," + random(255) + ")";
    }
}
