import { ProcessCellForExportParams, ValueFormatterParams, ValueParserParams } from "ag-grid-community";
import { TextConstants } from "constant";
import { localeNumberFormatPartsIdentifiers, parseLocalizedNumber, validateLocalizedInteger, validateLocalizedNumber } from "functions";
import {
    AttachmentFields, BOMItemFields, DataLockSection, DataLockSectionType, Entity, FieldInfoTyped, FieldType, FieldValidationState, IAttachment, IAuditLog, IDataLockInfo, ILabelsAndTooltip,
    IMaterial, IProjectRequest, IProjectRequestComposite, IProjectRequestPayload, IProjectTask, ITranslation, MaterialFields,
    ObjectFieldInfo, ObjectFieldInfoType, ObjectFieldInfoTyped, ProjectRequestFields, RequestStep, RequestStepReverseMapping,
    RequestStepTab, RequestStepTabData, RequestStepType, SelectedRequestStepTab, TranslationFields
} from "interfaces";
import moment from "moment";
import { CommonService, ValidationService } from "services";

export type FormValidationFieldsStepTabType = {
    [key in RequestStepTab]?: {
        projectRequest?: ProjectRequestFields[];
        material?: MaterialFields[];
        translation?: TranslationFields[];
        bomItem?: BOMItemFields[];
        attachment?: AttachmentFields[]
    };
};

export const StartFormFields: FormValidationFieldsStepTabType = {
    ProjectInfo: {
        projectRequest: [
            "requester", "requestTitle", "description", "companyId", "brand", "hasCourseMaterials",
            "division", "isBOMNeeded", "isCamDBUsed", "camDBProcessId", "isLabelingNeeded", "isTranslationNeeded", "isEShopInvolved",
            "hasPotentialDangerousGoods", "temperatureSensitive"
        ],
        attachment: [
            "category", "contentType", "fileName", "requestStep", "projectRequestId"
        ]
    },
    ProductInfo: {
        material: [
            "materialNumber", "productDescription", "hasExpiryDate", "legalManufacturer", "workpackageOrbit", "projectNumber",
            "productHierarchy", "gtin", "hibc", "translations"
        ],
        attachment: [
            "category", "contentType", "fileName", "requestStep", "projectRequestId"
        ]
    },
    Translations: {
        translation: ["shortDescription", "productMasterText", "languageCode"],
    },
};

export const KickOffFormFields: FormValidationFieldsStepTabType = {
    PLMData: {
        projectRequest: ["plmDesignOwner", "plmChangeNumber"],
        material: ["materialNumber", "productDescription", "legalManufacturer", "materialType", "configType", "phaseStatus"],
    },
    LogisticsData: {
        projectRequest: ["logisticFlow", "distributionType", "hasLogisticTask"],
        material: ["materialNumber", "productDescription", "batchManaged", "hasSerialNumber", "cogsPlant", "gtin"],
    },
    CheckList: {
        projectRequest: ["checkList"],
    },
};

export const ProductMasterDataCheckFormFields: FormValidationFieldsStepTabType = {
    Translations: {
        translation: [
            "masterDescriptionLine1", "masterDescriptionLine2", "masterDescriptionLine3","masterDescriptionLine4", "productMasterText", "shortDescription",
            "languageCode"
        ],
    },
};

export const ExternalInputFormFields: FormValidationFieldsStepTabType = {
    PackagingAndHandling: {
        material: [
            "materialNumber", "grossWeight"
        ]
        // material: [
        //     "materialNumber", "primaryPackage", "contains", "grossWeight", "length", "width", "height", "volume", "specialTemperatureRequired",
        //     "minStorageTemperature", "maxStorageTemperature", "maxTimeTransport", "frostSensitive", "otherRequirements", "airFreightComp",
        //     "temperatureLoggerRequired",
        // ],
    },
    DangerousGoods: {
        material: [
            "materialNumber", "limitedQty", "dgClassADR", "unNumber", "containsBattery", "safetyDataSheetReady",
        ],
    },
    RA: {
        material: [
            "materialNumber", "medicalDevice", "gmdnCode", "emdnCode", "euRule", "euClass", "ecCertificateReceived",
            "euComplianceLegislation", "itemCategoryApplies", "countriesAlreadyRegistered", "countriesWhereUnderRegisteration", "sterile",
            "forSingleUse", "sterilizationByUser", "sterilizationMethod", "rawMaterials", "ifuAvailable",
        ],
    },
    Customs: {
        material: ["materialNumber", "countryOfOrigin", "customsTariffCode"],
    },
};

export const TechnicalReadinessFormFields: FormValidationFieldsStepTabType = {
    Controlling: {
        material: ["materialNumber", "labOffice","prH5"],
    },
    RADB: {
        material: [
            "materialNumber", "basicUDIDI", "unitOfUseDI", "gmdnCode", "emdnCode", "needSterilization", "mriStatus", "kit", "comboProduct",
            "dmApplicable", "dmApplied", "diameter", "length", "width", "height", "gingivaHeight", "abutmentHeight", "angle", "volume",
            "granuleSize", "grossWeight", "mdamdnCode", "mdsCodeI", "mdsCodeII", "mdsCodeIII", "mdtCodeI", "mdtCodeII", "mdtCodeIII",
        ],
    },
    Customs: {
        material: ["materialNumber", "countryOfOrigin", "customsTariffCode"],
    },
};

export const ProjectRequestFieldsByStepTab: { [key in RequestStepType]?: FormValidationFieldsStepTabType } = {
    Start: StartFormFields,
    ProductMasterDataText: ProductMasterDataCheckFormFields,
    KickOff: KickOffFormFields,
    ExternalInput: ExternalInputFormFields,
    TechnicalReadiness: TechnicalReadinessFormFields,
};

export const SectionToEntityMapping:
    RequestStepTabData<RequestStepTab, { [key in Entity]?: keyof IProjectRequestComposite }> = {
    Start: {
        ProjectInfo: { projectRequest: "projectRequestInfoStart", attachment: "attachments" },
        ProductInfo: { material: "startMaterials" },
        Translations: { translation: "startTranslations" },
    },
    KickOff: {
        PLMData: { projectRequest: "projectRequestInfoPLMData", material: "plmDataMaterials" },
        LogisticsData: { projectRequest: "projectRequestInfoLogisticsData", material: "logisticsDataMaterials" },
        CheckList: { projectRequest: "projectRequestInfoCheckList" },
    },
    ProductMasterDataText: {
        Translations: { translation: "productMasterDataTextTranslations" },
    },
    ExternalInput: {
        PackagingAndHandling: { material: "packagingAndHandlingMaterials" },
        DangerousGoods: { material: "dangerousGoodsMaterials" },
        RA: { material: "raMaterials" },
        Customs: { material: "customsMaterials" },
    },
    TechnicalReadiness: {
        Controlling: { material: "controllingMaterials" },
        RADB: { material: "radbMaterials" },
        Customs: { material: "customsMaterials" },
    }
}
export class Util {

    public static setMaterialsInProjectRequest(updatedProjectRequest: IProjectRequest, materials: IMaterial[]): IProjectRequest {
        if (updatedProjectRequest) {
            updatedProjectRequest.materials = materials;
        }

        return updatedProjectRequest;
    }

    public static addMaterialsInProjectRequest(updatedProjectRequest: IProjectRequest, materials: IMaterial[]): IProjectRequest {
        if (updatedProjectRequest?.materials?.length) {
            let currentMaterials = updatedProjectRequest.materials ?? [];
            currentMaterials = currentMaterials.concat(materials);
            updatedProjectRequest.materials = currentMaterials;
            updatedProjectRequest.isChanged = true;
        }

        return updatedProjectRequest;
    }

    public static updateMaterialInProjectRequest(updatedProjectRequest: IProjectRequest, material: IMaterial): IProjectRequest {
        if (updatedProjectRequest?.materials?.length) {
            let currentMaterials = updatedProjectRequest.materials;
            const index = currentMaterials.findIndex(x => x.id === material.id)
            if (index !== -1) {
                currentMaterials[index] = material;
                updatedProjectRequest.isChanged = true;
            }
            updatedProjectRequest.materials = currentMaterials;
        }

        return updatedProjectRequest;
    }

    private static removeMaterialFromMaterialList(materialId: number, currentMaterials: IMaterial[]) {
        if (currentMaterials?.length) {
            const index = currentMaterials.findIndex(x => x.id === materialId);
            if (index !== -1) {
                if (materialId <= 0) {
                    currentMaterials.splice(index, 1);
                }
                else {
                    currentMaterials[index].isDeleted = true;
                }
            }

        }
    }

    public static removeMaterialFromProjectRequest(updatedProjectRequest: IProjectRequest, materialId: number): IProjectRequest {
        if (updatedProjectRequest?.materials?.length) {
            let currentMaterials = updatedProjectRequest.materials ?? [];
            Util.removeMaterialFromMaterialList(materialId, currentMaterials);
            updatedProjectRequest.materials = currentMaterials;
            updatedProjectRequest.isChanged = true;
        }

        return updatedProjectRequest;
    }

    public static removeMaterialsFromProjectRequest(updatedProjectRequest: IProjectRequest, materialIds: number[]): IProjectRequest {

        if (updatedProjectRequest?.materials?.length) {
            let currentMaterials = updatedProjectRequest.materials;
            for (const materialId of materialIds) {
                Util.removeMaterialFromMaterialList(materialId, currentMaterials);
            }
            updatedProjectRequest.materials = currentMaterials;
            updatedProjectRequest.isChanged = true;
        }

        return updatedProjectRequest;
    }

    public static updateTranslationInMaterials(currentMaterials: IMaterial[], translation: ITranslation): IMaterial[] {
        const index = currentMaterials.findIndex(x => x.id === translation.materialId);

        if (index !== -1) {
            let indexTranslation = currentMaterials[index].translations.findIndex(x => x.id === translation.id);
            if (indexTranslation !== -1) {
                currentMaterials[index].isAnyTranslationUpdated = true;
                currentMaterials[index].translations[indexTranslation] = translation;
            }
        }

        return currentMaterials;
    }

    public static updateTranslationInMaterial(updatedProjectRequest: IProjectRequest, translation: ITranslation): IProjectRequest {
        if (updatedProjectRequest?.materials?.length) {
            let currentMaterials = updatedProjectRequest.materials;
            this.updateTranslationInMaterials(currentMaterials, translation);
            updatedProjectRequest.isChanged = true;
        }

        return updatedProjectRequest;
    }

    public static setSectionLock(updatedProjectRequest: IProjectRequest, dataLock: IDataLockInfo): IProjectRequest {
        if (updatedProjectRequest) {

            // const oldIdx = updatedProjectRequest.dataLocks.findIndex(l => l.id === dataLock.id);
            // if (oldIdx !== -1) {
            //     updatedProjectRequest.dataLocks.splice(oldIdx, 1);
            // }
            updatedProjectRequest.dataLocks = [...updatedProjectRequest.dataLocks.filter(x=>!(x.sectionId === DataLockSection.BOMItems && x.entityId === dataLock.entityId)  && x.id !== dataLock.id), dataLock];
        }

        return updatedProjectRequest;
    }

    public static removeSectionLock(updatedProjectRequest: IProjectRequest, lockId: number): IProjectRequest {
        if (updatedProjectRequest) {

            const idxLock = updatedProjectRequest.dataLocks.findIndex(l => l.id === lockId);
            if (idxLock !== -1) {
                let newLocks = [...updatedProjectRequest.dataLocks];
                newLocks.splice(idxLock, 1);
                updatedProjectRequest.dataLocks = newLocks;
            }
        }

        return updatedProjectRequest;
    }

    public static updateTranslationsInMaterial(updatedProjectRequest: IProjectRequest, translations: ITranslation[]): IProjectRequest {
        if (updatedProjectRequest?.materials?.length) {
            let currentMaterials = updatedProjectRequest.materials;
            for (const translation of translations) {
                this.updateTranslationInMaterials(currentMaterials, translation);
            }
            updatedProjectRequest.isChanged = true;
        }

        return updatedProjectRequest;
    }

    public static setProjectRequestWithExistingBOMItems(currentProjectRequest: IProjectRequest, projectRequestUpdated: IProjectRequest) {
        if (currentProjectRequest && projectRequestUpdated) {
            for (const key in projectRequestUpdated) {
                if (key === 'materials') {
                    const newMaterials = projectRequestUpdated.materials ?? [];
                    newMaterials.forEach(newMaterial => {
                        const existingMaterial = currentProjectRequest.materials.find(x => x.id === newMaterial.id);
                        if (existingMaterial) {
                            newMaterial.bomItems = existingMaterial.bomItems;
                        }
                    });
                    currentProjectRequest.materials = newMaterials;
                }
                else {
                    if (Object.prototype.hasOwnProperty.call(projectRequestUpdated, key)) {
                        currentProjectRequest[key as keyof IProjectRequest] = projectRequestUpdated[key as keyof IProjectRequest] as never;
                    }
                }
            }

            currentProjectRequest.isChanged = false;
            currentProjectRequest.isProjectMetadataChanged = false;
        }

        return currentProjectRequest;
    }

    public static resetProjectRequestChangeFlags(projectRequest: IProjectRequest): IProjectRequest {
        if (projectRequest) {
            projectRequest.isChanged = false;
            projectRequest.isProjectMetadataChanged = false;
        }

        return projectRequest;
    }

    public static resetMaterialChangeFlags(projectRequest: IProjectRequest): IProjectRequest {
        if (projectRequest) {
            projectRequest.isChanged = false;

            projectRequest.materials?.forEach(material => {
                material.isUpdated = false;
            });
        }

        return projectRequest;
    }

    public static resetTranslationChangeFlags(projectRequest: IProjectRequest): IProjectRequest {
        if (projectRequest) {
            projectRequest.isChanged = false;

            projectRequest.materials?.forEach(material => {
                material.isUpdated = false;

                material.translations?.forEach(translation => {
                    translation.isUpdated = false;
                });
            });
        }

        return projectRequest;
    }

    public static resetBOMItemsChangeFlags(projectRequest: IProjectRequest): IProjectRequest {
        if (projectRequest) {
            projectRequest.isChanged = false;

            projectRequest.materials?.forEach(material => {
                material.isUpdated = false;

                material.bomItems?.forEach(bomItem => {
                    bomItem.isUpdated = false;
                });
            });
        }

        return projectRequest;
    }

    public static resetAllChangeFlagsInProjectRequest(projectRequest: IProjectRequest): IProjectRequest {
        if (projectRequest) {
            projectRequest.isChanged = false;
            projectRequest.isProjectMetadataChanged = false;

            projectRequest.materials?.forEach(material => {
                material.isUpdated = false;

                material.translations?.forEach(translation => {
                    translation.isUpdated = false;
                });

                material.bomItems?.forEach(bomItem => {
                    bomItem.isUpdated = false;
                });
            });
        }

        return projectRequest;
    }

    public static updateProjectRequestTask(projectRequest: IProjectRequest, task: IProjectTask): IProjectRequest {
        if (projectRequest) {
            const idx = projectRequest.projectTasks.findIndex(t => t.id === task.id);
            if (idx !== -1)
                projectRequest.projectTasks[idx] = task;
        }

        return projectRequest;
    }

    public static getTransformedValidatedPastedValueForCell<T>(params: ProcessCellForExportParams<T>, objectFieldInfo: ObjectFieldInfoTyped<T>) {

        let date: Date;
        const fieldInfo: FieldInfoTyped<T> = objectFieldInfo[params.column.getColDef().field as keyof T];
        let validationState: FieldValidationState;
        let obj: T = params.node.data;
        obj[fieldInfo.name] = params.value;

        switch (fieldInfo.type) {
            case FieldType.Int:
            case FieldType.Decimal:
                validationState = ValidationService.validateObjectField(obj, fieldInfo, ["required"]);
                if (validationState.isValid) {
                    return Number(params.value);
                }
                else {
                    CommonService.showErrorToast(`${String(fieldInfo.name)}: ${validationState.error}`, 3000);
                    return null;
                }

            case FieldType.Date:
                date = !params.value ? null : moment(params.value).isValid() ? new Date(params.value) : null;
                if (date) {
                    validationState = ValidationService.validateObjectField(obj, fieldInfo, ["required"]);
                    if (validationState.isValid) {
                        date.setHours(0, 0, 0, 0);
                    }
                    else {
                        CommonService.showErrorToast(`${String(fieldInfo.name)}: ${validationState.error}`, 3000);
                    }
                }
                return date;

            case FieldType.DateTime:
                date = !params.value ? null : moment(params.value).isValid() ? new Date(params.value) : null;
                if (date) {
                    validationState = ValidationService.validateObjectField(obj, fieldInfo, ["required"]);
                    if (validationState.isValid) {
                        return date;
                    }
                    else {
                        CommonService.showErrorToast(`${String(fieldInfo.name)}: ${validationState.error}`, 3000);
                    }
                }

                return null;

            case FieldType.Boolean:
                if (params.value.toString().toLowerCase() === "yes" || params.value.toString().toLowerCase() === "true") {
                    return true;
                }
                else if (params.value.toString().toLowerCase() === "no" || params.value.toString().toLowerCase() === "false") {
                    return false;
                }
                else if (params.value === null || params.value === undefined || params.value.toString() === "") {
                    return null;
                }

                CommonService.showErrorToast(`${String(fieldInfo.name)}: ${TextConstants.Common.Error_Message_YesNoAllowed}`, 3000);
                return null;

            default:
                validationState = ValidationService.validateObjectField(obj, fieldInfo, ["required"]);
                if (validationState.isValid) {
                    return params.value;
                }
                else {
                    CommonService.showErrorToast(`${String(fieldInfo.name)}: ${validationState.error}`, 3000);
                }
                return null;
        }

    }

    public static valueParserIntegerField = <T>(fieldInfo: FieldInfoTyped<T>) => (params: ValueParserParams<T>) => {
        if (ValidationService.isValidIntergerField(params.newValue)) {
            return params.newValue ? Number(params.newValue) : null;
        }
        else {
            CommonService.showErrorToast(`${fieldInfo?.label ?? "Field error"}: ${TextConstants.Common.Error_Message_InvalidInteger}`, 3000);

            if (params.newValue === "") {
                return null;
            }
            return params.data[fieldInfo?.name];
        }
    }

    public static valueParserNumericField = <T>(fieldInfo: FieldInfoTyped<T>) => (params: ValueParserParams<T>) => {
        if (ValidationService.isValidNumberField(params.newValue)) {
            return params.newValue ? Number(params.newValue) : null;
        }
        else {
            CommonService.showErrorToast(`${fieldInfo?.label ?? "Field error"}: ${TextConstants.Common.Error_Message_InvalidNumber}`, 3000);

            if (params.newValue === "") {
                return null;
            }
            return params.data[fieldInfo?.name];
        }
    }

    public static localizedIntegerValueFormatter = <T>(fieldInfo: FieldInfoTyped<T>, locale?: string) => (params: ValueFormatterParams<T>): string => {
        const newValue = params.value; // params.data[fieldInfo.name as keyof T] ? String(params.data[fieldInfo.name as keyof T]) : null;

        if (validateLocalizedInteger(newValue, locale)) {
            const { decimal } = localeNumberFormatPartsIdentifiers;

            let value = newValue ? parseLocalizedNumber(newValue, locale).toLocaleString(locale ?? navigator.language) : "";
            // console.log(`%cFormatted value: ${value}`, "color:red;");
            if (newValue !== null && value !== null && newValue !== undefined && value !== undefined && value !== newValue) {
                const newValueSplit = newValue?.split(decimal);
                const valueSplit = value?.split(decimal);
                if (newValueSplit?.length > 1) {
                    value = `${valueSplit[0]}${decimal}${newValueSplit[1]}`;
                }

            }
            return value;
        }
        else {
            //CommonService.showErrorToast(`${fieldInfo?.label ?? "Field error"}: ${TextConstants.Common.Error_Message_InvalidInteger}. [Numbers shall be grouped with comma (",") only]`, 3000);

            if (newValue === "") {
                return "";
            }
            return String(params.data[fieldInfo?.name]);
        }
    }

    public static localizedNumericValueFormatter = <T>(fieldInfo: FieldInfoTyped<T>, locale?: string) => (params: ValueFormatterParams<T>): string => {
        const newValue = params.value; // params.data[fieldInfo.name as keyof T] ? String(params.data[fieldInfo.name as keyof T]) : null;

        if (validateLocalizedNumber(newValue, locale)) {
            const { decimal } = localeNumberFormatPartsIdentifiers;

            let value = newValue ? parseLocalizedNumber(newValue, locale).toLocaleString(locale ?? navigator.language) : "";
            // console.log(`%cFormatted value: ${value}`, "color:red;");
            if (newValue !== null && value !== null && newValue !== undefined && value !== undefined && value !== newValue) {
                const newValueSplit = newValue?.split(decimal);
                const valueSplit = value?.split(decimal);
                if (newValueSplit?.length > 1) {
                    value = `${valueSplit[0]}${decimal}${newValueSplit[1]}`;
                }

            }
            return value;
        }
        else {
            //CommonService.showErrorToast(`${fieldInfo?.label ?? "Field error"}: ${TextConstants.Common.Error_Message_InvalidNumber}`, 3000);

            if (newValue === "") {
                return "";
            }
            return String(params.data[fieldInfo?.name]);
        }
    }

    public static parseLocalizedIntegerValue = <T>(fieldInfo: FieldInfoTyped<T>, newValue: any, currentValue: any, locale?: string) => {
        if (validateLocalizedInteger(newValue, locale)) {
            return newValue ? String(parseLocalizedNumber(newValue, locale)) : null;
        }
        else {
            CommonService.showErrorToast(`${fieldInfo?.label ?? "Field error"}: ${TextConstants.Common.Error_Message_InvalidInteger}`, 3000);

            if (newValue === "") {
                return null;
            }
            return currentValue;
        }
    }

    public static parseLocalizedNumericValue = <T>(fieldInfo: FieldInfoTyped<T>, newValue: any, currentValue: any, locale?: string, showErrorToast: boolean = true) => {
        if (validateLocalizedNumber(newValue, locale)) {
            let value = newValue;

            const { decimal, group } = localeNumberFormatPartsIdentifiers;
            if (newValue.includes(group)) {
                value = newValue ? String(parseLocalizedNumber(newValue, locale)) : null;

                if (newValue !== null && value !== null && newValue !== undefined && value !== undefined) {
                    const newValueSplit = newValue?.split(decimal);
                    const valueSplit = value?.split(decimal);
                    if (newValueSplit?.length > 1) {
                        value = `${valueSplit[0]}${decimal}${newValueSplit[1]}`;
                    }
                }
            }

            return value ?? null;
        }
        else {
            if(showErrorToast){
                CommonService.showErrorToast(`${fieldInfo?.label ?? "Field error"}: ${TextConstants.Common.Error_Message_InvalidNumber} [Numbers with decimals shall be seperated with dot ("."), not comma (",")]`, 3000);
            }

            if (newValue === "") {
                return null;
            }
            return currentValue;
        }
    }

    public static localizedIntegerValueParser = <T>(fieldInfo: FieldInfoTyped<T>, locale?: string) => (params: ValueParserParams<T>) => {
        const newValue = params.newValue;
        if(newValue === undefined || newValue === null){
            return newValue;
        }
        return Util.parseLocalizedIntegerValue(fieldInfo, newValue, params.data[fieldInfo?.name], locale);
    }

    public static localizedNumericValueParser = <T>(fieldInfo: FieldInfoTyped<T>, locale?: string) => (params: ValueParserParams<T>) => {
        const newValue = params.newValue;
        if(newValue === undefined || newValue === null){
            return newValue;
        }
        return Util.parseLocalizedNumericValue(fieldInfo, newValue, params.data[fieldInfo?.name], locale);
    }

    public static getFieldInfoForStep(fieldAndLabelInfosForStep: ILabelsAndTooltip[]): ObjectFieldInfoType {
        const fieldsInfoType: ObjectFieldInfoType = {};

        fieldAndLabelInfosForStep.forEach(fieldAndLabelInfo => {
            if (!fieldsInfoType[fieldAndLabelInfo.entityName]) {
                fieldsInfoType[fieldAndLabelInfo.entityName] = {};
            }

            fieldsInfoType[fieldAndLabelInfo.entityName][fieldAndLabelInfo.fieldName] = {
                label: fieldAndLabelInfo.label,
                description: fieldAndLabelInfo.description
            };
        });

        return fieldsInfoType;
    }

    public static updateSimpleApprovalTasksInProjectRequest(projectRequest: IProjectRequest, simpleApprovalTasks: IProjectTask[]): IProjectRequest {
        if (projectRequest) {
            simpleApprovalTasks.forEach(st => {
                const idx = projectRequest.projectTasks.findIndex(t => t.id === st.id);
                if (idx !== -1)
                    projectRequest.projectTasks[idx] = st;
            });
        }

        return projectRequest;
    }

    public static addProjectInfoToCompositeRequest(selectedRequestStepTab: SelectedRequestStepTab, entity: IProjectRequest, objectFieldInfos: ObjectFieldInfoTyped<IProjectRequest>, projectRequestComposite: IProjectRequestComposite) {
        //For all step tabs where data has been changed create sectional data
        for (const key in selectedRequestStepTab) {
            const step = key as RequestStepType;
            for (const tab of selectedRequestStepTab[step]) {
                /**
                 * Check if mapping between section and section data field has been defined.
                 * If it is defined, then put data into respective sectional objects.
                 * If it does not exists then you need to define the section to entity mapping
                 * in "SectionToEntityMapping" object.
                */

                if (SectionToEntityMapping?.[step]?.[tab]?.[Entity.ProjectRequest]) {
                    const fieldKey = SectionToEntityMapping[step][tab][Entity.ProjectRequest];

                    if (!projectRequestComposite[fieldKey]) {
                        (projectRequestComposite[fieldKey] as Partial<IProjectRequest>) = {
                            id: entity.id
                        } as any;
                    }

                    let projectInfoField = projectRequestComposite[fieldKey] as Partial<IProjectRequest>;

                    if (ProjectRequestFieldsByStepTab?.[step]?.[tab]?.[Entity.ProjectRequest]) {
                        for (const field of ProjectRequestFieldsByStepTab[step][tab][Entity.ProjectRequest]) {
                            const fieldInfo = objectFieldInfos[field as keyof IProjectRequest];
                            if (fieldInfo) {
                                if (fieldInfo.type === FieldType.User) {
                                    const idFieldName = `${fieldInfo.name}Id` as keyof IProjectRequest;
                                    if (entity[idFieldName]) {
                                        projectInfoField[idFieldName] = entity[idFieldName] as never;
                                    }
                                    else {
                                        projectInfoField[idFieldName] = 0 as never;
                                        projectInfoField[field as keyof IProjectRequest] = entity[field as keyof IProjectRequest] as never;
                                    }
                                }
                                else {
                                    projectInfoField[field as keyof IProjectRequest] = entity[field as keyof IProjectRequest] as never;
                                }
                            }
                            else {
                                projectInfoField[field as keyof IProjectRequest] = entity[field as keyof IProjectRequest] as never;
                            }
                        }
                    }
                    else {
                        /**
                         * Ideally code should not come here, if it comes here that means some configuration is 
                         * missing in "ProjectRequestFieldsByStepTab"
                         */
                        projectRequestComposite[fieldKey] = { ...entity } as any;
                    }
                }
            }
        }
    }

    public static addEntityToCompositeRequest<T extends { id?: number; }>(selectedRequestStepTab: SelectedRequestStepTab, entity: T, entityType: Entity, objectFieldInfos: ObjectFieldInfoTyped<T>, projectRequestComposite: IProjectRequestComposite) {
        //For all step tabs where data has been changed create sectional data
        for (const key in selectedRequestStepTab) {
            const step = key as RequestStepType;
            for (const tab of selectedRequestStepTab[step]) {
                /**
                 * Check if mapping between section and section data field has been defined.
                 * If it is defined, then put data into respective sectional objects.
                 * If it does not exists then you need to define the section to entity mapping
                 * in "SectionToEntityMapping" object.
                */

                if (SectionToEntityMapping?.[step]?.[tab]?.[entityType]) {
                    const fieldKey = SectionToEntityMapping[step][tab][entityType];
                    let existingChangedEntity: Partial<T>;
                    // const fieldCurrentLength = (projectRequestComposite[fieldKey] as Array<Partial<T>>)?.length ?? -1;
                    /**
                     * Check if data exists in section data field.
                     * If section data field exists, then check if the entry for the entity edited exists there already or not.
                     * If entry for the entity edited exists, then find that entry and then push data into it using ProjectRequestFieldsByStepTab.
                     * If entry for the entity edited does not exists, then create new blank entry for entity, and then, 
                     * push data into it using ProjectRequestFieldsByStepTab.
                     * If section data field does not exists, then initialize section data field, then, 
                     * create new blank entry for entity, and then, push data into it using ProjectRequestFieldsByStepTab.
                    */

                    if (!projectRequestComposite[fieldKey]) {
                        projectRequestComposite[fieldKey] = [] as Array<Partial<T>>;
                    }
                    else {
                        existingChangedEntity = (projectRequestComposite[fieldKey] as Array<Partial<T>>).find(x => x.id === entity.id);
                        // existingChangedEntity = (projectRequestComposite[fieldKey] as Array<Partial<T>>)[fieldCurrentLength - 1];
                    }

                    if (existingChangedEntity) {
                        //Only add changes to existing modified changes if we have definition, otherwise don't add.
                        if (ProjectRequestFieldsByStepTab?.[step]?.[tab]?.[entityType as keyof object]) {
                            const fieldsByStepTab = ProjectRequestFieldsByStepTab[step][tab];
                            for (const field of fieldsByStepTab[entityType as keyof typeof fieldsByStepTab]) {
                                existingChangedEntity[field as keyof T] = entity[field as keyof T] as never;
                            }
                        }
                    }
                    else {
                        let changedEntity: Partial<T> = {};
                        // changedEntity.id = entity.id <= 0 ? 0 : entity.id;
                        changedEntity.id = entity.id;

                        if (entityType === Entity.Translation) {
                            (changedEntity as Partial<ITranslation>).materialId = (entity as Partial<ITranslation>).materialId;
                            if (entity.id <= 0) {
                                (changedEntity as Partial<ITranslation>).projectRequestId = (entity as Partial<ITranslation>).projectRequestId;
                                (changedEntity as Partial<ITranslation>).languageCode = (entity as Partial<ITranslation>).languageCode;
                            }

                        }
                        else if (entityType === Entity.Material) {
                            (changedEntity as Partial<IMaterial>).projectRequestId = (entity as IMaterial).projectRequestId;
                        }

                        if (ProjectRequestFieldsByStepTab?.[step]?.[tab]?.[entityType]) {
                            for (const field of ProjectRequestFieldsByStepTab[step][tab][entityType]) {
                                const fieldInfo = objectFieldInfos[field as keyof T];
                                if (fieldInfo) {
                                    if (fieldInfo.type === FieldType.User) {
                                        const idFieldName = `${String(fieldInfo.name)}Id` as keyof T;
                                        if (entity[idFieldName]) {
                                            changedEntity[idFieldName] = entity[idFieldName] as never;
                                        }
                                        else {
                                            changedEntity[idFieldName] = 0 as never;
                                            changedEntity[field as keyof T] = entity[field as keyof T] as never;
                                        }
                                    }
                                    else if (fieldInfo.type === FieldType.CustomArray) {
                                        if (changedEntity.id <= 0) {
                                            changedEntity[field as keyof T] = (entity[field as keyof T] as unknown as any[])?.map(x => {
                                                const newValue = { ...x };
                                                // if (x.id <= 0) {
                                                //     newValue.id = 0;
                                                // }
                                                // if (entityType === Entity.Material) {
                                                //     (newValue as Partial<ITranslation>).materialId = changedEntity.id;
                                                // }

                                                return newValue;
                                            }) as never;
                                        }
                                    }
                                    else {
                                        changedEntity[field as keyof T] = entity[field as keyof T] as never;
                                    }
                                }
                                else {
                                    changedEntity[field as keyof T] = entity[field as keyof T] as never;
                                }
                            }
                        }
                        else {
                            /**
                             * Ideally code should not come here, if it comes here that means some configuration is 
                             * missing in "ProjectRequestFieldsByStepTab"
                             */
                            changedEntity = entity;
                        }

                        (projectRequestComposite[fieldKey] as Array<Partial<T>>).push(changedEntity)
                    }
                }
            }
        }
    }

    public static getChangedDataFromContext(selectedRequestStepTab: SelectedRequestStepTab, updatedProjectRequest: IProjectRequest, allEntityFieldInfos: Record<Entity, ObjectFieldInfo>): IProjectRequestPayload {
        let projectRequestComposite: IProjectRequestComposite = {};
        let materialsToDelete: number[] = [];
        let filesToDelete: number[] = [];
        let filesToAdd: File[] = [];

        if (updatedProjectRequest.isProjectMetadataChanged) {
            Util.addProjectInfoToCompositeRequest(selectedRequestStepTab, updatedProjectRequest, allEntityFieldInfos[Entity.ProjectRequest], projectRequestComposite);
        }

        const updatedMaterials = updatedProjectRequest.materials.filter(x =>
            x.id <= 0 || (x.id > 0 && (x.isDeleted || x.isUpdated || x.isAnyTranslationUpdated))
        );

        updatedMaterials.forEach((material: IMaterial) => {

            //Mark elements to delete
            if (material.isDeleted) {
                materialsToDelete.push(material.id);
            }
            else {
                //Add changed translations to update
                if (material.isAnyTranslationUpdated) {

                    material.translations?.filter(x => (x.isUpdated && x.id > 0) || x.id <= 0).forEach((translation: ITranslation) => {
                        //Add new translation to update only when material exists in DB otherwise add them through material heirarchy so that proper ids are assigned
                        Util.addEntityToCompositeRequest(selectedRequestStepTab, translation, Entity.Translation, allEntityFieldInfos[Entity.Translation] as ObjectFieldInfoTyped<ITranslation>, projectRequestComposite);
                    });
                }

                //Add changed/new material to update
                let anyFieldValueSpecified = false;

                if (material.id <= 0) {
                    anyFieldValueSpecified = Util.isAnyFieldSpecifiedInNewMaterial(material)
                }

                if ((anyFieldValueSpecified && material.id <= 0) || (material.id > 0 && material.isUpdated)) {
                    //For all step tabs where data has been changed create sectional data
                    Util.addEntityToCompositeRequest(selectedRequestStepTab, material, Entity.Material, allEntityFieldInfos[Entity.Material] as ObjectFieldInfoTyped<IMaterial>, projectRequestComposite);
                }
            }

        });

        updatedProjectRequest.attachments?.forEach((attachment: IAttachment) => {
            if (attachment.isDeleted) {
                filesToDelete.push(attachment.id);
            }
            else if (attachment.id <= 0) {
                filesToAdd.push(attachment.data);
                Util.addEntityToCompositeRequest(selectedRequestStepTab, {
                    ...attachment,
                    // id: 0,
                    data: null
                }, Entity.Attachment, allEntityFieldInfos[Entity.Attachment] as ObjectFieldInfoTyped<IAttachment>, projectRequestComposite);
            }
        });

        for (const key in projectRequestComposite) {
            const obj = projectRequestComposite[key as keyof typeof projectRequestComposite];
            if (Array.isArray(obj)) {
                for (const item of obj) {
                    if (item.id <= 0) {
                        item.id = 0;
                    }

                    const translations: ITranslation[] = (item as Partial<IMaterial>)?.translations;
                    if (translations?.length) {
                        for (const translation of translations) {
                            if (translation.id <= 0) {
                                translation.id = 0;
                                translation.materialId = 0;
                            }
                        }
                    }
                }
            }
            else {
                if (obj.id && obj.id <= 0) {
                    obj.id = 0;
                }
            }
        }

        const projectRequestPayload: IProjectRequestPayload = {
            id: updatedProjectRequest.id
        };

        if (Object.keys(projectRequestComposite).length) {
            projectRequestPayload.projectRequestComposite = projectRequestComposite;
        }

        if (materialsToDelete.length) {
            projectRequestPayload.materialsToDelete = materialsToDelete;
        }

        if (filesToAdd.length) {
            projectRequestPayload.filesToAdd = filesToAdd;
        }

        if (filesToDelete.length) {
            projectRequestPayload.filesToDelete = filesToDelete;
        }

        return projectRequestPayload;
    }

    public static getChangedMaterialEntityBasedOnStepTab(step: RequestStepType, tab: RequestStepTab, materials: IMaterial[]): Array<Partial<IMaterial>> {

        const changedMaterials: Array<Partial<IMaterial>> = [];

        for (const material of materials) {
            if (material.isUpdated) {
                let changedMaterial: Partial<IMaterial> = {};

                if (ProjectRequestFieldsByStepTab[step][tab].material) {
                    for (const field of ProjectRequestFieldsByStepTab[step][tab].material) {
                        changedMaterial[field] = material[field] as never;
                    }
                }
                else {
                    changedMaterial = material;
                }

                changedMaterials.push(changedMaterial);
            }

        }

        return changedMaterials;
    }

    public static checkIfProjectRequestPayloadEmpty(projectRequestPayload: IProjectRequestPayload): boolean {
        if (!projectRequestPayload || Object.keys(projectRequestPayload).length === 0) {
            return true;
        }

        if (
            (projectRequestPayload.projectRequestComposite && Object.keys(projectRequestPayload.projectRequestComposite).length) ||
            projectRequestPayload?.filesToAdd?.length ||
            projectRequestPayload?.filesToDelete?.length ||
            projectRequestPayload?.materialsToDelete?.length
        ) {
            return false;
        }

        return true;
    }

    public static getSectionKeyFromStepTab(step: RequestStepType, tab: RequestStepTab): DataLockSectionType {
        switch (step) {
            case RequestStepReverseMapping[RequestStep.Start]:
            case RequestStepReverseMapping[RequestStep.KickOff]:
                return `StartKickOff`;

            default:
                return `${step}-${tab}`;
        }
    }

    public static getStepTabFromSectionKey(sectionId: DataLockSectionType): { [step in RequestStepType]?: RequestStepTab[] } {
        switch (sectionId) {
            case "StartKickOff":
                return {
                    [RequestStepReverseMapping[RequestStep.Start]]: ["ProjectInfo", "ProductInfo", "Translations"],
                    [RequestStepReverseMapping[RequestStep.KickOff]]: ["PLMData", "LogisticsData", "CheckList"]
                };

            default:
                const [step, tab] = sectionId.split("-");
                return { [step]: [tab as RequestStepTab] };
        }
    }

    public static getCurrentUserLocksId(updatedProjectRequest: IProjectRequest, currentUserId: number): { ids: number[]; sectionIds: DataLockSectionType[] } {
        const ids: number[] = [];
        const sectionIds: DataLockSectionType[] = [];

        updatedProjectRequest?.dataLocks?.filter(x => x.lockedById === currentUserId)?.forEach(x => {
            ids.push(x.id);
            sectionIds.push(x.sectionId);
        });

        return { ids, sectionIds }
    }

    public static updateProjectRequestFromUpdatePayload(projectRequestPayload: IProjectRequestPayload, currentProjectRequest: IProjectRequest): IProjectRequest {

        if (projectRequestPayload?.dataLocksToDelete?.length) {
            currentProjectRequest.dataLocks = currentProjectRequest.dataLocks.filter(x => projectRequestPayload.dataLocksToDelete.indexOf(x.id) === -1);
        }

        if (projectRequestPayload?.filesToDelete?.length) {
            currentProjectRequest.attachments = currentProjectRequest.attachments.filter(x => projectRequestPayload.filesToDelete.indexOf(x.id) === -1);
        }

        if (projectRequestPayload?.materialsToDelete?.length) {
            currentProjectRequest.materials = currentProjectRequest.materials.filter(x => projectRequestPayload.materialsToDelete.indexOf(x.id) === -1);
        }

        if (projectRequestPayload?.projectRequestComposite) {
            if (projectRequestPayload.projectRequestComposite.attachments?.length) {
                const fixedData: IAttachment[] = projectRequestPayload.projectRequestComposite.attachments.
                    map(x => CommonService.fixTimeStampColumn(x)) as IAttachment[];
                currentProjectRequest.attachments.push(...fixedData);
            }

            if (projectRequestPayload.projectRequestComposite.auditLogs?.length) {
                const fixedData: IAuditLog[] = projectRequestPayload.projectRequestComposite.auditLogs as IAuditLog[];
                currentProjectRequest.auditLogs.push(...fixedData);
            }

            if (projectRequestPayload.projectRequestComposite.startTranslations?.length) {
                for (const translation of projectRequestPayload.projectRequestComposite.startTranslations) {
                    const material = currentProjectRequest.materials.find(x => x.id === translation.materialId);
                    if (material) {
                        const index = material.translations.findIndex(x => x.id === translation.id);
                        if (index >= 0) {
                            for (const key in translation) {
                                material.translations[index][key as keyof ITranslation] = translation[key as keyof ITranslation] as never;
                            }
                        }
                    }
                }

            }

            if (projectRequestPayload.projectRequestComposite.productMasterDataTextTranslations?.length) {
                for (const translation of projectRequestPayload.projectRequestComposite.productMasterDataTextTranslations) {
                    const material = currentProjectRequest.materials.find(x => x.id === translation.materialId);
                    if (material) {
                        const index = material.translations.findIndex(x => x.id === translation.id);
                        if (index >= 0) {
                            for (const key in translation) {
                                material.translations[index][key as keyof ITranslation] = translation[key as keyof ITranslation] as never;
                            }
                        }
                    }
                }

            }

            for (const key in projectRequestPayload.projectRequestComposite) {
                switch (key) {
                    case "projectRequestInfoStart":
                    case "projectRequestInfoPLMData":
                    case "projectRequestInfoLogisticsData":
                    case "projectRequestInfoCheckList":
                        const projectInfoObject: Partial<IProjectRequest> = projectRequestPayload.projectRequestComposite[key as keyof IProjectRequestComposite] as Partial<IProjectRequest>;
                        if (projectInfoObject) {
                            for (const field in projectInfoObject) {
                                currentProjectRequest[field as keyof IProjectRequest] = projectInfoObject[field as keyof IProjectRequest] as never;
                            }
                        }

                        break;


                    case "startMaterials":
                    case "plmDataMaterials":
                    case "logisticsDataMaterials":
                    case "packagingAndHandlingMaterials":
                    case "dangerousGoodsMaterials":
                    case "customsMaterials":
                    case "raMaterials":
                    case "controllingMaterials":
                    case "radbMaterials":
                        const materialObject: Partial<IMaterial>[] = projectRequestPayload.projectRequestComposite[key as keyof IProjectRequestComposite] as Partial<IMaterial>[];
                        if (materialObject) {
                            for (const material of materialObject) {
                                const index = currentProjectRequest.materials.findIndex(x => x.id === material.id);
                                if (index >= 0) {
                                    for (const field in material) {
                                        currentProjectRequest.materials[index][field as keyof IMaterial] = material[field as keyof IMaterial] as never;
                                    }
                                }
                            }
                        }

                        break;
                }
            }
        }

        return currentProjectRequest;
    }

    public static isAnyFieldSpecifiedInNewMaterial(material: IMaterial): boolean {
        let anyFieldValueSpecified = false;

        for (const field of StartFormFields.ProductInfo.material) {
            if (field !== "translations" && material[field] !== null && material[field] !== undefined) {
                anyFieldValueSpecified = true;
                break;
            }
        }

        return anyFieldValueSpecified;
    }
}