import { useDispatch } from "react-redux";
import { CommonService, HttpService } from "services";
import { IChoice, IHelpDocument, IMetadataConfig } from "interfaces";
import { showLoadingSpinner, hideLoadingSpinner, showErrorMessage, hideErrorMessage } from "components";
import { ErrorRegions } from "constant";
import { IFileInfo } from "ccs-azure";
import { MasterDataService } from "components/request/services/MasterDataService";

export class MetadataService {
  public apiEndpointMetadataConfig = `/api/MetadataConfig`;
  public apiEndpointMetadata = `/api/Metadata`;

  dispatch = useDispatch();

  public async getAllMetadataConfig(): Promise<IMetadataConfig[]> {
    this.dispatch(showLoadingSpinner());
    let order: IMetadataConfig[] = [];
    try {
      order = await HttpService.get(`${this.apiEndpointMetadataConfig}`);
      this.dispatch(hideLoadingSpinner());
    } catch (error) {
      this.dispatch(hideLoadingSpinner());
      this.dispatch(showErrorMessage({ error, errorOriginationKey: ErrorRegions.MetadataConfig }));
      setTimeout(() => {
        this.dispatch(hideErrorMessage(ErrorRegions.MetadataConfig));
      }, 5000);
    }
    return order;
  }

  public async getAllMasterTableData<T>(tableName: string): Promise<T[]> {
    return await HttpService.get(`${this.apiEndpointMetadataConfig}/${tableName}`);
  }

  public async getAllMasterDataFromDropdownList(fields: string[]): Promise<{ [key: string]: string[] }> {
    const allMasterData: { [key: string]: string[] } = {};
    const data = await HttpService.post(`${this.apiEndpointMetadata}/GetMasterDataForFields`, fields);

    for (const list of data as IChoice[]) {
      allMasterData[list.choiceKey] = list.choiceData.split("\n");
    }
    return allMasterData;
  }

  public async createMasterDataEntry(metaDataPayload: any) {
    this.dispatch(showLoadingSpinner());
    try {
      const response = await HttpService.post(`${this.apiEndpointMetadataConfig}`, metaDataPayload);
      this.dispatch(hideLoadingSpinner());
      return response;
    } catch (error) {
      this.dispatch(hideLoadingSpinner());
      this.dispatch(showErrorMessage({ error, errorOriginationKey: ErrorRegions.MetadataConfig }));
      setTimeout(() => {
        this.dispatch(hideErrorMessage(ErrorRegions.MetadataConfig));
      }, 5000);
      return null;
    }
  }

  public async updateMasterDataEntry(metaDataPayload: any) {
    this.dispatch(showLoadingSpinner());
    try {
      const response = await HttpService.put(`${this.apiEndpointMetadataConfig}`, metaDataPayload);
      this.dispatch(hideLoadingSpinner());
      return true;
    } catch (error) {
      this.dispatch(hideLoadingSpinner());
      this.dispatch(showErrorMessage({ error, errorOriginationKey: ErrorRegions.MetadataConfig }));
      setTimeout(() => {
        this.dispatch(hideErrorMessage(ErrorRegions.MetadataConfig));
      }, 5000);
      return false;
    }
  }

  public async deleteMultipleMasterDataEntry(tableName: string, ids: number[]) {
    this.dispatch(showLoadingSpinner());
    try {
      const response = await HttpService.delete(`${this.apiEndpointMetadataConfig}/${tableName}`, ids);
      this.dispatch(hideLoadingSpinner());
      return true;
    } catch (error) {
      this.dispatch(hideLoadingSpinner());
      this.dispatch(showErrorMessage({ error, errorOriginationKey: ErrorRegions.MetadataConfig }));
      setTimeout(() => {
        this.dispatch(hideErrorMessage(ErrorRegions.MetadataConfig));
      }, 5000);
      return false;
    }
  }

  public async downloadHelpDocumentById(file: IFileInfo) {
    const [filename, blob] = (await HttpService.get(`${this.apiEndpointMetadata}/DownloadHelpDocumentById/${Number(file.fileId)}`, true)) as [string, Blob];
    CommonService.downloadFile(blob, file.fileName);
  }

  public async downloadHelpDocumentByKey(key: string) {
    const [filename, blob] = (await HttpService.get(`${this.apiEndpointMetadata}/DownloadHelpDocumentByKey/${key}`, true)) as [string, Blob];
    CommonService.downloadFile(blob, filename);
  }

  public async createUpdateHelpDocumentEntry(helpDocument: IHelpDocument) {
    if (helpDocument.createdBy) {
      delete helpDocument.createdBy;
    }

    if (helpDocument.modifiedBy) {
      delete helpDocument.modifiedBy;
    }

    return await HttpService.post(`${this.apiEndpointMetadata}/HelpDocuments`, helpDocument);
  }

}
