import { useDispatch } from "react-redux";
import { HttpService } from "services";
import { IInvitation } from "interfaces";
import {
  showLoadingSpinner,
  hideLoadingSpinner,
  showErrorMessage,
  hideErrorMessage,
  showSuccessMessage,
  hideSuccessMessage,
} from "components";
import { ErrorRegions } from "constant";

export default class UserInvitationService {
  public apiEndpoint = `/api/Invitation`;
  dispatch = useDispatch();

  public async getAllInvitationData(): Promise<IInvitation[]> {
    this.dispatch(showLoadingSpinner());
    let order: IInvitation[] = [];
    try {
      order = await HttpService.get(`${this.apiEndpoint}`);
      this.dispatch(hideLoadingSpinner());
    } catch (error) {
      this.dispatch(hideLoadingSpinner());
      this.dispatch(showErrorMessage({ error, errorOriginationKey: ErrorRegions.Invitation }));
      setTimeout(() => {
        this.dispatch(hideErrorMessage(ErrorRegions.Invitation));
      }, 5000);
    }
    return order;
  }

  public async createInvitationEntry(invitation: IInvitation) {
    this.dispatch(showLoadingSpinner());
    try {
      const response = await HttpService.post(`${this.apiEndpoint}/Generate`, invitation);
      this.dispatch(hideLoadingSpinner());
      this.dispatch(showSuccessMessage({ successMessage: `External user invitation created for ${invitation.invitationEmail}` }));
      setTimeout(() => {
        this.dispatch(hideSuccessMessage());
      }, 5000);
      return response;
    } catch (error) {
      this.dispatch(hideLoadingSpinner());
      this.dispatch(showErrorMessage({ error, errorOriginationKey: ErrorRegions.Invitation }));
      setTimeout(() => {
        this.dispatch(hideErrorMessage(ErrorRegions.Invitation));
      }, 5000);
      return null;
    }
  }
}
