import { getTheme, mergeStyleSets, FontWeights, Modal, IIconProps } from '@fluentui/react';
import { IconButton, IButtonStyles } from '@fluentui/react/lib/Button';
import { TextConstants } from "constant";
import { useWindowResize } from 'hooks';
import { FAQView } from "./FAQView";

interface FAQPopUpViewProps {
    open: boolean;
    setModalOpen: () => void;
}

const cancelIcon: IIconProps = { iconName: 'Cancel' };

const theme = getTheme();

const contentStyles = mergeStyleSets({
    container: {
        display: 'flex',
        flexFlow: 'column nowrap',
        alignItems: 'stretch',
    },
    header: [
        theme.fonts.xxLarge,
        {
            flex: '1 1 auto',
            borderTop: `4px solid ${theme.palette.themePrimary}`,
            color: theme.palette.neutralPrimary,
            display: 'flex',
            alignItems: 'center',
            fontWeight: FontWeights.semibold,
            padding: '12px 12px 14px 24px',
        },
    ],
    heading: {
        color: theme.palette.neutralPrimary,
        fontWeight: FontWeights.semibold,
        fontSize: 'inherit',
        margin: '0',
    },
    body: {
        flex: '4 4 auto',
        padding: '0 24px 24px 24px',
        overflowY: 'hidden',
        selectors: {
            p: { margin: '14px 0' },
            'p:first-child': { marginTop: 0 },
            'p:last-child': { marginBottom: 0 },
        },
    },
});

const iconButtonStyles: Partial<IButtonStyles> = {
    root: {
        color: theme.palette.neutralPrimary,
        marginLeft: 'auto',
        marginTop: '4px',
        marginRight: '2px',
    },
    rootHovered: {
        color: theme.palette.neutralDark,
    },
};

export const FAQPopUpView = (props: FAQPopUpViewProps) => {
    const { setModalOpen, open } = props;
    const { width} = useWindowResize();
    return (
        <Modal
            titleAriaId={TextConstants.Label_FAQ}
            isOpen={open}
            onDismiss={setModalOpen}
            isBlocking={false}
            styles={{
                main: {
                    minWidth: width <= 768 ? '95%' : "80%"
                }
            }}
            containerClassName={contentStyles.container}
        >
            <div className={contentStyles.header}>
                <h2 className={contentStyles.heading}>
                    {TextConstants.Label_FAQ}
                </h2>
                <IconButton
                    styles={iconButtonStyles}
                    iconProps={cancelIcon}
                    ariaLabel="Close popup modal"
                    onClick={setModalOpen}
                />
            </div>
            <div className={contentStyles.body}>
                <FAQView />
            </div>
        </Modal>
    );
};
