import { createSlice } from "@reduxjs/toolkit";
import { IFAQList } from "interfaces/IFAQList";

const initialStateVal = [] as IFAQList[];

export const faqSlice = createSlice({
  name: "faqData",
  initialState: {
    value: initialStateVal,
  },
  reducers: {
    getFAQData: (state, action) => {
      state.value = action.payload;
    },
    updateFAQData: (state, action) => {
      let currentFAQData = [...state.value];
      let index = currentFAQData.findIndex((x) => x.id === action.payload.id);
      if (index !== -1) {
        currentFAQData[index].question = action.payload.question;
        currentFAQData[index].answer = action.payload.answer;
        currentFAQData[index].grouping = action.payload.grouping;
        state.value = currentFAQData;
      } else {
        state.value = [...currentFAQData, action.payload];
      }
    },
    deleteFAQData: (state, action) => {
      let currentFAQData = [...state.value];
      let index = currentFAQData.findIndex((x) => x.id === action.payload);
      currentFAQData.splice(index, 1);
      state.value = currentFAQData;
    },
    deleteMultipleFAQData: (state, action) => {
      let currentFAQData = [...state.value];
      currentFAQData = currentFAQData.filter((value) => !action.payload.includes(value.id));
      state.value = currentFAQData;
    },
  },
});

export const { updateFAQData, getFAQData, deleteFAQData, deleteMultipleFAQData } = faqSlice.actions;

export const faqReducer = faqSlice.reducer;
