import { IconButton, IIconProps, Modal } from "@fluentui/react";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useSearchParams } from "react-router-dom";
import { IHelperText } from "interfaces/IHelperText";
import styles from "../Admin.module.scss";
import HelpPageService from "./HelpPageService";
import { TextConstants } from "constant";

interface HelpViewPopUpProps {
  step: string;
  open: boolean;
  setModalOpen: () => void;
}

const cancelIcon: IIconProps = { iconName: "Cancel" };

export const HelpViewPopUp = (props: HelpViewPopUpProps) => {
  const value = useSelector((state: any) => state.helperData.value.filter((x: any) => String(x.key) === props.step));

  return (
    <Modal
      titleAriaId={TextConstants.Label_Help}
      isOpen={props.open}
      onDismiss={props.setModalOpen}
      isModeless={true}
      containerClassName={styles.modalMain}
      styles={{
        root: {
          zIndex: 999999,
        },
      }}
    >
      <div className={styles.modalHeader}>
        <span id="Add_Check_List_Entry">{TextConstants.Label_Help}</span>
        <IconButton
          iconProps={cancelIcon}
          ariaLabel="Close popup modal"
          onClick={props.setModalOpen}
          className={styles.btnBlack}
        />
      </div>
      {!!value[0] && <div dangerouslySetInnerHTML={{ __html: value[0]?.text }} />}
    </Modal>
  );
};
