import { useDispatch } from "react-redux";
import { HttpService } from "services";
import { IColorSchemeMatrix } from "interfaces";
import { showLoadingSpinner, hideLoadingSpinner, showErrorMessage, hideErrorMessage } from "components";
import { ErrorRegions } from "constant";

export default class ColorSchemeMatrixService {
  public apiEndpointMatrix: string = `/api/ColorSchemeMatrix`;
  dispatch = useDispatch();

  public async getAllColorSchemeMatrix(): Promise<IColorSchemeMatrix[]> {
    this.dispatch(showLoadingSpinner());
    let order: IColorSchemeMatrix[] = [];
    try {
      order = await HttpService.get(`${this.apiEndpointMatrix}`);
      this.dispatch(hideLoadingSpinner());
    } catch (error) {
      this.dispatch(hideLoadingSpinner());
      this.dispatch(showErrorMessage({ error, errorOriginationKey: ErrorRegions.ColorSchemeMatrix }));
      setTimeout(() => {
        this.dispatch(hideErrorMessage(ErrorRegions.ColorSchemeMatrix));
      }, 5000);
    }
    return order;
  }

  public async createColorSchemeMatrix(payload: any) {
    this.dispatch(showLoadingSpinner());
    try {
      const response = await HttpService.post(`${this.apiEndpointMatrix}`, payload);
      this.dispatch(hideLoadingSpinner());
      return response;
    } catch (error) {
      this.dispatch(hideLoadingSpinner());
      this.dispatch(showErrorMessage({ error, errorOriginationKey: ErrorRegions.ColorSchemeMatrix }));
      setTimeout(() => {
        this.dispatch(hideErrorMessage(ErrorRegions.ColorSchemeMatrix));
      }, 5000);
      return null;
    }
  }

  public async updateColorSchemeMatrix(payload: any) {
    this.dispatch(showLoadingSpinner());
    try {
      await HttpService.put(`${this.apiEndpointMatrix}`, payload);
      this.dispatch(hideLoadingSpinner());
      return true;
    } catch (error) {
      this.dispatch(hideLoadingSpinner());
      this.dispatch(showErrorMessage({ error, errorOriginationKey: ErrorRegions.ColorSchemeMatrix }));
      setTimeout(() => {
        this.dispatch(hideErrorMessage(ErrorRegions.ColorSchemeMatrix));
      }, 5000);
      return false;
    }
  }

  public async deleteMultipleColorSchemeMatrix(ids: number[]) {
    this.dispatch(showLoadingSpinner());
    try {
      await HttpService.delete(`${this.apiEndpointMatrix}`, ids);
      this.dispatch(hideLoadingSpinner());
      return true;
    } catch (error) {
      this.dispatch(hideLoadingSpinner());
      this.dispatch(showErrorMessage({ error, errorOriginationKey: ErrorRegions.ColorSchemeMatrix }));
      setTimeout(() => {
        this.dispatch(hideErrorMessage(ErrorRegions.ColorSchemeMatrix));
      }, 5000);
      return false;
    }
  }
}
