import {
  CommandBar, DetailsList, DetailsListLayoutMode, IColumn, ICommandBarItemProps, IconButton, IDetailsColumnProps, IDetailsHeaderProps, IDetailsListStyles, IDetailsRowProps, IRenderFunction, ITooltipHostProps, MarqueeSelection, Selection,
  SelectionMode, Stack, Text, TooltipHost,
} from "@fluentui/react";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useBoolean } from "@fluentui/react-hooks";
import { Link, useNavigate } from "react-router-dom";
import { RootState } from "store";
import styles from "../Admin.module.scss";
import HelpPageService from "./HelpPageService";
import { ConfirmBox } from "components/common";
import { TextConstants , ApplicationRoutes} from "constant";
import { RequestStep, RequestStepType } from "interfaces";
import { useWindowResize } from "hooks";
import { AdminPageContainer } from "../AdminPageContainer";
import {
  renderClickableRowWithMultipleSelectionEnabled,
} from "services";

type Props = {};

const editSolid12 = { iconName: "EditSolid12" };
const viewIcon = { 
  iconName: "View",
  className: "viewpagehelper"
};

const HelpPageList = (props: Props) => {
  let helpPageService = new HelpPageService();
  const dispatch = useDispatch();
  let navigate = useNavigate();

  const [hideDialog, { toggle: toggleHideDialog }] = useBoolean(true);
  const [deleteDialogResult, setDeleteDialogResult] = useState(false);
  const helperPages = useSelector((state: RootState) => state.helperData.value);

  const [selectedItems, setSelectedItems] = useState<number[]>();

  const { height } = useWindowResize();

  const selection = useMemo(
    () =>
      new Selection({
        onSelectionChanged: () => {
          setSelectedItems(selection.getSelection().map((i: any) => i.id));
        },
        selectionMode: SelectionMode.multiple,
      }),
    []
  );

  async function fetchHelpPageData() {
    await helpPageService.getHelperPage(true);
  }

  const deleteItem = () => {
    toggleHideDialog();
  };

  async function deleteMultipleHelpPage() {
    await helpPageService.deleteMultipleHelperPage(selectedItems ? selectedItems : []);
    setSelectedItems([]);
  }

  useEffect(() => {
    if (deleteDialogResult) {
      deleteMultipleHelpPage();

      setDeleteDialogResult(false);
    }
  }, [deleteDialogResult]);

  const columns: IColumn[] = [
    {
      key: "column0",
      name: TextConstants.Label_ID,
      fieldName: "id",
      minWidth: 10,
      maxWidth: 100,
      flexGrow: 0,
      isResizable: false,
      data: "string",
      //onColumnClick: this._onColumnClick,
      isPadded: true,
    },
    {
      key: "column1",
      name: TextConstants.Admin.Label_Help_Page_Step,
      fieldName: "key",
      minWidth: 300,
      flexGrow: 80,
      isResizable: false,
      data: "string",
      isPadded: true,
      onRender: (item: any) => {
        return <span>{RequestStep[item.key as RequestStepType]}</span>;
      },
    },
    {
      key: "column3",
      name: TextConstants.Label_View,
      fieldName: "view",
      minWidth: 70,
      maxWidth: 90,
      isResizable: false,
      data: "action",
      isPadded: true,
    },
  ];

  const addNewHelperPage = () => {
    navigate(ApplicationRoutes.HelpPageEditor);
  };

  const _cmditems: ICommandBarItemProps[] = [
    {
      key: "newItem",
      text: TextConstants.Label_Add_Item,
      iconProps: { iconName: "Add" },
      ariaLabel: "New",
      onClick: addNewHelperPage,
    },
    {
      key: "deleteItem",
      text: TextConstants.Label_Delete,
      iconProps: { iconName: "Delete" },
      ariaLabel: "Delete",
      onClick: deleteItem,
      disabled: !selectedItems?.length,
    },
  ];

  const setSelectedItem = (i: any) => {
    navigate(ApplicationRoutes.HelpPagePreview + "?key=" + i.key);
  };

  const onClickRow = useCallback(async (event: Event, item: any) => {
    if ((event.target as HTMLDivElement).className.indexOf('viewpagehelper') === -1) {
      navigate(ApplicationRoutes.HelpPageEditor+"?key=" + item.key);
    }
  }, []);

  const onCellClick = useCallback((item: any) => () => {
    navigate(ApplicationRoutes.HelpPagePreview +"?key=" + item.key);    
  }, []);

  const onRenderRow = useCallback((props: IDetailsRowProps, defaultRender?: IRenderFunction<IDetailsRowProps>): JSX.Element => {
    return renderClickableRowWithMultipleSelectionEnabled(props, onClickRow, defaultRender);
  }, [onClickRow]);

  const onRenderColumn = useCallback((item?: any, index?: number, column?: IColumn) => {
    const fieldName = column.fieldName;

    switch (fieldName) {
      case "view":
        return (
           <IconButton iconProps={viewIcon} ariaLabel="view" className={styles.btnHeightUnset} style={{ marginRight: "30px" }}
            onClick={onCellClick(item)} />
        );

      default:
        return item[fieldName];
    }
  }, [onCellClick])

  

  useEffect(() => {
    fetchHelpPageData();
  }, [dispatch]);

  const onRenderDetailsHeader = useCallback((props: IDetailsHeaderProps, defaultRender: (props?: IDetailsHeaderProps) => JSX.Element | null) => {
    if (!props) {
      return null;
    }
    const onRenderColumnHeaderTooltip: IRenderFunction<ITooltipHostProps> = tooltipHostProps => (
      <TooltipHost {...tooltipHostProps} />
    );
    return (
      <div className={styles.stickyHeaderTable}>
        {defaultRender!({
          ...props,
          onRenderColumnHeaderTooltip,
        })}
      </div>
    );
  }, [])

  return (
    <AdminPageContainer pageTitle={TextConstants.Admin.SectionHeading_Manage_Help_Pages} >
      <ConfirmBox
        title={TextConstants.Label_Delete}
        subtext={TextConstants.Admin.Delete_Message_Help_Page}
        open={hideDialog}
        setModalOpen={toggleHideDialog}
        setDeleteDialogResult={setDeleteDialogResult}
      />
      <Stack horizontal horizontalAlign="space-between" verticalAlign="center" tokens={{ childrenGap: 10 }}>
        <CommandBar
          styles={{ root: { padding: 0 } }}
          items={_cmditems}
          ariaLabel="Use left and right arrow keys to navigate between commands"
        />
      </Stack>
      <div style={{ maxHeight: 'calc(100vh - 289px)' }} className={styles.detailListContainer} data-is-scrollable="true">
        <MarqueeSelection selection={selection}>
          <DetailsList
            items={helperPages}
            compact={true}
            columns={columns}
            styles={{
              headerWrapper: {
                position: "sticky",
                top: 0,
                zIndex: 1
              }
            }}
            getKey={(i) => i.key}
            onRenderDetailsHeader={onRenderDetailsHeader}
            layoutMode={DetailsListLayoutMode.justified}
            isHeaderVisible={true}
            selection={selection}
            selectionPreservedOnEmptyClick={true}
            onRenderRow={onRenderRow}
            onRenderItemColumn={onRenderColumn}
          />
        </MarqueeSelection>
      </div>
    </AdminPageContainer>
  );
};

export default HelpPageList;
