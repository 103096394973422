import { Stack, css, CommandBarButton, TextField, DetailsList, IColumn, IconButton, TooltipHost, SelectionMode, IDialogProps, DialogType, Selection, IDetailsRowProps, IRenderFunction } from "@fluentui/react";
import { hideLoadingSpinner, showErrorMessage, showLoadingSpinnerWithMessage } from "components";
import { TextConstants } from "constant";
import { IUserGroupMembership } from "interfaces";
import { useCallback, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { CommonService, renderClickableRowWithMultipleSelectionEnabled } from "services";
import styles from "./GroupManagement.module.scss";
import { GroupService } from "./GroupService";

export interface IGroupMemberListProps {
    userGroupMemberships: IUserGroupMembership[];
    onUpdateMembership?: (updatedUserMemberships: IUserGroupMembership[]) => void;
}

const dialogProps: IDialogProps = {
    dialogContentProps: {
        type: DialogType.normal,
        title: TextConstants.ManageGroupForm.Confirmation_Message_RemoveUsersFromGroup,
    },
    modalProps: {
        isBlocking: false,
    },
};

export const GroupMemberList: React.FC<IGroupMemberListProps> = ({ userGroupMemberships: userMemberships, onUpdateMembership }: IGroupMemberListProps) => {

    const selection: Selection = new Selection({
        onSelectionChanged: () => {
            console.log("selectioncalled");
            const selectedMembers = selection.getSelection() as IUserGroupMembership[];
            setSelectedMembers(selectedMembers);
        },
    });

    const dispatch = useDispatch();
    const [allUserMemberships, setAllUserMemberships] = useState<IUserGroupMembership[]>(userMemberships);
    const [currentUserMemberships, setCurrentUserMemberships] = useState<IUserGroupMembership[]>(userMemberships);
    const [selectedMembers, setSelectedMembers] = useState<IUserGroupMembership[]>(null);
    const [filter, setFilter] = useState<string>(null);


    const deleteSelectedUserMemberships = async () => {
        if (selectedMembers?.length) {
            try {
                const selectedMemberIds = selectedMembers.map(x => x.id);
                dispatch(showLoadingSpinnerWithMessage(TextConstants.ManageGroupForm.Message_RemovingUsersFromGroup));

                const result = await GroupService.removeGroupMemberships(selectedMemberIds);
                if (result) {
                    let newUserMemberships = [...allUserMemberships];
                    newUserMemberships = newUserMemberships.filter(x => selectedMemberIds.indexOf(x.id) === -1);
                    setAllUserMemberships(newUserMemberships);
                    setSelectedMembers(null);
                    onUpdateMembership?.(newUserMemberships);
                }

            } catch (error) {
                dispatch(
                    showErrorMessage({
                        error: error,
                    })
                );
            }
            dispatch(hideLoadingSpinner());
        }
    };

    const deleteUserMembership = async (userMembershipId: number) => {
        try {
            dispatch(showLoadingSpinnerWithMessage(TextConstants.ManageGroupForm.Message_RemovingUsersFromGroup));

            const result = await GroupService.removeGroupMembership(userMembershipId);
            if (result) {
                let newUserMemberships = [...allUserMemberships];
                newUserMemberships = newUserMemberships.filter(x => x.id !== userMembershipId);
                setAllUserMemberships(newUserMemberships);
                onUpdateMembership?.(newUserMemberships);
            }
        } catch (error) {
            dispatch(
                showErrorMessage({
                    error: error,
                })
            );
        }

        dispatch(hideLoadingSpinner());
    }

    const onClickDeleteUser = (userMembershipId: number) => () => {
        const onConfirmCallback = () => {
            deleteUserMembership(userMembershipId);
        };

        CommonService.showConfirmationDialog(
            { ...dialogProps },
            TextConstants.Common.Label_Yes,
            TextConstants.Common.Label_No,
            onConfirmCallback
        );
    }

    const onClickDeleteUsers = () => {

        const onConfirmCallback = () => {
            deleteSelectedUserMemberships();
        };

        CommonService.showConfirmationDialog(
            { ...dialogProps },
            TextConstants.Common.Label_Yes,
            TextConstants.Common.Label_No,
            onConfirmCallback
        );
    }

    const onChangeFilter = (ev: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>, newFilter: string): void => {
        setFilter(newFilter);
        let filteredItems: IUserGroupMembership[] = [];

        if (newFilter) {
            newFilter = newFilter.toLowerCase();
            filteredItems = allUserMemberships.filter(i => i.user.displayName.toLowerCase().indexOf(newFilter) > -1);
        }
        else {
            filteredItems = [...allUserMemberships];
        }

        const currentSortedColumns: IColumn = columns.find(x => x.isSorted);

        if (currentSortedColumns) {
            filteredItems = _copyAndSort(filteredItems, currentSortedColumns.fieldName!, currentSortedColumns.isSortedDescending);
        }
        setCurrentUserMemberships(filteredItems);
    };

    const onColumnHeaderClick = useCallback((ev?: React.MouseEvent<HTMLElement, MouseEvent>, column?: IColumn) => {
        const items = currentUserMemberships;
        const newColumns: IColumn[] = columns.slice();
        const currColumn: IColumn = newColumns.filter(currCol => column.key === currCol.key)[0];
        newColumns.forEach((newCol: IColumn) => {
            if (newCol === currColumn) {
                currColumn.isSortedDescending = !currColumn.isSortedDescending;
                currColumn.isSorted = true;
            } else {
                newCol.isSorted = false;
                newCol.isSortedDescending = true;
            }
        })
        const newItems = _copyAndSort(items, currColumn.fieldName!, currColumn.isSortedDescending);
        setColumns(newColumns);
        setCurrentUserMemberships(newItems);
    }, [currentUserMemberships]);

    const getInitialListColumns = (): IColumn[] => [
        {
            key: "user",
            name: TextConstants.ManageGroupForm.Label_Table_Column_User,
            fieldName: "user",
            isSorted: true,
            isSortedDescending: false,
            minWidth: 200,
            isResizable: true,
            className: styles.listColumn,
            onColumnClick: onColumnHeaderClick
        },
        {
            key: "delete",
            name: undefined,
            fieldName: "delete",
            isSorted: false,
            isSortedDescending: false,
            minWidth: 50,
            isResizable: false,
            className: styles.listColumn,
        },
    ];

    const [columns, setColumns] = useState<IColumn[]>(() => getInitialListColumns());

    const onRenderRow = (props: IDetailsRowProps, defaultRender?: IRenderFunction<IDetailsRowProps>): JSX.Element => {
        return renderClickableRowWithMultipleSelectionEnabled(props, null, defaultRender);
    }

    useEffect(() => {
        let filteredItems = [];
        setAllUserMemberships(userMemberships);

        if (filter) {
            const newFilter = filter.toLowerCase();
            filteredItems = userMemberships.filter(i => i.user.displayName.toLowerCase().indexOf(newFilter) > -1);
        }
        else {
            filteredItems = userMemberships;
        }

        const currentSortedColumns: IColumn = columns.find(x => x.isSorted);

        if (currentSortedColumns) {
            filteredItems = _copyAndSort(filteredItems, currentSortedColumns.fieldName!, currentSortedColumns.isSortedDescending);
        }

        setCurrentUserMemberships(filteredItems);

    }, [userMemberships])

    useEffect(() => {
        const exitingColumns: IColumn[] = columns.slice();

        const newColumns = getInitialListColumns();
        newColumns[0].isSorted = exitingColumns[0].isSorted;
        newColumns[0].isSortedDescending = exitingColumns[0].isSortedDescending;
        newColumns[0].onColumnClick = onColumnHeaderClick;
        setColumns(newColumns);
    }, [onColumnHeaderClick])

    const onRenderItemColumn = (item?: IUserGroupMembership, index?: number, column?: IColumn) => {
        switch (column.fieldName) {
            case "delete":
                return (
                    <IconButton
                        className={styles.deleteButton}
                        iconProps={{ iconName: "Delete", className: styles.deleteButton }}
                        onClick={onClickDeleteUser(item.id)}
                    />
                );

            case "user":
                return (
                    <TooltipHost
                        content={item.user.email}
                        id={"tooltipUser"}
                        calloutProps={{ gapSpace: 0 }}
                        styles={{ root: { display: "inline-block" } }}
                    >
                        {item.user.displayName}
                    </TooltipHost>
                );

            default:
                return item[column.fieldName as keyof IUserGroupMembership];
        }
    }

    return (
        <Stack className={styles.groupMembersContainer}>
            <h2 className={css(styles.groupMembersLabel, "subHeading1")}>
                {TextConstants.ManageGroupForm.Heading_Section_GroupMembers}
            </h2>
            <Stack className={styles.actionBar} horizontal horizontalAlign="space-between">
                <CommandBarButton
                    className={styles.blackButton}
                    iconProps={{ iconName: "Delete", className: styles.blackButton }}
                    disabled={!selectedMembers?.length}
                    text={TextConstants.Common.Label_Button_Delete}
                    onClick={onClickDeleteUsers}
                />
                <TextField
                    className=""
                    value={filter}
                    label={TextConstants.Common.Label_Search}
                    styles={{
                        wrapper: {
                            display: "flex",
                            justifyContent: "space-between",
                            alignItems: "center",
                        },
                        fieldGroup: {
                            marginLeft: "10px",
                        },
                    }}
                    onChange={onChangeFilter}
                />
            </Stack>
            <div className={styles.memberTableContainer}>
                <DetailsList
                    items={currentUserMemberships ?? []}
                    columns={columns}
                    getKey={(item, index) => item.Id}
                    selection={selection}
                    checkboxCellClassName={styles.listSelectionChekbox}
                    selectionMode={SelectionMode.multiple}
                    selectionPreservedOnEmptyClick={true}
                    onRenderRow={onRenderRow}
                    onRenderItemColumn={onRenderItemColumn}
                />
            </div>
        </Stack>
    );
}

function _copyAndSort(items: IUserGroupMembership[], columnKey: string, isSortedDescending?: boolean): IUserGroupMembership[] {
    const key = columnKey as keyof IUserGroupMembership;
    switch (key) {
        case "user":
            return items.slice(0)
                .sort((a: IUserGroupMembership, b: IUserGroupMembership) =>
                ((isSortedDescending ? a[key].displayName < b[key].displayName :
                    a[key].displayName > b[key].displayName) ? 1 : -1));

        default:
            return items.slice(0)
                .sort((a: IUserGroupMembership, b: IUserGroupMembership) =>
                    ((isSortedDescending ? a[key] < b[key] : a[key] > b[key]) ? 1 : -1));
    }

}