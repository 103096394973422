import { IOutcomeDefinition } from "ccs-azure";

export enum AssignmentPrincipalType {
    User = 1,
    Group
}

export type TaskActionType = "Complete" | "Approve" | "Reject";

export const OutcomeDefinitions: { [key in TaskActionType]: IOutcomeDefinition } = {
    Complete: {
        key: 'Complete',
        attribute: 'Completed',
        displayName: 'Complete',
        orderId: 1
    },
    Approve: {
        key: 'Approve',
        attribute: 'Approved',
        displayName: 'Approve',
        orderId: 1
    },
    Reject: {
        key: 'Reject',
        attribute: 'Rejected',
        displayName: 'Reject',
        orderId: 2
    }
}