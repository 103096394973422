import { createSlice } from "@reduxjs/toolkit";
import { ICheckListItem } from "interfaces/ICheckListItem";

const initialStateVal = [
  {
    id: 1,
    checked: true,
    description: "Can we go to the park.",
    comments: " ",
  },
] as ICheckListItem[];

export const checkListSlice = createSlice({
  name: "checkList",
  initialState: {
    value: initialStateVal,
  },
  reducers: {
    updateCheckList: (state, action) => {
      state.value = action.payload;
    },
    updateCheckListItemComments: (state, action) => {
      let currentCheckList = [...state.value];
      let index = currentCheckList.findIndex((x) => x.id === action.payload.id);
      currentCheckList[index].comments = action.payload.comment;
      state.value = currentCheckList;
    },
    updateCheckListItemCheck: (state, action) => {
      let currentCheckList = [...state.value];
      let index = currentCheckList.findIndex((x) => x.id === action.payload.id);
      currentCheckList[index].checked = action.payload.isChecked;
      state.value = currentCheckList;
    },
  },
});

export const { updateCheckList, updateCheckListItemComments, updateCheckListItemCheck } = checkListSlice.actions;

export const checkListReducer = checkListSlice.reducer;
