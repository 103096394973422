import { FieldInfoTyped, IBase, IProjectRequest, IUser, RequestStepTabCombinationKey } from "interfaces";

export interface IMaterial extends IBase {
    projectRequestId?: number;
    projectRequest?: IProjectRequest;
    materialNumber?: string;
    productDescription?: string;
    hasExpiryDate?: boolean;
    legalManufacturer?: string;
    workpackageOrbit?: string;
    projectNumber?: string;
    productHierarchy?: string;
    brand?: string;
    gtin?: number;
    hibc?: string;
    materialType?: string;
    configType?: string;
    phaseStatus?: string;
    batchManaged?: boolean;
    hasSerialNumber?: boolean;
    cogsPlant?: string;
    primaryPackage?: boolean;
    contains?: string;
    grossWeight?: number;
    length?: string;
    width?: string;
    height?: string;
    volume?: string;
    specialTemperatureRequired?: boolean;
    minStorageTemperature?: number;
    maxStorageTemperature?: number;
    maxTimeTransport?: number;
    frostSensitive?: boolean;
    otherRequirements?: string;
    airFreightComp?: boolean;
    temperatureLoggerRequired?: boolean;
    limitedQty?: boolean;
    dgClassADR?: string;
    unNumber?: string;
    containsBattery?: boolean;
    medicalDevice?: boolean;
    otc?: boolean;
    rxOnly?: boolean;
    cndCode?: string;
    euRule?: string;
    euClass?: string;
    ecCertificateNo?: string;
    ecDeclarationName?: string;
    gmdnCode?: string;
    validFrom?: string;
    techBHinicalFile?: string;
    localRegistration?: boolean;
    sterile?: boolean;
    forSingleUse?: boolean;
    sterilizable?: boolean;
    sterilizationMethod?: string;
    rawMaterials?: string;
    ifuNumber?: string;
    countryOfOrigin?: string;
    customsTariffCode?: string;
    prH4?: string;
    prH5?: string;
    basicUDIDI?: string;
    unitOfUseDI?: string;
    gmdn?: string;
    emdnCode?: string;
    needSterilization?: boolean;
    mriStatus?: string;
    kit?: boolean;
    comboProduct?: boolean;
    dmApplicable?: boolean;
    dmApplied?: boolean;
    diameter?: string;
    gingivaHeight?: string;
    abutmentHeight?: string;
    angle?: string;
    granuleSize?: string;
    mdamdnCode?: string;
    mdsCodeI?: string;
    mdsCodeII?: string;
    mdsCodeIII?: string;
    mdtCodeI?: string;
    mdtCodeII?: string;
    mdtCodeIII?: string;
    ecCertificateReceived?: boolean;
    euComplianceLegislation?: string;
    itemCategoryApplies?: string;
    safetyDataSheetReady?: boolean;
    countriesAlreadyRegistered?: string;
    countriesWhereUnderRegisteration?: string;
    sterilizationByUser?: boolean;
    ifuAvailable?: boolean;
    labOffice?: string;
    bomItems?: IBOMItem[];
    translations?: ITranslation[];
    //lockInfo?: IDataLockInfo;
    isUpdated?: boolean;
    isAnyTranslationUpdated?: boolean;
    isDeleted?: boolean;
}

export type MaterialFields = keyof IMaterial;

export interface IBOMItem extends IBase {
    projectRequestId?: number;
    materialId?: number;
    item?: string;
    itemCategory?: string;
    fixedQty?: string;
    component?: string;
    shortText?: string;
    componentQty?: Number;
    baseUnitOfMeasure?: string;
    productionRelevant?: string;
    constructionRelevant?: string;
    costingRelevant?: string;

    isMainComponent?: boolean;
    isLMS?: boolean;
    isIFU?: boolean;

    isUpdated?: boolean;
    isDeleted?: boolean;
}

export type BOMItemFields = keyof IBOMItem;

export interface ITranslation extends IBase {
    projectRequestId?: number;
    materialId?: number;
    material?: IMaterial;
    productMasterText?: string;
    shortDescription?: string;
    masterDescriptionLine1?: string;
    masterDescriptionLine2?: string;
    masterDescriptionLine3?: string; 
    masterDescriptionLine4?: string;   
    languageCode: string;
    isUpdated?: boolean;
}

export type TranslationFields = keyof ITranslation;

export interface IDataLockInfo {
    id?: number;
    projectRequestId: number;
    entityId?: number;
    lockedById: number;
    lockedBy?: IUser;
    lockedSince?: Date;
    expires?: Date;
    sectionId?: DataLockSectionType;
}

export enum DataLockSection {
    BOMItems = "BOMItems",
    // tbd: all lock sections
}

export type DataLockSectionType = DataLockSection | RequestStepTabCombinationKey | "StartKickOff";

export type MaterialFieldInfoType = { [key in keyof IMaterial]: FieldInfoTyped<IMaterial> };
export type TranslationFieldInfoType = { [key in keyof ITranslation]: FieldInfoTyped<ITranslation> };
export type BOMItemFieldInfoType = { [key in keyof IBOMItem]: FieldInfoTyped<IBOMItem> };