import { ProjectRequestTask } from "interfaces"

export const ChartTaskColors: {[key in ProjectRequestTask]?: string} = {
    [ProjectRequestTask.BOMSAPLoad]: 'rgb(94,94,94)',
    [ProjectRequestTask.CAMDbRouting]: 'rgb(155,124,255)',
    [ProjectRequestTask.Controlling]: 'rgb(127, 127, 127)',
    [ProjectRequestTask.Customs]: 'rgb(12,89,148)',
    [ProjectRequestTask.CustomsEI]: 'rgb(0,64,114)',
    [ProjectRequestTask.CustomsEIReview]: 'rgb(108,151,183)',
    [ProjectRequestTask.DangerousGoods]: 'rgb(213,213,213)',
    [ProjectRequestTask.DangerousGoodsEI]: 'rgb(106,60,255)',
    [ProjectRequestTask.DangerousGoodsEIReview]: 'rgb(106,60,255)',
    [ProjectRequestTask.EShop]: 'rgb(122,225,255)',
    [ProjectRequestTask.EngineeringCollection]: 'rgb(34,201,252)',
    [ProjectRequestTask.IFUCollection]: 'rgb(66,21,213)',
    [ProjectRequestTask.ItemCreation]: 'rgb(27,165,208)',
    [ProjectRequestTask.KickOff]: 'rgb(21,124,155)',
    [ProjectRequestTask.Labeling]: 'rgb(165, 217, 255)',
    [ProjectRequestTask.LabelingCollection]: 'rgb(97,187,255)',
    [ProjectRequestTask.Logistics]: 'rgb(10,149,255)',
    [ProjectRequestTask.PM]: 'rgb(10,67,255)',
    [ProjectRequestTask.PackagingCollection]: 'rgb(4,122,213)',
    [ProjectRequestTask.PackagingAndHandling]: 'rgb(108,62,188)',
    [ProjectRequestTask.PackagingAndHandlingReview]: 'rgb(77,47,131)',
    [ProjectRequestTask.Picture]: 'rgb(55,151,171)',
    [ProjectRequestTask.ProductMasterDataCheck]: 'rgb(136,160,208)',
    [ProjectRequestTask.RA]: 'rgb(11,60,159)',
    [ProjectRequestTask.RADB]: 'rgb(121,166,255)',
    [ProjectRequestTask.RAChecklist]: 'rgb(60,87,142)',
    [ProjectRequestTask.RAReview]: 'rgb(108,128,168)',
    [ProjectRequestTask.Resubmit]: 'rgb(152,223,249)',
    [ProjectRequestTask.TemperatureSensitive]: 'rgb(64,128,255)',
    [ProjectRequestTask.Translations]: 'rgb(18,83,213)',
}