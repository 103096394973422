const numberFormat: Intl.NumberFormat = Intl.NumberFormat(navigator.language);

const localeNumberFormatParts: Intl.NumberFormatPart[] = numberFormat.formatToParts(12345.13);

export const localeNumberFormatPartsIdentifiers = {
    group: localeNumberFormatParts.find(part => part.type === 'group').value,
    decimal: localeNumberFormatParts.find(part => part.type === 'decimal').value,
}

export const parseLocalizedNumber = (value: string, locale?: string) => {
    let localeNumberFormatPartsIdentifiersByLocale = localeNumberFormatPartsIdentifiers;

    if (locale) {
        const numberFormat: Intl.NumberFormat = Intl.NumberFormat(locale);

        const localeNumberFormatParts: Intl.NumberFormatPart[] = numberFormat.formatToParts(12345.13);

        localeNumberFormatPartsIdentifiersByLocale = {
            group: localeNumberFormatParts.find(part => part.type === 'group').value,
            decimal: localeNumberFormatParts.find(part => part.type === 'decimal').value,
        }
    }

    let { decimal } = localeNumberFormatPartsIdentifiersByLocale;

    const cleanPattern = new RegExp(`[^-+0-9${decimal}]`, 'g')
    const cleaned = value.replace(cleanPattern, '');
    const normalized = cleaned.replace(decimal, '.');

    return parseFloat(normalized);
}

export const validateLocalizedNumber = (value: string, locale?: string) => {
    if (value !== null && value !== undefined) {
        let localeNumberFormatPartsIdentifiersByLocale = localeNumberFormatPartsIdentifiers;

        if (locale) {
            const numberFormat: Intl.NumberFormat = Intl.NumberFormat(locale);

            const localeNumberFormatParts: Intl.NumberFormatPart[] = numberFormat.formatToParts(12345.13);

            localeNumberFormatPartsIdentifiersByLocale = {
                group: localeNumberFormatParts.find(part => part.type === 'group').value,
                decimal: localeNumberFormatParts.find(part => part.type === 'decimal').value,
            }
        }

        let { decimal, group } = localeNumberFormatPartsIdentifiersByLocale;

        decimal = new RegExp('\\s').test(decimal) ? '\\s' : decimal;
        group = new RegExp('\\s').test(group) ? '\\s' : group;

        const testPattern = new RegExp(`[^-+0-9${decimal}${group}]`, 'g');

        if (value.indexOf(decimal) !== value.lastIndexOf(decimal) || value.indexOf(decimal) < value.lastIndexOf(group))
            return false;

        return !testPattern.test(value);
    }
    else {
        return true;
    }
}

export const validateLocalizedInteger = (value: string, locale?: string) => {
    if (value !== null && value !== undefined) {
        let localeNumberFormatPartsIdentifiersByLocale = localeNumberFormatPartsIdentifiers;

        if (locale) {
            const numberFormat: Intl.NumberFormat = Intl.NumberFormat(locale);

            const localeNumberFormatParts: Intl.NumberFormatPart[] = numberFormat.formatToParts(12345.13);

            localeNumberFormatPartsIdentifiersByLocale = {
                group: localeNumberFormatParts.find(part => part.type === 'group').value,
                decimal: localeNumberFormatParts.find(part => part.type === 'decimal').value,
            }
        }

        let { decimal, group } = localeNumberFormatPartsIdentifiersByLocale;

        decimal = new RegExp('\\s').test(decimal) ? '\\s' : decimal;
        group = new RegExp('\\s').test(group) ? '\\s' : group;

        const testPattern = new RegExp(`[^-+0-9${decimal}${group}]`, 'g');

        if (value.indexOf(decimal) >= 0)
            return false;

        return !testPattern.test(value);
    }
    else {
        return true;
    }
}